import React from 'react';
import {
    Form,
    Row,
    Col,
    Radio,
    Space,
    Select,
    Checkbox,
    Table,
    Button,
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ReactExport from 'react-export-excel';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../../utils/functions';

export default function TrialBalanceForm(props) {
    // Destructuring
    const {
        mode,
        branches,
        viewValues: values,
        onValuesChange,
        mainAccounts,
        subAccounts,
        report,
        isLoading,
        onDateChange,
        onDateKeyUp,
    } = props;

    // NEPALI DATEPICKER
    // From date
    const fromDateInput = document.getElementById('fromDate');
    fromDateInput &&
        fromDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, fromDateInput, mode)
        );
    // Till date
    const tillDateInput = document.getElementById('tillDate');
    tillDateInput &&
        tillDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, tillDateInput, mode)
        );

    // DATA SOURCE - START
    const dataSource = report?.length
        ? [
              ...report
                  .filter(item =>
                      values?.hideZeros ? item.closingBalance : item
                  )
                  .map(({ itemName, itemCode, closingBalance }, i) => {
                      return {
                          sn: i + 1,
                          itemName,
                          itemCode,
                          balance: closingBalance,
                      };
                  }),
              ...(values?.showSubTotals
                  ? [
                        {
                            sn: '',
                            itemName: 'TOTAL',
                            itemCode: '',
                            balance: report
                                .reduce(
                                    (prevVal, curVal) =>
                                        prevVal + +curVal.closingBalance,
                                    0
                                )
                                .toFixed(2),
                        },
                    ]
                  : []),
          ]
        : [];

    const columns = [
        {
            title: 'S.N',
            dataIndex: 'sn',
            key: 'sn',
            width: '80px',
            render: (text, data) => {
                if (data.itemName === 'TOTAL')
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
                else if (!data.itemCode || !data.itemName)
                    return <div className="ant-table-cell-link">{text}</div>;
                else
                    return (
                        <Link
                            to={
                                values?.level === 'accountLevel'
                                    ? {
                                          pathname: '/item-wise-ledger',
                                          search: `mano=${values.mano}&maName=${
                                              values.maName
                                          }&acno=${values.acno}&acName=${
                                              values.acName
                                          }&itemCode=${
                                              data.itemCode
                                          }&itemName=${
                                              data.itemName
                                          }&tillDate=${moment(
                                              values.tillDate
                                          ).format('YYYY-MM-DD')}`,
                                      }
                                    : {
                                          pathname: 'sub-account-balance',
                                          search: `acno=${values?.acno}&level=accountLevel`,
                                      }
                            }
                        >
                            {text}
                        </Link>
                    );
            },
        },
        {
            title: 'Item name',
            dataIndex: 'itemName',
            key: 'itemName',
            render: (text, data) => {
                if (data.itemName === 'TOTAL')
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
                else if (!data.itemCode || !data.itemName)
                    return <div className="ant-table-cell-link">{text}</div>;
                else
                    return (
                        <Link
                            to={
                                values?.level === 'accountLevel'
                                    ? {
                                          pathname: '/item-wise-ledger',
                                          search: `mano=${values.mano}&maName=${
                                              values.maName
                                          }&acno=${values.acno}&acName=${
                                              values.acName
                                          }&itemCode=${
                                              data.itemCode
                                          }&itemName=${
                                              data.itemName
                                          }&tillDate=${moment(
                                              values.tillDate
                                          ).format('YYYY-MM-DD')}`,
                                      }
                                    : {
                                          pathname: 'sub-account-balance',
                                          search: `acno=${values?.acno}&level=accountLevel`,
                                      }
                            }
                        >
                            {text}
                        </Link>
                    );
            },
        },
        {
            title: 'Item code',
            dataIndex: 'itemCode',
            key: 'itemCode',
            width: '100px',
            render: (text, data) => {
                if (data.itemName === 'TOTAL')
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
                else if (!data.itemCode || !data.itemName)
                    return <div className="ant-table-cell-link">{text}</div>;
                else
                    return (
                        <Link
                            to={
                                values?.level === 'accountLevel'
                                    ? {
                                          pathname: '/item-wise-ledger',
                                          search: `mano=${values.mano}&maName=${
                                              values.maName
                                          }&acno=${values.acno}&acName=${
                                              values.acName
                                          }&itemCode=${
                                              data.itemCode
                                          }&itemName=${
                                              data.itemName
                                          }&tillDate=${moment(
                                              values.tillDate
                                          ).format('YYYY-MM-DD')}`,
                                      }
                                    : {
                                          pathname: 'sub-account-balance',
                                          search: `acno=${values?.acno}&level=accountLevel`,
                                      }
                            }
                        >
                            {text}
                        </Link>
                    );
            },
        },
        {
            title: 'Balance Rs',
            dataIndex: 'balance',
            key: 'balance',
            width: '120px',
            render: (text, data) => {
                if (data.itemName === 'TOTAL')
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
                else if (!data.itemCode || !data.itemName)
                    return <div className="ant-table-cell-link">{text}</div>;
                else
                    return (
                        <Link
                            to={
                                values?.level === 'accountLevel'
                                    ? {
                                          pathname: '/item-wise-ledger',
                                          search: `mano=${values.mano}&maName=${
                                              values.maName
                                          }&acno=${values.acno}&acName=${
                                              values.acName
                                          }&itemCode=${
                                              data.itemCode
                                          }&itemName=${
                                              data.itemName
                                          }&tillDate=${moment(
                                              values.tillDate
                                          ).format('YYYY-MM-DD')}`,
                                      }
                                    : {
                                          pathname: 'sub-account-balance',
                                          search: `acno=${values?.acno}&level=accountLevel`,
                                      }
                            }
                        >
                            {text}
                        </Link>
                    );
            },
        },
    ];
    // DATA SOURCE - END

    return (
        <>
            <Form
                layout="vertical"
                onValuesChange={onValuesChange}
                // onFinish={onFormSubmit}
                fields={[
                    {
                        name: 'fromDate',
                        value:
                            values?.fromDate && ENG_ISO_TO_NEP(values.fromDate),
                    },
                    {
                        name: 'tillDate',
                        value:
                            values?.tillDate && ENG_ISO_TO_NEP(values.tillDate),
                    },
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'level',
                        value: values?.level,
                    },
                    {
                        name: 'mano',
                        value: values?.mano,
                    },
                    {
                        name: 'maName',
                        value: values?.maName,
                    },
                    {
                        name: 'acno',
                        value: values?.acno,
                    },
                    {
                        name: 'acName',
                        value: values?.acName,
                    },
                    {
                        name: 'showSubTotals',
                        value: values?.showSubTotals,
                    },
                    {
                        name: 'hideZeros',
                        value: values?.hideZeros,
                    },
                ]}
            >
                <Row gutter={20}>
                    {/* <Col span={4}>
                            <Form.Item label="From" name="fromDate">
                                <input type="text" className="ant-input" onKeyUp={e => onDateKeyUp(e, mode)} />
                            </Form.Item>
                        </Col> */}
                    <Col span={4}>
                        <Form.Item label="Till" name="tillDate">
                            <input
                                type="text"
                                className="ant-input"
                                onKeyUp={e => onDateKeyUp(e, mode)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Row gutter={20}>
                            <Col span={14}>
                                <Form.Item label="Main A/C" name="maName">
                                    <Select placeholder="Select...">
                                        {mainAccounts.length &&
                                            mainAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.maName}
                                                >
                                                    {item.maName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label=" " name="mano">
                                    <Select placeholder="Select...">
                                        {mainAccounts.length &&
                                            mainAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.mano}
                                                >
                                                    {item.mano}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={14}>
                                <Form.Item label="Sub A/C" name="acName">
                                    <Select
                                        placeholder="Select..."
                                        disabled={
                                            values?.level === 'mainAccountLevel'
                                        }
                                    >
                                        {subAccounts.length &&
                                            subAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.acName}
                                                >
                                                    {item.acName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label=" " name="acno">
                                    <Select
                                        placeholder="Select..."
                                        disabled={
                                            values?.level === 'mainAccountLevel'
                                        }
                                    >
                                        {subAccounts.length &&
                                            subAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.acno}
                                                >
                                                    {item.acno}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Level" name="level">
                            <Radio.Group
                            // onChange={this.onChange}
                            // value={value}
                            >
                                <Space direction="vertical">
                                    <Radio value="mainAccountLevel">
                                        Account
                                    </Radio>
                                    <Radio value="accountLevel">
                                        Sub account
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <div style={{ marginTop: '.6125rem' }}>
                            <Excel data={dataSource} />
                        </div>
                    </Col>
                    <Col span={2}>
                        <Form.Item label="Branch ID" name="branchID">
                            <Select placeholder="Select...">
                                <Select.Option value="all">All</Select.Option>
                                {branches?.length &&
                                    branches.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.branchID}
                                        >
                                            {item.branchID}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Branch Name" name="branchName">
                            <Select placeholder="Select...">
                                <Select.Option value="all">All</Select.Option>
                                {branches?.length &&
                                    branches.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.branchName}
                                        >
                                            {item.branchName}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Show options"
                            name="hideZeros"
                            valuePropName="checked"
                            className="form-item-collapsed"
                        >
                            <Checkbox>Hide zeros</Checkbox>
                        </Form.Item>
                        <Form.Item
                            // label="Show options"
                            name="showSubTotals"
                            valuePropName="checked"
                            className="form-item-collapsed"
                        >
                            <Checkbox>Show sub totals</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Row gutter={20}>
                <Col span={12}>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        className="ant-table-wrapper--collapsed ant-table-wrapper--link"
                        pagination={false}
                        scroll={{ y: 500 }}
                        loading={isLoading}
                    />
                </Col>
                <Col span={12}>
                    {/* <Table
                        dataSource={dataSourceRight}
                        columns={columns}
                        className="ant-table-wrapper--collapsed ant-table-wrapper--link"
                        pagination={false}
                    /> */}
                </Col>
            </Row>
        </>
    );
}

export function Excel({ data }) {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
        <ExcelFile
            element={<Button size="small">Excel</Button>}
            filename="Sub Account Balance"
        >
            <ExcelSheet data={data} name="Sub Account Balance">
                <ExcelColumn label="SN" value="sn" />
                <ExcelColumn label="Item Name" value="itemName" />
                <ExcelColumn label="Item Code" value="itemCode" />
                <ExcelColumn label="Balance Rs" value="balance" />
            </ExcelSheet>
        </ExcelFile>
    );
}
