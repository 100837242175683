import React from 'react';
import { Modal, Table, Checkbox } from 'antd';
import moment from 'moment';

export default function LoanScheduleModal({
    visible,
    setLoanScheduleModal,
    tableDataSource,
}) {
    const columns = [
        {
            title: 'No',
            dataIndex: 'installmentNO',
            key: 'installmentNO',
        },
        {
            title: 'Installment Date',
            dataIndex: 'installmentDate',
            key: 'installmentDate',
            render: text => moment(text).format('YYYY-MM-DD'),
        },
        {
            title: 'Installment Rs',
            dataIndex: 'installmentAmount',
            key: 'installmentAmount',
        },
        {
            title: 'Interest Rs',
            dataIndex: 'interestInstallment',
            key: 'interestInstallment',
        },
        {
            title: 'Penalty Rs',
            dataIndex: 'penalty',
            key: 'penalty',
        },
        {
            title: 'Pay',
            dataIndex: 'pay',
            key: 'pay',
            render: text => <Checkbox></Checkbox>,
        },
        {
            title: 'Pay Amount',
            dataIndex: 'payingRs',
            key: 'payingRs',
        },
    ];
    return (
        <Modal
            title="Loan Schedule"
            visible={visible}
            okButtonProps={{ hidden: true }}
            cancelText="Close"
            onCancel={() => setLoanScheduleModal(false)}
        >
            <Table
                dataSource={tableDataSource?.map((item, i) => {
                    return {
                        key: i + 1,
                        ...item,
                    };
                })}
                columns={columns}
                pagination={false}
                size="small"
            />
        </Modal>
    );
}
