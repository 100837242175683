import React from 'react';
import { message } from 'antd';
import moment from 'moment';
import { axios } from '../../../utils/axios';
import {
    DLT_KEYS_FROM_OBJ_EXCEPT,
    SET_DEFAULT_VALUES,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
    DLT_KEYS_FROM_OBJ,
} from '../../../utils/functions';
import {
    GET_LOAN_TYPE_ACCOUNTS,
    GET_LOAN_MEMBERS,
    GET_LOAN_INTEREST_DATE,
    GET_LOAN_RECOVERY_INFO,
    GET_CASH_COUNTERS,
    GET_BANKS,
    GET_OTHER_ACCOUNTS,
    GET_OTHER_ITEMS,
    GET_LOAN_RECOVERY_DEPOSIT_ACCOUNTS,
} from '../../../utils/axios';
import LoanRecoveryForm from '../../../components/Credits/LoanRecovery/LoanRecoveryForm/LoanRecoveryForm';
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import InterestReportModal from '../../../components/Credits/LoanRecovery/InterestReportModal/InterestReportModal';
import LoanScheduleModal from '../../../components/Credits/LoanRecovery/LoanScheduleModal/LoanScheduleModal';

export default function LoanRecovery() {
    // Global states
    // Local states
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [loanTypeAccounts, setLoanTypeAccounts] = React.useState([]);
    const [loanMembers, setLoanMembers] = React.useState([]);
    const [viewValues, setViewValues] = React.useState();
    const [cashCounters, setCashCounters] = React.useState([]);
    const [banks, setBanks] = React.useState([]);
    const [otherAccounts, setOtherAccounts] = React.useState([]);
    const [otherItems, setOtherItems] = React.useState([]);
    const [depositAccountsModal, setDepositAccountsModal] =
        React.useState(false);
    const [intCalcReportModal, setIntCalcReportModal] = React.useState(false);
    const [loanScheduleModal, setLoanScheduleModal] = React.useState(false);

    // Refs
    const formRef = React.useRef();

    // On view form values change
    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        // const values = viewValues;

        // Update values in state
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(item => item[key] === value);
            return updateValues(branch);
        } else if (key === 'acno' || key === 'acName') {
            const loanType = loanTypeAccounts.find(item => item[key] === value);
            setViewValues(loanType);
            return setDefaultValues();
        } else if (key === 'loanNo' || key === 'name') {
            const member = loanMembers.find(item => item[key] === value);
            updateValues({
                ...DLT_KEYS_FROM_OBJ_EXCEPT(viewValues, [
                    'branchID',
                    'branchName',
                    'acName',
                    'acno',
                ]),
                ...member,
            });
            return setDefaultValues();
        } else if (key === 'cashItemNo' || key === 'cashItem') {
            const itemKey = key === 'cashItemNo' ? 'itemCode' : 'itemName';
            const item = cashCounters.find(item => item[itemKey] === value);
            return updateValues({
                cashItemNo: item.itemCode,
                cashItem: item.itemName,
            });
        } else if (key === 'bankItemNo' || key === 'bankItem') {
            const itemKey = key === 'bankItemNo' ? 'itemCode' : 'itemName';
            const item = banks.find(item => item[itemKey] === value);
            return updateValues({
                bankItemNo: item.itemCode,
                bankItem: item.itemName,
            });
        } else if (key === 'otherACNO' || key === 'otherAcName') {
            const itemKey = key === 'otherACNO' ? 'acno' : 'acName';
            const item = otherAccounts.find(item => item[itemKey] === value);
            console.log('item: ', item);
            return updateValues({
                otherACNO: item.acno,
                otherAcName: item.acName,
            });
        } else if (key === 'otherItemNo' || key === 'otherItem') {
            const itemKey = key === 'otherItemNo' ? 'itemCode' : 'itemName';
            const item = otherItems.find(item => item[itemKey] === value);
            console.log('item: ', item);
            return updateValues({
                otherItemNo: item.itemCode,
                otherItem: item.itemName,
            });
        } else if (key === 'lpFundCheckbox') {
            // Validate form
            if (!value) formRef.current.validateFields();

            const deductedTotal = +viewValues?.deductedTotal || 0;
            const rebateBal = +viewValues?.rebateBal || 0;
            const totalPayment = deductedTotal - rebateBal;

            const lpBalance = viewValues?.lpBalance;
            const cashBal = +viewValues?.cashBal || 0;
            const bankBal = +viewValues?.bankBal || 0;
            const depositAccountsTotal = +viewValues?.depositAccountsTotal || 0;
            const shareBalancePaid = +viewValues?.shareBalancePaid || 0;
            const otherBal = +viewValues?.otherBal || 0;

            const amount =
                totalPayment -
                (cashBal +
                    bankBal +
                    depositAccountsTotal +
                    shareBalancePaid +
                    otherBal);
            updateValues({
                lpBalancePaid: value
                    ? lpBalance < amount
                        ? lpBalance
                        : amount
                    : '',
            });
        } else if (key === 'shareCheckbox') {
            // Validate form
            if (!value) formRef.current.validateFields();

            const deductedTotal = +viewValues?.deductedTotal || 0;
            const rebateBal = +viewValues?.rebateBal || 0;
            const totalPayment = deductedTotal - rebateBal;

            const shareBalance = viewValues?.shareBalance;
            const lpBalancePaid = +viewValues?.lpBalancePaid || 0;
            const cashBal = +viewValues?.cashBal || 0;
            const bankBal = +viewValues?.bankBal || 0;
            const depositAccountsTotal = +viewValues?.depositAccountsTotal || 0;
            const otherBal = +viewValues?.otherBal || 0;

            const amount =
                totalPayment -
                (lpBalancePaid +
                    cashBal +
                    bankBal +
                    depositAccountsTotal +
                    otherBal);

            updateValues({
                shareBalPaid: value
                    ? shareBalance < amount
                        ? shareBalance
                        : amount
                    : '',
            });
        } else if (key === 'cashCheckbox') {
            // Validate form
            if (!value) formRef.current.validateFields();

            const deductedTotal = +viewValues?.deductedTotal || 0;
            const rebateBal = +viewValues?.rebateBal || 0;
            const totalPayment = deductedTotal - rebateBal;

            const lpBalancePaid = +viewValues?.lpBalancePaid || 0;
            const bankBal = +viewValues?.bankBal || 0;
            const depositAccountsTotal = +viewValues?.depositAccountsTotal || 0;
            const shareBalPaid = +viewValues?.shareBalPaid || 0;
            const otherBal = +viewValues?.otherBal || 0;

            const amount =
                totalPayment -
                (lpBalancePaid +
                    bankBal +
                    depositAccountsTotal +
                    shareBalPaid +
                    otherBal);

            updateValues({
                cashBal: value ? amount : '',
            });
        } else if (key === 'bankCheckbox') {
            // Validate form
            if (!value) formRef.current.validateFields();

            const deductedTotal = +viewValues?.deductedTotal || 0;
            const rebateBal = +viewValues?.rebateBal || 0;
            const totalPayment = deductedTotal - rebateBal;

            const lpBalancePaid = +viewValues?.lpBalancePaid || 0;
            const cashBal = +viewValues?.cashBal || 0;
            const depositAccountsTotal = +viewValues?.depositAccountsTotal || 0;
            const shareBalPaid = +viewValues?.shareBalPaid || 0;
            const otherBal = +viewValues?.otherBal || 0;

            const amount =
                totalPayment -
                (lpBalancePaid +
                    cashBal +
                    depositAccountsTotal +
                    shareBalPaid +
                    otherBal);

            updateValues({
                bankBal: value ? amount : '',
            });
        } else if (key === 'otherCheckbox') {
            // Validate form
            if (!value) formRef.current.validateFields();

            const deductedTotal = +viewValues?.deductedTotal || 0;
            const rebateBal = +viewValues?.rebateBal || 0;
            const totalPayment = deductedTotal - rebateBal;

            const lpBalancePaid = +viewValues?.lpBalancePaid || 0;
            const cashBal = +viewValues?.cashBal || 0;
            const bankBal = +viewValues?.bankBal || 0;
            const depositAccountsTotal = +viewValues?.depositAccountsTotal || 0;
            const shareBalPaid = +viewValues?.shareBalPaid || 0;

            const amount =
                totalPayment -
                (lpBalancePaid +
                    cashBal +
                    bankBal +
                    depositAccountsTotal +
                    shareBalPaid);

            updateValues({
                otherBal: value ? amount : '',
            });
        }

        // Update values
        updateValues(val);
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            transDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            loanCalcDate: moment().format(),
            interestCalcDate: moment().subtract(1, 'days').format(),
            months: '3',
        };
        SET_DEFAULT_VALUES(defaultValues, mode, setViewValues);
    }, []);

    // On loan payment checkbox change
    const onLoanPaymentCheckboxChange = (e, data) => {
        const isChecked = e.target.checked;
        setViewValues(prevValues => ({
            ...prevValues,
            [data.deductedFieldName]: isChecked ? data.receivable : 0,
        }));

        if (isChecked && data.key === '3') {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__1: true,
                duePenaltyPaid: viewValues?.duepenalty,
            }));
        } else if (isChecked && data.key === '4') {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__2: true,
                dueInterestPaid: viewValues?.dueinterest,
            }));
        } else if (isChecked && data.key === '5') {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__2: true,
                dueInterestPaid: viewValues?.dueinterest,
                loanPaymentCheckbox__4: true,
                interestPaid: viewValues?.interest,
            }));
        } else if (isChecked && data.key === '6') {
            setViewValues(prevValues => ({
                ...prevValues,
                loanBal: viewValues?.currentKistabalance,
            }));
        }
    };

    // Handle submit amount
    const handleSubmitAmount = () => {
        let remainingAmount = viewValues?.submitAmount;

        setViewValues(prevValues => ({
            ...prevValues,
            loanPaymentCheckbox__1: false,
            loanPaymentCheckbox__2: false,
            loanPaymentCheckbox__3: false,
            loanPaymentCheckbox__4: false,
            loanPaymentCheckbox__5: false,
            loanPaymentCheckbox__6: false,
            duePenaltyPaid: 0,
            dueInterestPaid: 0,
            penaltyPaid: 0,
            interestPaid: 0,
            additionalInterestPaid: 0,
            loanBal: 0,
            return: 0,
        }));

        // Due penalty
        const duepenalty = viewValues?.duepenalty;
        if (duepenalty < remainingAmount) {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__1: true,
                duePenaltyPaid: duepenalty,
            }));
            remainingAmount -= duepenalty;
        } else {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__1: true,
                duePenaltyPaid: remainingAmount,
            }));
            return;
        }

        // Due interest
        const dueinterest = viewValues?.dueinterest;
        if (dueinterest < remainingAmount) {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__2: true,
                dueInterestPaid: dueinterest,
            }));
            remainingAmount -= dueinterest;
        } else {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__2: true,
                dueInterestPaid: remainingAmount,
            }));
            return;
        }

        // Penalty
        const penalty = viewValues?.penalty;
        if (penalty < remainingAmount) {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__3: true,
                penaltyPaid: penalty,
            }));
            remainingAmount -= penalty;
        } else {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__3: true,
                penaltyPaid: remainingAmount,
            }));
            return;
        }

        // Penalty
        const interest = viewValues?.interest;
        if (interest < remainingAmount) {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__4: true,
                interestPaid: interest,
            }));
            remainingAmount -= interest;
        } else {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__4: true,
                interestPaid: remainingAmount,
            }));
            return;
        }

        // Additional Interest
        const additionalinterest = viewValues?.additionalinterest;
        if (additionalinterest < remainingAmount) {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__5: true,
                additionalInterestPaid: additionalinterest,
            }));
            remainingAmount -= additionalinterest;
        } else {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__5: true,
                additionalInterestPaid: remainingAmount,
            }));
            return;
        }

        // Balance
        const balance = viewValues?.balance;
        if (balance < remainingAmount) {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__6: true,
                loanBal: balance,
            }));
            remainingAmount -= balance;
        } else {
            setViewValues(prevValues => ({
                ...prevValues,
                loanPaymentCheckbox__6: true,
                loanBal: remainingAmount,
            }));
            return;
        }

        setViewValues(prevValues => ({
            ...prevValues,
            return: remainingAmount,
        }));
    };

    // On show accounts button click
    const onShowAccountsClick = async () => {
        const memberNO = viewValues?.memberNO;
        const branchID = viewValues?.branchID;
        const loanCalcDate = viewValues?.loanCalcDate;

        if (!memberNO || !branchID || !loanCalcDate) return;

        const payload = {
            memberNO,
            branchID,
            loanCalcDate,
        };

        const depositAccounts = await GET_LOAN_RECOVERY_DEPOSIT_ACCOUNTS(
            payload
        );
        console.log('depositAccounts: ', depositAccounts);
    };

    // On deposit accounts modal ok
    const onDepositAccountsModalOk = () => {};

    // On deposit accounts modal cancel
    const onDepositAccountsModalCancel = () => {
        setDepositAccountsModal(false);
    };

    // On Total loan click
    const onTotalLoanClick = () => {
        setViewValues(prevValues => ({
            ...prevValues,
            loanPaymentCheckbox__1: true,
            duePenaltyPaid: viewValues?.duepenalty,
            loanPaymentCheckbox__2: true,
            dueInterestPaid: viewValues?.dueinterest,
            loanPaymentCheckbox__3: true,
            penaltyPaid: viewValues?.penalty,
            loanPaymentCheckbox__4: true,
            interestPaid: viewValues?.interest,
            loanPaymentCheckbox__5: true,
            additionalInterestPaid: viewValues?.additionalinterest,
            loanPaymentCheckbox__6: true,
            loanBal: viewValues?.balance,
        }));
    };

    // On form submit
    const onFormSubmit = () => {
        const deductedTotal = +viewValues?.deductedTotal || 0;
        const rebateBal = +viewValues?.rebateBal || 0;
        const totalPayment = deductedTotal - rebateBal;
        const netPayment = +viewValues?.netPayment || 0;

        if (!totalPayment) return message.error('No amount to recover');

        if (totalPayment !== netPayment)
            return message.error('Unbalanced Transaction');

        formRef.current
            .validateFields()
            .then(async res => {
                const {
                    loanNo,
                    acno,
                    interestCalcDate,
                    loanCalcDate,
                    branchID,
                    loanBal,
                    memberNO: memberNo,
                    name,
                    selfCalculation,
                    interest,
                    interestPaid,
                    additionalinterest,
                    additionalInterestPaid,
                    dueInterestPaid,
                    penalty,
                    penaltyPaid,
                    duePenaltyPaid,
                    currentInstallMentNo,
                    calcIntDetail,
                    calcPenaltyDetail,
                    rebateBal,
                    rebatePenaltyBal,
                    rebateDuePenaltyBal,
                    rebateDueIntBal,
                    rebateIntBal,
                    rebateAddIntBal,
                    lpBalancePaid,
                    shareBalPaid,
                    cashItem,
                    cashItemNo,
                    cashBal,
                    cBVRCNO,
                    bankItem,
                    bankItemNo,
                    bankBal,
                    bBVRCNO,
                    otherACNO,
                    otherItem,
                    otherItemNo,
                    otherBal,
                } = viewValues;

                const payload = {
                    loanNo,
                    acno,
                    interestCalcDate,
                    loanCalcDate,
                    branchID,
                    loanBal: +loanBal || 0,
                    memberNo,
                    name,
                    isSelfCal: selfCalculation || false,
                    interestBal: +interest || 0,
                    interestPaid: +interestPaid || 0,
                    additionalInterestBal: +additionalinterest || 0,
                    additionalInterestPaid: +additionalInterestPaid || 0,
                    dueInterestPaid: +dueInterestPaid || 0,
                    penaltyBal: +penalty || 0,
                    penaltyPaid: +penaltyPaid || 0,
                    duepenaltyPaid: +duePenaltyPaid || 0,
                    addPenaltyIntBal: +duePenaltyPaid ? true : false,
                    noofInstallmentPaid: +currentInstallMentNo,
                    calcIntDetail,
                    calcPenaltyDetail,
                    rebateBal: +rebateBal || 0,
                    rebatePenaltyBal: +rebatePenaltyBal || 0,
                    rebateDuePenaltyBal: +rebateDuePenaltyBal || 0,
                    rebateDueIntBal: +rebateDueIntBal || 0,
                    rebateIntBal: +rebateIntBal || 0,
                    rebateAddIntBal: +rebateAddIntBal || 0,
                    lpBalancePaid: +lpBalancePaid || 0,
                    shareBalPaid: +shareBalPaid || 0,
                    // deposits: [
                    //     {
                    //         depAcno: 'string',
                    //         depAccountNo: 'string',
                    //         depBal: 0,
                    //         chqno: 'string',
                    //     },
                    // ],
                    cashItem,
                    cashItemNo,
                    cashBal: +cashBal || 0,
                    cBVRCNO,
                    cbbranchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    bankItem,
                    bankItemNo,
                    bankBal: +bankBal || 0,
                    bBVRCNO,
                    otherACNO,
                    otherItem,
                    otherItemNo,
                    otherBal: +otherBal || 0,
                    obranchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    enteredBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                };
                console.log('payload: ', payload);

                const response = await axios.post(
                    `${process.env.REACT_APP_HOST}/api/LoanRecovery/LoanRecovery`,
                    payload
                );
                console.log('response: ', response);
                if (response.data.statusCode !== 0)
                    return message.error(response.data.statusMessage);

                message.success(response.data.statusMessage);
                setViewValues(prevValues => ({
                    ...DLT_KEYS_FROM_OBJ_EXCEPT(prevValues, [
                        'branchID',
                        'branchName',
                    ]),
                    months: '3',
                    ...response.data.data,
                }));
                setLoanMembers([]);
            })
            .catch(err => console.log(err));
    };

    // On Int Calc Report Click
    const onIntCalcReportClick = () => {
        setIntCalcReportModal(true);
    };

    // On loan schedule click
    const onLoanScheduleClick = () => {
        setLoanScheduleModal(true);
    };

    // On submit amount keyup
    const onSubmitAmountKeyUp = e => {
        if (e.keyCode === 13) handleSubmitAmount();
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        if (key === 'loanCalcDate' || key === 'interestCalcDate') {
            setViewValues(prevValues => ({
                ...DLT_KEYS_FROM_OBJ(prevValues, [
                    'loanPaymentCheckbox__1',
                    'loanPaymentCheckbox__2',
                    'loanPaymentCheckbox__3',
                    'loanPaymentCheckbox__4',
                    'loanPaymentCheckbox__5',
                    'loanPaymentCheckbox__6',
                    'duePenaltyPaid',
                    'dueInterestPaid',
                    'penaltyPaid',
                    'interestPaid',
                    'additionalInterestPaid',
                    'loanBal',
                    'rebateDuePenaltyBal',
                    'rebateDueIntBal',
                    'rebatePenaltyBal',
                    'rebateIntBal',
                    'rebateAddIntBal',
                ]),
            }));
        }
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Get loan type accounts
    React.useEffect(() => {
        (async () => {
            const loanTypeAccounts = await GET_LOAN_TYPE_ACCOUNTS();
            setLoanTypeAccounts(loanTypeAccounts);
        })();
    }, []);

    // Get loan members
    React.useEffect(() => {
        if (viewValues?.branchID && viewValues?.acno) {
            (async () => {
                const loanMembers = await GET_LOAN_MEMBERS(
                    viewValues.branchID,
                    viewValues.acno
                );
                setLoanMembers(loanMembers);
            })();
        }
    }, [viewValues?.branchID, viewValues?.acno]);

    // Get loan interest date
    React.useEffect(() => {
        if (
            !viewValues?.loanCalcDate ||
            !viewValues?.months ||
            !viewValues?.loanNo ||
            !viewValues?.acno ||
            !viewValues?.branchID
        )
            return;

        const payload = {
            loanCalcDate: viewValues?.loanCalcDate,
            months: viewValues?.months,
            loanNo: viewValues?.loanNo,
            acno: viewValues?.acno,
            branchID: viewValues?.branchID,
        };

        (async () => {
            const res = await GET_LOAN_INTEREST_DATE(payload);
            setViewValues(prevValues => ({
                ...prevValues,
                interestCalcDate: res,
            }));
        })();
    }, [
        viewValues?.loanCalcDate,
        viewValues?.months,
        viewValues?.loanNo,
        viewValues?.acno,
        viewValues?.branchID,
    ]);

    // Get loan recovery info
    React.useEffect(() => {
        if (viewValues?.selfCalculation) return;

        const loanNo = viewValues?.loanNo;
        const acno = viewValues?.acno;
        const interestCalcDate = viewValues?.interestCalcDate;
        const loanCalcDate = viewValues?.loanCalcDate;
        const branchID = viewValues?.branchID;
        const loanBal = viewValues?.loanBal || 0;

        if (!loanNo || !acno || !interestCalcDate || !loanCalcDate || !branchID)
            return;

        const payload = {
            loanNo,
            acno,
            interestCalcDate,
            loanCalcDate,
            branchID,
            loanBal,
        };

        (async () => {
            const res = await GET_LOAN_RECOVERY_INFO(payload);
            console.log('res: ', res);
            setViewValues(prevValues => ({
                ...prevValues,
                ...res,
            }));
        })();
    }, [
        viewValues?.loanNo,
        viewValues?.acno,
        viewValues?.interestCalcDate,
        viewValues?.loanCalcDate,
        viewValues?.branchID,
        viewValues?.loanBal,
        viewValues?.selfCalculation,
    ]);

    // Set receivable total
    React.useEffect(() => {
        const receivableTotal =
            +viewValues?.duepenalty +
            +viewValues?.dueinterest +
            +viewValues?.penalty +
            +viewValues?.interest +
            +viewValues?.additionalinterest +
            +viewValues?.balance;

        setViewValues(prevValues => ({
            ...prevValues,
            receivableTotal,
        }));
    }, [
        viewValues?.duepenalty,
        viewValues?.dueinterest,
        viewValues?.penalty,
        viewValues?.interest,
        viewValues?.additionalinterest,
        viewValues?.balance,
    ]);

    // Set deducted total
    React.useEffect(() => {
        const deductedTotal =
            (+viewValues?.duePenaltyPaid || 0) +
            (+viewValues?.dueInterestPaid || 0) +
            (+viewValues?.penaltyPaid || 0) +
            (+viewValues?.interestPaid || 0) +
            (+viewValues?.additionalInterestPaid || 0) +
            (+viewValues?.loanBal || 0);

        setViewValues(prevValues => ({
            ...prevValues,
            deductedTotal,
        }));
    }, [
        viewValues?.duePenaltyPaid,
        viewValues?.dueInterestPaid,
        viewValues?.penaltyPaid,
        viewValues?.interestPaid,
        viewValues?.additionalInterestPaid,
        viewValues?.loanBal,
    ]);

    // Set Rebate total
    React.useEffect(() => {
        const rebateDuePenaltyBal = +viewValues?.rebateDuePenaltyBal || 0;
        const rebateDueIntBal = +viewValues?.rebateDueIntBal || 0;
        const rebatePenaltyBal = +viewValues?.rebatePenaltyBal || 0;
        const rebateIntBal = +viewValues?.rebateIntBal || 0;
        const rebateAddIntBal = +viewValues?.rebateAddIntBal || 0;

        const rebateBal =
            rebateDuePenaltyBal +
            rebateDueIntBal +
            rebatePenaltyBal +
            rebateIntBal +
            rebateAddIntBal;

        setViewValues(prevValues => ({
            ...prevValues,
            rebateBal,
        }));
    }, [
        viewValues?.rebateDuePenaltyBal,
        viewValues?.rebateDueIntBal,
        viewValues?.rebatePenaltyBal,
        viewValues?.rebateIntBal,
        viewValues?.rebateAddIntBal,
    ]);

    // Set net payment
    React.useEffect(() => {
        const lpBalancePaid = +viewValues?.lpBalancePaid || 0;
        const cashBal = +viewValues?.cashBal || 0;
        const bankBal = +viewValues?.bankBal || 0;
        const depositAccountsTotal = +viewValues?.depositAccountsTotal || 0;
        const shareBalPaid = +viewValues?.shareBalPaid || 0;
        const otherBal = +viewValues?.otherBal || 0;

        const netPayment =
            lpBalancePaid +
            cashBal +
            bankBal +
            depositAccountsTotal +
            shareBalPaid +
            otherBal;

        setViewValues(prevValues => ({
            ...prevValues,
            netPayment,
        }));
    }, [
        viewValues?.lpBalancePaid,
        viewValues?.cashBal,
        viewValues?.bankBal,
        viewValues?.depositAccountsTotal,
        viewValues?.shareBalPaid,
        viewValues?.otherBal,
    ]);

    // Get cash counters, banks and others
    React.useEffect(() => {
        const branchID = JSON.parse(
            localStorage.getItem('USER_CREDENTIALS')
        ).branchID;
        (async () => {
            // Get cash counters
            const counters = await GET_CASH_COUNTERS(branchID);
            setCashCounters(counters);
            // Get banks
            const banks = await GET_BANKS(branchID);
            setBanks(banks);
            // Other accounts
            const otherAccounts = await GET_OTHER_ACCOUNTS(branchID);
            setOtherAccounts(otherAccounts);
        })();
    }, [viewValues?.branchID, setBanks, setCashCounters, setOtherAccounts]);

    // Get other items
    React.useEffect(() => {
        if (viewValues?.otherACNO) {
            const branchID = JSON.parse(
                localStorage.getItem('USER_CREDENTIALS')
            ).branchID;
            setOtherItems([]);
            (async () => {
                const otherItems = await GET_OTHER_ITEMS(
                    branchID,
                    undefined,
                    viewValues?.otherACNO
                );
                setOtherItems(otherItems);
            })();
        }
    }, [viewValues?.branchID, viewValues?.otherACNO, setOtherItems]);

    // Form props
    const formProps = {
        ref: formRef,
        setDefaultValues,
        loanTypeAccounts,
        loanMembers,
        onValuesChange,
        onLoanPaymentCheckboxChange,
        onDateChange,
        onDateKeyUp,
        handleSubmitAmount,
        cashCounters,
        banks,
        otherAccounts,
        otherItems,
        onShowAccountsClick,
        onTotalLoanClick,
        onFormSubmit,
        onIntCalcReportClick,
        onSubmitAmountKeyUp,
        onLoanScheduleClick,
    };
    return (
        <PageWrapper>
            <LoanRecoveryForm {...formProps} values={viewValues} />
            {/* <Modal
                title="Deposit Accounts"
                visible={depositAccountsModal}
                onOk={onDepositAccountsModalOk}
                onCancel={onDepositAccountsModalCancel}
                width={1000}
                okText="Save"
            >
            </Modal> */}
            {/* Int Calc Report Modal */}
            <InterestReportModal
                visible={intCalcReportModal}
                setIntCalcReportModal={setIntCalcReportModal}
                viewValues={viewValues}
            />
            {/* Loan schedule modal */}
            <LoanScheduleModal
                visible={loanScheduleModal}
                setLoanScheduleModal={setLoanScheduleModal}
                tableDataSource={viewValues?.loanKistas}
            />
        </PageWrapper>
    );
}
