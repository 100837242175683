import React from 'react';
import {
    Row,
    Col,
    Form,
    Input,
    Radio,
    Space,
    Table,
    Select,
    Button,
} from 'antd';
import {
    GET_DATEPICKER_CONFIG,
    ENG_ISO_TO_NEP,
} from '../../../utils/functions';

export default function KYMForm(props) {
    // Destructuring
    const {
        onDateChange,
        onDateKeyUp,
        branches,
        members,
        onValuesChange,
        values,
        relations,
        onKymSubmit,
    } = props;
    // NEPALI DATEPICKER
    // Birth date
    const birthDateInput = document.getElementById('birthDate');
    birthDateInput &&
        birthDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, birthDateInput)
        );

    // Trans date
    const transDateInput = document.getElementById('kymDate');
    transDateInput &&
        transDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, transDateInput)
        );

    const otherCoopMembershipData = [
        {
            key: '1',
            sn: '(१.)',
            name: '',
            memberNo: '',
        },
        {
            key: '2',
            sn: '(२.)',
            name: '',
            memberNo: '',
        },
        {
            key: '3',
            sn: '(३.)',
            name: '',
            memberNo: '',
        },
        {
            key: '4',
            sn: '(४.)',
            name: '',
            memberNo: '',
        },
        {
            key: '5',
            sn: '(५.)',
            name: '',
            memberNo: '',
        },
    ];

    const otherCoopMembershipColumns = [
        {
            title: 'सि.नं',
            dataIndex: 'sn',
            key: 'sn',
        },
        {
            title: 'संस्थाको नाम, ठेगाना',
            dataIndex: 'name',
            key: 'name',
            render: (_, data) => (
                <Form.Item
                    name={`otherCoopName${+data.key}`}
                    wrapperCol={{ span: 24 }}
                >
                    <Input />
                </Form.Item>
            ),
        },
        {
            title: 'सदस्यता नं',
            dataIndex: 'memberNo',
            key: 'memberNo',
            render: (_, data) => (
                <Form.Item name={`otherCoopMemNO${+data.key}`}>
                    <Input />
                </Form.Item>
            ),
        },
    ];

    const fmMembershipOfSameCoopData = [
        {
            key: '1',
            sn: '(१.)',
            name: '',
            number: '',
        },
        {
            key: '2',
            sn: '(२.)',
            name: '',
            number: '',
        },
        {
            key: '3',
            sn: '(३.)',
            name: '',
            number: '',
        },
        {
            key: '4',
            sn: '(४.)',
            name: '',
            number: '',
        },
        {
            key: '5',
            sn: '(५.)',
            name: '',
            number: '',
        },
    ];

    const fmMembershipOfSameCoopColumns = [
        {
            title: 'सि.नं',
            dataIndex: 'sn',
            key: 'sn',
        },
        {
            title: 'नाम, थर',
            dataIndex: 'name',
            key: 'name',
            render: (_, data) => (
                <Form.Item
                    name={`fmNameSameCoop${+data.key}`}
                    wrapperCol={{ span: 24 }}
                >
                    <Input />
                </Form.Item>
            ),
        },
        {
            title: 'सदस्यता नं',
            dataIndex: 'number',
            key: 'number',
            render: (_, data) => (
                <Form.Item name={`fmMemberNOSameCoop${+data.key}`}>
                    <Input />
                </Form.Item>
            ),
        },
    ];

    const familyMemberInOtherCoopData = [
        {
            key: '1',
            sn: '(१.)',
            name: '',
            relation: '',
            address: '',
            number: '',
        },
        {
            key: '2',
            sn: '(२.)',
            name: '',
            relation: '',
            address: '',
            number: '',
        },
        {
            key: '3',
            sn: '(३.)',
            name: '',
            relation: '',
            address: '',
            number: '',
        },
        {
            key: '4',
            sn: '(४.)',
            name: '',
            relation: '',
            address: '',
            number: '',
        },
        {
            key: '5',
            sn: '(५.)',
            name: '',
            relation: '',
            address: '',
            number: '',
        },
    ];

    const familyMemberInOtherCoopColumns = [
        {
            title: 'सि.नं',
            dataIndex: 'sn',
            key: 'sn',
        },
        {
            title: 'नाम, थर',
            dataIndex: 'name',
            key: 'name',
            render: (_, data) => (
                <Form.Item
                    name={`fmocName${+data.key}`}
                    wrapperCol={{ span: 24 }}
                >
                    <Input />
                </Form.Item>
            ),
        },
        {
            title: 'नाता',
            dataIndex: 'relation',
            key: 'relation',
            render: (_, data) => (
                <Form.Item
                    name={`fmocRelation${+data.key}`}
                    wrapperCol={{ span: 24 }}
                >
                    <Input />
                </Form.Item>
            ),
        },
        {
            title: 'संस्थाको नाम, ठेगाना',
            dataIndex: 'address',
            key: 'address',
            render: (_, data) => (
                <Form.Item
                    name={`fmocCoopName${+data.key}`}
                    wrapperCol={{ span: 24 }}
                >
                    <Input />
                </Form.Item>
            ),
        },
        {
            title: 'सदस्यता नं',
            dataIndex: 'number',
            key: 'number',
            render: (_, data) => (
                <Form.Item
                    name={`fmocMemberno${+data.key}`}
                    wrapperCol={{ span: 24 }}
                >
                    <Input />
                </Form.Item>
            ),
        },
    ];

    const incomeAbove4LakhData = [
        {
            key: '1',
            sn: '(१.)',
            source: 'खेतिपाती',
            amount: '',
            fieldName: 'incomeFarming',
        },
        {
            key: '2',
            sn: '(२.)',
            source: 'व्यवसाय',
            amount: '',
            fieldName: 'incomeBusiness',
        },
        {
            key: '3',
            sn: '(३.)',
            source: 'नोकरी',
            amount: '',
            fieldName: 'incomeEmployment',
        },
        {
            key: '4',
            sn: '(४.)',
            source: 'वैदेशिक',
            amount: '',
            fieldName: 'incomeForeignEmp',
        },
        {
            key: '5',
            sn: '(५.)',
            source: 'अन्य',
            amount: '',
            fieldName: 'incomeOther',
        },
    ];

    const incomeAbove4LakhColumns = [
        {
            title: 'सि.नं',
            dataIndex: 'sn',
            key: 'sn',
        },
        {
            title: 'स्रोत',
            dataIndex: 'source',
            key: 'source',
        },
        {
            title: 'रकम',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, data) => (
                <Form.Item name={data.fieldName}>
                    <Input />
                </Form.Item>
            ),
        },
    ];

    const organizationalDepositData = [
        {
            key: '1',
            sn: '(१.)',
            description: 'शेयर',
            amount: '',
            fieldName: 'totalShare',
        },
        {
            key: '2',
            sn: '(२.)',
            description: 'बचत',
            amount: '',
            fieldName: 'totalSaving',
        },
    ];

    const organizationalDepositColumns = [
        {
            title: 'सि.नं',
            dataIndex: 'sn',
            key: 'sn',
        },
        {
            title: 'विवरण',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'रकम',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, data) => (
                <Form.Item name={data.fieldName}>
                    <Input />
                </Form.Item>
            ),
        },
    ];
    return (
        <>
            <h3 className="text-center margin-top">
                <strong>
                    बचत तथा ऋणको मुख्य कारोबार गर्ने सदस्यको पहिचान सम्बन्धी
                    विवरण
                </strong>
            </h3>
            <Form
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 8 }}
                labelAlign="left"
                onValuesChange={onValuesChange}
                fields={[
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'kymDate',
                        value:
                            values?.kymDate && ENG_ISO_TO_NEP(values.kymDate),
                    },
                    {
                        name: 'gender',
                        value: values?.gender,
                    },
                    {
                        name: 'maritalStatus',
                        value: values?.maritalStatus,
                    },
                    {
                        name: 'familyType',
                        value: values?.familyType,
                    },
                    {
                        name: 'OccupationType',
                        value: values?.OccupationType,
                    },
                    {
                        name: 'spOccupationType',
                        value: values?.spOccupationType,
                    },
                    {
                        name: 'oeName',
                        value: values?.oeName,
                    },
                    {
                        name: 'oeRelation',
                        value: values?.oeRelation,
                    },
                    {
                        name: 'oeOccupationType',
                        value: values?.oeOccupationType,
                    },
                    {
                        name: 'oeOccupationName',
                        value: values?.oeOccupationName,
                    },
                    {
                        name: 'highRankingRelation',
                        value: values?.highRankingRelation,
                    },
                    {
                        name: 'hrpName',
                        value: values?.hrpName,
                    },
                    {
                        name: 'hrpRelation',
                        value: values?.hrpRelation,
                    },
                    {
                        name: 'hrpPosition',
                        value: values?.hrpPosition,
                    },
                    {
                        name: 'liveInWorkingArea',
                        value: values?.liveInWorkingArea,
                    },
                    {
                        name: 'voterNo',
                        value: values?.voterNo,
                    },
                    {
                        name: 'electionBooth',
                        value: values?.electionBooth,
                    },
                    {
                        name: 'LivingDuration',
                        value: values?.LivingDuration,
                    },
                    {
                        name: 'passportNo',
                        value: values?.passportNo,
                    },
                    {
                        name: 'purposeOfMembership',
                        value: values?.purposeOfMembership,
                    },
                    {
                        name: 'otherCoopMembership',
                        value: values?.otherCoopMembership,
                    },
                    {
                        name: 'otherCoopName1',
                        value: values?.otherCoopName1,
                    },
                    {
                        name: 'otherCoopName2',
                        value: values?.otherCoopName2,
                    },
                    {
                        name: 'otherCoopName3',
                        value: values?.otherCoopName3,
                    },
                    {
                        name: 'otherCoopName4',
                        value: values?.otherCoopName4,
                    },
                    {
                        name: 'otherCoopName5',
                        value: values?.otherCoopName5,
                    },
                    {
                        name: 'otherCoopName6',
                        value: values?.otherCoopName6,
                    },
                    {
                        name: 'otherCoopMemNO1',
                        value: values?.otherCoopMemNO1,
                    },
                    {
                        name: 'otherCoopMemNO2',
                        value: values?.otherCoopMemNO2,
                    },
                    {
                        name: 'otherCoopMemNO3',
                        value: values?.otherCoopMemNO3,
                    },
                    {
                        name: 'otherCoopMemNO4',
                        value: values?.otherCoopMemNO4,
                    },
                    {
                        name: 'otherCoopMemNO5',
                        value: values?.otherCoopMemNO5,
                    },
                    {
                        name: 'familyMemberinOtherCoop',
                        value: values?.familyMemberinOtherCoop,
                    },
                    {
                        name: 'fmocName1',
                        value: values?.fmocName1,
                    },
                    {
                        name: 'fmocName2',
                        value: values?.fmocName2,
                    },
                    {
                        name: 'fmocName3',
                        value: values?.fmocName3,
                    },
                    {
                        name: 'fmocName4',
                        value: values?.fmocName4,
                    },
                    {
                        name: 'fmocName5',
                        value: values?.fmocName5,
                    },
                    {
                        name: 'fmocRelation1',
                        value: values?.fmocRelation1,
                    },
                    {
                        name: 'fmocRelation2',
                        value: values?.fmocRelation2,
                    },
                    {
                        name: 'fmocRelation3',
                        value: values?.fmocRelation3,
                    },
                    {
                        name: 'fmocRelation4',
                        value: values?.fmocRelation4,
                    },
                    {
                        name: 'fmocRelation5',
                        value: values?.fmocRelation5,
                    },
                    {
                        name: 'fmocCoopName1',
                        value: values?.fmocCoopName1,
                    },
                    {
                        name: 'fmocCoopName2',
                        value: values?.fmocCoopName2,
                    },
                    {
                        name: 'fmocCoopName3',
                        value: values?.fmocCoopName3,
                    },
                    {
                        name: 'fmocCoopName4',
                        value: values?.fmocCoopName4,
                    },
                    {
                        name: 'fmocCoopName5',
                        value: values?.fmocCoopName5,
                    },
                    {
                        name: 'fmocMemberno1',
                        value: values?.fmocMemberno1,
                    },
                    {
                        name: 'fmocMemberno2',
                        value: values?.fmocMemberno2,
                    },
                    {
                        name: 'fmocMemberno3',
                        value: values?.fmocMemberno3,
                    },
                    {
                        name: 'fmocMemberno4',
                        value: values?.fmocMemberno4,
                    },
                    {
                        name: 'fmocMemberno5',
                        value: values?.fmocMemberno5,
                    },
                    {
                        name: 'purposeOfMultipleMembership',
                        value: values?.purposeOfMultipleMembership,
                    },
                    {
                        name: 'fmPurposeOfMM',
                        value: values?.fmPurposeOfMM,
                    },
                    {
                        name: 'fmMembershipOfSameCoop',
                        value: values?.fmMembershipOfSameCoop,
                    },
                    {
                        name: 'fmNameSameCoop1',
                        value: values?.fmNameSameCoop1,
                    },
                    {
                        name: 'fmNameSameCoop2',
                        value: values?.fmNameSameCoop2,
                    },
                    {
                        name: 'fmNameSameCoop3',
                        value: values?.fmNameSameCoop3,
                    },
                    {
                        name: 'fmNameSameCoop4',
                        value: values?.fmNameSameCoop4,
                    },
                    {
                        name: 'fmNameSameCoop5',
                        value: values?.fmNameSameCoop5,
                    },
                    {
                        name: 'fmMemberNOSameCoop1',
                        value: values?.fmMemberNOSameCoop1,
                    },
                    {
                        name: 'fmMemberNOSameCoop2',
                        value: values?.fmMemberNOSameCoop2,
                    },
                    {
                        name: 'fmMemberNOSameCoop3',
                        value: values?.fmMemberNOSameCoop3,
                    },
                    {
                        name: 'fmMemberNOSameCoop4',
                        value: values?.fmMemberNOSameCoop4,
                    },
                    {
                        name: 'fmMemberNOSameCoop5',
                        value: values?.fmMemberNOSameCoop5,
                    },
                    {
                        name: 'annualIncome',
                        value: values?.annualIncome,
                    },
                    {
                        name: 'incomeFarming',
                        value: values?.incomeFarming,
                    },
                    {
                        name: 'incomeBusiness',
                        value: values?.incomeBusiness,
                    },
                    {
                        name: 'incomeEmployment',
                        value: values?.incomeEmployment,
                    },
                    {
                        name: 'incomeForeignEmp',
                        value: values?.incomeForeignEmp,
                    },
                    {
                        name: 'incomeOther',
                        value: values?.incomeOther,
                    },
                    {
                        name: 'totalShare',
                        value: values?.totalShare,
                    },
                    {
                        name: 'totalSaving',
                        value: values?.totalSaving,
                    },
                    {
                        name: 'noOfTransactionTimes',
                        value: values?.noOfTransactionTimes,
                    },
                    {
                        name: 'estimatedYearlyTransaction',
                        value: values?.estimatedYearlyTransaction,
                    },
                    {
                        name: 'LoanAmount',
                        value: values?.LoanAmount,
                    },
                    {
                        name: 'description',
                        value: values?.description,
                    },
                    {
                        name: 'docProvided',
                        value: values?.docProvided,
                    },
                ]}
            >
                <div className="kym__each">
                    <Row gutter={20}>
                        <Col span={16}>
                            <Row gutter={20}>
                                <Col span={16}>
                                    <Row gutter={20}>
                                        <Col span={16}>
                                            <Form.Item
                                                label="Branch"
                                                labelCol={{ span: 8 }}
                                                wrapperCol={{ span: 24 }}
                                                name="branchName"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Required',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Select..."
                                                    showSearch
                                                    filterOption={(
                                                        input,
                                                        option
                                                    ) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase()
                                                            ) >= 0
                                                    }
                                                    style={{ width: '100%' }}
                                                >
                                                    {branches?.length &&
                                                        branches.map(
                                                            (item, i) => (
                                                                <Select.Option
                                                                    key={i}
                                                                    value={
                                                                        item.branchName
                                                                    }
                                                                >
                                                                    {
                                                                        item.branchName
                                                                    }
                                                                </Select.Option>
                                                            )
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item
                                                labelCol={{ span: 8 }}
                                                wrapperCol={{ span: 24 }}
                                                name="branchID"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Required',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Select..."
                                                    showSearch
                                                    filterOption={(
                                                        input,
                                                        option
                                                    ) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase()
                                                            ) >= 0
                                                    }
                                                >
                                                    {branches?.length &&
                                                        branches.map(
                                                            (item, i) => (
                                                                <Select.Option
                                                                    key={i}
                                                                    value={
                                                                        item.branchID
                                                                    }
                                                                >
                                                                    {
                                                                        item.branchID
                                                                    }
                                                                </Select.Option>
                                                            )
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        label="मिति"
                                        name="kymDate"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 24 }}
                                    >
                                        <input
                                            type="text"
                                            className="ant-input"
                                            onKeyUp={onDateKeyUp}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col span={16}>
                                    <Row gutter={20}>
                                        <Col span={16}>
                                            <Form.Item
                                                label="सदस्यता नं"
                                                labelCol={{ span: 8 }}
                                                wrapperCol={{ span: 24 }}
                                                name="memberNO"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Required',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Select..."
                                                    showSearch
                                                    filterOption={(
                                                        input,
                                                        option
                                                    ) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase()
                                                            ) >= 0
                                                    }
                                                    style={{ width: '100%' }}
                                                >
                                                    {members?.length &&
                                                        members.map(
                                                            (item, i) => (
                                                                <Select.Option
                                                                    key={i}
                                                                    value={
                                                                        item.memberNO
                                                                    }
                                                                >
                                                                    {
                                                                        item.memberNO
                                                                    }
                                                                </Select.Option>
                                                            )
                                                        )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    {/* <Form.Item
                                        label="KYM ID"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 24 }}
                                    >
                                        <Select
                                            placeholder="Select..."
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {branches?.length &&
                                                branches.map((item, i) => (
                                                    <Select.Option
                                                        key={i}
                                                        value={item.branchID}
                                                    >
                                                        {item.branchID}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item> */}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={8}></Col>
                    </Row>
                </div>
                <div className="kym__each">
                    <h3>क‍. ब्यक्तिगत विवरणः</h3>
                    <div className="kym__each-inner">
                        <Form.Item label="१. नाम, थर" name="unName">
                            <div className="ant-input ant-input--fake">
                                {values?.unName}
                            </div>
                        </Form.Item>
                        <Form.Item label="२. जन्म मिति" name="birthDate">
                            <div className="ant-input ant-input--fake">
                                {ENG_ISO_TO_NEP(values?.dateOfBirth, 'ne')}
                            </div>
                        </Form.Item>
                        <Form.Item label="३. नगरिताको प्रमाणपत्र नं">
                            <div className="ant-input ant-input--fake">
                                {values?.citizenshipno}
                            </div>
                        </Form.Item>
                        <Form.Item label="४. नगरिताको प्रमाणपत्र जारी गर्ने कार्यालय">
                            <div className="ant-input ant-input--fake">
                                {values?.citDistrict}
                            </div>
                        </Form.Item>
                        <Form.Item label="५. लिङ्ग" name="gender">
                            <Radio.Group disabled>
                                {/* Remaining Nepali */}
                                <Radio value="M">परुष</Radio>
                                <Radio value="F">महल</Radio>
                                <Radio value="I">त</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </div>
                <div className="kym__each">
                    <h3>ख. पारिवारिक विवरण</h3>
                    <div className="kym__each-inner">
                        <Form.Item label="६. आमाको नाम">
                            <div className="ant-input ant-input--fake">
                                {values?.unMotherName}
                            </div>
                        </Form.Item>
                        <Form.Item label="७. बुबाको नाम">
                            <div className="ant-input ant-input--fake">
                                {values?.unFather}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="८. वैवाहिक स्थिति"
                            name="maritalStatus"
                        >
                            <Radio.Group disabled>
                                <Radio value="अविवाहित">विवाहित</Radio>
                                <Radio value="विवाहित">अविवाहित</Radio>
                                <Radio value="एकलअविवाहित">एकल</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="९. पति वा पत्नीको नाम, थर">
                            <div className="ant-input ant-input--fake">
                                {values?.unSpouse}
                            </div>
                        </Form.Item>
                        <Form.Item label="१०. परिवारको किसिम">
                            <Radio.Group>
                                <Space direction="vertical" name="familyType">
                                    <Radio value={1}>
                                        संयुक्त र एकै भातभान्छा
                                    </Radio>
                                    <Radio value={2}>
                                        संयुक्त तर अलग भातभान्छा
                                    </Radio>
                                    <Radio value={3}>
                                        छुटीभिन्न वा एकसरुवा(न्युक्लियर)
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </div>
                <div className="kym__each">
                    <h3>ग. पेशाको विवरण</h3>
                    <div className="kym__each-inner">
                        <Form.Item
                            label="११. आफ्नो मुख्य पेशा"
                            name="OccupationType"
                        >
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value={1}>खेतिपाती</Radio>
                                    <Radio value={2}>व्यवसाय (खुलाउने)</Radio>
                                    <Radio value={3}>नोकरी (खुलाउने)</Radio>
                                    <Radio value={4}>
                                        वैदेशिक रोजगारी (खुलाउने)
                                    </Radio>
                                    <Radio value={5}>अन्य (खुलाउने)</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        {values?.OccupationType &&
                        values?.OccupationType !== 1 ? (
                            <Form.Item
                                label=" "
                                name="spOccupationName"
                                colon={false}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Input placeholder="कृपया आफ्नो मुख्य पेसा खुलाउनुहोस" />
                            </Form.Item>
                        ) : null}
                        <Form.Item label="१२. स्थायी लेखा नं">
                            <div className="ant-input ant-input--fake">
                                {values?.panno}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="१३. पति वा पत्नीको मुख्य पेशा"
                            name="spOccupationType"
                        >
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value={1}>खेतिपाती</Radio>
                                    <Radio value={2}>व्यवसाय (खुलाउने)</Radio>
                                    <Radio value={3}>नोकरी (खुलाउने)</Radio>
                                    <Radio value={4}>
                                        वैदेशिक रोजगारी (खुलाउने)
                                    </Radio>
                                    <Radio value={5}>अन्य (खुलाउने)</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        {values?.spOccupationType &&
                        values?.spOccupationType !== 1 ? (
                            <Form.Item
                                label=" "
                                name="spOccupationName"
                                colon={false}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Input placeholder="कृपया आफ्नो पति/पत्नीको  मुख्य पेसा खुलाउनुहोस" />
                            </Form.Item>
                        ) : null}
                        <Form.Item
                            label="१४. आफु, पति वा पत्नीबाहेक परिवारमा अर्को मुख्य कमाउने सदस्य"
                            name="oeName"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label="१४.१ नाता" name="oeRelation">
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {relations?.length &&
                                    relations.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.relationNepali}
                                        >
                                            {item.relationNepali}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="१४.२ पेशा" name="oeOccupationType">
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value={1}>खेतिपाती</Radio>
                                    <Radio value={2}>व्यवसाय (खुलाउने)</Radio>
                                    <Radio value={3}>नोकरी (खुलाउने)</Radio>
                                    <Radio value={4}>
                                        वैदेशिक रोजगारी (खुलाउने)
                                    </Radio>
                                    <Radio value={5}>अन्य (खुलाउने)</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        {values?.oeOccupationType &&
                        values?.oeOccupationType !== 1 ? (
                            <Form.Item
                                label=" "
                                name="oeOccupationName"
                                colon={false}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Input placeholder="कृपया आफ्नो पति/पत्नीको  मुख्य पेसा खुलाउनुहोस" />
                            </Form.Item>
                        ) : null}
                        <Form.Item
                            label="१५. आफैँ वा परिवारको कुनै सदस्य उच्च पदीय राजनीतिक, प्रशासनिक, वा साङ्गठनिक भूमिकामा रहे, नरहेको"
                            labelCol={{ span: 24 }}
                        ></Form.Item>
                        <Form.Item
                            label=""
                            style={{ paddingLeft: '2rem' }}
                            name="highRankingRelation"
                        >
                            <Radio.Group>
                                <Radio value={true}>रहेको</Radio>
                                <Radio value={false}>नरहेको</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="१६. आफैँ वा परिवारको कुनै सदस्य उच्च पदीय राजनीतिक, प्रशासनिक, वा साङ्गठनिक भूमिकामा भए विवरण"
                            labelCol={{ span: 24 }}
                        ></Form.Item>

                        <Form.Item
                            label="(१). नाम"
                            name="hrpName"
                            labelAlign="left"
                            style={{ paddingLeft: '2rem' }}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="(२). नाता"
                            name="hrpRelation"
                            labelAlign="left"
                            style={{ paddingLeft: '2rem' }}
                        >
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {relations?.length &&
                                    relations.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.relationNepali}
                                        >
                                            {item.relationNepali}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="(३). पद वा सार्वजनिक भूमिका"
                            nae="hrpPosition"
                            labelAlign="left"
                            style={{ paddingLeft: '2rem' }}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </div>
                <div className="kym__each">
                    <h3>घ. रहनसहनको विवरण:</h3>
                    <div className="kym__each-inner">
                        <Form.Item label="१७. स्थायी ठेगाना"></Form.Item>
                        <Form.Item
                            label="(१). प्रदेश"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.unProvince}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(२). जिल्ला"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.unDistrict}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(३).नगरपालिका/गाउँपालिका"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.unvdc}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(४).वडा"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.unWardNO}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(५). गाउँ/टोल"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.unTole}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(६). घर नं"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.blockNO}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(७). सम्पर्क नं"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.mobileNO}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(८). इमेल आइ.डी"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.emailID}
                            </div>
                        </Form.Item>
                        <Form.Item label="१८. अस्थायी ठेगाना"></Form.Item>
                        <Form.Item
                            label="(१). प्रदेश"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.untProvince}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(२). जिल्ला"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.untDistrict}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(३).नगरपालिका/गाउँपालिका"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.untvdc}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(४).वडा"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.untWardNO}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(५). गाउँ/टोल"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.untTole}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="(६). घर नं"
                            className="kym__nested-form-item"
                        >
                            <div className="ant-input ant-input--fake">
                                {values?.tmpBlockNo}
                            </div>
                        </Form.Item>
                        <Form.Item
                            label="१९. संस्थाको कार्यक्षेत्रको बसोबासः"
                            name="liveInWorkingArea"
                        >
                            <Radio.Group>
                                <Radio value={true}>स्थायी</Radio>
                                <Radio value={false}>अस्थायी</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="२०. मतदाता परिचय-पत्र नं"
                            name="voterNo"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label="२१. मतदानस्थल" name="electionBooth">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="२२. सस्थाको कार्यक्षेत्रमा वर्षमा बिताउने अवधि"
                            name="LivingDuration"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="२३. राहदानी नं. भएमा"
                            name="passportNo"
                        >
                            <Input />
                        </Form.Item>
                    </div>
                </div>
                <div className="kym__each">
                    <h3>ङ. सहकारी सदस्यता</h3>
                    <div className="kym__each-inner">
                        <Form.Item
                            label="२४. संस्थाको सदस्यता बन्नुको उद्देश्य"
                            name="purposeOfMembership"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="२५. आफैँ अर्को सहकारी संस्थाको पनि सदस्य भए, नभएको"
                            name="otherCoopMembership"
                        >
                            <Radio.Group>
                                <Radio value={true}>भएको</Radio>
                                <Radio value={false}>नभएको</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="२६. आफैँ अर्को सहकारी संस्थाको पनि सदस्य भएको भए विवरण"></Form.Item>
                        <Table
                            dataSource={otherCoopMembershipData}
                            columns={otherCoopMembershipColumns}
                            size="small"
                            pagination={false}
                            style={{ margin: '1rem 0' }}
                        />
                        <Form.Item
                            label="२७. आफूबाहेक परिवारको सदस्य अर्को सहकारी संस्थाको पनि सदस्य  भए, नभएको"
                            name="familyMemberinOtherCoop"
                            labelCol={{ span: 12 }}
                        >
                            <Radio.Group>
                                <Radio value={true}>भएको</Radio>
                                <Radio value={false}>नभएको</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="२८. आफूबाहेक परिवारको सदस्य अर्को सहकारी संस्थाको पनि सदस्य भएको भए विवरण"
                            labelCol={{ span: 24 }}
                        ></Form.Item>
                        <Table
                            dataSource={familyMemberInOtherCoopData}
                            columns={familyMemberInOtherCoopColumns}
                            size="small"
                            pagination={false}
                            style={{ margin: '1rem 0' }}
                        />
                        <Form.Item
                            label="२९. आफैँ अन्य सहकारी संस्थाको पनि सदस्य भएको भए दोहोरो वा बहुसंस्थामा सदस्यताको प्रयोजन"
                            name="purposeOfMultipleMembership"
                            labelCol={{ span: 12 }}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="३०. आफूबाहेक परिवारको अन्य सहकारी संस्थाको पनि सदस्य भएको भए दोहोरो वा बहुसंस्थामा सदस्यताको प्रयोजन"
                            name="fmPurposeOfMM"
                            labelCol={{ span: 12 }}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="३१. परिवारको अर्को सदस्य पनि यसै संस्थाको सदस्य भएको भए,नभएको"
                            name="fmMembershipOfSameCoop"
                            labelCol={{ span: 12 }}
                        >
                            <Radio.Group>
                                <Radio value={true}>भएको</Radio>
                                <Radio value={false}>नभएको</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="३२. परिवारको अर्को सदस्य पनि यसै संस्थाको सदस्य भएको भए विवरण"></Form.Item>
                        <Table
                            dataSource={fmMembershipOfSameCoopData}
                            columns={fmMembershipOfSameCoopColumns}
                            size="small"
                            pagination={false}
                            style={{ margin: '1rem 0' }}
                        />
                        {/* <Form.Item label="१८. अ�थ�य� ठ� ग�न�"></Form.Item>
                        <Form.Item
                            label="(१). प्रद�श�"
                            className="kym__nested-form-item"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="(२).�ज���"
                            className="kym__nested-form-item"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="(३).नगरप��लक�/ग�उ�प��लक��"
                            className="kym__nested-form-item"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="(४). व���"
                            className="kym__nested-form-item"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="(५). ग�उ�/ट�ल�"
                            className="kym__nested-form-item"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="(६). घर न��"
                            className="kym__nested-form-item"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label="१९. सथक कयत्रक बसबस">
                            <Radio.Group>
                                <Radio>थय</Radio>
                                <Radio>अथय</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="२०. मतदत परचय-पत्र न">
                            <Input />
                        </Form.Item>
                        <Form.Item label="२१. मतदनथल">
                            <Input />
                        </Form.Item>
                        <Form.Item label="२२. सथक कयत्रम वषम बतउन अव">
                            <Input />
                        </Form.Item>
                        <Form.Item label="२३. रहदन न. भएम">
                            <Input />
                        </Form.Item> */}
                    </div>
                </div>
                <div className="kym__each">
                    <h3>च. आम्दानीको विवरण:</h3>
                    <div className="kym__each-inner">
                        <Form.Item
                            label="३३. वार्षिक पारिवारिक (आफ्नो,पति वा पत्नीको र एउटै भातभान्छाको सदस्यहरुको) आम्दानी(जग्गाको खुद आयस्ता, नोकरीको पारिश्रमिक, व्यवसायको मुनाफा इत्यादि)"
                            labelCol={{ span: 24 }}
                        ></Form.Item>
                        <Form.Item
                            label=""
                            style={{ paddingLeft: '2rem' }}
                            name="annualIncome"
                        >
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value={1}>रु. ४ लाखसम्म</Radio>
                                    <Radio value={2}>
                                        रु. ४ लाखभन्दा बढि रु.१० लाखसम्म
                                    </Radio>
                                    <Radio value={3}>
                                        रु. १० लाखभन्दा बढि रु.२५ लाखसम्म
                                    </Radio>
                                    <Radio value={4}>
                                        रु. २५ लाखभन्दा बढि रु.५० लाखसम्म
                                    </Radio>
                                    <Radio value={5}>रु.५० लाख बढि</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="३४. रु.४ लाखभन्दा बढि वार्षिक पारिवारिक आम्दानी भए पछिल्लो आर्थिक वर्षको आय र स्रोतको विवरण"
                            labelCol={{ span: 24 }}
                        ></Form.Item>
                        <Table
                            dataSource={incomeAbove4LakhData}
                            columns={incomeAbove4LakhColumns}
                            size="small"
                            pagination={false}
                            style={{ margin: '1rem 0' }}
                        />
                    </div>
                </div>
                <div className="kym__each">
                    <h3>छ. वित्तीय कारोबारको विवरण</h3>
                    <div className="kym__each-inner">
                        <Form.Item
                            label="३५. प्रारम्भमा संस्थामा जम्मा गर्ने वा हालसम्ममा स‌ंस्थामा जम्मा गरिसकेको रकमको विवरण"
                            labelCol={{ span: 24 }}
                        ></Form.Item>
                        <Table
                            dataSource={organizationalDepositData}
                            columns={organizationalDepositColumns}
                            size="small"
                            pagination={false}
                            style={{ margin: '1rem 0' }}
                        />
                        <Form.Item
                            label="३६.संस्थाको खातामा अनुमानित बचतको रकमकलम"
                            labelCol={{ span: 24 }}
                        ></Form.Item>
                        <Form.Item
                            label="क.बर्षमा करोबर गर्ने पटक"
                            name="noOfTransactionTimes"
                            className="kym__nested-form-item"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="ख.वार्षिक रुपमा जम्मा गर्ने अनुमानित रकमः रु."
                            name="estimatedYearlyTransaction"
                            className="kym__nested-form-item"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="३७.संस्थासँग अनुमानित ऋणको  रकमकलमः रु."
                            name="LoanAmount"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="३८.अभिलेखमा राख्नयोग्य थप विवरण वा द्रष्टव्य"
                            name="description"
                        >
                            <Input />
                        </Form.Item>
                        <h4>
                            (१) मैले पेस गरेको यो विवरण भविष्यमा कुनै परिवर्तन
                            आएमा त्यस्तो परिवर्तन भएको मितिले ३५ दिनभित्र
                            संस्थामा पेश गर्नेछु।
                        </h4>
                    </div>
                </div>
                <div className="kym__each">
                    <h3>झ. संलग्न कागजातहरु:</h3>
                    <div className="kym__each-inner">
                        <Form.Item
                            label="३५.प्ररम्भम सथम जम्म गन व हलसम्मम स थम जम्म गरसक क रकमक ववरण"
                            name="docProvided"
                            labelCol={{ span: 24 }}
                        >
                            <Radio.Group style={{ paddingLeft: '2rem' }}>
                                <Space direction="vertical">
                                    <Radio value={1}>
                                        नेपाली नागरिकताको प्रमाण-पत्र प्रतिलिपि
                                    </Radio>
                                    <Radio value={2}>
                                        मतदाता परिचय-पत्रको प्रतिलिपि
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </div>
                <div className="kym__each">
                    <h3>सिफारिसकर्ता सदस्यको</h3>
                    <div className="kym__each-inner">
                        <Form.Item
                            label="दस्तखत:"
                            labelCol={{ span: 24 }}
                        ></Form.Item>
                        <Form.Item
                            label="नाम:"
                            labelCol={{ span: 24 }}
                        ></Form.Item>
                        <Form.Item
                            label="सदस्यता नं:"
                            labelCol={{ span: 24 }}
                        ></Form.Item>
                    </div>
                </div>
                <Button type="primary" onClick={onKymSubmit}>
                    Save
                </Button>
            </Form>
        </>
    );
}
