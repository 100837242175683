import React from 'react';
// Import from dependencies
import { Button, message } from 'antd';
// Import utils
import axios, { GET_USERS, GET_USER_DETAIL } from '../../../utils/axios';
import { SET_DEFAULT_VALUES } from '../../../utils/functions';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import BranchAssignmentForm from '../../../components/AdministrativePanel/BranchAssignment/BranchAssignmentForm/BranchAssignmentForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function BranchAssignment() {
    // Local states
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [users, setUsers] = React.useState([]);
    const [assignedBranches, setAssignedBranches] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [isProcessing, setIsProcessing] = React.useState(false);

    // Refs
    // const formRef = React.useRef();

    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            counterBranchID: JSON.parse(
                localStorage.getItem('USER_CREDENTIALS')
            ).branchID,
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            undefined,
            undefined
        );
    }, []);

    // On view form values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];
        // let values;
        // if (mode === 'view') values = viewValues;
        // else if (mode === 'edit') values = editValues;
        // else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (key === 'userName') {
            const user = users.find(user => user.userName === value);
            getUserDetail(user.userID);
            getAssignedBranches(value);
        } else if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(branch => branch[key] === value);
            return updateValues(branch);
        }
        // Update values
        updateValues(val);
    };

    // Update assigned branches
    const updateAssignedBranches = () => {
        setIsProcessing(true);
        axios
            .put(
                `${process.env.REACT_APP_HOST}/api/branch/UpdateAssignedBranchIDs/${viewValues.userName}`,
                assignedBranches
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                message.success(res.data.statusMessage);
            })
            .catch(err => {
                console.error(err, 'Failed to update assigned branches');
                message.error('Failed to update branches');
            })
            .finally(() => {
                setIsProcessing(false);
            });
    };

    // COMMON
    // Get user detail
    const getUserDetail = async userID => {
        const userDetail = await GET_USER_DETAIL(userID);
        setViewValues(userDetail);
    };

    // Get assigned branches
    const getAssignedBranches = async userName => {
        setAssignedBranches([]);
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_HOST}/api/branch/GetAssignedBranchIDs/${userName}`
            );
            if (res.data.statusCode !== 0)
                return message.error(res.data.statusMessage);
            console.log(res.data.data);
            setAssignedBranches(res.data.data);
        } catch (err) {
            console.error(err, 'Failed to get assigned branches');
            message.error('Something went wrong. Please try again!');
        }
    };

    // Form props
    const formProps = {
        onValuesChange,
        users,
        assignedBranches,
        setAssignedBranches,
        setDefaultValues,
        branches,
    };

    // Get users
    React.useState(() => {
        // Get users
        (async () => {
            const users = await GET_USERS();
            setUsers(users);
        })();
    }, []);

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
    }, [setDefaultValues]);

    return (
        <PageWrapper>
            <BranchAssignmentForm
                {...formProps}
                mode="view"
                values={viewValues}
            />
            <BottomActionBarFixed style={{ justifyContent: 'start' }}>
                <Button onClick={updateAssignedBranches} loading={isProcessing}>
                    Update
                </Button>
            </BottomActionBarFixed>
        </PageWrapper>
    );
}
