import React from 'react';
import {
    GET_SAVINGS_STATEMENT_ALL,
    GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS,
} from '../../../utils/axios';
import {
    ON_COLUMNS_CHANGE,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../utils/functions';
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import StatementAllForm from '../../../components/Savings/StatementAll/StatementAllForm/StatementAllForm';
import moment from 'moment';

export default function StatementAll() {
    // Local states
    const [viewValues, setViewValues] = React.useState(null);
    const [statement, setStatement] = React.useState(null);
    const [activeDepTypeAccounts, setActiveDepTypeAccounts] =
        React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [columns, setColumns] = React.useState([
        'Balance',
        'Int. Common(%)',
        'Old Interest',
        'Cur. Interest',
        'Total Int.',
        'Total Tax',
        'Withdrawable',
    ]);
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];

    // UTILS
    // On pagination change
    const onPaginationChange = (page, pageSize) => {
        console.log(page, pageSize);
        setPage(page);
        setPageSize(pageSize);
    };

    // On columns list change
    const onColumnsChange = e => {
        ON_COLUMNS_CHANGE(e, setColumns);
    };

    // On form values change
    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];

        // Update values on state
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        if (key === 'acName' || key === 'acno') {
            if (value === 'All')
                return updateValues({ acName: 'All', acno: 'All' });
            const acc = activeDepTypeAccounts.find(item => item[key] === value);
            return updateValues(acc);
        } else if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(item => item[key] === value);
            return updateValues(branch);
        }

        // Update values
        updateValues(val);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            queryDate: moment().format('YYYY-MM-DD'),
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // Get active deposit type accounts
    React.useEffect(() => {
        (async () => {
            const activeDepTypeAccounts =
                await GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS();
            setActiveDepTypeAccounts(activeDepTypeAccounts);
        })();
    }, []);

    // Get statement
    React.useEffect(() => {
        if (
            !viewValues?.acno ||
            !viewValues?.queryDate ||
            !viewValues?.branchID
        )
            return;
        setIsLoading(true);
        const payload = {
            pageNO: page,
            rpp: pageSize,
            mainbookNo: viewValues?.acno,
            queryDate: viewValues?.queryDate,
            branchID: viewValues?.branchID,
        };

        (async () => {
            const statement = await GET_SAVINGS_STATEMENT_ALL(payload);
            setStatement(statement);
            setIsLoading(false);
        })();
    }, [
        page,
        pageSize,
        viewValues?.acno,
        viewValues?.queryDate,
        viewValues?.branchID,
    ]);

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    // Form props
    const formProps = {
        statement,
        isLoading,
        onPaginationChange,
        columnsList: columns,
        onColumnsChange,
        onValuesChange,
        values: viewValues,
        onDateChange,
        onDateKeyUp,
        activeDepTypeAccounts,
        branches,
    };
    return (
        <PageWrapper>
            <StatementAllForm {...formProps} />
        </PageWrapper>
    );
}
