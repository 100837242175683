import React from 'react';
import { Row, Col, Form } from 'antd';
import moment from 'moment';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';

export default function MemberInfoPersonalDetail(props) {
    // Destructuring
    const { memberProperties } = props;
    return (
        <div>
            <Row gutter={20}>
                <Col span={16}>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Member No"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.memberNO}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Entry Date">N/A</Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Member Name"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.memberName}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item></Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Address"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.memAddress}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Temporary Address"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.memtempAddress}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Date of Birth"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.dobEnglish
                                        ? moment(
                                              memberProperties?.dobEnglish
                                          ).format('DD-MM-YYYY')
                                        : ''}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.dobEnglish
                                        ? ENG_ISO_TO_NEP(
                                              memberProperties?.dobEnglish
                                          )
                                        : ''}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Gr. Father's Name"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.grandFather}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.unGrandFather}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Father's Name"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.fatherName}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.unFather}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Mother's Name"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.motherName}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.unMotherName}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Marital Status"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.maritalStatus}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Spouse"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.spouse}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.unSpouse}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Caste Group"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.casteGroup}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Education"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.education}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item
                                label="Occupation"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.occupation}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Monthly Income"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.monthlyIncome}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="PAN No">
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.panno}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Citizenship No"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.monthlyIncome}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Citizenship Dist.">N/A</Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Total Share"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.shareAmount}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Total Saving"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.totalSaving}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Total Loan"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.totalLoan}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="L. Payment Fund"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {memberProperties?.allLpFundBalance}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}></Col>
            </Row>
        </div>
    );
}
