import React from 'react';
import { Button, message, Modal } from 'antd';
import axios from '../../../utils/axios';
import { UPDATE_AXIOS_AUTH, ON_FILE_CHANGE } from '../../../utils/functions';
import { useRecoilValue } from 'recoil';
import { userCredentials as userCredentialsAtom } from '../../../recoil/atoms';
import moment from 'moment';
// Import functions
import {
    JSON_TO_FORM_DATA,
    DLT_KEYS_FROM_OBJ,
    SET_DEFAULT_VALUES,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../utils/functions';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import StaffRegistrationForm from '../../../components/AdministrativePanel/StaffRegistrationForm/StaffRegistrationForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function StaffRegistration() {
    // Global states
    const userCredentials = useRecoilValue(userCredentialsAtom);
    // Local states
    const [casteGroups, setCasteGroups] = React.useState([]);
    const [castes, setCastes] = React.useState([]);
    const [provinces, setProvinces] = React.useState([]);
    const [districts, setDistricts] = React.useState([]);
    const [maritalStatuses, setMaritalStatuses] = React.useState([]);
    const [municipalities, setMunicipalities] = React.useState([]);
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [staffTypes, setStaffTypes] = React.useState([]);
    const [staffs, setStaffs] = React.useState([]);
    const [staffPosts, setStaffPosts] = React.useState([]);
    const [memberNos, setMemberNos] = React.useState([]);
    const [religions, setReligions] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(
            key,
            date,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues, setEditValues, setAddValues);
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            entranceDate: moment().format(),
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    // Get select dropdown options
    const getSelectionData = () => {
        // Get staff types
        if (!staffTypes.length) getStaffTypes();
        // Get staff posts
        if (!staffPosts.length) getStaffPosts();
        // Get provinces
        if (!provinces.length) getProvinces();
        // Get caste groups
        if (!casteGroups.length) getCasteGroups();
        // Get religions
        if (!religions.length) getReligions();
        // Get marital statuses
        if (!maritalStatuses.length) getMaritalStatuses();
    };

    // Get caste groups
    const getCasteGroups = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Caste/CasteGroup`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setCasteGroups(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get caste groups');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get castes
    const getCastes = casteGroup => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Caste/Caste/${casteGroup}`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data);
                setCastes(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get castes');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get provinces
    const getProvinces = casteGroup => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/District/Province`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setProvinces(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get provinces');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get districts
    const getDistricts = province => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/District/${province}`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setDistricts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get districts');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get marital statuses
    const getMaritalStatuses = province => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/MaritalStatus`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);

                setMaritalStatuses(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get marital statuses');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get municipalities
    const getMunicipalities = district => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/District/Municipal/${district}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data, 'mun');
                setMunicipalities(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get municipalities');
                message.error('Something went wrong. Please try again!');
            });
    };
    // Get staff types
    const getStaffTypes = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/StaffTypePost/GetStaffType`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setStaffTypes(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get staff types');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get staff posts
    const getStaffPosts = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/StaffTypePost/GetPost`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setStaffPosts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get staff posts');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get staffs by branch
    const getStaffs = React.useCallback(branchID => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/Staff/GetByBranchID/${branchID}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data);
                setStaffs(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get staffs');
                message.error('Something went wrong. Please try again!');
            });
    }, []);

    // Get staff detail
    const getStaffDetail = staffID => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Staff/Detail/${staffID}`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data, 'Staff detail');
                setViewValues(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get staff detail');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get member nos
    const getMemberNos = branchID => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/Member/GetMemberNo/${branchID}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setMemberNos(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get staff detail');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get religions
    const getReligions = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Caste/Religion`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setReligions(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get religions');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get member detail
    const getMemberDetail = memberNo => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/Staff/GetMemberDetail/${memberNo}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data, 'member detail');
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get staff detail');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get new staff ID
    const getNewStaffID = React.useCallback(branchID => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/Staff/AddStaffID/${branchID}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setAddValues(prevValues => ({
                    ...prevValues,
                    staffID: res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get new staff ID');
                message.error('Something went wrong. Please try again!');
            });
    }, []);

    // EDIT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        getSelectionData();
        if (!districts.length) getDistricts(viewValues?.province);
        if (!municipalities.length) getMunicipalities(viewValues?.district);
        if (!castes.length) getCastes(viewValues?.casteGroup);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = {
                    ...editValues,
                    modifiedBy: userCredentials.userName,
                };
                const formData = JSON_TO_FORM_DATA(payload);

                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/Staff/Update/${editValues?.staffID}`,
                        formData
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);

                        setViewValues(editValues);
                        setEditModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Updated!');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to update staff');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setEditModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {});
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD MODAL
    // On add modal open
    const onAddModalOpen = () => {
        getSelectionData();
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On add modal ok
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = {
                    ...addValues,
                    modifiedBy: userCredentials.userName,
                };
                const formData = JSON_TO_FORM_DATA(payload);
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/Staff/Add/${addValues?.staffID}`,
                        formData
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        setStaffs([
                            ...staffs,
                            {
                                staffID: addValues?.staffID,
                                staffName: addValues?.staffName,
                            },
                        ]);
                        setViewValues(addValues);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        setAddValues(null);
                        message.success('New staff added!');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to add staff');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {});
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // DELETE ACCOUNT MODAL
    // On delete modal open
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On delete modal ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/Staff/Delete/${viewValues?.staffID}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setStaffs(
                    [...staffs].filter(
                        staff => staff.staffID !== viewValues?.staffID
                    )
                );
                setViewValues(null);
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Staff deleted!');
            })
            .catch(err => {
                console.error(err, 'Failed to delete staff');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };
    // On delete modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // On values change
    const onValuesChange = (val, mode) => {
        console.log('val: ', val);
        // const key = Object.keys(val)[0];
        // const value = val[key];
        let values;
        if (mode === 'view') values = addValues;
        else if (mode === 'edit') values = editValues;
        else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (val.hasOwnProperty('branchID')) {
            const branch = branches.find(
                branch => branch.branchID === val.branchID
            );
            if (mode === 'view') setViewValues(branch);
            else updateValues(branch);
        } else if (val.hasOwnProperty('branchName')) {
            const branch = branches.find(
                branch => branch.branchName === val.branchName
            );
            if (mode === 'view') setViewValues(branch);
            else updateValues(branch);
        } else if (val.hasOwnProperty('staffID')) {
            getStaffDetail(val.staffID);
        } else if (val.hasOwnProperty('memberNo')) {
            if (mode === 'add') getMemberDetail(val.memberNo);
        } else if (val.hasOwnProperty('district')) {
            getMunicipalities(val.district);
        } else if (val.hasOwnProperty('province')) {
            getDistricts(val.province);
            // Delete fields from state
            values &&
                updateValues(DLT_KEYS_FROM_OBJ(values, ['district', 'vdcMun']));
        } else if (val.hasOwnProperty('casteGroup')) {
            getCastes(val.casteGroup);
            // Delete fields from state
            values &&
                updateValues(
                    DLT_KEYS_FROM_OBJ(values, ['casteName', 'religion'])
                );
        } else if (
            val.hasOwnProperty(`entranceDate__${mode}`) ||
            val.hasOwnProperty(`dateOfBirth__${mode}`)
        ) {
            return;
        }
        updateValues(val);
    };

    // On checkbox change
    const onCheckboxChange = (e, mode) => {
        const key = e.target.name;
        const checked = e.target.checked;

        if (mode === 'edit') {
            setEditValues(prevValues => ({
                ...prevValues,
                [key]: checked,
            }));
        } else if (mode === 'add') {
            setAddValues(prevValues => ({
                ...prevValues,
                [key]: checked,
            }));
        }
    };

    // On file change
    const onFileChange = (e, mode) => {
        ON_FILE_CHANGE(e, mode, setEditValues, setAddValues);
    };

    // Form props
    const formProps = {
        ref: formRef,
        branches,
        getStaffs,
        getNewStaffID,
        staffs,
        staffTypes,
        staffPosts,
        memberNos,
        provinces,
        districts,
        municipalities,
        casteGroups,
        castes,
        religions,
        maritalStatuses,
        onCheckboxChange,
        getMemberNos,
        onValuesChange,
        onFileChange,
        setDefaultValues,
        onDateChange,
        onDateKeyUp,
    };

    // On component mount
    React.useEffect(() => {
        UPDATE_AXIOS_AUTH();
    }, []);
    return (
        <PageWrapper>
            <StaffRegistrationForm
                {...formProps}
                mode="view"
                values={viewValues}
            />
            <BottomActionBarFixed>
                <Button
                    type="primary"
                    onClick={onEditModalOpen}
                    disabled={!viewValues?.staffID}
                >
                    Edit
                </Button>
                <Button type="primary" onClick={onAddModalOpen}>
                    Add
                </Button>
                <Button
                    type="primary"
                    onClick={onDeleteModalOpen}
                    disabled={!viewValues?.staffID}
                >
                    Delete
                </Button>
            </BottomActionBarFixed>
            {/* Edit account modal */}
            <Modal
                title="Edit account"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                onOk={onEditModalOk}
                onCancel={onEditModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: editModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <StaffRegistrationForm
                    {...formProps}
                    mode="edit"
                    values={editValues}
                />
            </Modal>
            {/* Add modal */}
            <Modal
                title="Add staff"
                visible={addModal.visible}
                confirmLoading={addModal.confirmLoading}
                onOk={onAddModalOk}
                onCancel={onAddModalCancel}
                okText="Create"
                cancelButtonProps={{
                    disabled: addModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1100}
            >
                <StaffRegistrationForm
                    {...formProps}
                    mode="add"
                    values={addValues}
                />
            </Modal>
            {/* Delete account modal */}
            <Modal
                title="Delete staff?"
                visible={deleteModal.visible}
                confirmLoading={deleteModal.confirmLoading}
                onOk={onDeleteModalOk}
                onCancel={onDeleteModalCancel}
                okText="Delete"
                cancelButtonProps={{
                    disabled: deleteModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>The account and its data will be deleted forever</p>
            </Modal>
        </PageWrapper>
    );
}
