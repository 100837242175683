import React from 'react';
import { Button, message, Modal } from 'antd';
import { useRecoilValue } from 'recoil';
import {
    branches as branchesAtom,
    userCredentials as userCredentialsAtom,
} from '../../../recoil/atoms';
import moment from 'moment';
// Import utils
import axios from '../../../utils/axios';
import {
    SET_DEFAULT_VALUES,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../utils/functions';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import AssetsRegistrationForm from '../../../components/ItemSetup/AssetsRegistrationForm/AssetsRegistrationForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function AssetsRegistration() {
    // Global states
    const branches = useRecoilValue(branchesAtom);
    const userCredentials = useRecoilValue(userCredentialsAtom);
    // Local states
    const [mainAccounts, setMainAccounts] = React.useState([]);
    const [accounts, setAccounts] = React.useState([]);
    const [assets, setAssets] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [asyncUpdateModal, setAsyncUpdateModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(
            key,
            date,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues, setEditValues, setAddValues);
    };

    // Get bank accounts
    const getMainAccounts = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/AssetsType/MainAccount`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setMainAccounts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get main accounts');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get accounts
    const getAccounts = mano => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/AssetsType/AssetsAccount/${mano}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setAccounts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get accounts');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get assets
    const getAssets = (branchID, acno) => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/AssetItem/GetAssetsID/${branchID}/${acno}`
            )
            .then(res => {
                console.log(res);
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setAssets(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get assets');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get asset detail
    const getAssetDetail = (acno, assetsNo) => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/AssetItem/Detail/${acno}/${assetsNo}`
            )
            .then(res => {
                console.log(res);
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);

                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get assets');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get new asset no
    const getNewAssetNo = acno => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/AssetItem/GetNewID/${addValues?.branchID}/${addValues?.mano}/${acno}`
            )
            .then(res => {
                console.log(res);
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setAddValues(prevValues => ({
                    ...prevValues,
                    assetsNo: res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get new asset number');
                message.error('Something went wrong. Please try again!');
            });
    };

    // On main account change
    const onMAChange = obj => {
        // prettier-ignore
        ['acno', 'acName', 'nepName', 'assetsNo', 'assetName', 'brand', 'asstSize', 'color', 'marketPrice', 'depreciation', 'description'].forEach(key => delete obj[key])
        return obj;
    };

    // On main account change
    const onAccChange = obj => {
        // prettier-ignore
        [ 'assetsNo', 'assetName', 'brand', 'asstSize', 'color', 'marketPrice', 'depreciation', 'description'].forEach(key => delete obj[key])
        return obj;
    };

    // On asset change
    const onAssetChange = obj => {
        // prettier-ignore
        [ 'brand', 'asstSize', 'color', 'marketPrice', 'depreciation', 'description'].forEach(key => delete obj[key])
        return obj;
    };

    // On values change
    const onValuesChange = (val, mode) => {
        // const key = Object.keys(val)[0];
        // const value = val[key];
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (val.hasOwnProperty('mano')) {
            if (mode === 'view') {
                const valuesTemp = onMAChange({ ...viewValues });
                setViewValues(valuesTemp);
            } else if (mode === 'add') {
                const valuesTemp = onMAChange({ ...addValues });
                setAddValues(valuesTemp);
            }
            const acc = mainAccounts.find(acc => acc.mano === val.mano);
            updateValues({ maName: acc.maName });
            getAccounts(acc.mano);
        } else if (val.hasOwnProperty('maName')) {
            if (mode === 'view') {
                const valuesTemp = onMAChange({ ...viewValues });
                setViewValues(valuesTemp);
            } else if (mode === 'add') {
                const valuesTemp = onMAChange({ ...addValues });
                setAddValues(valuesTemp);
            }
            const acc = mainAccounts.find(acc => acc.maName === val.maName);
            updateValues({ mano: acc.mano });
            getAccounts(acc.mano);
        } else if (val.hasOwnProperty('branchID')) {
            const branch = branches.find(
                branch => branch.branchID === val.branchID
            );
            updateValues({ branchName: branch.branchName });
        } else if (val.hasOwnProperty('branchName')) {
            const branch = branches.find(
                branch => branch.branchName === val.branchName
            );
            updateValues({ branchID: branch.branchID });
        } else if (val.hasOwnProperty('acno')) {
            const acc = accounts.find(acc => acc.acno === val.acno);
            if (mode === 'view') {
                const valuesTemp = onAccChange({ ...viewValues });
                setViewValues(valuesTemp);
                getAssets(viewValues?.branchID, acc.acno);
            } else if (mode === 'add') {
                const valuesTemp = onAccChange({ ...addValues });
                setAddValues(valuesTemp);
                getNewAssetNo(acc.acno);
            }
            updateValues({ acName: acc.acName, nepName: acc.nepName });
        } else if (val.hasOwnProperty('acName')) {
            const acc = accounts.find(acc => acc.acName === val.acName);
            if (mode === 'view') {
                const valuesTemp = onAccChange({ ...viewValues });
                setViewValues(valuesTemp);
                getAssets(viewValues?.branchID, acc.acno);
            } else if (mode === 'add') {
                const valuesTemp = onAccChange({ ...addValues });
                setAddValues(valuesTemp);
                getNewAssetNo(acc.acno);
            }
            updateValues({ acno: acc.acno, nepName: acc.nepName });
        } else if (val.hasOwnProperty('assetsNo')) {
            if (mode === 'view') {
                const valuesTemp = onAssetChange({ ...viewValues });
                setViewValues(valuesTemp);
                const asset = assets.find(
                    asset => asset.assetsNo === val.assetsNo
                );
                updateValues({ assetName: asset.assetName });
                getAssetDetail(viewValues?.acno, asset.assetsNo);
            } else if (mode === 'add') {
                const valuesTemp = onAssetChange({ ...addValues });
                setAddValues(valuesTemp);
            }
        } else if (val.hasOwnProperty('assetName')) {
            if (mode === 'view') {
                const valuesTemp = onAssetChange({ ...viewValues });
                setViewValues(valuesTemp);
                const asset = assets.find(
                    asset => asset.assetName === val.assetName
                );
                updateValues({ assetsNo: asset.assetsNo });
                getAssetDetail(viewValues?.acno, asset.assetsNo);
            } else if (mode === 'add') {
                const valuesTemp = onAssetChange({ ...addValues });
                setAddValues(valuesTemp);
                return updateValues({ assetName: val.assetName.toUpperCase() });
            }
        } else if (val.hasOwnProperty(`entranceDate__${mode}`)) return;

        updateValues(val);
    };

    // EDIT ACCOUNT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current.validateFields().then(() => {
            setEditModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));

            axios
                .patch(
                    `${process.env.REACT_APP_HOST}/api/AssetItem/Update/${editValues?.acno}/${editValues?.assetsNo}`,
                    editValues
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        if (res.data.statusCode === 2)
                            return setAsyncUpdateModal(prevValues => ({
                                ...prevValues,
                                visible: true,
                            }));
                        else return message.error(res.data.statusMessage);

                    setViewValues(editValues);
                    setEditModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    message.success('Updated!');
                })
                .catch(err => {
                    console.error(err, 'Failed to update asset');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setEditModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };

    // On async update modal ok
    const onAsyncUpdateModalOk = () => {
        setAsyncUpdateModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .patch(
                `${process.env.REACT_APP_HOST}/api/AssetItem/UpdateAll/${editValues?.acno}/${editValues?.assetsNo}`,
                editValues
            )
            .then(res => {
                if (res.data.statusCode !== 0) {
                    setAsyncUpdateModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                    return message.error(res.data.statusMessage);
                }
                setViewValues(editValues);
                setAsyncUpdateModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                setEditModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Updated async!');
            })
            .catch(err => {
                console.error(err, 'Failed to update asset asynchronously');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setAsyncUpdateModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // On async update modal cancel
    const onAsyncUpdateModalCancel = () => {
        setAsyncUpdateModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD ACCOUNT MODAL
    // On add modal open
    const onAddModalOpen = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On add modal ok
    const onAddModalOk = () => {
        formRef.current.validateFields().then(() => {
            setAddModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));
            const payload = {
                ...addValues,
                user: userCredentials.userName,
            };
            axios
                .post(
                    `${process.env.REACT_APP_HOST}/api/AssetItem/Add/${addValues?.acno}/${addValues?.assetsNo}`,
                    payload
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    setViewValues(addValues);
                    setAddModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    setAssets([
                        ...assets,
                        {
                            assetsNo: addValues?.assetsNo,
                            assetName: addValues?.assetName,
                        },
                    ]);
                    setAddValues(null);
                    message.success('New item created!');
                })
                .catch(err => {
                    console.error(err, 'Failed to create new asset item');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setAddModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // DELETE ACCOUNT MODAL
    // On delete modal open
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On delete modal ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/AssetItem/Delete/${viewValues?.acno}/${viewValues?.assetsNo}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setAssets(
                    [...assets].filter(
                        asset => asset.assetsNo !== viewValues.assetsNo
                    )
                );
                setViewValues(null);
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Item deleted!');
            })
            .catch(err => {
                console.error(err, 'Failed to delete asset item');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };
    // On add modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            entranceDate: moment().format(),
            depreciation: '0',
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditModal,
            setAddValues
        );
    }, []);

    // Form props
    const formProps = {
        ref: formRef,
        mainAccounts,
        accounts,
        assets,
        onValuesChange,
        setDefaultValues,
        onDateChange,
        onDateKeyUp,
    };

    // On component mount
    React.useEffect(() => {
        // Get bank accounts
        getMainAccounts();
    }, []);
    return (
        <PageWrapper>
            <AssetsRegistrationForm
                {...formProps}
                mode="view"
                values={viewValues}
            />
            {/* Edit account modal */}
            <Modal
                title="Edit account"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                onOk={onEditModalOk}
                onCancel={onEditModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: editModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <AssetsRegistrationForm
                    {...formProps}
                    mode="edit"
                    values={editValues}
                />
            </Modal>
            {/* Async edit modal */}
            <Modal
                title="Update item?"
                visible={asyncUpdateModal.visible}
                confirmLoading={asyncUpdateModal.confirmLoading}
                onOk={onAsyncUpdateModalOk}
                onCancel={onAsyncUpdateModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: asyncUpdateModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>
                    The update may take a while. Please be patient during the
                    operation.
                </p>
            </Modal>
            {/* Add account modal */}
            <Modal
                title="Add item"
                visible={addModal.visible}
                confirmLoading={addModal.confirmLoading}
                onOk={onAddModalOk}
                onCancel={onAddModalCancel}
                okText="Create"
                cancelButtonProps={{
                    disabled: addModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <AssetsRegistrationForm
                    {...formProps}
                    mode="add"
                    values={addValues}
                />
            </Modal>
            {/* Delete account modal */}
            <Modal
                title="Delete item?"
                visible={deleteModal.visible}
                confirmLoading={deleteModal.confirmLoading}
                onOk={onDeleteModalOk}
                onCancel={onDeleteModalCancel}
                okText="Delete"
                cancelButtonProps={{
                    disabled: deleteModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>The item and its data will be deleted forever</p>
            </Modal>
            {/* Actions bar */}
            <BottomActionBarFixed>
                <Button
                    type="primary"
                    onClick={onEditModalOpen}
                    disabled={!viewValues?.assetsNo}
                >
                    Edit
                </Button>
                <Button type="primary" onClick={onAddModalOpen}>
                    Add
                </Button>
                <Button
                    type="primary"
                    onClick={onDeleteModalOpen}
                    disabled={!viewValues?.assetsNo}
                >
                    Delete
                </Button>
            </BottomActionBarFixed>
        </PageWrapper>
    );
}
