import React from 'react';
import {
    Form,
    Table,
    Pagination,
    Button,
    Row,
    Col,
    Select,
    Radio,
    Input,
    Checkbox,
} from 'antd';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../utils/functions';
import ColumnsSelection from '../../../Common/ColumnsSelection/ColumnsSelection';
import Print from '../../../Common/Print/Print';
import ReactToPrint from 'react-to-print';

export default function ActiveInactiveAccountsForm(props) {
    // Destructuring
    const {
        statement,
        isLoading,
        onPaginationChange,
        columnsList,
        onColumnsChange,
        onDateChange,
        onDateKeyUp,
        values,
        onValuesChange,
        activeDepTypeAccounts,
        branches,
        indeterminate,
        onCheckAllChange,
        checkAll,
        onCheckboxChange,
        showDisableAccountsConfirm,
    } = props;

    // Refs
    const printRef = React.useRef();

    // NEPALI DATEPICKER
    // Query date
    const queryDateInput = document.getElementById('queryDate');
    queryDateInput &&
        queryDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, queryDateInput)
        );

    // Variables and Constants

    const dataSource = statement?.dataList?.map((item, i) => {
        return {
            key: i + 1 + '',
            ...item,
        };
    });

    const columns = [
        ...(values?.state === 'inactive'
            ? [
                  {
                      title: () => (
                          <>
                              <Checkbox
                                  indeterminate={indeterminate}
                                  onChange={onCheckAllChange}
                                  checked={checkAll}
                              ></Checkbox>
                          </>
                      ),
                      dataIndex: 'disabled',
                      key: 'disabled',
                      render: (bool, data) => (
                          <Checkbox
                              id={`stateCheckbox_${+data.key}`}
                              checked={bool}
                              onChange={onCheckboxChange}
                          ></Checkbox>
                      ),
                  },
              ]
            : []),
        {
            title: 'S.N',
            dataIndex: 'sn',
            key: 'sn',
        },
        {
            title: 'Deposit Type',
            dataIndex: 'depositType',
            key: 'depositType',
        },
        ...(columnsList.includes('mainBookNo')
            ? [
                  {
                      title: 'Type No',
                      dataIndex: 'mainBookNo',
                      key: 'mainBookNo',
                  },
              ]
            : []),
        {
            title: 'Account No',
            dataIndex: 'accountNo',
            key: 'accountNo',
        },
        {
            title: 'Account Holder',
            dataIndex: 'dname',
            key: 'dname',
        },
        ...(columnsList.includes('Member No')
            ? [
                  {
                      title: 'Member No',
                      dataIndex: 'memberNo',
                      key: 'memberNo',
                  },
              ]
            : []),
        ...(columnsList.includes('Balance')
            ? [
                  {
                      title: 'Balance',
                      dataIndex: 'balance',
                      key: 'balance',
                  },
              ]
            : []),
        ...(columnsList.includes('Int. Rate')
            ? [
                  {
                      title: 'Int. Rate',
                      dataIndex: 'interestRate',
                      key: 'interestRate',
                  },
              ]
            : []),
        ...(columnsList.includes('Ind. Int. Date')
            ? [
                  {
                      title: 'Ind. Int. Date',
                      dataIndex: 'individualStartDate',
                      key: 'individualStartDate',
                  },
              ]
            : []),
        ...(columnsList.includes('Common Int. Date')
            ? [
                  {
                      title: 'Common Int. Date',
                      dataIndex: 'intStartDate',
                      key: 'intStartDate',
                      render: text => ENG_ISO_TO_NEP(text),
                  },
              ]
            : []),
        ...(columnsList.includes('Int. Provision')
            ? [
                  {
                      title: 'Int. Provision',
                      dataIndex: 'intProvision',
                      key: 'intProvision',
                  },
              ]
            : []),
        ...(columnsList.includes('Int. Payable')
            ? [
                  {
                      title: 'Int. Payable',
                      dataIndex: 'intPayable',
                      key: 'intPayable',
                  },
              ]
            : []),
        ...(columnsList.includes('Old Interest')
            ? [
                  {
                      title: 'Old Interest',
                      dataIndex: 'unpaidInterest',
                      key: 'unpaidInterest',
                  },
              ]
            : []),
        ...(columnsList.includes('Cur. Interest')
            ? [
                  {
                      title: 'Cur. Interest',
                      dataIndex: 'currentInterest',
                      key: 'currentInterest',
                  },
              ]
            : []),
        ...(columnsList.includes('Tax Rate')
            ? [
                  {
                      title: 'Tax Rate',
                      dataIndex: 'taxRate',
                      key: 'taxRate',
                  },
              ]
            : []),
        ...(columnsList.includes('Provision Tax')
            ? [
                  {
                      title: 'Provision Tax',
                      dataIndex: 'provisionTax',
                      key: 'provisionTax',
                  },
              ]
            : []),
        ...(columnsList.includes('Current Tax')
            ? [
                  {
                      title: 'Current Tax',
                      dataIndex: 'taxAmount',
                      key: 'taxAmount',
                  },
              ]
            : []),
        ...(columnsList.includes('Total Int.')
            ? [
                  {
                      title: 'Total Int.',
                      dataIndex: 'totalInterest',
                      key: 'totalInterest',
                  },
              ]
            : []),
        ...(columnsList.includes('Total Tax')
            ? [
                  {
                      title: 'Total Tax',
                      dataIndex: 'totalTax',
                      key: 'totalTax',
                  },
              ]
            : []),
        ...(columnsList.includes('Withdrawable')
            ? [
                  {
                      title: 'Withdrawable',
                      dataIndex: 'withdrawable',
                      key: 'withdrawable',
                  },
              ]
            : []),
        {
            title: 'Last Trans Date',
            dataIndex: 'lastTrasDate',
            key: 'lastTrasDate',
            render: text => ENG_ISO_TO_NEP(text),
        },
        ...(columnsList.includes('Expire Date')
            ? [
                  {
                      title: 'Expire Date',
                      dataIndex: 'expireDate',
                      key: 'expireDate',
                      render: text => ENG_ISO_TO_NEP(text),
                  },
              ]
            : []),
        ...(columnsList.includes('Entry Date')
            ? [
                  {
                      title: 'Entry Date',
                      dataIndex: 'entranceDate',
                      key: 'entranceDate',
                      render: text => ENG_ISO_TO_NEP(text),
                  },
              ]
            : []),
        ...(columnsList.includes('Min. Bal.')
            ? [
                  {
                      title: 'Min. Bal.',
                      dataIndex: 'minBal',
                      key: 'minBal',
                  },
              ]
            : []),
        ...(columnsList.includes('Is Locked')
            ? [
                  {
                      title: 'Is Locked',
                      dataIndex: 'locked',
                      key: 'locked',
                      render: text => (text ? 'Yes' : 'No'),
                  },
              ]
            : []),
        ...(columnsList.includes('Locked Amount')
            ? [
                  {
                      title: 'Locked Amount',
                      dataIndex: 'lockedAmount',
                      key: 'lockedAmount',
                  },
              ]
            : []),
        ...(columnsList.includes('Branch ID')
            ? [
                  {
                      title: 'Branch ID',
                      dataIndex: 'branchID',
                      key: 'branchID',
                  },
              ]
            : []),
    ];

    const columnsSelectionList = [
        'Type No',
        'Member No',
        'Balance',
        'Int. Rate',
        'Ind. Int. Date',
        'Common Int. Date',
        'Int. Provision',
        'Int. Payable',
        'Old Interest',
        'Cur. Interest',
        'Tax Rate',
        'Provision Tax',
        'Current Tax',
        'Total Int.',
        'Total Tax',
        'Withdrawable',
        'Expire Date',
        'Entry Date',
        'Min. Bal.',
        'Is Locked',
        'Locked Amount',
        'Branch ID',
    ];

    return (
        <>
            <Form
                onValuesChange={onValuesChange}
                fields={[
                    {
                        name: 'queryDate',
                        value:
                            values?.queryDate &&
                            ENG_ISO_TO_NEP(values?.queryDate),
                    },
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'acName',
                        value: values?.acName,
                    },
                    {
                        name: 'acno',
                        value: values?.acno,
                    },
                ]}
            >
                <Form.Item name="state">
                    <Radio.Group
                        buttonStyle="solid"
                        style={{
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                        }}
                        size="small"
                    >
                        <Radio.Button value="active">Active</Radio.Button>
                        <Radio.Button value="inactive">Inactive</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Row gutter={20}>
                    <Col span={20}>
                        <Row gutter={20}>
                            <Col span={8}>
                                <Form.Item
                                    label="Deposit A/C"
                                    name="acName"
                                    labelCol={{ span: 7 }}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        <Select.Option key="0" value="All">
                                            All
                                        </Select.Option>
                                        {activeDepTypeAccounts?.length &&
                                            activeDepTypeAccounts.map(
                                                (item, i) => (
                                                    <Select.Option
                                                        key={i + 1 + ''}
                                                        value={item.acName}
                                                    >
                                                        {item.acName}
                                                    </Select.Option>
                                                )
                                            )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="acno">
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        <Select.Option key="0" value="All">
                                            All
                                        </Select.Option>
                                        {activeDepTypeAccounts?.length &&
                                            activeDepTypeAccounts.map(
                                                (item, i) => (
                                                    <Select.Option
                                                        key={i + 1 + ''}
                                                        value={item.acno}
                                                    >
                                                        {item.acno}
                                                    </Select.Option>
                                                )
                                            )}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Max Bal"
                                    name="maxBal"
                                    labelCol={{ span: 7 }}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={8}>
                                <Form.Item
                                    label="Branch"
                                    name="branchName"
                                    labelCol={{ span: 7 }}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {branches?.length &&
                                            branches.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.branchName}
                                                >
                                                    {item.branchName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item name="branchID">
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {branches?.length &&
                                            branches.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.branchID}
                                                >
                                                    {item.branchID}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label={`${
                                        values?.state === 'active'
                                            ? 'From'
                                            : 'Till'
                                    } Date`}
                                    name="queryDate"
                                    labelCol={{ span: 7 }}
                                >
                                    <input
                                        type="text"
                                        className="ant-input"
                                        onKeyUp={onDateKeyUp}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={4} className="text-right">
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    type="default"
                                    style={{
                                        marginLeft: 'auto',
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Print
                                </Button>
                            )}
                            content={() => printRef.current}
                        />
                        <ColumnsSelection
                            columns={columnsSelectionList}
                            selectedColumns={columnsList}
                            onColumnsChange={onColumnsChange}
                        />
                    </Col>
                </Row>
            </Form>

            <Print ref={printRef} title="SMS Banking Accounts">
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    size="small"
                    style={{ marginTop: '1rem' }}
                    loading={isLoading}
                />
            </Print>
            <div className="d-flex" style={{ marginTop: '.5rem' }}>
                <Pagination
                    size="small"
                    total={statement?.totalRecords}
                    defaultCurrent={1}
                    showSizeChanger
                    onChange={onPaginationChange}
                    style={{ marginTop: '.5rem' }}
                />
                <Button
                    type="primary"
                    size="small"
                    style={{ marginLeft: 'auto' }}
                    onClick={showDisableAccountsConfirm}
                    disabled={!statement?.dataList?.find(item => item.disabled)}
                >
                    Disable account(s)
                </Button>
            </div>
        </>
    );
}
