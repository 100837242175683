import React from 'react';
// Import from dependencies
import { Form, Select, Row, Col, Table, Checkbox, Pagination } from 'antd';

const GroupWiseMemberReportForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        onValuesChange,
        values,
        isLoading,
        groupTypes,
        branches,
        balanceAccounts,
        accountBalance,
        selectedShareAccounts,
        setSelectedShareAccounts,
        selectedDepositAccounts,
        setSelectedDepositAccounts,
        selectedLoanAccounts,
        setSelectedLoanAccounts,
        onPaginationChange,
    } = props;

    // Local states
    const [sharesIndeterminate, setSharesIndeterminate] = React.useState(false);
    const [sharesCheckedAll, setSharesCheckedAll] = React.useState(false);
    const [depositsIndeterminate, setDepositsIndeterminate] =
        React.useState(false);
    const [depositsCheckedAll, setDepositsCheckedAll] = React.useState(false);
    const [loansIndeterminate, setLoansIndeterminate] = React.useState(false);
    const [loansCheckedAll, setLoansCheckedAll] = React.useState(false);

    // Variables and Constants
    const shareAccountsOptions = balanceAccounts?.shareAccount?.map(item => {
        return {
            label: item.accounts,
            value: item.acno,
        };
    });

    const depositAccountsOptions = balanceAccounts?.depositAccount?.map(
        item => {
            return {
                label: item.accounts,
                value: item.acno,
            };
        }
    );

    const loanAccountsOptions = balanceAccounts?.loanAccount?.map(item => {
        return {
            label: item.accounts,
            value: item.acno,
        };
    });

    // Utils
    // Share accounts
    const onShareAccountsChange = list => {
        setSelectedShareAccounts(list);
        setSharesIndeterminate(
            !!list.length && list.length < shareAccountsOptions.length
        );
        setSharesCheckedAll(list.length === shareAccountsOptions.length);
    };

    const onSharesCheckAllChange = e => {
        setSelectedShareAccounts(
            e.target.checked ? shareAccountsOptions.map(item => item.value) : []
        );
        setSharesIndeterminate(false);
        setSharesCheckedAll(e.target.checked);
    };

    // Deposit accounts
    const onDepositAccountsChange = list => {
        setSelectedDepositAccounts(list);
        setDepositsIndeterminate(
            !!list.length && list.length < depositAccountsOptions.length
        );
        setDepositsCheckedAll(list.length === depositAccountsOptions.length);
    };

    const onDepositsCheckAllChange = e => {
        setSelectedDepositAccounts(
            e.target.checked
                ? depositAccountsOptions.map(item => item.value)
                : []
        );
        setDepositsIndeterminate(false);
        setDepositsCheckedAll(e.target.checked);
    };

    // Loan accounts
    const onLoanAccountsChange = list => {
        setSelectedLoanAccounts(list);
        setLoansIndeterminate(
            !!list.length && list.length < loanAccountsOptions.length
        );
        setLoansCheckedAll(list.length === loanAccountsOptions.length);
    };

    const onLoansCheckAllChange = e => {
        setSelectedLoanAccounts(
            e.target.checked ? loanAccountsOptions.map(item => item.value) : []
        );
        setLoansIndeterminate(false);
        setLoansCheckedAll(e.target.checked);
    };

    // Table columns
    const columns = [
        {
            title: 'Member No',
            dataIndex: 'memberNO',
            key: 'memberNO',
            width: 100,
        },
        {
            title: 'Member Name',
            dataIndex: 'memberName',
            key: 'memberName',
            width: 400,
        },
        ...(selectedShareAccounts?.length
            ? balanceAccounts?.shareAccount.map(item => {
                  const title = item.accounts.split(' (')[0];
                  const key = title.replaceAll(' ', '_');
                  return {
                      title,
                      dataIndex: key,
                      key: key,
                  };
              })
            : []),
        ...(selectedDepositAccounts?.length
            ? [...balanceAccounts?.depositAccount]
                  .filter(item => selectedDepositAccounts.includes(item.acno))
                  .map(item => {
                      const title = item.accounts.split(' (')[0];
                      const key = title.replaceAll(' ', '_');
                      return {
                          title,
                          dataIndex: key,
                          key: key,
                      };
                  })
            : []),
        ...(selectedLoanAccounts?.length
            ? [...balanceAccounts?.loanAccount]
                  .filter(item => selectedLoanAccounts.includes(item.acno))
                  .map(item => {
                      const title = item.accounts.split(' (')[0];
                      const key = title.replaceAll(' ', '_');
                      return {
                          title,
                          dataIndex: key,
                          key: key,
                      };
                  })
            : []),
    ];

    // Table data source
    const dataSource = accountBalance?.memberBalanceList?.map(item => {
        const data = {
            memberNO: item.memberNO,
            memberName: item.memberName,
        };
        item.accountBalance.map(
            item => (data[item.acName.replaceAll(' ', '_')] = item.balance)
        );
        return data;
    });

    return (
        <Form
            ref={ref}
            layout="horizontal"
            onValuesChange={onValuesChange}
            fields={[
                {
                    name: 'groupId',
                    value: values?.groupId,
                },
                {
                    name: 'groupName',
                    value: values?.groupName,
                },
                {
                    name: 'branchID',
                    value: values?.branchID,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
            ]}
        >
            <Row gutter={20}>
                <Col span={8}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Group"
                                name="groupId"
                                labelCol={{ span: 7 }}
                                labelAlign="left"
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value="All">
                                        All
                                    </Select.Option>
                                    {groupTypes?.length &&
                                        groupTypes?.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.groupId}
                                            >
                                                {item.groupId}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="groupName">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value="All">
                                        All
                                    </Select.Option>
                                    {groupTypes?.length &&
                                        groupTypes?.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.groupName}
                                            >
                                                {item.groupName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Branch"
                                name="branchID"
                                labelCol={{ span: 7 }}
                                labelAlign="left"
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value="All">
                                        All
                                    </Select.Option>
                                    {branches?.length &&
                                        branches?.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.branchID}
                                            >
                                                {item.branchID}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="branchName">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value="All">
                                        All
                                    </Select.Option>
                                    {branches?.length &&
                                        branches?.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.branchName}
                                            >
                                                {item.branchName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={16}>
                    <Row gutter={20}>
                        <Col span={6}>
                            <div className="d-flex-center">
                                <div>
                                    <h4>
                                        <strong>Shares</strong>
                                    </h4>
                                </div>
                                <div style={{ marginLeft: 'auto' }}>
                                    <Checkbox
                                        indeterminate={sharesIndeterminate}
                                        checked={sharesCheckedAll}
                                        onChange={onSharesCheckAllChange}
                                    >
                                        Check All
                                    </Checkbox>
                                </div>
                            </div>
                            <div className="group-wise-checkbox-wrapper">
                                <Checkbox.Group
                                    options={shareAccountsOptions}
                                    value={selectedShareAccounts}
                                    onChange={onShareAccountsChange}
                                />
                            </div>
                        </Col>
                        <Col span={9}>
                            <div className="d-flex-center">
                                <div>
                                    <h4>
                                        <strong>Deposits</strong>
                                    </h4>
                                </div>
                                <div style={{ marginLeft: 'auto' }}>
                                    <Checkbox
                                        indeterminate={depositsIndeterminate}
                                        checked={depositsCheckedAll}
                                        onChange={onDepositsCheckAllChange}
                                    >
                                        Check All
                                    </Checkbox>
                                </div>
                            </div>
                            <div className="group-wise-checkbox-wrapper">
                                <Checkbox.Group
                                    options={depositAccountsOptions}
                                    value={selectedDepositAccounts}
                                    onChange={onDepositAccountsChange}
                                />
                            </div>
                        </Col>
                        <Col span={9}>
                            <div className="d-flex-center">
                                <div>
                                    <h4>
                                        <strong>Loans</strong>
                                    </h4>
                                </div>
                                <div style={{ marginLeft: 'auto' }}>
                                    <Checkbox
                                        indeterminate={loansIndeterminate}
                                        checked={loansCheckedAll}
                                        onChange={onLoansCheckAllChange}
                                    >
                                        Check All
                                    </Checkbox>
                                </div>
                            </div>

                            <div className="group-wise-checkbox-wrapper">
                                <Checkbox.Group
                                    options={loanAccountsOptions}
                                    value={selectedLoanAccounts}
                                    onChange={onLoanAccountsChange}
                                />
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Table
                className="table-th-single-line"
                dataSource={dataSource}
                columns={columns}
                size="small"
                loading={isLoading}
                pagination={false}
                scroll={{
                    x: true,
                }}
            />
            <Pagination
                style={{ marginTop: '1rem' }}
                size="small"
                total={accountBalance?.totalRecords}
                showSizeChanger
                onChange={onPaginationChange}
            />
        </Form>
    );
});

export default GroupWiseMemberReportForm;
