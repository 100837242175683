import React from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';

const Print = React.forwardRef((props, ref) => {
    // Enterprise detail
    const enterpriseDetail = JSON.parse(
        localStorage.getItem('ENTERPRISE_DETAIL')
    );

    // Destructuring
    const { fromDate, tillDate, footer, lang = 'en' } = props;
    return (
        <div ref={ref} id="print" className="print">
            <div className="print__info">
                <p className="print__date">
                    Printed on: {moment().format('DD/MM/YYYY')}
                </p>
                <p className="print__reg-no">
                    Reg No: {enterpriseDetail?.regNO}&nbsp; Pan No:{' '}
                    {enterpriseDetail?.panno}
                </p>
            </div>

            <div className="print__header">
                <div className="print__header-left">
                    <img
                        src={`${process.env.REACT_APP_STATIC_FILES}/${enterpriseDetail.logofile}`}
                        alt="logo"
                    />
                </div>
                <div className="print__header-mid">
                    <h1 className="print__enterprise-name">
                        {lang === 'np'
                            ? enterpriseDetail?.nepName
                            : enterpriseDetail?.companyName}
                    </h1>
                    <h2 className="print__address">
                        {lang === 'np'
                            ? enterpriseDetail?.nepTol
                            : enterpriseDetail?.tole}
                        -{enterpriseDetail?.ward},{' '}
                        {lang === 'np'
                            ? enterpriseDetail?.municipalNepali
                            : enterpriseDetail?.municipalName}
                        ,{' '}
                        {lang === 'np'
                            ? enterpriseDetail?.districtNepali
                            : enterpriseDetail?.districtName}
                    </h2>
                    <h3 className="print__phone">
                        {lang === 'np' ? 'फोन' : 'Phone'}:{' '}
                        {enterpriseDetail?.telephone1},{' '}
                        {enterpriseDetail?.telephone2}
                    </h3>
                    <h4 className="print__title">{props.title}</h4>
                </div>
                <div className="print__header-right">
                    {fromDate && (
                        <p>From: {moment(fromDate).format('DD/MM/YYYY')}</p>
                    )}
                    {tillDate && (
                        <p className="print__up-to-date">
                            {fromDate ? 'To' : 'Upto'}:{' '}
                            {moment(tillDate).format('DD/MM/YYYY')}
                        </p>
                    )}
                </div>
            </div>

            <div className="print__body">{props.children}</div>

            <div className="print__footer">
                <Row gutter={40}>
                    {footer?.map((item, i) => (
                        <Col span={8} key={i}>
                            <div className="print__footer-each">{item}</div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
});

export default Print;
