import React from 'react';
import { Form, Row, Table, Col, Space, Button } from 'antd';
import { GET_MEMBER_SHARE_PROPERTIES } from '../../../../utils/axios';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';
import Loading from '../../../../components/Common/Loading/Loading';

export default function MemberInfoShareDetail(props) {
    // Destructuring
    const { viewValues } = props;

    // Local states
    const [shareProperties, setShareProperties] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // Variables and constants
    const columns = [
        {
            title: 'Issued Date',
            dataIndex: 'issueddate',
            key: 'issueddate',
            render: text => ENG_ISO_TO_NEP(text),
        },
        {
            title: 'Certificate No',
            dataIndex: 'certiNo',
            key: 'certiNo',
        },
        {
            title: 'No. of Shares',
            dataIndex: 'noOfShare',
            key: 'noOfShare',
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
        },
    ];

    // On component mount
    React.useEffect(() => {
        if (!viewValues?.memberNO) return;
        setIsLoading(true);
        (async () => {
            const shareProperties = await GET_MEMBER_SHARE_PROPERTIES(
                viewValues.memberNO
            );
            console.log('shareProperties: ', shareProperties);
            setShareProperties(shareProperties);
            setIsLoading(false);
        })();
    }, [viewValues?.memberNO]);

    // Loading indicator
    if (isLoading) return <Loading />;
    return (
        <div>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item
                        label="Share Rate Rs"
                        labelCol={{ span: 12 }}
                        labelAlign="left"
                    >
                        100.00
                    </Form.Item>
                    <Form.Item
                        label="Total No. of Shares"
                        labelCol={{ span: 12 }}
                        labelAlign="left"
                    >
                        {shareProperties?.totalShareNo}
                    </Form.Item>
                    <Form.Item
                        label="Share Amount Rs"
                        labelCol={{ span: 12 }}
                        labelAlign="left"
                    >
                        {shareProperties?.sharAmount}
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Total Defined"
                        labelCol={{ span: 8 }}
                        labelAlign="left"
                    >
                        {shareProperties?.totalDefined}
                    </Form.Item>
                    <Form.Item
                        label="Undefined"
                        labelCol={{ span: 8 }}
                        labelAlign="left"
                    >
                        {shareProperties?.totalUndefiend}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={16}>
                    <Table
                        dataSource={shareProperties?.certificateList}
                        columns={columns}
                        size="small"
                        pagination={false}
                    />
                </Col>
            </Row>
            <Space style={{ marginTop: '1rem' }}>
                <Button type="primary" size="small">
                    Add More
                </Button>
                <Button type="primary" size="small">
                    Certificates
                </Button>
            </Space>
        </div>
    );
}
