import React from 'react';
import { message } from 'antd';
import { useRecoilValue } from 'recoil';
import {
    cashCounters as cashCountersAtom,
    banks as banksAtom,
    otherItems as otherItemsAtom,
    otherAccounts as otherAccountsAtom,
} from '../../../../recoil/atoms';
import moment from 'moment';
import {
    SET_CASH_AMOUNT,
    SET_BANK_AMOUNT,
    SET_OTHER_AMOUNT,
    SET_TOTAL_AMOUNT,
    DLT_KEYS_FROM_OBJ,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../../utils/functions';
import axios, {
    GET_ITEM_BALANCE,
    GET_MAIN_ACCOUNTS_FOR_RECEIVABLES,
    GET_ACCOUNTS_FOR_RECEIVABLES,
    GET_ITEMS_FOR_RECEIVABLES,
} from '../../../../utils/axios';
import PageWrapper from '../../../../components/Common/PageWrapper/PageWrapper';
import PayInAdvanceForm from '../../../../components/Accounts/GeneralAdvance/PayInAdvance/PayInAdvanceForm/PayInAdvanceForm';

export default function PayInAdvance() {
    // Global states
    const cashCounters = useRecoilValue(cashCountersAtom);
    const banks = useRecoilValue(banksAtom);
    const otherAccounts = useRecoilValue(otherAccountsAtom);
    const otherItems = useRecoilValue(otherItemsAtom);
    // Local states
    const [mainAccounts, setMainAccounts] = React.useState([]);
    const [accounts, setAccounts] = React.useState([]);

    const [payableItems, setPayableItems] = React.useState([]);
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [isProcessing, setIsProcessing] = React.useState(false);

    // Ref
    const formRef = React.createRef();

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            transDate: moment().format(),
            paidBy: 'Office',
            isClearance: false,
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On values change
    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        const values = viewValues;
        console.log(key, value);
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'isCash') {
            SET_CASH_AMOUNT(values, value, updateValues);
        } else if (key === 'isBank') {
            SET_BANK_AMOUNT(values, value, updateValues);
        } else if (key === 'isOther') {
            SET_OTHER_AMOUNT(values, value, updateValues);
        } else if (key === 'itemCode' || key === 'itemName') {
            const item = payableItems.find(item => item[key] === value);
            return updateValues(item);
        } else if (key === 'transDate') {
            return;
        } else if (key === 'cashNo' || key === 'cashName') {
            const counterKey = key === 'cashNo' ? 'itemCode' : 'itemName';
            const counter = cashCounters.find(
                counter => counter[counterKey] === value
            );
            return updateValues({
                cashNo: counter.itemCode,
                cashName: counter.itemName,
            });
        } else if (key === 'bankNo' || key === 'bankName') {
            const bankKey = key === 'bankNo' ? 'itemCode' : 'itemName';
            const bank = banks.find(bank => bank[bankKey] === value);

            return updateValues({
                bankNo: bank.itemCode,
                bankName: bank.itemName,
            });
        } else if (key === 'otherNo' || key === 'otherName') {
            const otherKey = key === 'otherNo' ? 'itemCode' : 'itemName';
            const other = otherItems.find(item => item[otherKey] === value);

            return updateValues({
                otherNo: other.itemCode,
                otherName: other.itemName,
            });
        } else if (key === 'otherAcno' || key === 'otherAcName') {
            const accKey = key === 'otherAcno' ? 'acno' : 'acName';
            const acc = otherAccounts.find(acc => acc[accKey] === value);
            return updateValues({
                ...DLT_KEYS_FROM_OBJ(viewValues, ['otherNo', 'otherName']),
                otherMano: acc.mano,
                otherAcno: acc.acno,
                otherAcName: acc.acName,
            });
        } else if (key === 'mano' || key === 'maName') {
            const mainAccount = mainAccounts.find(item => item[key] === value);
            return updateValues({
                ...DLT_KEYS_FROM_OBJ(values, [
                    'acno',
                    'acName',
                    'itemCode',
                    'itemName',
                    'payable',
                ]),
                ...mainAccount,
            });
        } else if (key === 'acno' || key === 'acName') {
            const account = accounts.find(item => item[key] === value);
            return updateValues({
                ...DLT_KEYS_FROM_OBJ(values, [
                    'itemCode',
                    'itemName',
                    'payable',
                ]),
                ...account,
            });
        }
        // Update values
        updateValues(val);
    };

    // On form submit
    const onFormSubmit = () => {
        formRef.current
            .validateFields([
                'transDate',
                'cashNo',
                'cashName',
                'bankNo',
                'bankName',
                'otherNo',
                'otherName',
                'otherAcno',
                'otherAcName',
            ])
            .then(() => {
                setIsProcessing(true);
                const payload = {
                    transDate: viewValues?.transDate,
                    enteredBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                    branchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    transactionType: 'Advance Paid',
                    description: viewValues?.description,
                    debitTrans: tableData.map(item => {
                        return {
                            mano: '120',
                            acno:
                                viewValues?.mano === '120'
                                    ? viewValues.acno
                                    : '120.02',
                            bvrcno: item?.bvrcno || null,
                            itemCode: item?.itemCode,
                            itemName: item?.itemName,
                            itemLocation: JSON.parse(
                                localStorage.getItem('USER_CREDENTIALS')
                            ).branchName,
                            receivedPaidBy: item?.paidBy,
                            remarks1: '',
                            rate: item?.paid,
                            interBranchID: JSON.parse(
                                localStorage.getItem('USER_CREDENTIALS')
                            ).branchID,
                            amount: item?.paid,
                            quantity: 1,
                            particulars: `${item.acName} PAID`,
                        };
                    }),
                    creditTrans: [
                        ...(viewValues?.isCash
                            ? [
                                  {
                                      mano: '080',
                                      acno: '080.01',
                                      bvrcno: viewValues?.cashBvrcno,
                                      itemCode: viewValues?.cashNo,
                                      itemName: viewValues?.cashName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      receivedPaidBy: tableData[0].paidBy,
                                      remarks1: '0',
                                      rate: viewValues?.cashAmount,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues.cashAmount,
                                      quantity: '1',
                                      particulars: `Advance Cash Paid`,
                                  },
                              ]
                            : []),
                        ...(viewValues.isBank
                            ? [
                                  {
                                      mano: bankAccounts[0].mano,
                                      acno: bankAccounts[0].acno,
                                      bvrcno: viewValues?.bankBvrcno,
                                      itemCode: viewValues?.bankNo,
                                      itemName: viewValues?.bankName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      receivedPaidBy: tableData[0].paidBy,
                                      remarks1: '0',
                                      rate: viewValues?.bankAmount,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.bankAmount,
                                      quantity: '1',
                                      particulars: `By ${viewValues?.bankName}`,
                                  },
                              ]
                            : []),
                        ...(viewValues?.isOther
                            ? [
                                  {
                                      mano: viewValues.otherMano,
                                      acno: viewValues.otherAcno,
                                      bvrcno: '',
                                      itemCode: viewValues?.otherNo,
                                      itemName: viewValues?.otherName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      receivedPaidBy: tableData[0].paidBy,
                                      remarks1: '0',
                                      rate: viewValues?.otherAmount,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.otherAmount,
                                      quantity: '1',
                                      particulars: `By ${viewValues?.otherName}`,
                                  },
                              ]
                            : []),
                    ],
                };
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/AllTransactions/Receivables`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0) {
                            setIsProcessing(false);
                            return message.error(res.data.statusMessage);
                        }
                        setViewValues(prevValues => ({
                            ...prevValues,
                            ...res.data.data,
                        }));
                        message.success(res.data.statusMessage);
                    })
                    .catch(err => {
                        console.error(err, 'Failed to process advance payment');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                        setIsProcessing(false);
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // Set total amount
    React.useEffect(() => {
        const cashAmount = +viewValues?.cashAmount || 0;
        const bankAmount = +viewValues?.bankAmount || 0;
        const otherAmount = +viewValues?.otherAmount || 0;
        SET_TOTAL_AMOUNT(cashAmount, bankAmount, otherAmount, setViewValues);
    }, [
        viewValues?.cashAmount,
        viewValues?.bankAmount,
        viewValues?.otherAmount,
        setViewValues,
    ]);

    // Handle new
    const handleNew = () => {
        setViewValues(null);
        setTableData([]);
        setDefaultValues();
        setIsProcessing(false);
    };

    // Handle data add
    const handleDataAdd = () => {
        formRef.current
            .validateFields(['acName', 'acno', 'itemName', 'itemCode', 'paid'])
            .then(() => {
                if (
                    tableData.find(
                        item =>
                            item.acno === viewValues?.acno &&
                            item.itemCode === viewValues?.itemCode
                    )
                )
                    return message.error('Item already selected!');
                setTableData(prevValues => [
                    ...prevValues,
                    {
                        key: tableData.length,
                        acno: viewValues?.acno,
                        acName: viewValues?.acName,
                        itemCode: viewValues?.itemCode,
                        itemName: viewValues?.itemName,
                        paidBy: viewValues?.paidBy,
                        payable: viewValues?.payable,
                        paid: viewValues?.paid,
                        bvrcno: viewValues?.bvrcno,
                    },
                ]);
                setViewValues(prevValues => ({
                    ...DLT_KEYS_FROM_OBJ(prevValues, [
                        'bvrcno',
                        'itemName',
                        'itemCode',
                        'payable',
                        'paid',
                    ]),
                }));
            })
            .catch(() => {
                message.error('Please fill all required fields!');
                const test = formRef.current.getFieldsError(['acno']);
                console.log('test: ', test);
            });
    };

    // Handle data remove
    const handleDataRemove = key => {
        const data = tableData.filter(item => item.key !== key);
        setTableData(data);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Form props
    const formProps = {
        ref: formRef,
        mainAccounts,
        accounts,
        payableItems,
        onValuesChange,
        isProcessing,
        handleNew,
        onFormSubmit,
        tableData,
        handleDataAdd,
        handleDataRemove,
        onDateChange,
        onDateKeyUp,
    };

    // Get payable items
    React.useEffect(() => {
        if (viewValues?.acno) {
            setPayableItems([]);
            (async () => {
                const items = await GET_ITEMS_FOR_RECEIVABLES(viewValues.acno);
                setPayableItems(items);
            })();
        }
    }, [viewValues?.acno]);

    // Get Payable (Item balance)
    React.useEffect(() => {
        if (viewValues?.acno && viewValues?.itemCode) {
            (async () => {
                const mano = '120';
                const acno =
                    viewValues?.mano === '120' ? viewValues.acno : '120.02';
                const itemCode = viewValues.itemCode;
                const balance = await GET_ITEM_BALANCE(
                    undefined,
                    mano,
                    acno,
                    itemCode
                );
                console.log('balance: ', balance);
                setViewValues(prevValues => ({
                    ...prevValues,
                    payable: balance,
                }));
            })();
        }
    }, [viewValues?.acno, viewValues?.itemCode, viewValues?.mano]);

    // Get and set pay receipt bank accounts
    React.useEffect(() => {
        if (viewValues?.isClearance !== undefined && viewValues?.branchID) {
            axios
                .get(
                    `${
                        process.env.REACT_APP_HOST
                    }/api/PayReceipt/BankAccounts/${viewValues?.branchID}/${
                        JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                            .userName
                    }}?isClearance=${viewValues?.isClearance}`
                )
                .then(res => {
                    setBankAccounts(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get banks');
                    message.error('Failed to get banks');
                });
        }
    }, [viewValues?.isClearance, viewValues?.branchID]);

    // Calculate pre total
    React.useEffect(() => {
        const preTotal = tableData.reduce(
            (prevVal, curVal) => +prevVal + +curVal.paid,
            0
        );
        setViewValues(prevValues => ({
            ...prevValues,
            preTotal,
        }));
    }, [tableData]);

    // Get main accounts
    React.useEffect(() => {
        (async () => {
            const mainAccounts = await GET_MAIN_ACCOUNTS_FOR_RECEIVABLES();
            setMainAccounts(mainAccounts);
        })();
    }, []);

    // Get accounts
    React.useEffect(() => {
        if (viewValues?.mano) {
            (async () => {
                const accounts = await GET_ACCOUNTS_FOR_RECEIVABLES(
                    viewValues.mano
                );
                setAccounts(accounts);
            })();
        }
    }, [viewValues?.mano]);

    // Set default values
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    return (
        <PageWrapper>
            <PayInAdvanceForm {...formProps} values={viewValues} />
        </PageWrapper>
    );
}
