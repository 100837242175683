import React from 'react';
import moment from 'moment';
import axios from '../../../utils/axios';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import TrialBalanceForm from '../../../components/Reports/TrialBalance/TrialBalanceForm/TrialBalanceForm';
import { ON_DATE_CHANGE, ON_DATE_KEYUP } from '../../../utils/functions';
import { message } from 'antd';

export default function TrialBalance() {
    // Local states
    const branches = JSON.parse(localStorage.getItem('BRANCHES'));
    const [viewValues, setViewValues] = React.useState(null);
    const [trialBalance, setTrialBalance] = React.useState(null);

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            fromDate: moment().format(),
            tillDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            acHeadTotal: true,
            level: 'accountLevel',
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On values change
    const onValuesChange = val => {
        console.log('val: ', val);
        const key = Object.keys(val)[0];
        const value = val[key];
        // const values = viewValues;
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'fromDate' || key === 'tillDate') {
            return;
        } else if (key === 'branchID' || key === 'branchName') {
            if (value === 'all') {
                return updateValues({
                    branchID: 'all',
                    branchName: 'all',
                });
            }
            const branch = branches.find(item => item[key] === value);
            updateValues(branch);
        }
        // Update values
        updateValues(val);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Form props
    const formProps = {
        branches,
        viewValues,
        onValuesChange,
        trialBalance,
        onDateChange,
        onDateKeyUp,
    };

    React.useEffect(() => {
        if (viewValues) {
            const {
                tillDate,
                // fromDate,
                suspenseAccount,
                odLoanSide,
                acHeadTotal,
                branchID,
                level,
            } = viewValues;
            const payload = {
                tillDate,
                // fromDate,
                suspenseAccount,
                odLoanSide,
                total: acHeadTotal,
                branchID,
            };
            // console.log('payload: ', payload);
            let url;
            if (level === 'mainAccountLevel') {
                url = 'api/TrialBalance/ALLTrailBalanceMainaccount';
            } else {
                url = 'api/TrialBalance/ALLTrailBalance';
            }

            axios
                .post(`${process.env.REACT_APP_HOST}/${url}`, payload)
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    console.log(res.data.data);
                    setTrialBalance(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get trial balance');
                    message.error(err, 'Failed to get trial balance');
                });
        }
    }, [viewValues]);

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    return (
        <PageWrapper>
            <TrialBalanceForm {...formProps} />
        </PageWrapper>
    );
}
