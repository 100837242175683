import React from 'react';
// Import from dependencies
import { Button, Tooltip, AutoComplete } from 'antd';
import {
    UserAddOutlined,
    StockOutlined,
    AppstoreAddOutlined,
    PieChartOutlined,
    BellOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { GET_PAGE_TITLE } from '../../../utils/functions';

export default function AppLayoutBreadcrumb(props) {
    // Destructuring
    const { handleMemberSearch, members, handleMemberSelect } = props;
    const location = useLocation();

    return (
        <div className="breadcrumb block-general">
            <div className="app-layout-breadcrumb">
                <div className="app-layout-breadcrumb__left">
                    {GET_PAGE_TITLE(location)}
                    {/* <Breadcrumb>
                        <Breadcrumb.Item>Main</Breadcrumb.Item>
                        {GET_BREADCRUMB(location).map((item, i) => (
                            <Breadcrumb.Item key={i}>{item}</Breadcrumb.Item>
                        ))}
                    </Breadcrumb> */}
                </div>
                <div className="app-layout-breadcrumb__mid">
                    <div className="app-layout-breadcrumb__transactional-date">
                        <label>
                            <strong>Transaction date:</strong>
                        </label>
                        12th December, 2021
                    </div>
                </div>
                <div className="app-layout-breadcrumb__search">
                    <AutoComplete
                        onSearch={handleMemberSearch}
                        onSelect={handleMemberSelect}
                        placeholder="Search member ..."
                        defaultActiveFirstOption={true}
                        allowClear
                        dropdownMatchSelectWidth={false}
                    >
                        {members?.length &&
                            members?.map((item, i) => (
                                <AutoComplete.Option
                                    key={i}
                                    value={`${item.memName}-${item.ownerno}-${item.memberno}`}
                                >
                                    {item.memName}-{item.ownerno}-
                                    {item.memberno}
                                </AutoComplete.Option>
                            ))}
                    </AutoComplete>
                </div>
                <div className="app-layout-breadcrumb__right">
                    <div className="app-layout-quick-menu">
                        <label className="app-layout-quick-menu__label">
                            Quick menu:
                        </label>
                        <Tooltip title="Register" placement="bottom">
                            <Button type="primary" icon={<UserAddOutlined />} />
                        </Tooltip>
                        <Tooltip title="Share Addition" placement="bottom">
                            <Button type="primary" icon={<StockOutlined />} />
                        </Tooltip>
                        <Tooltip title="Dashboard" placement="bottom">
                            <Button
                                type="primary"
                                icon={<PieChartOutlined />}
                            />
                        </Tooltip>
                        <Tooltip title="Counter deposit" placement="bottom">
                            <Button
                                type="primary"
                                icon={<AppstoreAddOutlined />}
                            />
                        </Tooltip>
                        <Tooltip title="Notifications" placement="bottom">
                            <Button type="primary" icon={<BellOutlined />} />
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
}
