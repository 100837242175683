import React from 'react';
import {
    Form,
    Select,
    Input,
    Row,
    Col,
    Radio,
    Checkbox,
    Button,
    Table,
    Space,
} from 'antd';
import { ENG_ISO_TO_NEP } from '../../../utils/functions';

export default function PaymentSystemForm(props) {
    // Destructuring
    const {
        kistaPeriod,
        mode,
        onCheckboxChange,
        values,
        updateValuesInState,
        handleCreateSchedule,
        loanSchedule,
        isCreatingSchedule,
        onDateKeyUp,
    } = props;

    const dataSource = loanSchedule?.dataList?.map((sch, i) => ({
        key: i + 1,
        ...sch,
    }));

    const columns = [
        {
            title: 'Installment No',
            dataIndex: 'installmentNO',
            key: 'installmentNO',
        },
        {
            title: 'Installment Date',
            dataIndex: 'installmentDate',
            key: 'installmentDate',
        },
        {
            title: 'Principal',
            dataIndex: 'principal',
            key: 'principal',
        },
        {
            title: 'Interest Pmt',
            dataIndex: 'interestPmt',
            key: 'interestPmt',
        },
        {
            title: 'Loan Pmt',
            dataIndex: 'loanPmt',
            key: 'loanPmt',
        },
        {
            title: 'Total Pmt',
            dataIndex: 'totalPmt',
            key: 'totalPmt',
        },
        {
            title: 'Remaining Loan',
            dataIndex: 'remLoan',
            key: 'remLoan',
        },
        {
            title: 'Int Days',
            dataIndex: 'intDays',
            key: 'intDays',
        },
    ];

    React.useEffect(() => {
        if (mode !== 'view') {
            if (values?.kistaPeriod) {
                if (values.kistaPeriod === 'Daily')
                    updateValuesInState(
                        { installmentInterval: '1', dmy: 0 },
                        mode
                    );
                else if (values?.kistaPeriod === 'Monthly')
                    updateValuesInState(
                        { installmentInterval: '1', dmy: 1 },
                        mode
                    );
                else if (values?.kistaPeriod === 'Three Monthly')
                    updateValuesInState(
                        { installmentInterval: '3', dmy: 1 },
                        mode
                    );
                else if (values?.kistaPeriod === 'Half Yearly')
                    updateValuesInState(
                        { installmentInterval: '6', dmy: 1 },
                        mode
                    );
                else if (values?.kistaPeriod === 'Yearly')
                    updateValuesInState(
                        { installmentInterval: '1', dmy: 2 },
                        mode
                    );
            }
        }
    }, [values?.kistaPeriod, mode, updateValuesInState]);
    return (
        <div>
            <Row gutter={20}>
                <Col span={24}>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                label="Interest calculating system"
                                name="intCalcMethod"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={0}>Diminishing</Radio>
                                    <Radio value={1}>Flat Interest </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9}>
                            <Form.Item
                                label="Payment System"
                                name="lps"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value="Installment">
                                        Normal installment
                                    </Radio>
                                    <Radio value="Fixed">
                                        Fixed installment
                                    </Radio>
                                    <Radio value="Interest">
                                        Interest only
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item
                                label="Interest Rate"
                                name="rateOfInterest"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (+value < 0 || +value > 50) {
                                                return Promise.reject(
                                                    new Error('0 to 50')
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item
                                label="No Of Installment"
                                name="totalNoOfKista"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (+value < 1 || +value > 999) {
                                                return Promise.reject(
                                                    new Error('1 to 999')
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item
                                label="Installment Period"
                                name="kistaPeriod"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {kistaPeriod.map((period, i) => (
                                        <Select.Option
                                            key={i}
                                            value={period.kistaPeriod}
                                        >
                                            {period.kistaPeriod}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={2}>
                            <Form.Item
                                label="Interval"
                                name="installmentInterval"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value)
                                                return Promise.resolve();
                                            if (+value <= 0) {
                                                return Promise.reject(
                                                    new Error('Invalid')
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                label="DMY"
                                name="dmy"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={0}>Day</Radio>
                                    <Radio value={1}>Month</Radio>
                                    <Radio value={2}>Year </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item
                                label="First Installment On"
                                name={`installmentStartDate_${mode}`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <input
                                    type="text"
                                    className="ant-input"
                                    onKeyUp={e => onDateKeyUp(e, mode)}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label="And"
                                name="sameDay"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={true}>Same day</Radio>
                                    <Radio value={false}>Month end of nm</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label="Loan Payment On Every"
                                name="lpInterval"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value)
                                                return Promise.resolve();
                                            if (+value <= 0) {
                                                return Promise.reject(
                                                    new Error('Invalid')
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input addonAfter="installment" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label="Interest On Previous Month"
                                name="intBeforeInstallment"
                            >
                                <Checkbox
                                    checked={values?.intBeforeInstallment}
                                    name="intBeforeInstallment"
                                    onChange={e => onCheckboxChange(e, mode)}
                                ></Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}>
                            <Form.Item
                                label="Equal loan Kista"
                                name="equalLoanPmt"
                            >
                                <Checkbox
                                    checked={values?.equalLoanPmt}
                                    name="equalLoanPmt"
                                    onChange={e => onCheckboxChange(e, mode)}
                                ></Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label="Payment From Beginning"
                                name="pmtFromBegining"
                            >
                                <Checkbox
                                    checked={values?.pmtFromBegining}
                                    name="pmtFromBegining"
                                    onChange={e => onCheckboxChange(e, mode)}
                                ></Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label="Total Duration"
                                name="loanTerm"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Input
                                    addonAfter={
                                        values?.kistaPeriod === 'Daily'
                                            ? 'days'
                                            : 'months'
                                    }
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4}></Col>
                        <Col xs={24} sm={12} md={6}>
                            <Form.Item
                                label="Final Date"
                                name={`finalDate_${mode}`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <div className="ant-input ant-input--fake">
                                    {ENG_ISO_TO_NEP(values?.finalDate)}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br></br>
                    <Space>
                        <Button
                            type="default"
                            onClick={() => handleCreateSchedule(mode)}
                            disabled={mode !== 'add'}
                        >
                            Create Schedule
                        </Button>
                        <Button type="default"> Show Schedule</Button>
                        <Form.Item
                            name="roundedBy"
                            style={{ marginBottom: '0' }}
                        >
                            <Input
                                addonAfter="Round"
                                style={{
                                    height: '100% !important',
                                    width: '10rem',
                                }}
                            />
                        </Form.Item>
                        <Button type="default"> Print</Button>
                    </Space>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                        loading={isCreatingSchedule}
                        style={{ width: '100%' }}
                    />
                </Col>
            </Row>
        </div>
    );
}
