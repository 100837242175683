import React from 'react';
// Import from dependencies
import { useRecoilState } from 'recoil';
import { verificationStatus as verificationStatusAtom } from '../../recoil/atoms';
// Import from utils
import { USER_CREDENTIALS } from '../../utils/localStorage';
// Import components
import PublicRoutes from '../../routes/PublicRoutes';
import App from '../../App';

export default function Checkpoint() {
    // Global states
    const [verificationStatus, setVerificationStatus] = useRecoilState(
        verificationStatusAtom
    );

    // On component mount
    React.useEffect(() => {
        // Set verification status based on token availability
        if (localStorage.getItem(USER_CREDENTIALS)) {
            setVerificationStatus('authorized');
        } else {
            setVerificationStatus('unauthorized');
        }
    }, [setVerificationStatus]);

    // Display routes based on verification status
    if (verificationStatus === 'authorized') return <App />;
    if (verificationStatus === 'unauthorized') return <PublicRoutes />;
    return null;
}
