import React from 'react';
import { Form, Row, Col, Select, Table, Button, Checkbox, Space } from 'antd';
import ReactToPrint from 'react-to-print';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../utils/functions';
import Print from '../../../Common/Print/Print';
import PrintSettingsModal from '../../../Common/PrintSettingsModal/PrintSettingsModal';
import SettingsIconTrigger from '../../../Common/SettingsIconTrigger/SettingsIconTrigger';
import { PRINT_SETTINGS_FOOTER } from '../../../../utils/helpers';

export default function ShowStatementForm(props) {
    // Local states
    const [printSettingsModal, setPrintSettingsModal] = React.useState(false);
    const [printSettingsFooter, setPrintSettingsFooter] = React.useState([
        'Prepared by',
    ]);
    const [printSettingsFooterTemp, setPrintSettingsFooterTemp] =
        React.useState(
            JSON.parse(localStorage.getItem('PRINT_SETTINGS_FOOTER')) || [
                PRINT_SETTINGS_FOOTER[0],
            ]
        );

    // Destructuring
    const {
        branches,
        viewValues: values,
        onValuesChange,
        statement,
        depTypeAccounts,
        savingTypeAccounts,
        isLoading,
        onDateChange,
        onDateKeyUp,
    } = props;

    const {
        rateofInterest,
        taxRate,
        intProvision,
        unpaidInterest,
        interest,
        tax,
        provisionTax,
        odInterest,
        balance,
        odIntrate,
        address,
    } = statement || {};

    // Refs
    const printRef = React.useRef();

    // NEPALI DATEPICKER
    // From date
    const fromDateInput = document.getElementById('fromDate');
    fromDateInput &&
        fromDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, fromDateInput)
        );
    // Till date
    const tillDateInput = document.getElementById('tillDate');
    tillDateInput &&
        tillDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, tillDateInput)
        );

    // Variables and constants
    const accuredInt = (
        +intProvision +
        +unpaidInterest +
        +interest -
        +tax -
        +provisionTax
    ).toFixed(2);

    // DATA SOURCE - START
    const dataSource = statement?.statement.length ? statement.statement : [];

    const columns = [
        {
            title: 'Date',
            dataIndex: 'transDate',
            key: 'transDate',
            render: text => ENG_ISO_TO_NEP(text, 'en'),
        },
        ...(values?.showJournalNo
            ? [
                  {
                      title: 'Journal No',
                      dataIndex: 'journalNo',
                      key: 'journalNo',
                  },
              ]
            : []),
        {
            title: 'Particulars',
            dataIndex: 'particular',
            key: 'particular',
        },
        {
            title: 'Withdraw',
            dataIndex: 'debit',
            key: 'debit',
        },
        {
            title: 'Deposit',
            dataIndex: 'credit',
            key: 'credit',
        },
        ...(values?.showBalance
            ? [
                  {
                      title: 'Balance',
                      dataIndex: 'balance',
                      key: 'balance',
                  },
              ]
            : []),
        ...(values?.showDocNo
            ? [
                  {
                      title: 'Doc No',
                      dataIndex: 'bvrcno',
                      key: 'bvrcno',
                  },
              ]
            : []),
        ...(values?.showReceivedPaidBy
            ? [
                  {
                      title: 'Received Paid By',
                      dataIndex: 'receivedPaidBy',
                      key: 'receivedPaidBy',
                  },
              ]
            : []),
        ...(values?.showTransactionType
            ? [
                  {
                      title: 'Transaction Type',
                      dataIndex: 'transactionType',
                      key: 'transactionType',
                  },
              ]
            : []),
    ];
    // DATA SOURCE - END

    // Print
    // On Ok
    const onPrintSettingsModalOk = () => {
        setPrintSettingsFooter(printSettingsFooterTemp);
        localStorage.setItem(
            'PRINT_SETTINGS_FOOTER',
            JSON.stringify(printSettingsFooterTemp)
        );
        setPrintSettingsModal(false);
    };
    // On Cancel
    const onPrintSettingsModalCancel = () => {
        console.log(printSettingsFooter);
        setPrintSettingsFooterTemp(printSettingsFooter);
        setPrintSettingsModal(false);
    };

    return (
        <>
            <Form
                layout="horizontal"
                onValuesChange={onValuesChange}
                // onFinish={onFormSubmit}
                fields={[
                    {
                        name: 'fromDate',
                        value:
                            values?.fromDate && ENG_ISO_TO_NEP(values.fromDate),
                    },
                    {
                        name: 'tillDate',
                        value:
                            values?.tillDate && ENG_ISO_TO_NEP(values.tillDate),
                    },
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'suspenseAccount',
                        value: values?.suspenseAccount,
                    },
                    {
                        name: 'odLoanSide',
                        value: values?.odLoanSide,
                    },
                    {
                        name: 'acHeadTotal',
                        value: values?.acHeadTotal,
                    },
                    {
                        name: 'level',
                        value: values?.level,
                    },
                    {
                        name: 'acno',
                        value: values?.acno,
                    },
                    {
                        name: 'acName',
                        value: values?.acName,
                    },
                    {
                        name: 'accountNo',
                        value: values?.accountNo,
                    },
                    {
                        name: 'fullName',
                        value: values?.fullName,
                    },
                    {
                        name: 'showJournalNo',
                        value: values?.showJournalNo,
                    },
                    {
                        name: 'showBalance',
                        value: values?.showBalance,
                    },
                    {
                        name: 'showDocNo',
                        value: values?.showDocNo,
                    },
                    {
                        name: 'showReceivedPaidBy',
                        value: values?.showReceivedPaidBy,
                    },
                    {
                        name: 'showTransactionType',
                        value: values?.showTransactionType,
                    },
                ]}
            >
                <Row gutter={20}>
                    <Col span={4}>
                        <Form.Item
                            label="From"
                            name="fromDate"
                            labelCol={{ span: 6 }}
                        >
                            <input
                                type="text"
                                className="ant-input"
                                onKeyUp={onDateKeyUp}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Till"
                            name="tillDate"
                            labelCol={{ span: 6 }}
                        >
                            <input
                                type="text"
                                className="ant-input"
                                onKeyUp={onDateKeyUp}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Row gutter={20}>
                            <Col span={17}>
                                <Form.Item
                                    label="Branch"
                                    name="branchName"
                                    labelCol={{ span: 8 }}
                                >
                                    <Select placeholder="Select...">
                                        <Select.Option value="all">
                                            All
                                        </Select.Option>
                                        {branches?.length &&
                                            branches.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.branchName}
                                                >
                                                    {item.branchName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item label="" name="branchID">
                                    <Select placeholder="Select...">
                                        <Select.Option value="all">
                                            All
                                        </Select.Option>
                                        {branches?.length &&
                                            branches.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.branchID}
                                                >
                                                    {item.branchID}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={17}>
                                <Form.Item
                                    label="Deposit Type"
                                    name="acName"
                                    labelCol={{ span: 8 }}
                                >
                                    <Select placeholder="Select...">
                                        {depTypeAccounts?.length &&
                                            depTypeAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.acName}
                                                >
                                                    {item.acName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item label="" name="acno">
                                    <Select placeholder="Select...">
                                        {depTypeAccounts?.length &&
                                            depTypeAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.acno}
                                                >
                                                    {item.acno}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={17}>
                                <Form.Item
                                    label="Account No"
                                    name="accountNo"
                                    labelCol={{ span: 8 }}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {savingTypeAccounts?.length &&
                                            savingTypeAccounts.map((acc, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={acc.accountNo}
                                                >
                                                    {acc.accountNo}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}></Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={17}>
                                <Form.Item
                                    label="Account Holder"
                                    name="fullName"
                                    labelCol={{ span: 8 }}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {savingTypeAccounts?.length &&
                                            savingTypeAccounts.map((acc, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={acc.fullName}
                                                >
                                                    {acc.fullName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}></Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={17}>
                                <Form.Item
                                    label="Address"
                                    labelCol={{ span: 8 }}
                                >
                                    {address}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Int Rate"
                                    labelCol={{ span: 8 }}
                                >
                                    {rateofInterest}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="OD I Rate"
                                    labelCol={{ span: 8 }}
                                >
                                    {odIntrate}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="TDS Rate"
                                    labelCol={{ span: 8 }}
                                >
                                    {taxRate}
                                </Form.Item>
                            </Col>
                            <Col span={12}></Col>
                        </Row>
                        <Form.Item label="Accrued Int" labelCol={{ span: 6 }}>
                            {accuredInt}
                        </Form.Item>
                        <Form.Item
                            label="Accrued OD Int"
                            labelCol={{ span: 6 }}
                        >
                            {odInterest}
                        </Form.Item>
                        <Form.Item label="Current Bal" labelCol={{ span: 6 }}>
                            {balance}
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <ReactToPrint
                            trigger={() => (
                                <Button type="default">Print</Button>
                            )}
                            content={() => printRef.current}
                        />
                        <SettingsIconTrigger
                            onClick={() => setPrintSettingsModal(true)}
                        />
                    </Col>
                </Row>
                <Row gutter={20}>
                    {/* <Col span={4}></Col> */}
                    <Col span={24}>
                        <Space>
                            <Form.Item
                                name="showJournalNo"
                                valuePropName="checked"
                            >
                                <Checkbox>Journal No</Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="showBalance"
                                valuePropName="checked"
                            >
                                <Checkbox>Balance</Checkbox>
                            </Form.Item>
                            <Form.Item name="showDocNo" valuePropName="checked">
                                <Checkbox>Doc No</Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="showReceivedPaidBy"
                                valuePropName="checked"
                            >
                                <Checkbox>Received Paid By</Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="showTransactionType"
                                valuePropName="checked"
                            >
                                <Checkbox>Transaction Type</Checkbox>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Print ref={printRef} footer={printSettingsFooter}>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    className="ant-table-wrapper--collapsed"
                    pagination={false}
                    bordered
                    loading={isLoading}
                />
            </Print>
            {/* Print settings modal */}
            <PrintSettingsModal
                visible={printSettingsModal}
                onOk={onPrintSettingsModalOk}
                onCancel={onPrintSettingsModalCancel}
                footerTemp={printSettingsFooterTemp}
                setFooterTemp={setPrintSettingsFooterTemp}
            />
        </>
    );
}
