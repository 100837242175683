import React from 'react';
import {
    Row,
    Col,
    Form,
    Select,
    Input,
    Space,
    Button,
    Table,
    Radio,
    AutoComplete,
    message,
} from 'antd';
import validator from 'validator';
import moment from 'moment';
import axios from '../../../../utils/axios';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
    ADD_ZEROS,
} from '../../../../utils/functions';
import PaymentSource from '../../../Common/PaymentSource/PaymentSource';
import Print from '../../../Common/Print/Print';
import ReactToPrint from 'react-to-print';

const CollectionEntryForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        mode,
        values,
        onValuesChange,
        setDefaultValues,
        collectors,
        onFormSubmit,
        isProcessing,
        handleNew,
        tableData,
        setTableData,
        accounts,
        results,
        setResults,
        onDateChange,
        onDateKeyUp,
        getBlankTableData,
    } = props;

    // Refs
    const printRef = React.useRef();

    // Global states
    // Local states
    const [isLoadingTableData, setIsLoadingTableData] = React.useState(false);

    // NEPALI DATEPICKER
    // Trans date
    const transDateInput = document.getElementById('transDate');
    transDateInput &&
        transDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, transDateInput, mode)
        );
    // Collection date
    const collectionDateInput = document.getElementById('collectionDate');
    collectionDateInput &&
        collectionDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, collectionDateInput, mode)
        );

    // Handle search
    const handleSearch = async (val, data) => {
        // Update value in state
        const i = tableData.findIndex(
            item => item.accountNo === data.accountNo
        );
        const tableDataNew = [...tableData];
        tableDataNew[i].accountHolder = val;
        setTableData(tableDataNew);
        // Set results
        setResults([]);
        if (!val) return;

        try {
            const res = await axios.get(
                `${
                    process.env.REACT_APP_HOST
                }/api/CollectionEntry/AccountSearch/${val}?ACNO=${
                    values?.acno || ''
                }&BranchID=${
                    JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                        .branchID
                }&staffID=${values?.staffID || ''}`
            );
            // console.log('res: ', res);
            if (res.data.statusCode !== 0)
                return message.error(res.data.statusMessage);
            // const results = res.data.data.map(
            //     item =>
            //         `${item.type}-${item.acno}-${item.accountNo}-${item.accountHolder}`
            // );
            const results = res.data.data;
            console.log('results: ', results);
            setResults(results);
        } catch (err) {
            console.error(err, 'Failed to get results');
        }
    };

    const handleSelect = async (data, option) => {
        setIsLoadingTableData(true);

        const arr = data.split('-');
        const type = arr[0];
        const accountNo = arr[2];
        const i = tableData.findIndex(
            item => item.accountNo === option.accountNo
        );
        const date = moment(values?.transDate).format('YYYY-MM-DD');
        const branchID = JSON.parse(
            localStorage.getItem('USER_CREDENTIALS')
        ).branchID;
        const acno = arr[1];

        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/CollectionEntry/CollectionAccount/${type}/${accountNo}/${date}?&BranchID=${branchID}&acno=${acno}`
        );

        const resData = res.data.data[0];

        // Check if account is already in table
        // No
        if (i === -1) {
            setTableData(prevValues => [
                ...prevValues,
                {
                    key: tableData.length + '',
                    ...resData,
                    [`collectedAmt${+option.key}`]: 0,
                    [`remark${+option.key}`]: resData.remark,
                    finalBalance: 0,
                },
            ]);
            // Yes
        } else {
            const newTableData = [...tableData];
            newTableData[i] = {
                ...newTableData[i],
                ...resData,
                [`collectedAmt${+option.key}`]: 0,
                [`remark${+option.key}`]: resData.remark,
                finalBalance: resData.balance,
            };
            setTableData(newTableData);
        }
        // Add new blank table data
        if (tableData.length === 0 || !option.accountNo)
            setTableData(prevValues => [
                ...prevValues,
                getBlankTableData(tableData.length + ''),
            ]);
        // Set is loading
        setIsLoadingTableData(false);
        setResults([]);
        // Focus on collected amount
        document.getElementById(`collectedAmt${+option.key}`).select();
    };

    // Fill All
    const handleFillAll = async () => {
        const branchID = JSON.parse(
            localStorage.getItem('USER_CREDENTIALS')
        ).branchID;
        const date = moment(values?.transDate).format('YYYY-MM-DD');
        const acno = values?.acno || '';
        const staffID = values?.staffID || '';

        setIsLoadingTableData(true);
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/CollectionEntry/FillAll/${branchID}/Savings/${date}?ACNO=${acno}&StaffID=${staffID}`
        );

        console.log('Fill all data: ', res.data.data);

        let data = res.data.data.map((item, i) => {
            return {
                key: i + '',
                ...item,
                [`collectedAmt${i}`]: 0,
                [`remark${i}`]: item.remark,
            };
        });
        data.length = 10;
        setTableData(data);
        setIsLoadingTableData(false);
    };

    const columns = [
        {
            title: 'Account No',
            dataIndex: 'accountNo',
            key: 'accountNo',
            width: '120px',
        },
        {
            title: 'Account Holder',
            dataIndex: 'accountHolder',
            key: 'accountHolder',
            render: (text, data) => (
                <AutoComplete
                    value={text}
                    style={{
                        width: '100%',
                    }}
                    onSearch={val => handleSearch(val, data)}
                    onSelect={val => handleSelect(val, data)}
                    placeholder="Search ..."
                    defaultActiveFirstOption={true}
                >
                    {results
                        .filter(item => {
                            if (
                                tableData.find(
                                    data =>
                                        data.accountNo === item.accountNo &&
                                        data.acno === item.acno
                                )
                            )
                                return null;
                            return item;
                        })
                        .map((item, i) => (
                            <AutoComplete.Option
                                key={i}
                                value={`${item.type}-${item.acno}-${item.accountNo}-${item.accountHolder}`}
                                style={
                                    tableData.find(
                                        test =>
                                            test.accountNo === item.accountNo
                                    )
                                        ? { background: '#F8F64B' }
                                        : {}
                                }
                            >
                                {item.type}-{item.acno}-{item.accountNo}-
                                {item.accountHolder}
                            </AutoComplete.Option>
                        ))}
                </AutoComplete>
            ),
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            width: '150px',
        },
        {
            title: 'Collected Amt',
            dataIndex: 'collectedAmt',
            key: 'collectedAmt',
            width: '120px',
            render: (text, data) => (
                <Form.Item
                    name={`collectedAmt${+data.key}`}
                    wrapperCol={24}
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (!value || validator.isInt(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Digits Only'));
                            },
                        }),
                    ]}
                >
                    <Input
                        style={{ width: '100%', textAlign: 'right' }}
                        disabled={!data?.accountNo}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Previous Balance',
            dataIndex: 'balance',
            key: 'balance',
            width: '100px',
            render: text => (
                <div style={{ textAlign: 'right' }}>{ADD_ZEROS(text + '')}</div>
            ),
        },
        {
            title: 'Final Balance',
            dataIndex: 'finalBalance',
            key: 'finalBalance',
            width: '100px',
            render: text => (
                <div style={{ textAlign: 'right' }}>
                    {text && ADD_ZEROS(text + '')}
                </div>
            ),
        },
        {
            title: 'Remarks',
            dataIndex: 'remark',
            key: 'remark',
            width: '100px',
            render: (text, data) => (
                <Form.Item name={`remark${+data.key}`} wrapperCol={24}>
                    <Input
                        style={{ width: '100%' }}
                        disabled={!data?.accountNo}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Previous Remarks',
            dataIndex: 'previousRemark',
            key: 'previousRemark',
            width: '150px',
        },
    ];

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);
    return (
        <Form
            ref={ref}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onValuesChange={val => onValuesChange(val, mode)}
            fields={[
                {
                    name: 'branchID',
                    value: values?.branchID,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'transDate',
                    value:
                        values?.transDate && ENG_ISO_TO_NEP(values?.transDate),
                },
                {
                    name: 'collectionDate',
                    value:
                        values?.collectionDate &&
                        ENG_ISO_TO_NEP(values?.collectionDate),
                },
                {
                    name: 'memberNo',
                    value: values?.memberNo,
                },
                {
                    name: 'memberName',
                    value: values?.memberName,
                },
                {
                    name: 'address',
                    value: values?.address,
                },
                {
                    name: 'mobileNO',
                    value: values?.mobileNO,
                },
                {
                    name: 'paidBy',
                    value: values?.paidBy,
                },
                {
                    name: 'cashNo',
                    value: values?.cashNo,
                },
                {
                    name: 'cashName',
                    value: values?.cashName,
                },
                {
                    name: 'bankNo',
                    value: values?.bankNo,
                },
                {
                    name: 'bankName',
                    value: values?.bankName,
                },
                {
                    name: 'otherAcno',
                    value: values?.otherAcno,
                },
                {
                    name: 'otherAcName',
                    value: values?.otherAcName,
                },
                {
                    name: 'otherNo',
                    value: values?.otherNo,
                },
                {
                    name: 'otherName',
                    value: values?.otherName,
                },

                {
                    name: 'preTotal',
                    value: values?.preTotal,
                },
                {
                    name: 'isCash',
                    value: values?.isCash,
                },
                {
                    name: 'cashAmount',
                    value: values?.cashAmount,
                },
                {
                    name: 'isBank',
                    value: values?.isBank,
                },
                {
                    name: 'bankAmount',
                    value: values?.bankAmount,
                },
                {
                    name: 'isOther',
                    value: values?.isOther,
                },
                {
                    name: 'otherAmount',
                    value: values?.otherAmount,
                },
                {
                    name: 'totalAmount',
                    value: values?.totalAmount,
                },
                {
                    name: 'totalAmountInWords',
                    value: values?.totalAmountInWords,
                },
                {
                    name: 'journalNO',
                    value: values?.journalNO,
                },
                {
                    name: 'receiptNo',
                    value: values?.receiptNo,
                },
                {
                    name: 'transNo',
                    value: values?.transNo,
                },
                {
                    name: 'isClearance',
                    value: values?.isClearance,
                },
                {
                    name: 'accountNo',
                    value: values?.accountNo,
                },
                {
                    name: 'staffID',
                    value: values?.staffID,
                },
                {
                    name: 'staffName',
                    value: values?.staffName,
                },
                {
                    name: 'collectedBy',
                    value: values?.collectedBy,
                },
                {
                    name: 'collectedByID',
                    value: values?.collectedByID,
                },
                {
                    name: 'collectionOf',
                    value: values?.collectionOf,
                },
                {
                    name: 'acno',
                    value: values?.acno,
                },
                {
                    name: 'acName',
                    value: values?.acName,
                },
                ...tableData.map((item, i) => {
                    return {
                        name: `collectedAmt${i}`,
                        value: item[`collectedAmt${i}`],
                    };
                }),
                ...tableData.map((item, i) => {
                    return {
                        name: `remark${i}`,
                        value: item[`remark${i}`],
                    };
                }),
            ]}
        >
            <Row gutter={20}>
                <Col span={12}>
                    <Form.Item
                        label="Collection Of"
                        name="collectionOf"
                        labelCol={8}
                        wrapperCol={24}
                    >
                        <Radio.Group>
                            <Radio value="deposit">Deposit</Radio>
                            <Radio value="loan">Loan Payment Fund</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        label="Date"
                        name="transDate"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Collected On" name="collectionDate">
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label="Account Type" name="acName">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {accounts?.length &&
                                accounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.acName}>
                                        {acc.acName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Type Code" name="acno">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {accounts?.length &&
                                accounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.acno}>
                                        {acc.acno}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}></Col>
                <Col span={6}>
                    <Form.Item label="Journal No." name="journalNO">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label="Field Of" name="staffName">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {collectors?.length &&
                                collectors.map((member, i) => (
                                    <Select.Option
                                        key={i}
                                        value={member.staffName}
                                    >
                                        {member.staffName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="ID" name="staffID">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {collectors?.length &&
                                collectors.map((member, i) => (
                                    <Select.Option
                                        key={i}
                                        value={member.staffID}
                                    >
                                        {member.staffID}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}></Col>
                <Col span={6}>
                    <Form.Item label="Receipt No." name="receiptNo">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label="Collected By" name="collectedBy">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {collectors?.length &&
                                collectors.map((member, i) => (
                                    <Select.Option
                                        key={i}
                                        value={member.staffName}
                                    >
                                        {member.staffName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="ID" name="collectedByID">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {collectors?.length &&
                                collectors.map((member, i) => (
                                    <Select.Option
                                        key={i}
                                        value={member.staffID}
                                    >
                                        {member.staffID}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}></Col>
                <Col span={6}>
                    <Form.Item label="Trans No." name="transNo">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Space style={{ margin: '.5rem 0' }}>
                <Button onClick={handleFillAll}>Fill All Items</Button>
                <Button onClick={handleNew}>Clear Items</Button>
                <Button>Load Sheet</Button>
                <ReactToPrint
                    trigger={() => (
                        <Button type="default">Print Receipt</Button>
                    )}
                    content={() => printRef.current}
                />
                <Button>Export to Excel</Button>
            </Space>
            <Print ref={printRef} title="Collection Entry">
                <Table
                    size="small"
                    loading={isLoadingTableData}
                    className="ant-table-wrapper--collapsed"
                    dataSource={tableData}
                    columns={columns}
                    pagination={false}
                    scroll={{ y: 240 }}
                />
            </Print>
            <Row gutter={20}>
                <Col span={16}></Col>
                <Col span={6} style={{ marginTop: '.5rem' }}>
                    <Form.Item label="Total Collection" name="preTotal">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <PaymentSource
                title="Receipt Source"
                mode="receipt"
                values={values}
            />
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label=" " colon={false}>
                        <Space style={{ marginTop: '1.5rem' }}>
                            <Button
                                onClick={onFormSubmit}
                                disabled={isProcessing}
                            >
                                Save
                            </Button>
                            <ReactToPrint
                                trigger={() => (
                                    <Button type="default">
                                        Print Receipt
                                    </Button>
                                )}
                                content={() => printRef.current}
                            />
                            <Button>Deno</Button>
                            <Button onClick={handleNew}>New</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default CollectionEntryForm;
