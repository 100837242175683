import React from 'react';
import {
    GET_MEMBERS,
    GET_SHARE_CERTIFICATE_NO_LIST,
    GET_SHARE_CERTIFICATE_DETAIL,
} from '../../../utils/axios';
import { useLocation } from 'react-router';
// Components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import PrintShareCertificateForm from '../../../components/Membership/PrintShareCertificate/PrintShareCertificateForm/PrintShareCertificateForm';

export default function PrintShareCertificate() {
    // Local states
    const [viewValues, setViewValues] = React.useState(null);
    const [members, setMembers] = React.useState([]);
    const [certificateNos, setCertificateNos] = React.useState([]);
    const [certificateDetail, setCertificateDetail] = React.useState([]);

    // Refs
    const shareCertificateFormRef = React.useRef();

    // Use query
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery();

    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];

        // Update values on state
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        if (key === 'memberNO' || key === 'memberName') {
            const member = members.find(item => item[key] === value);
            return updateValues(member);
        }

        // Update values
        updateValues(val);
    };

    // Get data
    React.useEffect(() => {
        (async () => {
            // Members
            const members = await GET_MEMBERS();
            setMembers(members);
        })();
    }, []);

    // Get share certificate nos
    React.useEffect(() => {
        if (viewValues?.memberNO) {
            setCertificateNos([]);
            (async () => {
                const certificateNos = await GET_SHARE_CERTIFICATE_NO_LIST(
                    viewValues.memberNO
                );
                setCertificateNos(certificateNos);
            })();
        }
    }, [viewValues?.memberNO]);

    // Get share certificate detail
    React.useEffect(() => {
        if (viewValues?.memberNO && viewValues?.certificateNo) {
            (async () => {
                const certificateDetail = await GET_SHARE_CERTIFICATE_DETAIL(
                    viewValues.memberNO,
                    viewValues.certificateNo
                );
                console.log('certificateDetail: ', certificateDetail);
                setCertificateDetail(certificateDetail);
            })();
        }
    }, [viewValues?.memberNO, viewValues?.certificateNo]);

    // Get query params
    React.useEffect(() => {
        const memberNO = query.get('memberNo');
        const memberName = query.get('memberName');
        const certificateNo = query.get('certiNo');

        setViewValues(prevValues => ({
            ...prevValues,
            memberNO,
            memberName,
            certificateNo: certificateNo === 'undefined' ? '' : certificateNo,
        }));
    }, [query]);

    const formProps = {
        onValuesChange,
        members,
        certificateNos,
        certificateDetail,
    };
    return (
        <PageWrapper>
            <PrintShareCertificateForm
                {...formProps}
                ref={shareCertificateFormRef}
                mode="view"
                values={viewValues}
            />
        </PageWrapper>
    );
}
