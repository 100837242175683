import React from 'react';
// Import from dependencies
import { message, Modal } from 'antd';
import { useRecoilValue } from 'recoil';
import { cashCounters as cashCountersAtom } from '../../../../recoil/atoms';
// Import utils
import axios, {
    GET_BANKS,
    GET_ALL_CASH_ITEMS,
    GET_BANK_DETAIL,
    GET_ITEM_BALANCE,
} from '../../../../utils/axios';
import moment from 'moment';
import { ToWords } from 'to-words';
import {
    SET_DEFAULT_VALUES,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../../utils/functions';
// Import components
import PageWrapper from '../../../../components/Common/PageWrapper/PageWrapper';
import DepositToBankForm from '../../../../components/Accounts/Banking/DepositToBank/DepositToBankForm/DepositToBankForm';

export default function RemitPayment() {
    // Global states
    const cashCounters = useRecoilValue(cashCountersAtom);
    // Local states
    const [banks, setBanks] = React.useState([]);
    const [cashItems, setCashItems] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [confirmModal, setConfirmModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [canSubmit, setCanSubmit] = React.useState(true);

    // Refs
    const formRef = React.useRef();

    // On view form values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];
        // let values;
        // if (mode === 'view') values = viewValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (key === 'transDate') {
            return;
        } else if (key === 'bankCode' || key === 'bankName') {
            const bankKey = key === 'bankCode' ? 'itemCode' : 'itemName';
            const bank = banks.find(bank => bank[bankKey] === value);
            return updateValues({
                bankCode: bank.itemCode,
                bankName: bank.itemName,
            });
        } else if (key === 'cashCode' || key === 'cashName') {
            const cashItemKey = key === 'cashCode' ? 'itemCode' : 'itemName';
            const cashItem = cashItems.find(
                cashItem => cashItem[cashItemKey] === value
            );
            return updateValues({
                cashCode: cashItem.itemCode,
                cashName: cashItem.itemName,
            });
        }
        // Update values
        updateValues(val);
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            transDate: moment().format(),
            collectedBy: 'Accountant',
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            undefined,
            undefined
        );
    }, []);

    // On form submit
    const onFormSubmit = () => {
        formRef.current
            .validateFields()
            .then(res => {
                setConfirmModal(prevValues => ({
                    ...prevValues,
                    visible: true,
                }));
            })
            .catch(err => {
                message.error('Invalid entry!');
            });
    };

    // Submit form
    const submitForm = val => {
        // console.log(viewValues, 'viewValues');
        // console.log(val, 'val');
        const payload = {
            transDate: viewValues.transDate,
            enteredBy: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .userName,
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            transactionType: 'Bank Deposit',
            description: viewValues?.description,
            creditTrans: [
                {
                    mano: '080',
                    acno: '080.01',
                    bvrcno: viewValues?.voucherNo,
                    itemCode: viewValues?.cashCode,
                    itemName: viewValues?.cashName,
                    itemLocation: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchName,
                    receivedPaidBy: viewValues?.collectedBy,
                    remarks1: '',
                    rate: viewValues?.totalCollection,
                    interBranchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    amount: viewValues?.totalCollection,
                    quantity: '1',
                    particulars: `Cash Deposited - ${viewValues?.collectedBy}`,
                },
            ],
            debitTrans: [
                {
                    mano: '090',
                    acno: '090.01',
                    bvrcno: viewValues?.voucherNo,
                    itemCode: viewValues?.bankCode,
                    itemName: viewValues?.bankName,
                    itemLocation: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    receivedPaidBy: viewValues?.collectedBy,
                    remarks1: '',
                    rate: viewValues?.totalCollection,
                    interBranchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    amount: viewValues?.totalCollection,
                    quantity: '1',
                    particulars: `${viewValues?.bankName} Deposited`,
                },
            ],
        };
        console.log('payload: ', payload);
        axios
            .post(
                `${process.env.REACT_APP_HOST}/api/AllTransactions/BankDeposit`,
                payload
            )
            .then(res => {
                if (res.data.statusCode !== 0) {
                    setConfirmModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                    return message.error(res.data.statusMessage);
                }
                console.log(res);
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
                message.success(res.data.statusMessage);
                setConfirmModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                    visible: false,
                }));
                setCanSubmit(false);
            })
            .catch(err => {
                console.error(err, 'Failed to Deposit to Bank');
                message.error('Something went wrong. Please try again!');
                setConfirmModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // Handle new
    const handleNew = () => {
        setViewValues(null);
        setDefaultValues('view');
        setCanSubmit(true);
    };

    // On confirm modal ok
    const onConfirmModalOk = () => {
        setConfirmModal({
            ...confirmModal,
            confirmLoading: true,
        });
        submitForm();
    };

    // On confirm modal cancel
    const onConfirmModalCancel = () => {
        setConfirmModal(prevValues => ({
            ...prevValues,
            visible: false,
            confirmLoading: false,
        }));
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Form props
    const formProps = {
        ref: formRef,
        onValuesChange,
        setDefaultValues,
        cashCounters,
        banks,
        onFormSubmit,
        handleNew,
        cashItems,
        onDateChange,
        onDateKeyUp,
        canSubmit,
    };

    // Get bank detail
    React.useEffect(() => {
        if (viewValues?.bankCode) {
            (async () => {
                const bankDetail = await GET_BANK_DETAIL(viewValues?.bankCode);
                console.log('bankDetail: ', bankDetail);
                setViewValues(prevValues => ({
                    ...prevValues,
                    bankAcno: bankDetail.bankAccountNo,
                    bankAddress: bankDetail.address,
                }));
            })();
        }
    }, [viewValues?.bankCode]);

    // Get bank balance
    React.useEffect(() => {
        if (viewValues?.bankCode) {
            (async () => {
                // Get bank balance
                const bankBalance = await GET_ITEM_BALANCE(
                    undefined,
                    '090',
                    '090.01',
                    viewValues?.bankCode
                );
                setViewValues(prevValues => ({
                    ...prevValues,
                    bankBalance,
                }));
            })();
        }
    }, [viewValues?.bankCode]);

    // Get cash balance
    React.useEffect(() => {
        if (viewValues?.cashCode) {
            (async () => {
                // Get cash balance
                const cashBalance = await GET_ITEM_BALANCE(
                    undefined,
                    '080',
                    '080.01',
                    viewValues?.cashCode
                );
                setViewValues(prevValues => ({
                    ...prevValues,
                    cashBalance,
                }));
            })();
        }
    }, [viewValues?.cashCode]);

    // Set amount in words
    React.useEffect(() => {
        if (viewValues?.totalCollection) {
            const totalCollection = viewValues?.totalCollection;
            const toWords = new ToWords({
                converterOptions: {
                    currency: true,
                },
            });
            let totalCollectionInWords = toWords.convert(totalCollection);
            setViewValues(prevValues => ({
                ...prevValues,
                totalCollectionInWords,
            }));
        }
    }, [viewValues?.totalCollection]);

    // Getter and Setter
    React.useEffect(() => {
        (async () => {
            // Get banks
            const banks = await GET_BANKS();
            setBanks(banks);
            // Get cash items
            const cashItems = await GET_ALL_CASH_ITEMS();
            setCashItems(cashItems);
        })();
    }, [setBanks]);

    return (
        <PageWrapper>
            <DepositToBankForm {...formProps} mode="view" values={viewValues} />
            <Modal
                title="Save Transaction?"
                visible={confirmModal.visible}
                confirmLoading={confirmModal.confirmLoading}
                onOk={onConfirmModalOk}
                onCancel={onConfirmModalCancel}
                okText="Save"
                maskClosable={false}
            >
                <p>Do you wish to save this entry?</p>
            </Modal>
        </PageWrapper>
    );
}
