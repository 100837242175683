import React from 'react';
import {
    Form,
    Row,
    Col,
    Select,
    Table,
    Pagination,
    Button,
    Dropdown,
    Menu,
    Space,
    Checkbox,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ENG_ISO_TO_NEP } from '../../../utils/functions';
import ReactToPrint from 'react-to-print';
import SettingsIconTrigger from '../../Common/SettingsIconTrigger/SettingsIconTrigger';
import { PRINT_SETTINGS_FOOTER } from '../../../utils/helpers';
import Print from '../../Common/Print/Print';
import PrintSettingsModal from '../../Common/PrintSettingsModal/PrintSettingsModal';

export default function SharePanjikaForm(props) {
    // Destructuring
    const {
        onValuesChange,
        areas,
        values,
        groups,
        groupTypes,
        sharePanjika,
        sharePanjikaInfo,
        isLoading,
        onPaginationChange,
        page,
        resPerPage,
    } = props;

    // Local states
    const [columnsDropdown, setColumnsDropdown] = React.useState(false);
    const [columnsList, setColumnsList] = React.useState(['']);
    const [printSettingsModal, setPrintSettingsModal] = React.useState(false);
    const [printSettingsFooter, setPrintSettingsFooter] = React.useState([
        'Prepared by',
    ]);
    const [printSettingsFooterTemp, setPrintSettingsFooterTemp] =
        React.useState(
            JSON.parse(localStorage.getItem('PRINT_SETTINGS_FOOTER')) || [
                PRINT_SETTINGS_FOOTER[0],
            ]
        );

    // Refs
    const printRef = React.useRef();

    // Print
    // On Ok
    const onPrintSettingsModalOk = () => {
        setPrintSettingsFooter(printSettingsFooterTemp);
        localStorage.setItem(
            'PRINT_SETTINGS_FOOTER',
            JSON.stringify(printSettingsFooterTemp)
        );
        setPrintSettingsModal(false);
    };
    // On Cancel
    const onPrintSettingsModalCancel = () => {
        console.log(printSettingsFooter);
        setPrintSettingsFooterTemp(printSettingsFooter);
        setPrintSettingsModal(false);
    };

    const dataSource = sharePanjika?.map((item, i) => {
        return {
            sn: i + 1,
            ...item,
        };
    });

    const columns = [
        {
            title: 'SN',
            dataIndex: 'sn',
            key: 'sn',
        },
        {
            title: 'Mem No',
            dataIndex: 'memberNo',
            key: 'memberNo',
        },
        ...(columnsList.includes('Reg No')
            ? [
                  {
                      title: 'Reg No',
                      dataIndex: 'regSaveId',
                      key: 'regSaveId',
                  },
              ]
            : []),
        {
            title: 'Name',
            dataIndex: 'memberName',
            key: 'memberName',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        ...(columnsList.includes('Contact')
            ? [
                  {
                      title: 'Contact',
                      dataIndex: 'contactNO',
                      key: 'contactNO',
                  },
              ]
            : []),
        {
            title: 'Shares',
            dataIndex: 'shareAmount',
            key: 'shareAmount',
        },
        {
            title: 'Savings',
            dataIndex: 'totalSaving',
            key: 'totalSaving',
        },
        {
            title: 'Loans',
            dataIndex: 'totalLoan',
            key: 'totalLoan',
        },
    ];

    // On Columns list change
    const onColumnsListChange = e => {
        console.log(e);
        const checked = e.target.checked;
        const value = e.target.value;

        if (checked) setColumnsList(prevValues => [...prevValues, value]);
        else
            setColumnsList(prevValues =>
                prevValues.filter(item => item !== value)
            );
    };

    // Table columns
    const columnsDropdownMenu = (
        <Menu onClick={() => setColumnsDropdown(true)}>
            <Menu.Item key="0">
                <Checkbox onChange={onColumnsListChange} value="Reg No">
                    Reg No
                </Checkbox>
            </Menu.Item>
            <Menu.Item key="1">
                <Checkbox onChange={onColumnsListChange} value="Contact">
                    Contact
                </Checkbox>
            </Menu.Item>
        </Menu>
    );

    return (
        <Form
            layout="horizontal"
            onValuesChange={val => onValuesChange(val)}
            fields={[
                {
                    name: ['transDate'],
                    value:
                        values?.transDate && ENG_ISO_TO_NEP(values.transDate),
                },
                {
                    name: ['groupName'],
                    value: values?.groupName,
                },
                {
                    name: ['groupId'],
                    value: values?.groupId,
                },
                {
                    name: ['groupType'],
                    value: values?.groupType,
                },
                {
                    name: ['address'],
                    value: values?.address,
                },
                {
                    name: ['coordinator'],
                    value: values?.coordinator,
                },
            ]}
        >
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label="Area" name="area" labelCol={{ span: 8 }}>
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value="All">All</Select.Option>
                            {areas.map((item, i) => (
                                <Select.Option key={i} value={item.areaName}>
                                    {item.areaName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Group Type"
                        name="groupType"
                        labelCol={{ span: 7 }}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {groupTypes.map((item, i) => (
                                <Select.Option key={i} value={item.groupType}>
                                    {item.groupType}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Address"
                        name="address"
                        labelCol={{ span: 7 }}
                    >
                        <input
                            className="ant-input ant-input--fake"
                            disabled
                        ></input>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <ReactToPrint
                        trigger={() => <Button type="default">Print</Button>}
                        content={() => printRef.current}
                    />
                    <SettingsIconTrigger
                        onClick={() => setPrintSettingsModal(true)}
                    />
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item
                        label="Group Name"
                        name="groupName"
                        labelCol={{ span: 8 }}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value="All">All</Select.Option>
                            {groups.map((item, i) => (
                                <Select.Option key={i} value={item.groupName}>
                                    {item.groupName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Group ID"
                        name="groupId"
                        labelCol={{ span: 7 }}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value="All">All</Select.Option>
                            {groups.map((item, i) => (
                                <Select.Option key={i} value={item.groupId}>
                                    {item.groupId}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Coordinator"
                        name="coordinator"
                        labelCol={{ span: 7 }}
                    >
                        <input
                            className="ant-input ant-input--fake"
                            disabled
                        ></input>
                    </Form.Item>
                </Col>
            </Row>
            <div className="align-right">
                <Dropdown
                    className="fake-link"
                    visible={columnsDropdown}
                    overlay={columnsDropdownMenu}
                    trigger={['click']}
                    onVisibleChange={flag => setColumnsDropdown(flag)}
                >
                    <Space>
                        Columns
                        <DownOutlined />
                    </Space>
                </Dropdown>
            </div>
            <Print
                ref={printRef}
                title="Share Panjika"
                footer={printSettingsFooter}
            >
                <Table
                    className="ant-table-wrapper--collapsed"
                    dataSource={dataSource}
                    columns={columns}
                    bordered
                    loading={isLoading}
                    pagination={false}
                />
            </Print>
            <Pagination
                size="small"
                total={sharePanjikaInfo?.totalRecords}
                showSizeChanger
                onChange={onPaginationChange}
                defaultCurrent={page}
                defaultPageSize={resPerPage}
                style={{ marginTop: '.5rem' }}
            />

            {/* Print settings modal */}
            <PrintSettingsModal
                visible={printSettingsModal}
                onOk={onPrintSettingsModalOk}
                onCancel={onPrintSettingsModalCancel}
                footerTemp={printSettingsFooterTemp}
                setFooterTemp={setPrintSettingsFooterTemp}
            />
        </Form>
    );
}
