import React from 'react';
import { Form, Row, Col, Space, Select, Button } from 'antd';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import ReactExport from 'react-export-excel';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../utils/functions';
import Print from '../../../Common/Print/Print';
import PrintSettingsModal from '../../../Common/PrintSettingsModal/PrintSettingsModal';
import SettingsIconTrigger from '../../../Common/SettingsIconTrigger/SettingsIconTrigger';

export default function ProfitLossForm(props) {
    // Local states
    const [printSettingsModal, setPrintSettingsModal] = React.useState(false);
    const [printSettingsFooter, setPrintSettingsFooter] = React.useState([
        'Prepared by',
    ]);
    const [printSettingsFooterTemp, setPrintSettingsFooterTemp] =
        React.useState(
            JSON.parse(localStorage.getItem('PRINT_SETTINGS_FOOTER')) || [
                'Prepared by',
            ]
        );

    // Destructuring
    const {
        mode,
        branches,
        viewValues: values,
        onValuesChange,
        profitLoss,
        onDateChange,
        onDateKeyUp,
    } = props;

    // Refs
    const printRef = React.useRef();

    // NEPALI DATEPICKER
    // From date
    const fromDateInput = document.getElementById('fromDate');
    fromDateInput &&
        fromDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, fromDateInput, mode)
        );
    // Till date
    const tillDateInput = document.getElementById('tillDate');
    tillDateInput &&
        tillDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, tillDateInput, mode)
        );

    // DATA SOURCE - START
    const dataSourceLeft = [];
    const dataSourceRight = [];
    const dataLeft = profitLoss
        ? profitLoss?.profitLoss.filter((item, i) => i === 0 && item)
        : [];

    const dataRight = profitLoss
        ? profitLoss?.profitLoss.filter((item, i) => i === 1 && item)
        : [];

    const fillDataSource = (item, dataSource, source) => {
        dataSource.push({
            key: dataSource.length + 1,
            one: '',
            two: item?.saName,
            three: '',
        });
        item.dataList.forEach(item => {
            dataSource.push({
                key: dataSource.length + 1,
                one: item.mano,
                two: item.maName,
                three: item.balance,
            });
            item?.acList &&
                item.acList.forEach(item => {
                    dataSource.push({
                        key: dataSource.length + 1,
                        one: item.acno,
                        two: item.acName,
                        three: item.balance,
                    });
                });
        });
        dataSource.push({
            key: dataSource.length + 1,
            one: '',
            two: `TOTAL ${item.saName}`,
            three: item.totalBalance,
        });
    };

    dataLeft.forEach(item => fillDataSource(item, dataSourceLeft, 'debit'));
    dataRight.forEach(item => fillDataSource(item, dataSourceRight, 'credit'));

    // Profit/Loss of the period
    const dataSource1 =
        profitLoss?.plDescription === 'Profit of the period'
            ? dataSourceLeft
            : dataSourceRight;
    dataSource1.push({
        key: dataSource1.length + 1,
        one: '',
        two: profitLoss?.plDescription,
        three: profitLoss?.plBalance,
    });

    // Total
    dataSourceLeft.push({
        key: dataSourceLeft.length + 1,
        one: '',
        two: 'TOTAL',
        three: profitLoss?.totalDebit,
    });
    dataSourceRight.push({
        key: dataSourceRight.length + 1,
        one: '',
        two: 'TOTAL',
        three: profitLoss?.totalCredit,
    });

    // Wrong balance
    let dataSource;
    if (profitLoss?.wrongBalance < 0) {
        dataSource = dataSourceLeft;
    } else if (profitLoss?.wrongBalance > 0) {
        dataSource = dataSourceRight;
    }
    dataSource &&
        dataSource.push({
            key: dataSource.length + 1,
            one: '',
            two: 'WRONG BALANCE',
            three: Math.abs(profitLoss?.wrongBalance),
        });

    // DATA SOURCE - END

    // Print
    // On Ok
    const onPrintSettingsModalOk = () => {
        setPrintSettingsFooter(printSettingsFooterTemp);
        localStorage.setItem(
            'PRINT_SETTINGS_FOOTER',
            JSON.stringify(printSettingsFooterTemp)
        );
        setPrintSettingsModal(false);
    };
    // On Cancel
    const onPrintSettingsModalCancel = () => {
        console.log(printSettingsFooter);
        setPrintSettingsFooterTemp(printSettingsFooter);
        setPrintSettingsModal(false);
    };

    // Larger data among left and right
    const largerData =
        dataSourceLeft.length > dataSourceRight.length
            ? dataSourceLeft
            : dataSourceRight;

    return (
        <>
            <Form
                layout="vertical"
                onValuesChange={onValuesChange}
                // onFinish={onFormSubmit}
                fields={[
                    {
                        name: 'fromDate',
                        value:
                            values?.fromDate && ENG_ISO_TO_NEP(values.fromDate),
                    },
                    {
                        name: 'tillDate',
                        value:
                            values?.tillDate && ENG_ISO_TO_NEP(values.tillDate),
                    },
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'suspenseAccount',
                        value: values?.suspenseAccount,
                    },
                    {
                        name: 'odLoanSide',
                        value: values?.odLoanSide,
                    },
                    {
                        name: 'acHeadTotal',
                        value: values?.acHeadTotal,
                    },
                    {
                        name: 'level',
                        value: values?.level,
                    },
                ]}
            >
                <Row gutter={20}>
                    <Col span={7}>
                        <Space style={{ marginTop: '1.5rem' }}>
                            <Button type="default" size="small">
                                From Today
                            </Button>
                            <Button type="default" size="small">
                                Today Only
                            </Button>
                            <Button type="default" size="small">
                                Fiscal Year
                            </Button>
                        </Space>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="From" name="fromDate">
                            <input
                                type="text"
                                className="ant-input"
                                onKeyUp={e => onDateKeyUp(e, mode)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Till" name="tillDate">
                            <input
                                type="text"
                                className="ant-input"
                                onKeyUp={e => onDateKeyUp(e, mode)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Branch ID" name="branchID">
                            <Select placeholder="Select...">
                                <Select.Option value="all">All</Select.Option>
                                {branches?.length &&
                                    branches.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.branchID}
                                        >
                                            {item.branchID}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Branch Name" name="branchName">
                            <Select placeholder="Select...">
                                <Select.Option value="all">All</Select.Option>
                                {branches?.length &&
                                    branches.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.branchName}
                                        >
                                            {item.branchName}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    type="default"
                                    style={{ marginTop: '1.5rem' }}
                                    size="small"
                                >
                                    Print
                                </Button>
                            )}
                            content={() => printRef.current}
                        />
                        <SettingsIconTrigger
                            onClick={() => setPrintSettingsModal(true)}
                        />
                        <Excel
                            largerData={largerData}
                            dataLeft={dataSourceLeft}
                            dataRight={dataSourceRight}
                        />
                    </Col>
                </Row>
            </Form>
            <Print
                ref={printRef}
                title="Profit/Loss Account"
                footer={printSettingsFooter}
            >
                <table className="table-primary">
                    <thead>
                        <tr>
                            <th width="8%">
                                <span>A/C No</span>
                            </th>
                            <th width="30%">
                                <span>Particulars</span>
                            </th>
                            <th width="12%">
                                <span>Amount Rs</span>
                            </th>
                            <th width="8%">
                                <span>A/C No</span>
                            </th>
                            <th width="30%">
                                <span>Particulars</span>
                            </th>
                            <th width="12%">
                                <span>Amount Rs</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {largerData?.map((_, i) => {
                            const isLinkLeft =
                                dataSourceLeft[i]?.one &&
                                dataSourceLeft[i]?.two &&
                                dataSourceLeft[i]?.three;

                            const isLinkRight =
                                dataSourceRight[i]?.one &&
                                dataSourceRight[i]?.two &&
                                dataSourceRight[i]?.three;

                            const hasUnderlineLeft =
                                !dataSourceLeft[i]?.one &&
                                !dataSourceLeft[i]?.three;

                            const hasUnderlineRight =
                                !dataSourceRight[i]?.one &&
                                !dataSourceRight[i]?.three;
                            return (
                                <tr key={i}>
                                    <td>
                                        {isLinkLeft ? (
                                            dataSourceLeft[i]?.one
                                        ) : (
                                            <strong>
                                                {dataSourceLeft[i]?.one}
                                            </strong>
                                        )}
                                    </td>
                                    <td className={isLinkLeft && 'td-link'}>
                                        {isLinkLeft ? (
                                            <TableLink
                                                acno={dataSourceLeft[i]?.one}
                                                values={values}
                                                text={dataSourceLeft[i]?.two}
                                            />
                                        ) : (
                                            <strong
                                                style={{
                                                    borderBottom:
                                                        hasUnderlineLeft &&
                                                        '2px solid #000000',
                                                }}
                                            >
                                                {dataSourceLeft[i]?.two}
                                            </strong>
                                        )}
                                    </td>
                                    <td>
                                        {isLinkLeft ? (
                                            dataSourceLeft[i]?.three
                                        ) : (
                                            <strong>
                                                {dataSourceLeft[i]?.three}
                                            </strong>
                                        )}
                                    </td>
                                    <td>
                                        {isLinkRight ? (
                                            dataSourceRight[i]?.one
                                        ) : (
                                            <strong>
                                                {dataSourceRight[i]?.one}
                                            </strong>
                                        )}
                                    </td>
                                    <td className={isLinkRight && 'td-link'}>
                                        {isLinkRight ? (
                                            <TableLink
                                                acno={dataSourceRight[i]?.one}
                                                values={values}
                                                text={dataSourceRight[i]?.two}
                                            />
                                        ) : (
                                            <strong
                                                style={{
                                                    borderBottom:
                                                        hasUnderlineRight &&
                                                        '2px solid #000000',
                                                }}
                                            >
                                                {dataSourceRight[i]?.two}
                                            </strong>
                                        )}
                                    </td>
                                    <td>
                                        {isLinkRight ? (
                                            dataSourceRight[i]?.three
                                        ) : (
                                            <strong>
                                                {dataSourceRight[i]?.three}
                                            </strong>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Print>
            {/* Print settings modal */}
            <PrintSettingsModal
                visible={printSettingsModal}
                onOk={onPrintSettingsModalOk}
                onCancel={onPrintSettingsModalCancel}
                footerTemp={printSettingsFooterTemp}
                setFooterTemp={setPrintSettingsFooterTemp}
            />
        </>
    );
}

export const TableLink = props => {
    const { acno, values, text } = props;
    return (
        <Link
            to={{
                pathname: '/sub-account-balance',
                search: `acno=${acno}&level=${values?.level}`,
            }}
        >
            {text}
        </Link>
    );
};

export function Excel({ largerData, dataLeft, dataRight }) {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    const data = largerData.map((_, i) => {
        return {
            acnoLeft: dataLeft[i]?.one,
            particularLeft: dataLeft[i]?.two,
            amountLeft: dataLeft[i]?.three,
            acnoRight: dataRight[i]?.one,
            particularRight: dataRight[i]?.two,
            amountRight: dataRight[i]?.three,
        };
    });

    return (
        <ExcelFile
            element={<Button size="small">Excel</Button>}
            filename="Trial Balance"
        >
            <ExcelSheet data={data} name="Trial Balance">
                <ExcelColumn label="A/C No" value="acnoLeft" />
                <ExcelColumn label="Particular" value="particularLeft" />
                <ExcelColumn label="Amount Rs" value="amountLeft" />
                <ExcelColumn label="A/C No" value="acnoRight" />
                <ExcelColumn label="Particular" value="particularRight" />
                <ExcelColumn label="Amount Rs" value="amountRight" />
            </ExcelSheet>
        </ExcelFile>
    );
}
