import React from 'react';
import { Form, Radio, Table, Checkbox, Pagination, Button } from 'antd';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';
import ColumnsSelection from '../../../Common/ColumnsSelection/ColumnsSelection';
import ReactToPrint from 'react-to-print';
import Print from '../../../Common/Print/Print';

export default function MobileBankingAccountsForm(props) {
    // Destructuring
    const {
        onSelectionChange,
        reportType,
        accountsList,
        isLoading,
        onPaginationChange,
        columnsList,
        onColumnsChange,
    } = props;

    // Refs
    const printRef = React.useRef();

    // Variables and Constants
    const options = [
        {
            label: 'Active',
            value: 'active',
        },
        {
            label: 'Inactive',
            value: 'inactive',
        },
        {
            label: 'Deactivated',
            value: 'deactivated',
        },
        {
            label: 'Active Member',
            value: 'activemember',
        },
        {
            label: 'Inactive Member',
            value: 'inactivemember',
        },
    ];
    const dataSource = accountsList?.dataList?.map((item, i) => {
        return {
            key: i + 1 + '',
            ...item,
        };
    });

    const columns = [
        ...(columnsList.includes('Mobile Feature')
            ? [
                  {
                      title: 'Mobile Feature',
                      dataIndex: 'mbFeature',
                      key: 'mbFeature',
                      render: text => (
                          <Checkbox checked={text} disabled></Checkbox>
                      ),
                  },
              ]
            : []),
        {
            title: 'Member No',
            dataIndex: 'memberNO',
            key: 'memberNO',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'A/C Type',
            dataIndex: 'acName',
            key: 'acName',
        },
        ...(columnsList.includes('A/C Type No')
            ? [
                  {
                      title: 'A/C Type No',
                      dataIndex: 'acno',
                      key: 'acno',
                  },
              ]
            : []),

        {
            title: 'Account No',
            dataIndex: 'accountNO',
            key: 'accountNO',
        },
        ...(columnsList.includes('Entry Date')
            ? [
                  {
                      title: 'Entry Date',
                      dataIndex: 'mbEntryDate',
                      key: 'mbEntryDate',
                      render: text => ENG_ISO_TO_NEP(text),
                  },
              ]
            : []),
        ...(columnsList.includes('Expiry Date')
            ? [
                  {
                      title: 'Expiry Date',
                      dataIndex: 'mbExpireDate',
                      key: 'mbExpireDate',
                      render: text => ENG_ISO_TO_NEP(text),
                  },
              ]
            : []),

        {
            title: 'Mobile No',
            dataIndex: 'mbMobileNO',
            key: 'mbMobileNO',
        },
        ...(columnsList.includes('Renewed By')
            ? [
                  {
                      title: 'Renewed By',
                      dataIndex: 'mbRenewedBy',
                      key: 'mbRenewedBy',
                  },
              ]
            : []),
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const columnsSelectionList = [
        'Mobile Feature',
        'A/C Type No',
        'Entry Date',
        'Expiry Date',
        'Renewed By',
    ];

    return (
        <>
            <Form>
                <div className="d-flex" style={{ marginBottom: '1rem' }}>
                    <Radio.Group
                        options={options}
                        onChange={onSelectionChange}
                        defaultValue={reportType}
                        optionType="button"
                        buttonStyle="solid"
                    />
                    <ReactToPrint
                        trigger={() => (
                            <Button
                                type="default"
                                style={{ marginLeft: 'auto' }}
                            >
                                Print
                            </Button>
                        )}
                        content={() => printRef.current}
                    />
                </div>
            </Form>
            <ColumnsSelection
                columns={columnsSelectionList}
                selectedColumns={columnsList}
                onColumnsChange={onColumnsChange}
            />
            <Print ref={printRef} title="Mobile Banking Accounts">
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    size="small"
                    style={{ marginTop: '1rem' }}
                    loading={isLoading}
                />
            </Print>
            <Pagination
                size="small"
                total={accountsList?.totalRecords}
                showSizeChanger
                onChange={onPaginationChange}
                style={{ marginTop: '.5rem' }}
            />
        </>
    );
}
