import React from 'react';
import {
    GET_GROUPS,
    GET_MEMBER_BALANCE_ACCOUNTS,
    GET_MEMBER_ACCOUNT_BALANCE,
} from '../../../utils/axios';
// Components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import GroupWiseMemberReportForm from '../../../components/Membership/GroupWiseMemberReport/GroupWiseMemberReportForm/GroupWiseMemberReportForm';

export default function GroupWiseMemberReport() {
    // Local states
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [groupTypes, setGroupTypes] = React.useState([]);
    const [balanceAccounts, setBalanceAccounts] = React.useState([]);
    const [accountBalance, setAccountBalance] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedShareAccounts, setSelectedShareAccounts] = React.useState(
        []
    );
    const [selectedDepositAccounts, setSelectedDepositAccounts] =
        React.useState([]);
    const [selectedLoanAccounts, setSelectedLoanAccounts] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);

    // Refs
    const shareCertificateFormRef = React.useRef();

    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];

        // Update values on state
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        if (key === 'groupId' || key === 'groupName') {
            if (value.toLowerCase() === 'all')
                return updateValues({ groupId: 'All', groupName: 'All' });
            const groupType = groupTypes.find(item => item[key] === value);
            return updateValues(groupType);
        } else if (key === 'branchID' || key === 'branchName') {
            if (value.toLowerCase() === 'all')
                return updateValues({ branchID: 'All', branchName: 'All' });
            const branch = branches.find(item => item[key] === value);
            return updateValues(branch);
        }

        // Update values
        updateValues(val);
    };

    // Utils
    const onPaginationChange = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    // Get member balance
    React.useEffect(() => {
        if (
            viewValues?.groupName &&
            viewValues?.branchID &&
            page &&
            pageSize &&
            (selectedShareAccounts.length ||
                selectedDepositAccounts?.length ||
                selectedLoanAccounts?.length)
        ) {
            setIsLoading(true);
            const payload = {
                group: viewValues.groupName,
                branchID: viewValues.branchID,
                acnoList: [
                    ...selectedShareAccounts,
                    ...selectedDepositAccounts,
                    ...selectedLoanAccounts,
                ],
                pageNo: page,
                rpp: pageSize,
            };
            (async () => {
                const accountBalance = await GET_MEMBER_ACCOUNT_BALANCE(
                    payload
                );
                setAccountBalance(accountBalance);
                console.log('accountBalance: ', accountBalance);
                setIsLoading(false);
            })();
        }
    }, [
        viewValues?.groupName,
        viewValues?.branchID,
        selectedShareAccounts?.length,
        selectedDepositAccounts?.length,
        selectedLoanAccounts?.length,
        selectedShareAccounts,
        selectedDepositAccounts,
        selectedLoanAccounts,
        page,
        pageSize,
    ]);

    // Get data
    React.useEffect(() => {
        (async () => {
            const groupTypes = await GET_GROUPS();
            setGroupTypes(groupTypes);
            const balanceAccounts = await GET_MEMBER_BALANCE_ACCOUNTS();
            console.log('balanceAccounts: ', balanceAccounts);
            setBalanceAccounts(balanceAccounts);
        })();
    }, [viewValues?.noOfShare]);

    const formProps = {
        branches,
        groupTypes,
        onValuesChange,
        isLoading,
        balanceAccounts,
        accountBalance,
        selectedShareAccounts,
        setSelectedShareAccounts,
        selectedDepositAccounts,
        setSelectedDepositAccounts,
        selectedLoanAccounts,
        setSelectedLoanAccounts,
        onPaginationChange,
    };
    return (
        <PageWrapper>
            <GroupWiseMemberReportForm
                {...formProps}
                ref={shareCertificateFormRef}
                mode="view"
                values={viewValues}
            />
        </PageWrapper>
    );
}
