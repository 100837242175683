import React from 'react';
// Import from dependencies
import { Layout, Menu } from 'antd';
import { DashboardOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
// Import utils
import { SIDE_NAV_MENU_ITEMS } from '../../../utils/sideNav';
// Import components
import Logo from '../Logo/Logo';

export default function SideNav() {
    // Local states
    const [openKeys, setOpenKeys] = React.useState(['']);

    // Destructuring
    const { Sider } = Layout;
    const { SubMenu } = Menu;

    // Side nav sub menu items keys
    const subMenuKeys = Array.from(
        Array(SIDE_NAV_MENU_ITEMS.length),
        (x, i) => `sub${i}`
    );

    // On side nav menu open
    const onSideNavOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (subMenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    return (
        <Sider
            className="side-nav"
            // collapsible
            // collapsed={collapsed}
            // onCollapse={this.onCollapse}
        >
            <div className="side-nav__logo">
                <Logo />
            </div>

            <Menu
                className="side-nav__menu"
                theme="dark"
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onSideNavOpenChange}
                selectable={false}
            >
                <NavLink to="/dashboard" className="side-nav__menu--top-level">
                    <Menu.Item
                        key="1"
                        className="side-nav__menu-item side-nav__menu-item--primary"
                        icon={<DashboardOutlined />}
                    >
                        Dashboard
                    </Menu.Item>
                </NavLink>
                {/* Side nav menu items */}
                {SIDE_NAV_MENU_ITEMS.map((item, i) => (
                    <SubMenu
                        key={`sub${i}`}
                        icon={item.icon}
                        title={item.title}
                    >
                        {item.items.map((item, j) => (
                            <>
                                {item?.subMenu ? (
                                    <SubMenu key={j} title={item.title}>
                                        {item.subMenu.map((subMenu, k) => (
                                            <NavLink key={k} to={subMenu.to}>
                                                <Menu.Item>
                                                    {subMenu.title}
                                                </Menu.Item>
                                            </NavLink>
                                        ))}
                                    </SubMenu>
                                ) : (
                                    <NavLink key={j} exact to={item.to}>
                                        <Menu.Item key={j}>
                                            {item.title}
                                        </Menu.Item>
                                    </NavLink>
                                )}
                            </>
                        ))}
                    </SubMenu>
                ))}
            </Menu>
        </Sider>
    );
}
