import React from 'react';
import { Button, message, Modal } from 'antd';
// Import utils
import axios from '../../utils/axios';
import { useRecoilValue } from 'recoil';
import { branches as branchesAtom } from '../../recoil/atoms';
// Import components
import {
    UPDATE_AXIOS_AUTH,
    SET_DEFAULT_VALUES,
    DLT_KEYS_FROM_OBJ,
} from '../../utils/functions';
import PageWrapper from '../../components/Common/PageWrapper/PageWrapper';
import CollectionGroupForm from '../../components/CollectionGroupForm/CollectionGroupForm';
import BottomActionBarFixed from '../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function GroupType() {
    // Global states
    const branches = useRecoilValue(branchesAtom);
    // Local states
    const [collectionGroups, setCollectionGroups] = React.useState([]);
    const [staffs, setStaffs] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // COMMON FUNCTIONS
    // Get collection groups
    const getCollectionGroups = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/CollectionGroup`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setCollectionGroups(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get collection groups');
                message.error('Something went wrong. Please refresh the page');
            });
    };
    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    // Get collection group detail
    const getCollectionGroupDetail = cgno => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/CollectionGroup/detail/${cgno}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data);
                setViewValues(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get collection group detail');
                message.error('Something went wrong. Please refresh the page');
            });
    };

    // Get staffs by branch ID
    const getStaffs = React.useCallback(branchID => {
        setStaffs([]);
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/Staff/GetByBranchID/${
                    branchID || '02'
                }`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data, 'Staffs');
                setStaffs(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get staffs');
                message.error('Something went wrong. Please refresh the page');
            });
    }, []);

    // VIEW FORM
    // On view form values change
    const onValuesChange = (val, mode) => {
        console.log(val);
        const key = Object.keys(val)[0];
        const value = val[key];
        let values;
        if (mode === 'view') values = viewValues;
        else if (mode === 'edit') values = editValues;
        else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (val.hasOwnProperty('cgno') || val.hasOwnProperty('cgName')) {
            const group = collectionGroups.find(group => group[key] === value);
            updateValues(group);
            if (mode === 'view') getCollectionGroupDetail(group.cgno);
        } else if (
            val.hasOwnProperty('branchID') ||
            val.hasOwnProperty('branchName')
        ) {
            const branch = branches.find(branch => branch[key] === value);
            updateValues({
                ...DLT_KEYS_FROM_OBJ(values, ['staffID', 'staffName']),
                ...branch,
            });
            getStaffs(val.branchID);
        } else if (
            val.hasOwnProperty('staffID') ||
            val.hasOwnProperty('staffName')
        ) {
            const staff = staffs.find(staff => staff[key] === value);
            updateValues(staff);
        }
        updateValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // EDIT ACCOUNT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/CollectionGroup/Update/${editValues.cgno}`,
                        editValues
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);

                        setViewValues(res.data.data);
                        setEditModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Updated!');
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to update collection group account'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setEditModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD ACCOUNT MODAL
    // On add modal open
    const onAddModalOpen = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On add modal ok
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/CollectionGroup/Add`,
                        addValues
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        const data = res.data.data;
                        console.log('add data: ', data);
                        setCollectionGroups([
                            ...collectionGroups,
                            { cgno: data.acgno, cgName: data.cgName },
                        ]);
                        setViewValues(data);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        setAddValues(null);
                        message.success('New account created!');
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to create collection group account'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // DELETE ACCOUNT MODAL
    // On delete modal open
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On delete modal ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/CollectionGroup/Delete/${viewValues.cgno}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setViewValues(null);
                setCollectionGroups(
                    [...collectionGroups].filter(
                        group => group.cgno !== viewValues.cgno
                    )
                );
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Account deleted!');
            })
            .catch(err => {
                console.error(err, 'Failed to delete area type account');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };
    // On add modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // FORM PROPS
    const formProps = {
        ref: formRef,
        collectionGroups,
        staffs,
        getStaffs,
        setDefaultValues,
        onValuesChange,
    };

    // On component mount
    React.useEffect(() => {
        UPDATE_AXIOS_AUTH();
        getCollectionGroups();
    }, []);
    return (
        <PageWrapper>
            {/* View form */}
            <CollectionGroupForm
                {...formProps}
                mode="view"
                values={viewValues}
            />
            {/* Edit account modal */}
            <Modal
                title="Edit account"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                onOk={onEditModalOk}
                onCancel={onEditModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: editModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <CollectionGroupForm
                    {...formProps}
                    mode="edit"
                    values={editValues}
                />
            </Modal>
            {/* Add account modal */}
            <Modal
                title="Add account"
                visible={addModal.visible}
                confirmLoading={addModal.confirmLoading}
                onOk={onAddModalOk}
                onCancel={onAddModalCancel}
                okText="Create"
                cancelButtonProps={{
                    disabled: addModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <CollectionGroupForm
                    {...formProps}
                    mode="add"
                    values={addValues}
                />
            </Modal>
            {/* Delete account modal */}
            <Modal
                title="Delete account?"
                visible={deleteModal.visible}
                confirmLoading={deleteModal.confirmLoading}
                onOk={onDeleteModalOk}
                onCancel={onDeleteModalCancel}
                okText="Delete"
                cancelButtonProps={{
                    disabled: deleteModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>The account and its data will be deleted forever</p>
            </Modal>
            {/* Actions bar */}
            <BottomActionBarFixed>
                <Button
                    type="primary"
                    onClick={onEditModalOpen}
                    disabled={!viewValues?.cgno}
                >
                    Edit
                </Button>
                <Button type="primary" onClick={onAddModalOpen}>
                    Add
                </Button>
                <Button
                    type="primary"
                    onClick={onDeleteModalOpen}
                    disabled={!viewValues?.cgno}
                >
                    Delete
                </Button>
            </BottomActionBarFixed>
        </PageWrapper>
    );
}
