import React from 'react';
import { Row, Col, Form, Select, Input, Space, Button } from 'antd';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import { ENG_ISO_TO_NEP } from '../../utils/functions';
import Branches from '../Common/Geography/Branches/Branches';
import PaymentSource from '../Common/PaymentSource/PaymentSource';

export default function ShareAdditionForm(props) {
    // Destructuring
    const {
        mode,
        values,
        onValuesChange,
        setDefaultValues,
        members,
        onFormSubmit,
        isProcessing,
        handleNew,
    } = props;
    // Global states

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);

    return (
        <Form
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onValuesChange={val => onValuesChange(val, mode)}
            onFinish={onFormSubmit}
            fields={[
                {
                    name: 'branchID',
                    value: values?.branchID,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'transDate',
                    value:
                        values?.transDate && ENG_ISO_TO_NEP(values?.transDate),
                },
                {
                    name: 'memberNO',
                    value: values?.memberNO,
                },
                {
                    name: 'memberName',
                    value: values?.memberName,
                },
                {
                    name: 'opAddress',
                    value: values?.opAddress,
                },
                {
                    name: 'mobileNO',
                    value: values?.mobileNO,
                },
                {
                    name: 'paidBy',
                    value: values?.paidBy,
                },
                {
                    name: 'description',
                    value: values?.description,
                },
                {
                    name: 'cashNo',
                    value: values?.cashNo,
                },
                {
                    name: 'cashName',
                    value: values?.cashName,
                },
                {
                    name: 'bankNo',
                    value: values?.bankNo,
                },
                {
                    name: 'bankName',
                    value: values?.bankName,
                },
                {
                    name: 'otherAcno',
                    value: values?.otherAcno,
                },
                {
                    name: 'otherAcName',
                    value: values?.otherAcName,
                },
                {
                    name: 'otherNo',
                    value: values?.otherNo,
                },
                {
                    name: 'otherName',
                    value: values?.otherName,
                },
                {
                    name: 'currentShare',
                    value: values?.currentShare,
                },
                {
                    name: 'preTotal',
                    value: values?.preTotal,
                },
                {
                    name: 'isCash',
                    value: values?.isCash,
                },
                {
                    name: 'cashAmount',
                    value: values?.cashAmount,
                },
                {
                    name: 'isBank',
                    value: values?.isBank,
                },
                {
                    name: 'bankAmount',
                    value: values?.bankAmount,
                },
                {
                    name: 'isOther',
                    value: values?.isOther,
                },
                {
                    name: 'otherAmount',
                    value: values?.otherAmount,
                },
                {
                    name: 'totalAmount',
                    value: values?.totalAmount,
                },
                {
                    name: 'totalAmountInWords',
                    value: values?.totalAmountInWords,
                },
                {
                    name: 'journalNO',
                    value: values?.journalNO,
                },
                {
                    name: 'receiptNo',
                    value: values?.receiptNo,
                },
                {
                    name: 'transNo',
                    value: values?.transNo,
                },
                {
                    name: 'isClearance',
                    value: values?.isClearance,
                },
            ]}
        >
            <Row gutter={20}>
                <Col span={10}>
                    <Branches
                        item="branchName"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 24 }}
                        required
                    />
                </Col>
                <Col span={2}>
                    <Branches
                        item="branchID"
                        label={false}
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 24 }}
                        required
                    />
                </Col>
                <Col span={6}></Col>
                <Col span={6}>
                    <Form.Item
                        label="Date"
                        name="transDate"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <NepaliDatePicker inputClassName="ant-input" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={10}>
                    <Form.Item
                        label="Member No."
                        name="memberNO"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {members?.length &&
                                members.map((member, i) => (
                                    <Select.Option
                                        key={i}
                                        value={member.memberNO}
                                    >
                                        {member.memberNO}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}></Col>
                <Col span={6}>
                    <Form.Item label="Journal No." name="journalNO">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={10}>
                    <Form.Item
                        label="Member Name"
                        name="memberName"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 24 }}
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {members?.length &&
                                members.map((member, i) => (
                                    <Select.Option
                                        key={i}
                                        value={member.memberName}
                                    >
                                        {member.memberName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={8}></Col>
                <Col span={6}>
                    <Form.Item label="Receipt No." name="receiptNo">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={10}>
                    <Form.Item
                        label="Address"
                        name="opAddress"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={8}></Col>
                <Col span={6}>
                    <Form.Item label="Trans No." name="transNo">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={10}>
                    <Form.Item
                        label="Mobile"
                        name="mobileNO"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={8}></Col>
                <Col span={6}>
                    <Form.Item name="currentShare" label="Current Share">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={10}>
                    <Form.Item
                        label="Paid by"
                        name="paidBy"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}></Col>
                <Col span={6}>
                    <Form.Item
                        label="Share Amt"
                        name="preTotal"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={10}>
                    <Form.Item
                        label="Description"
                        name="description"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 24 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <PaymentSource
                title="Receipt Source"
                mode="receipt"
                values={values}
            />
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label=" " colon={false}>
                        <Space style={{ marginTop: '1.5rem' }}>
                            <Button htmlType="submit" disabled={isProcessing}>
                                Save
                            </Button>
                            <Button>Print Receipt</Button>
                            <Button>Deno</Button>
                            <Button onClick={handleNew}>New</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
