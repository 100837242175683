import React from 'react';
import { Form, Select, Input, Row, Col, DatePicker, Radio } from 'antd';
import moment from 'moment';
import validator from 'validator';
import {
    DATE_FORMAT,
    PROFILE_PIC_SUPPORTED_EXT,
} from '../../../../utils/constants';
import {
    SET_PROFILE_PIC,
    CONTAINS_DIGITS_ONLY,
} from '../../../../utils/functions';
import { GET_GROUPS, GET_AREAS } from '../../../../utils/axios';
import PhotoPlaceholder from '../../../../assets/images/placeholder-image.png';

export default function PersonalDetailForm(props) {
    // Local states
    const [photoUrl, setPhotoUrl] = React.useState(null);
    // Destructuring
    const {
        mode,
        members,
        values,
        groups,
        setGroups,
        areas,
        setAreas,
        memberNOValidationStatus,
        regSavIdValidationStatus,
        onFileChange,
        onDateKeyUp,
        onDateChangeEng,
    } = props;
    // On component mount
    React.useEffect(() => {
        (async () => {
            const groups = await GET_GROUPS();
            setGroups(groups);
            const areas = await GET_AREAS();
            setAreas(areas);
        })();

        // Set profile pic
        SET_PROFILE_PIC(values?.photo, values?.photoLoc, setPhotoUrl);
    }, [setGroups, setAreas, values?.photo, values?.photoLoc]);

    return (
        <div className="personal-detail-form">
            <Row gutter={40}>
                <Col xs={24} lg={19}>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Branch Name" name="branchName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Branch ID" name="branchID">
                                <Input disabled />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item
                                label="Entrance Date"
                                name={`entryDate__${mode}`}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <input
                                    type="text"
                                    className="ant-input"
                                    onKeyUp={e => onDateKeyUp(e, mode)}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Member No."
                                name="memberNO"
                                rules={
                                    mode === 'add' &&
                                    JSON.parse(
                                        localStorage.getItem(
                                            'ENTERPRISE_DETAIL'
                                        )
                                    ).fetchIDbeforeInsert && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                                validateStatus={memberNOValidationStatus}
                                hasFeedback={mode === 'add' && values?.memberNO}
                                help={
                                    memberNOValidationStatus === 'error'
                                        ? 'Already taken'
                                        : null
                                }
                            >
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {members.length &&
                                            members.map((member, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={member.memberNO}
                                                >
                                                    {member.memberNO}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                ) : (
                                    <Input
                                        placeholder="Enter..."
                                        disabled={mode === 'edit'}
                                    />
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Reg. Saving AC/NO"
                                name="regSaveId"
                                rules={
                                    mode === 'add' &&
                                    JSON.parse(
                                        localStorage.getItem(
                                            'ENTERPRISE_DETAIL'
                                        )
                                    ).fetchIDbeforeInsert && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                                validateStatus={regSavIdValidationStatus}
                                hasFeedback={
                                    mode === 'add' && values?.regSaveId
                                }
                                help={
                                    regSavIdValidationStatus === 'error'
                                        ? 'Already taken'
                                        : null
                                }
                            >
                                <Input
                                    placeholder="Enter..."
                                    disabled={mode !== 'add'}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item
                                label="Share Holder No."
                                name="shareHolderNO"
                                rules={[
                                    () => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                CONTAINS_DIGITS_ONLY(value)
                                            ) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(
                                                new Error('Digits only')
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={12}>
                            <Form.Item
                                label="Member Name"
                                name="memberName"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item label="सदस्यको नाम" name="unName">
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Date of Birth (English)"
                                name="dobEnglish"
                            >
                                <span style={{ display: 'none' }}></span>
                                <DatePicker
                                    value={
                                        values?.dobEnglish &&
                                        moment(
                                            moment(values?.dobEnglish).format(
                                                DATE_FORMAT
                                            )
                                        )
                                    }
                                    format={DATE_FORMAT}
                                    allowClear={false}
                                    onChange={date =>
                                        onDateChangeEng(
                                            date,
                                            'dobEnglish',
                                            mode
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="जन्म मिति (नेपालि)"
                                name={`dobEnglish__${mode}`}
                            >
                                <input
                                    type="text"
                                    className="ant-input"
                                    onKeyUp={e => onDateKeyUp(e, mode)}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item
                                label="Gender"
                                name="gender"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Radio.Group>
                                    <Radio value="M">Male</Radio>
                                    <Radio value="F">Female</Radio>
                                    <Radio value="I">Institution</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Service Area"
                                name="area"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {areas?.map((area, i) => (
                                        <Select.Option
                                            key={i}
                                            value={area.areaName}
                                        >
                                            {area.areaName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Group"
                                name="group"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {groups?.map((group, i) => (
                                        <Select.Option
                                            key={i}
                                            value={group.groupName}
                                        >
                                            {group.groupName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={12}></Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Telephone No." name="telephone">
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Mobile No."
                                name="mobileNO"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                        {
                                            len: 10,
                                            message: 'Must be 10 digits',
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    validator.isInt(value)
                                                ) {
                                                    return Promise.resolve();
                                                }

                                                return Promise.reject(
                                                    new Error('Digits only')
                                                );
                                            },
                                        }),
                                    ]
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item
                                label="Email ID"
                                name="emailID"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            type: 'email',
                                            message: 'Invalid email address',
                                        },
                                    ]
                                }
                            >
                                <Input placeholder="Enter..." />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={5}>
                    <Form.Item label="Add/Change Photo" name="photoLoc">
                        <div className="avatar">
                            <div className="avatar--pic">
                                <img
                                    src={photoUrl || PhotoPlaceholder}
                                    alt="Profile"
                                />
                            </div>
                            {mode !== 'view' && (
                                <div className="avatar--upload-button">
                                    <input
                                        type="file"
                                        id="photo"
                                        name="photo"
                                        accept={PROFILE_PIC_SUPPORTED_EXT}
                                        onChange={e => onFileChange(e, mode)}
                                    />
                                </div>
                            )}
                        </div>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
