import React from 'react';
import { GET_ACTIVE_SMS_ACCOUNTS_LIST } from '../../../utils/axios';
import { ON_COLUMNS_CHANGE } from '../../../utils/functions';
// Components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import SmsBankingAccountsForm from '../../../components/Savings/SmsBankingAccounts/SmsBankingAccountsForm/SmsBankingAccountsForm';

export default function SmsBankingAccounts() {
    // Local states
    const [reportType, setReportType] = React.useState('active');
    const [accountsList, setAccountsList] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [columns, setColumns] = React.useState([]);

    // Utils
    const onSelectionChange = e => {
        setReportType(e.target.value);
    };

    const onPaginationChange = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    const onColumnsChange = e => {
        ON_COLUMNS_CHANGE(e, setColumns);
    };

    React.useEffect(() => {
        setIsLoading(true);
        const payload = {
            reportType,
            pageNO: page,
            rpp: pageSize,
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
        };

        (async () => {
            const accountsList = await GET_ACTIVE_SMS_ACCOUNTS_LIST(payload);
            setAccountsList(accountsList);
            setIsLoading(false);
        })();
    }, [reportType, page, pageSize]);

    // Form props
    const formProps = {
        reportType,
        onSelectionChange,
        accountsList,
        isLoading,
        onPaginationChange,
        columnsList: columns,
        onColumnsChange,
    };
    return (
        <PageWrapper>
            <SmsBankingAccountsForm {...formProps} />
        </PageWrapper>
    );
}
