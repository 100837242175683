import React from 'react';
import { SettingOutlined } from '@ant-design/icons';

export default function SettingsIconTrigger(props) {
    // Destructuring
    const { onClick } = props;
    return (
        <span className="print__settings" onClick={onClick}>
            <SettingOutlined />
        </span>
    );
}
