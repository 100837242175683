import React from 'react';
import {
    GET_MEMBERS,
    GET_MEMBER_FULL_STATEMENT,
    GET_SHARE_ISSUE,
} from '../../../utils/axios';
import moment from 'moment';
import {
    ON_DATE_KEYUP,
    ON_DATE_CHANGE,
    DLT_KEYS_FROM_OBJ,
} from '../../../utils/functions';
// Components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import MemberStatementForm from '../../../components/Membership/MemberStatement/MemberStatementForm/MemberStatementForm';

export default function MemberStatement() {
    // Local states
    const [members, setMembers] = React.useState([]);
    const [fullStatement, setFullStatement] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [shareMode, setShareMode] = React.useState('new');
    const [isLoading, setIsLoading] = React.useState(false);

    // Refs
    const shareCertificateFormRef = React.useRef();

    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];

        // Update values on state
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        if (key === 'memberNO' || key === 'memberName') {
            onShareIssueCancel();
            const member = members.find(member => member[key] === value);
            return updateValues(member);
        }

        // Update values
        updateValues(val);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // SHARE ISSUE
    const onShareIssueNewClick = async () => {
        setShareMode('loading');
        const shareIssue = await GET_SHARE_ISSUE();
        setViewValues(prevValues => ({
            ...prevValues,
            ...shareIssue,
            noOfShare: '',
            shareAmount: '',
            issueddate: moment().format('YYYY-MM-DD'),
        }));
        setShareMode('issue');
    };

    const onShareIssueCancel = () => {
        setViewValues(prevValues => ({
            ...DLT_KEYS_FROM_OBJ(prevValues, [
                'noOfShare',
                'certiNo',
                'from',
                'to',
                'shareAmount',
            ]),
        }));
        setShareMode('new');
    };

    const onShareRecordClick = record => {
        setShareMode('new');
        setViewValues(prevValues => ({
            ...prevValues,
            ...record,
        }));
    };

    // Get member full statement
    React.useEffect(() => {
        if (!viewValues?.memberNO) return;
        setIsLoading(true);
        (async () => {
            const shareCertificate = await GET_MEMBER_FULL_STATEMENT(
                viewValues.memberNO
            );
            console.log('shareCertificate: ', shareCertificate);
            setFullStatement(shareCertificate);
            setIsLoading(false);
        })();
    }, [viewValues?.memberNO]);

    // Get and set members
    React.useEffect(() => {
        (async () => {
            const members = await GET_MEMBERS();
            setMembers(members);
        })();
    }, []);

    // Set default values
    React.useEffect(() => {
        const defaultValues = {
            issueddate: moment().format('YYYY-MM-DD'),
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    }, []);

    // Set Share No To
    React.useEffect(() => {
        const noOfShare = +viewValues?.noOfShare || 1;
        const from = +viewValues?.from || 0;
        const to = noOfShare - 1 + from;
        setViewValues(prevValues => ({
            ...prevValues,
            to,
        }));
    }, [viewValues?.noOfShare, viewValues?.from]);

    // Set Share Amount
    React.useEffect(() => {
        const noOfShare = +viewValues?.noOfShare || 0;
        const shareAmount = noOfShare * 100;
        setViewValues(prevValues => ({
            ...prevValues,
            shareAmount,
        }));
    }, [viewValues?.noOfShare]);

    const formProps = {
        members,
        onValuesChange,
        fullStatement,
        onDateKeyUp,
        onDateChange,
        shareMode,
        onShareIssueNewClick,
        onShareIssueCancel,
        onShareRecordClick,
        isLoading,
    };
    return (
        <PageWrapper>
            <MemberStatementForm
                {...formProps}
                ref={shareCertificateFormRef}
                mode="view"
                values={viewValues}
            />
        </PageWrapper>
    );
}
