import React from 'react';
import { Row, Col, Form, Select, Input, Space, Button, Table } from 'antd';
import validator from 'validator';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../utils/functions';
import Branches from '../../../Common/Geography/Branches/Branches';
import PaymentSource from '../../../Common/PaymentSource/PaymentSource';

export default function ReceiptEntryForm(props) {
    // Destructuring
    const {
        mode,
        values,
        onValuesChange,
        setDefaultValues,
        members,
        onFormSubmit,
        isProcessing,
        handleNew,
        tableData,
        getDetailInstant,
        onDateChange,
        onDateKeyUp,
    } = props;
    // Global states

    const data = [
        ...tableData.filter(item => item.transaction === 'Entrance'),
        ...tableData.filter(item => item.transaction === 'Savings'),
        ...tableData.filter(item => item.transaction === 'Share'),
        ...tableData.filter(item => item.transaction === 'Loan'),
        ...tableData.filter(
            item =>
                item.transaction !== 'Entrance' &&
                item.transaction !== 'Savings' &&
                item.transaction !== 'Share' &&
                item.transaction !== 'Loan'
        ),
    ];

    const dataSource = data.map((item, i) => {
        return {
            key: i,
            ...item,
            [`received${i}`]: item.received,
            [`remark${i}`]: item.remark,
        };
    });

    const columns = [
        {
            title: 'Type',
            dataIndex: 'transaction',
            key: 'transaction',
            width: '90px',
        },
        {
            title: 'A/C No',
            dataIndex: 'acno',
            key: 'acno',
            width: '80px',
        },
        {
            title: 'Account',
            dataIndex: 'accountName',
            key: 'accountName',
        },
        {
            title: 'Item Code',
            dataIndex: 'itemCode',
            key: 'itemCode',
            width: '120px',
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            width: '100px',
        },
        {
            title: 'Received Rs',
            dataIndex: 'received',
            key: 'received',
            width: '120px',
            render: (text, data) => (
                <Form.Item
                    name={`received${data.key}`}
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (!value || validator.isInt(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Digits only'));
                            },
                        }),
                    ]}
                    wrapperCol={24}
                >
                    <Input
                        value={text}
                        style={{ width: '100%', maxWidth: '100%' }}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Remarks',
            dataIndex: 'remark',
            key: 'remark',
            width: '160px',
            render: (text, data) => (
                <Form.Item name={`remark${data.key}`} wrapperCol={24}>
                    <Input
                        value={text}
                        style={{ width: '100%', maxWidth: '100%' }}
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Previous remarks',
            dataIndex: 'previousRemarks',
            key: 'previousRemarks',
            width: '150px',
        },
    ];

    // NEPALI DATEPICKER
    // Trans date
    const transDateInput = document.getElementById('transDate');
    transDateInput &&
        transDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, transDateInput, mode)
        );

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);
    return (
        <Form
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onValuesChange={val => onValuesChange(val, mode)}
            onFinish={onFormSubmit}
            fields={[
                {
                    name: 'branchID',
                    value: values?.branchID,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'transDate',
                    value:
                        values?.transDate && ENG_ISO_TO_NEP(values?.transDate),
                },
                {
                    name: 'memberNO',
                    value: values?.memberNO,
                },
                {
                    name: 'memberName',
                    value: values?.memberName,
                },
                {
                    name: 'address',
                    value: values?.address,
                },
                {
                    name: 'mobileNO',
                    value: values?.mobileNO,
                },
                {
                    name: 'paidBy',
                    value: values?.paidBy,
                },
                {
                    name: 'cashNo',
                    value: values?.cashNo,
                },
                {
                    name: 'cashName',
                    value: values?.cashName,
                },
                {
                    name: 'bankNo',
                    value: values?.bankNo,
                },
                {
                    name: 'bankName',
                    value: values?.bankName,
                },
                {
                    name: 'otherAcno',
                    value: values?.otherAcno,
                },
                {
                    name: 'otherAcName',
                    value: values?.otherAcName,
                },
                {
                    name: 'otherNo',
                    value: values?.otherNo,
                },
                {
                    name: 'otherName',
                    value: values?.otherName,
                },

                {
                    name: 'preTotal',
                    value: values?.preTotal,
                },
                {
                    name: 'isCash',
                    value: values?.isCash,
                },
                {
                    name: 'cashAmount',
                    value: values?.cashAmount,
                },
                {
                    name: 'isBank',
                    value: values?.isBank,
                },
                {
                    name: 'bankAmount',
                    value: values?.bankAmount,
                },
                {
                    name: 'isOther',
                    value: values?.isOther,
                },
                {
                    name: 'otherAmount',
                    value: values?.otherAmount,
                },
                {
                    name: 'totalAmount',
                    value: values?.totalAmount,
                },
                {
                    name: 'totalAmountInWords',
                    value: values?.totalAmountInWords,
                },
                {
                    name: 'journalNO',
                    value: values?.journalNO,
                },
                {
                    name: 'receiptNo',
                    value: values?.receiptNo,
                },
                {
                    name: 'transNo',
                    value: values?.transNo,
                },
                {
                    name: 'isClearance',
                    value: values?.isClearance,
                },
                {
                    name: 'accountNo',
                    value: values?.accountNo,
                },
                ...tableData.map((item, i) => {
                    return {
                        name: `received${i}`,
                        value: item[`received${i}`],
                    };
                }),
                ...tableData.map((item, i) => {
                    return {
                        name: `remark${i}`,
                        value: item[`remark${i}`],
                    };
                }),
            ]}
        >
            <Row gutter={20}>
                <Col span={6}>
                    <Branches item="branchName" required />
                </Col>
                <Col span={6}>
                    <Branches item="branchID" required />
                </Col>
                <Col span={4}></Col>
                <Col span={6}>
                    <Form.Item
                        label="Date"
                        name="transDate"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item
                        label="Member No."
                        name="memberNO"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {members?.length &&
                                members.map((member, i) => (
                                    <Select.Option
                                        key={i}
                                        value={member.memberNO}
                                    >
                                        {member.memberNO}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Account No" name="accountNo">
                        <Input onPressEnter={getDetailInstant} />
                    </Form.Item>
                </Col>
                <Col span={4}></Col>
                <Col span={6}>
                    <Form.Item label="Journal No." name="journalNO">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item
                        label="Member Name"
                        name="memberName"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {members?.length &&
                                members.map((member, i) => (
                                    <Select.Option
                                        key={i}
                                        value={member.memberName}
                                    >
                                        {member.memberName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={10}></Col>
                <Col span={6}>
                    <Form.Item label="Receipt No." name="receiptNo">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label="Address" name="address">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Mobile" name="mobileNO">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={4}></Col>
                <Col span={6}>
                    <Form.Item label="Trans No." name="transNo">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label="Income Source" name="incomeSource">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={10}></Col>
                <Col span={6}></Col>
            </Row>
            <Table
                // size="small"
                className="ant-table-wrapper--collapsed"
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{ y: 240 }}
            />
            <Row gutter={20}>
                <Col span={16}></Col>
                <Col span={6} style={{ marginTop: '.5rem' }}>
                    <Form.Item label="Total Amount" name="preTotal">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <PaymentSource
                title="Receipt Source"
                mode="receipt"
                values={values}
            />
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label=" " colon={false}>
                        <Space style={{ marginTop: '1.5rem' }}>
                            <Button htmlType="submit" disabled={isProcessing}>
                                Save
                            </Button>
                            <Button>Print Receipt</Button>
                            <Button>Deno</Button>
                            <Button onClick={handleNew}>New</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
