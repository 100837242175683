import React from 'react';
import { Button, Modal, message } from 'antd';
import moment from 'moment';
// Import utils
import axios from '../../../utils/axios';
import {
    UPDATE_AXIOS_AUTH,
    SET_DEFAULT_VALUES,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
    DLT_KEYS_FROM_OBJ_EXCEPT,
} from '../../../utils/functions';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import BankRegistrationForm from '../../../components/ItemSetup/BankRegistrationForm/BankRegistrationForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function BankRegistration() {
    // Local states
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [banks, setBanks] = React.useState([]);
    const [bankClasses, setBankClasses] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [asyncUpdateModal, setAsyncUpdateModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Get bank accounts
    const getBankAccounts = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Banks/BankACNO`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setBankAccounts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get bank accounts');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get banks
    const getBanks = React.useCallback(branchID => {
        console.log('test');
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Banks/GetBanks/${branchID}`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setBanks(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get banks');
                message.error('Something went wrong. Please try again!');
            });
    }, []);

    // Get bank detail
    const getBankDetail = bankID => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Banks/Detail/${bankID}`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);

                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get bank detail');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get bank classes
    const getBankClasses = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Banks/BankClass`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setBankClasses(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get bank classes');
                message.error('Something went wrong. Please try again!');
            });
    };

    // Get new bank code
    const getNewBankCode = React.useCallback(
        branchID => {
            axios
                .get(
                    `${process.env.REACT_APP_HOST}/api/Banks/GetNewID/${addValues?.acno}/${branchID}`
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);

                    setAddValues(prevValues => ({
                        ...prevValues,
                        itemCode: res.data.data,
                    }));
                })
                .catch(err => {
                    console.error(err, 'Failed to get new bank code');
                    message.error('Something went wrong. Please try again!');
                });
        },
        [addValues?.acno]
    );

    // VIEW FORM

    // EDIT ACCOUNT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current.validateFields().then(() => {
            setEditModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));

            axios
                .patch(
                    `${process.env.REACT_APP_HOST}/api/Banks/Update/${editValues?.acno}/${editValues?.itemCode}`,
                    editValues
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        if (res.data.statusCode === 2)
                            return setAsyncUpdateModal(prevValues => ({
                                ...prevValues,
                                visible: true,
                            }));
                        else return message.error(res.data.statusMessage);

                    setViewValues(editValues);
                    setEditModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    message.success('Updated!');
                })
                .catch(err => {
                    console.error(err, 'Failed to update area type account');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setEditModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };

    // On async update modal ok
    const onAsyncUpdateModalOk = () => {
        setAsyncUpdateModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .patch(
                `${process.env.REACT_APP_HOST}/api/Banks/UpdateAll/${editValues?.acno}/${editValues?.itemCode}`,
                editValues
            )
            .then(res => {
                if (res.data.statusCode !== 0) {
                    setAsyncUpdateModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                    return message.error(res.data.statusMessage);
                }
                setViewValues(editValues);
                setAsyncUpdateModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                setEditModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Updated async!');
            })
            .catch(err => {
                console.error(
                    err,
                    'Failed to update area type account asynchronously'
                );
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setAsyncUpdateModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // On async update modal cancel
    const onAsyncUpdateModalCancel = () => {
        setAsyncUpdateModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD ACCOUNT MODAL
    // On add modal open
    const onAddModalOpen = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On add modal ok
    const onAddModalOk = () => {
        formRef.current.validateFields().then(() => {
            setAddModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));
            axios
                .post(
                    `${process.env.REACT_APP_HOST}/api/Banks/Add/${addValues?.itemCode}`,
                    addValues
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    setViewValues(addValues);
                    setAddModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    setBanks([
                        ...banks,
                        {
                            itemCode: addValues.itemCode,
                            bankName: addValues.bankName,
                        },
                    ]);
                    setAddValues(null);
                    message.success('New bank account created!');
                })
                .catch(err => {
                    console.error(err, 'Failed to create bank account');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setAddModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // DELETE ACCOUNT MODAL
    // On delete modal open
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On delete modal ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/Banks/Delete/${viewValues?.acno}/${viewValues?.itemCode}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setBanks(
                    [...banks].filter(
                        bank => bank.itemCode !== viewValues.itemCode
                    )
                );
                setViewValues(null);
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Account deleted!');
            })
            .catch(err => {
                console.error(err, 'Failed to delete bank account');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };
    // On add modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // COMMON
    const onValuesChange = (val, mode) => {
        // const key = Object.keys(val)[0];
        // const value = val[key];
        let values;
        if (mode === 'view') values = viewValues;
        else if (mode === 'edit') values = editValues;
        else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (val.hasOwnProperty('acno')) {
            const acc = bankAccounts.find(acc => acc.acno === val.acno);
            updateValues({ acName: acc.acName, nepName: acc.nepName });
        } else if (val.hasOwnProperty('acName')) {
            const acc = bankAccounts.find(acc => acc.acName === val.acName);
            updateValues({ acno: acc.acno, nepName: acc.nepName });
        } else if (val.hasOwnProperty('nepName')) {
            const acc = bankAccounts.find(acc => acc.nepName === val.nepName);
            updateValues({ acno: acc.acno, acName: acc.acName });
        } else if (
            val.hasOwnProperty('branchID') ||
            val.hasOwnProperty('branchName')
        ) {
            const branch = branches.find(
                branch => branch.branchID === val.branchID
            );
            updateValues({
                ...(mode === 'view'
                    ? {
                          ...DLT_KEYS_FROM_OBJ_EXCEPT(values, [
                              'branchID',
                              'branchName',
                              'acName',
                              'acno',
                          ]),
                      }
                    : {}),
                ...branch,
            });
        } else if (val.hasOwnProperty('itemCode')) {
            if (mode !== 'add') {
                const bank = banks.find(bank => bank.itemCode === val.itemCode);
                updateValues({ bankName: bank.bankName });
                getBankDetail(bank.itemCode);
            }
        } else if (val.hasOwnProperty('bankName')) {
            if (mode === 'view') {
                const bank = banks.find(bank => bank.bankName === val.bankName);
                updateValues({ itemCode: bank.itemCode });
                getBankDetail(bank.itemCode);
            } else {
                updateValues({ bankName: val.bankName.toUpperCase() });
                return;
            }
        } else if (val.hasOwnProperty(`startDate__${mode}`)) return;

        updateValues(val);
    };
    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            startDate: moment().format(),
            accountType: 'CURRENT',
            bankAccountNo: '0',
            interestRate: '0',
            acno: '090.01',
            acName: 'BANKS',
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(
            key,
            date,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues, setEditValues, setAddValues);
    };

    // Form props
    const formProps = {
        ref: formRef,
        bankAccounts,
        branches,
        banks,
        bankClasses,
        onValuesChange,
        setDefaultValues,
        getBanks,
        getNewBankCode,
        onDateChange,
        onDateKeyUp,
    };

    // On component mount
    React.useEffect(() => {
        UPDATE_AXIOS_AUTH();
        // Get bank accounts
        getBankAccounts();
        // Get bank classes
        getBankClasses();
    }, []);
    return (
        <PageWrapper>
            <BankRegistrationForm
                {...formProps}
                mode="view"
                values={viewValues}
            />
            {/* Edit account modal */}
            <Modal
                title="Edit item"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                onOk={onEditModalOk}
                onCancel={onEditModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: editModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <BankRegistrationForm
                    {...formProps}
                    mode="edit"
                    values={editValues}
                />
            </Modal>
            {/* Async edit modal */}
            <Modal
                title="Update item?"
                visible={asyncUpdateModal.visible}
                confirmLoading={asyncUpdateModal.confirmLoading}
                onOk={onAsyncUpdateModalOk}
                onCancel={onAsyncUpdateModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: asyncUpdateModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>
                    The update may take a while. Please be patient during the
                    operation.
                </p>
            </Modal>
            {/* Add account modal */}
            <Modal
                title="Add item"
                visible={addModal.visible}
                confirmLoading={addModal.confirmLoading}
                onOk={onAddModalOk}
                onCancel={onAddModalCancel}
                okText="Create"
                cancelButtonProps={{
                    disabled: addModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <BankRegistrationForm
                    {...formProps}
                    mode="add"
                    values={addValues}
                />
            </Modal>
            {/* Delete account modal */}
            <Modal
                title="Delete account?"
                visible={deleteModal.visible}
                confirmLoading={deleteModal.confirmLoading}
                onOk={onDeleteModalOk}
                onCancel={onDeleteModalCancel}
                okText="Delete"
                cancelButtonProps={{
                    disabled: deleteModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>The account and its data will be deleted forever</p>
            </Modal>
            {/* Actions bar */}
            <BottomActionBarFixed>
                <Button
                    type="primary"
                    onClick={onEditModalOpen}
                    disabled={!viewValues?.itemCode}
                >
                    Edit
                </Button>
                <Button type="primary" onClick={onAddModalOpen}>
                    Add
                </Button>
                <Button
                    type="primary"
                    onClick={onDeleteModalOpen}
                    disabled={!viewValues?.itemCode}
                >
                    Delete
                </Button>
            </BottomActionBarFixed>
        </PageWrapper>
    );
}
