import React from 'react';
import { Row, Col, Form, Input, Select, Checkbox } from 'antd';

const StaffPostsForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        staffPosts,
        staffTypes,
        values,
        onValuesChange,
        onCheckboxChange,
        mode,
    } = props;

    return (
        <Form
            ref={ref}
            layout="vertical"
            onValuesChange={onValuesChange}
            fields={[
                {
                    name: ['postID'],
                    value: values?.postID,
                },
                {
                    name: ['staffTypeID'],
                    value: values?.staffTypeID,
                },
                {
                    name: ['post'],
                    value: values?.post,
                },
            ]}
        >
            <Row gutter={20}>
                {mode !== 'add' && (
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item label="Post ID" name="postID">
                            {mode === 'view' ? (
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {staffPosts.map((type, i) => (
                                        <Select.Option
                                            key={i}
                                            value={type.postID}
                                        >
                                            {type.postID}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ) : (
                                <p>{values?.postID}</p>
                            )}
                        </Form.Item>
                    </Col>
                )}

                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Staff Type"
                        name="staffTypeID"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {staffTypes.map((type, i) => (
                                <Select.Option key={i} value={type.staffTypeID}>
                                    {type.staffType}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Post"
                        name="post"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        {mode === 'view' ? (
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {staffPosts.map((type, i) => (
                                    <Select.Option key={i} value={type.post}>
                                        {type.post}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <Input />
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Is Active">
                        <Checkbox
                            checked={values?.isActive}
                            name="isActive"
                            onChange={e => onCheckboxChange(e, mode)}
                            disabled={mode === 'view'}
                        >
                            Is Active
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default StaffPostsForm;
