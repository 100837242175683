import React from 'react';
import {
    Row,
    Col,
    Form,
    Select,
    Checkbox,
    Input,
    Image,
    Button,
    Space,
    message,
} from 'antd';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../../utils/functions';
import Branches from '../../../../Common/Geography/Branches/Branches';
import PaymentSource from '../../../../Common/PaymentSource/PaymentSource';

export default function OverdraftWithdrawForm(props) {
    // Destructuring
    const {
        depTypeAccounts,
        savingTypeAccounts,
        chequeNos,
        values,
        onValuesChange,
        getDepShortInfoQuick,
        isProcessing,
        handleNew,
        onFormSubmit,
        onDateChange,
        onDateKeyUp,
    } = props;

    // Local states
    const [showSignature, setShowSignature] = React.useState(false);

    // NEPALI DATEPICKER
    // Formed date
    const formedDateInput = document.getElementById('transDate');
    formedDateInput &&
        formedDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, formedDateInput)
        );

    // Handle show signature
    const handleShowSignature = () => {
        if (values?.signature) setShowSignature(true);
        else message.error('No signature found');
    };
    return (
        <Form
            layout="horizontal"
            onValuesChange={onValuesChange}
            onFinish={onFormSubmit}
            fields={[
                {
                    name: 'branchID',
                    value: values?.branchID,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'acno',
                    value: values?.acno,
                },
                {
                    name: 'acName',
                    value: values?.acName,
                },
                {
                    name: 'accountNo',
                    value: values?.accountNo,
                },
                {
                    name: 'accountNoAlt',
                    value: values?.accountNoAlt,
                },
                {
                    name: 'fullName',
                    value: values?.fullName,
                },
                {
                    name: 'memberNo',
                    value: values?.memberNo,
                },
                {
                    name: 'address',
                    value: values?.address,
                },
                {
                    name: 'chequeSystem',
                    value: values?.chequeSystem,
                },
                {
                    name: 'contactNo',
                    value: values?.contactNo,
                },
                {
                    name: 'expireDate',
                    value:
                        values?.expireDate && ENG_ISO_TO_NEP(values.expireDate),
                },
                {
                    name: 'guarantedAmount',
                    value: values?.guarantedAmount,
                },
                {
                    name: 'lockedAmount',
                    value: values?.lockedAmount,
                },
                {
                    name: 'minBal',
                    value: values?.minBal,
                },
                {
                    name: 'actualBal',
                    value: values?.actualBal,
                },
                {
                    name: 'oDlimit',
                    value: values?.oDlimit,
                },
                {
                    name: 'receivedPaidBy',
                    value: values?.receivedPaidBy,
                },
                {
                    name: 'transDate',
                    value:
                        values?.transDate && ENG_ISO_TO_NEP(values.transDate),
                },
                {
                    name: 'untimeCharge',
                    value: values?.untimeCharge,
                },
                {
                    name: 'preTotal',
                    value: values?.preTotal,
                },
                {
                    name: 'cashNo',
                    value: values?.cashNo,
                },
                {
                    name: 'cashName',
                    value: values?.cashName,
                },
                {
                    name: 'cashAmount',
                    value: values?.cashAmount,
                },
                {
                    name: 'bankNo',
                    value: values?.bankNo,
                },
                {
                    name: 'bankName',
                    value: values?.bankName,
                },
                {
                    name: 'bankAmount',
                    value: values?.bankAmount,
                },
                {
                    name: 'otherNo',
                    value: values?.otherNo,
                },
                {
                    name: 'otherName',
                    value: values?.otherName,
                },
                {
                    name: 'otherAmount',
                    value: values?.otherAmount,
                },
                {
                    name: 'otherAcno',
                    value: values?.otherAcno,
                },
                {
                    name: 'otherAcName',
                    value: values?.otherAcName,
                },
                {
                    name: 'journalNO',
                    value: values?.journalNO,
                },
                {
                    name: 'receiptNo',
                    value: values?.receiptNo,
                },
                {
                    name: 'transNo',
                    value: values?.transNo,
                },
                {
                    name: 'totalAmount',
                    value: values?.totalAmount,
                },
                {
                    name: 'totalAmountInWords',
                    value: values?.totalAmountInWords,
                },
                {
                    name: 'withdrawAmount',
                    value: values?.withdrawAmount,
                },
            ]}
        >
            <Row gutter={20}>
                <Col span={15}>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={9} lg={12}>
                            <Branches item="branchName" />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Branches item="branchID" />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6} lg={5}>
                            <Form.Item
                                label="Account No"
                                className="borderinfo"
                            ></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={13} lg={13}>
                            <Form.Item name="accountNoAlt">
                                <Input
                                    className="ant-input--highlight-secondary"
                                    onPressEnter={getDepShortInfoQuick}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={13} lg={4}></Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6} lg={5}>
                            <Form.Item label="Deposit Type"></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={13}>
                            <Form.Item
                                name="acName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depTypeAccounts?.length &&
                                        depTypeAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acName}
                                            >
                                                {acc.acName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item
                                name="acno"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depTypeAccounts?.length &&
                                        depTypeAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acno}
                                            >
                                                {acc.acno}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6} lg={5}>
                            <Form.Item label="Account No"></Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6} lg={13}>
                            <Form.Item
                                name="accountNo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {savingTypeAccounts?.length &&
                                        savingTypeAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.accountNo}
                                            >
                                                {acc.accountNo}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6} lg={5}>
                            <Form.Item label="Name"></Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6} lg={13}>
                            <Form.Item name="fullName">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {savingTypeAccounts?.length &&
                                        savingTypeAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.fullName}
                                            >
                                                {acc.fullName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item name="memberNo">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6} lg={5}>
                            <Form.Item label="Address"></Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6} lg={13}>
                            <Form.Item name="address">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6} lg={5}>
                            <Form.Item label="Withdrawn By"></Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6} lg={13}>
                            <Form.Item name="receivedPaidBy">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6} lg={5}>
                            <Form.Item label="Description"></Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6} lg={13}>
                            <Form.Item name="description">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6} lg={5}>
                            <Form.Item label="Withdraw After "></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={13}>
                            <Form.Item name="expireDate">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6} lg={5}>
                            <Form.Item label="Contact No"></Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6} lg={13}>
                            <Form.Item name="contactNo">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item>
                                <Button type="default" className="btns">
                                    Statement
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={6}>
                            <Form.Item>
                                <Button
                                    type="default"
                                    className="btns"
                                    onClick={handleShowSignature}
                                >
                                    Signature
                                </Button>
                                <Image
                                    width={200}
                                    style={{ display: 'none' }}
                                    src={`${process.env.REACT_APP_STATIC_FILES}/${values?.signature}`}
                                    preview={{
                                        visible: showSignature,
                                        src: `${process.env.REACT_APP_STATIC_FILES}/${values?.signature}`,
                                        onVisibleChange: value => {
                                            setShowSignature(value);
                                        },
                                    }}
                                    onError={() => {
                                        if (!values?.signature) return;
                                        message.error(
                                            'Invalid signature found'
                                        );
                                        setShowSignature(false);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={6}>
                            <Form.Item>
                                <Button type="default" className="btns">
                                    Notes
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={6}>
                            <Form.Item>
                                <Button type="default" className="btns">
                                    Guarantee Info
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item
                                // label="Cheque System "
                                // labelAlign="left"
                                name="chequeSystem"
                                valuePropName="checked"
                            >
                                <Checkbox disabled>Cheque system</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <Form.Item
                                label="Cheque No"
                                // labelAlign="left"
                            ></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={8}>
                            <Form.Item
                                name="chequeNo"
                                rules={[
                                    {
                                        required: values?.chequeSystem,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {chequeNos?.length &&
                                        chequeNos.map((cheque, i) => (
                                            <Select.Option value={cheque}>
                                                {cheque}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={9}>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={6} lg={8}>
                            <Form.Item label="Date"></Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6} lg={16}>
                            <Form.Item name="transDate">
                                <input
                                    type="text"
                                    className="ant-input"
                                    onKeyUp={onDateKeyUp}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={10} lg={8}>
                            <Form.Item label="Journal No"></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9} lg={16}>
                            <Form.Item name="journalNO">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={8}>
                            <Form.Item label="Trans No "></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9} lg={16}>
                            <Form.Item name="transNo">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={24}>
                            <br></br>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={8}>
                            <Form.Item label="Actual Balance"></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9} lg={16}>
                            <Form.Item name="actualBal">
                                <Input disabled />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6} lg={8}>
                            <Form.Item label="Minimum Bal"></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9} lg={16}>
                            <Form.Item name="minBal">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={8}>
                            <Form.Item label="Locked Amt"></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9} lg={16}>
                            <Form.Item name="lockedAmount">
                                <Input disabled />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6} lg={8}>
                            <Form.Item label="Guaranteed Amt"></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9} lg={16}>
                            <Form.Item name="guarantedAmount">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={8}>
                            <Form.Item label="OD Limit"></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9} lg={16}>
                            <Form.Item name="oDlimit">
                                <Input disabled />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={6} lg={8}>
                            <Form.Item label="Withdraw Amount"></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9} lg={16}>
                            <Form.Item
                                name="withdrawAmount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                +getFieldValue('oDlimit') ===
                                                    0 ||
                                                +getFieldValue('oDlimit') >=
                                                    +value
                                            ) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(
                                                new Error('Amount overflow')
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input className="ant-input--highlight-primary" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={8}>
                            <Form.Item label="Withdraw Fine"></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9} lg={16}>
                            <Form.Item name="untimeCharge">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={8}>
                            <Form.Item label="Total Debit"></Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9} lg={16}>
                            <Form.Item name="preTotal">
                                <Input
                                    disabled
                                    className="ant-input--highlight-primary"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <PaymentSource
                title="Payment Source"
                mode="payment"
                values={values}
            />
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label=" " colon={false}>
                        <Space style={{ marginTop: '1.5rem' }}>
                            <Button htmlType="submit" disabled={isProcessing}>
                                Save
                            </Button>
                            <Button>Print Receipt</Button>
                            <Button>Deno</Button>
                            <Button onClick={handleNew}>New</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
