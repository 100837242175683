import defaultAxios from 'axios';
import { message } from 'antd';
import moment from 'moment';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

// Create an instance of axios

export const axios = defaultAxios.create({
    // headers: {
    //     Authorization: `Bearer ${``}`,
    // },
    withCredentials: true,
});

const refreshAuthLogic = failedRequest =>
    axios
        .post(`${process.env.REACT_APP_HOST}/api/login/refresh-token`)
        .then(res => {
            console.log('res: ', res);
            if (res.data.statusCode === 2) {
                axios
                    .post(`${process.env.REACT_APP_HOST}/api/login/logout`)
                    .then(res => console.log(res, 'logout res'))
                    .catch(err => console.log(err, 'logout err'))
                    .finally(() => {
                        localStorage.clear();
                        window.location.replace('/auth/login');
                    });
                return;
            }
            // failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
            return Promise.resolve();
        });

// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic);

export const GET_CASTE_GROUPS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Caste/CasteGroup`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get caste groups');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_CASTES = async casteGroup => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Caste/Caste/${casteGroup}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get castes');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_RELIGIONS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Caste/Religion`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get religions');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MARITAL_STATUSES = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MaritalStatus`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get marital statues');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_PROVINCES = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/District/Province`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get provinces');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_DISTRICTS = async province => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/District/${province}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get provinces');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MUNICIPALITIES = async district => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/District/Municipal/${district}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get municipalities');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBERS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Member/GetMembers/${
                JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
            }`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get members');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OWNERS = async branchID => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Owner/GetOwner?BranchID=${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get owners');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OWNER_TYPES = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Owner/OwnerType`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get owner types');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_DETAIL = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Member/GetMemberDetail/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_PROPERTIES = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/MemberFullProperties/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member properties');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_DEPOSIT_PROPERTIES = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/MemberDepositProperties/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member deposit properties');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_LOAN_PROPERTIES = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/MemberLoanProperties/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member loan properties');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_SHARE_PROPERTIES = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/MemberShare/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member share properties');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_LP_FUND_PROPERTIES = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/LoanPaymentFund/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member loan payment fund properties');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OWNER_DETAIL = async ownerNO => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Owner/GetOwnerDetail/${ownerNO}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_EDUCATION = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/OtherDetail/Education`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get education types');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OCCUPATION = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/OtherDetail/Occupation`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get occupation types');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_RELATION_TYPES = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Caste/Relations`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get relation types');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_RELATIONS_LIST = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Caste/RelationsList`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get relation list');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_GROUPS = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_HOST}/api/Group`);
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get groups');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_GROUP_TYPES = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/GroupType`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get group types');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_GROUP_DETAIL = async groupId => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Group/Detail/${groupId}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get group detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_AREAS = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_HOST}/api/Area`);
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get areas');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_STAFFS_BY_BRANCH = async branchID => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Staff/GetByBranchID/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get staffs');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_COLLECTORS = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/CollectionEntry/GetCollectors/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get collectors');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_KISTA_PERIODS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanType/Kistaperiod`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get kista periods');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_TYPE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanType/GetAccountNos`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan type accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_TYPE_ACCOUNTS_FULL = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanType/GetAccountNos`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan type accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_TYPE_ACC_INFO = async acno => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/LoanTypeInfo/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan type account info');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_TYPE_ACCOUNT_DETAIL = async acno => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanType/GetDetail/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan type account detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_HOLDER_ACCOUNT_NOS = async (branchID, acno) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/GetLoanNo/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan holder accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_HOLDER_ACCOUNT_DETAIL = async (acno, loanNo) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/LoanDetail/${acno}/${loanNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan holder account detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_NEW_LOAN_TYPE_ACC_NO = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanType/NewLoantypecode`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get new loan type account number');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_MEMBERS = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/GetLoanMember/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan members');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_MEMBER_INFO_SHORT = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
    loanNo,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/LoanInfoShort/${branchID}/${loanNo}?ACNO=${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan member info short');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_NEW_SAVING_TYPE_ACC_NO = async (branchID, acno) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/GetNewAccountNo/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get new Saving type account number');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ENTERPRISE_DETAIL = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/enterpriseDetail`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get enterprise detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_GUARDIAN_DETAIL = async memberNO => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Owner/Guardianinfo?Memberno=${memberNO}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get guardian detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_BRANCHES = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_HOST}/api/branch`);
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get branches');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_USERS = async () => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_HOST}/api/Users`);
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get users');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_USER_DETAIL = async userID => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Users/${userID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get user detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/DepositType/ActiveDepositType`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Active deposit type accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_TYPE_ACCOUNTS = async (branchID, acno) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/GetAccounts/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Saving type accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_TYPE_ACCOUNTS_FULL = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/AccountFullID/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Saving type accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_TYPE_ACCOUNT_DETAIL = async (acno, accountNo) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/AccountDetail/${acno}/${accountNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Saving type account detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_ACCOUNT_INFO_SHORT = async (
    branchID,
    accountNo,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/AccountInfoShort/${branchID}/${accountNo}?ACNO=${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Saving type info short');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_FIXED_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/DepositType/FixedAccount`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Fixed accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_FIXED_ACCOUNT_INFO_SHORT = async (
    branchID,
    accountNo,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/FixedAccountInfo/${branchID}/${accountNo}?ACNO=${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Fixed account info short');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBERS_BY_BRANCH = async branchID => {
    if (!branchID) return;
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Member/GetMembers/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Members');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_INFO_SHORT = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/MemberInfoShort/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Member info short');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_ACC_BASIC_INFO = async acno => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/BaseInfo/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Member basic info');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_DEPOSIT_INFO_SHORT = async memberNO => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/MemberDepositInfoShort/${memberNO}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Member deposit info short');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_DEPOSIT_TYPE_TRANSFERABLE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/DepositType/GetTransferable`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Deposit type transferable accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OWNERS_BY_MEMBER_NO = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/GetOwnerID/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Owners');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_ACC_MEM_LIST = async (memberNo, acno) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/MemberAccountList/${memberNo}/${acno}`
        );
        // if (res.data.statusCode !== 0)
        //     return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get Saving account members list');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_NEW_LOAN_NO = async (branchID, acno) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/GetNewAccountNo/${branchID}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get new loan no');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_END_DATE = async payload => {
    if (payload.dmy === 'Not Mentioned') return payload.startDate;
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Dates/AddDates`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get end date');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_INTEREST_DATE = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/LoanRecovery/LoanRecoveryDates`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan interest date');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_RECOVERY_INFO = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/LoanRecovery/GetLoanInfo`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan recovery info');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_RECOVERY_BALANCE = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/LoanRecovery/GetLoanBalance`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan recovery balance');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_DATE = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Dates/InstallmentDays`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan date');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_PAYMENT_SCHEDULE = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/LoanHolder/LoanPayemntSchedule`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);

        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan date');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_COUNTERS_BY_BRANCH = async branchID => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Counters/GetBranchCounter/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get counters');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ASSIGNED_COUNTERS = async userName => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Counters/GetAssignedCounter/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get assigned counters');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_CASH_COUNTERS = async (
    branchID,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/CashCounter/${branchID}/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get cash counters');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_BANKS = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/Banks/${branchID}/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get banks');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_BANK_ACCOUNTS = async (
    branchID,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/Banks/${branchID}/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get banks');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_BANK_DETAIL = async itemCode => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Banks/Detail/${itemCode}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get bank detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OTHER_ACCOUNTS = async (branchID, userName) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/OtherAccounts/${branchID}/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get bank accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_OTHER_ITEMS = async (
    branchID,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName,
    acno
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/GetItems/${branchID}/${userName}/${acno}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get other items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVING_ACCOUNT_CHEQUE_NOS = async (acno, accountNo) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/ChequeNos/${acno}/${accountNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get saving account cheque nos');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ALL_CASH_ITEMS = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/PayReceipt/CashItems/${branchID}/${userName}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get saving account cheque nos');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ITEM_BALANCE = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID,
    mano,
    acno,
    itemCode
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/CheckBalance/ItemBalance/${branchID}/${mano}/${acno}/${itemCode}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get item balance');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_REMITS = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Remit/GetRemit/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get remits');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_PAYABLE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/PayableAccounts`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get payable accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_PAYABLE_ITEMS = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetPayable/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get payable item');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_HOUSE_ITEMS = async (
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/RentedOffice/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get house items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MONTHS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Dates/MonthsList`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get months');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_NEP_MONTH = async dateISO => {
    const date = moment(dateISO).format('YYYY-MM-DD');
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Dates/CurrentMonth/${date}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get nepali month');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MAIN_ACCOUNTS_FOR_RECEIVABLES = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/MANOForReceivable`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get main accounts for receivables');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ACCOUNTS_FOR_RECEIVABLES = async mano => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/ACNOForReceivables/${mano}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get accounts for receivables');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ITEMS_FOR_RECEIVABLES = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/ItemsforReceivables/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get items for receivables');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_RECEIVABLE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/ReceivablesAccounts`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get receivable accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_RECEIVABLE_ITEMS = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/ReceivablesItems/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get receivable items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_INCOME_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetIncomeAccount`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get income accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_INCOME_ITEMS = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetIncome/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get income items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_EXPENDITURE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetExpenseAccount`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get expenditure accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_EXPENDITURE_ITEMS = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetExpense/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get expenditure items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_INVESTMENT_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetInvestmentAccount`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get investment accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_INVESTMENT_ITEMS = async (
    acno,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/TransAccount/GetInvestment/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get investment items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_CLOSING_ACCOUNT_INFO = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/SavingAccount/DepositClosing`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get closing account info');
        message.error('Something went wrong. Please try again!');
    }
};

export const SEARCH_MEMBER = async (
    searchValue,
    userName = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName
) => {
    try {
        const payload = {
            userName,
            searchValue,
        };
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/FindMember/MemberSearch`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to search member');
        message.error('Something went wrong. Please try again!');
    }
};

export const SEARCH_MEMBER_DETAIL = async (memberno, isOwner) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/FindMember/FindMember/${memberno}?isOwner=${isOwner}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MAIN_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MainAccount/ALL`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get main accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SUB_ACCOUNTS = async mano => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Account/All/${mano}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get sub accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ITEMS = async (acno, branchID) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/ItemMaster/GetItems/${acno}/${branchID}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get items');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ITEM_WISE_LEDGER = async (
    branchID,
    fromDate,
    tillDate,
    mano,
    acno,
    itemCode
) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/AccountBalance/Itemwiseledger/${branchID}/${fromDate}/${tillDate}/${mano}/${acno}/${itemCode}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get item wise ledger');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_JOURNAL_VOUCHER = async payload => {
    console.log('payload: ', payload);
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Reports/JournalVoucher`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get journal voucher');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_JOURNAL_LIST = async (fromDateISO, tillDateISO) => {
    const fromDate = moment(fromDateISO).format('YYYY-MM-DD');
    const tillDate = moment(tillDateISO).format('YYYY-MM-DD');
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Reports/JournalList/${fromDate}/${tillDate}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get journal list');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SHARE_PANJIKA = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Share/SharePanjika`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get share panjika');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SHARE_CERTIFICATE = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Share/ShareCertificate/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get share certificate');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SHARE_CERTIFICATE_DETAIL = async (memberNo, certificateNo) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Share/ShareCertificates/${memberNo}/${certificateNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get share certificate detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SHARE_CERTIFICATE_NO_LIST = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Share/CertificateList/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get share certificate number list');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SHARE_ISSUE = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Share/IssueShare`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get share issue');
        message.error('Something went wrong. Please try again!');
    }
};

export const ISSUE_SHARE_CERTIFICATE = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Share/IssueShareCertificate`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to issue share certificate');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_FULL_STATEMENT = async memberNo => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/MemberFullStatement/${memberNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member full statement');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_BALANCE_ACCOUNTS = async () => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/MembersAccountHead`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member balance accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBER_ACCOUNT_BALANCE = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/MemberInfo/MembersAccountBalance`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get member account balance');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MEMBERS_BY_STATE = async (payload, state) => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Member/${state}`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get active members');
        message.error('Something went wrong. Please try again!');
    }
};

export const SAVE_MEMBERS_STATE = async payload => {
    try {
        const res = await axios.patch(
            `${process.env.REACT_APP_HOST}/api/Member/MemberState`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        message.success(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to disable members');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SMS_BANKING_ACTIVE = async (acno, accountNo) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Depositfeature/SMSActiveAccount/${acno}/${accountNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get SMS banking active');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MOBILE_BANKING_ACTIVE = async (acno, accountNo) => {
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Depositfeature/MobileBankingActiveAccount/${acno}/${accountNo}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get mobile banking active');
        message.error('Something went wrong. Please try again!');
    }
};

export const ACTIVATE_SMS_BANKING = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Depositfeature/ActivateSMSBanking`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        message.success(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to activate SMS banking');
        message.error('Something went wrong. Please try again!');
    }
};

export const ACTIVATE_MOBILE_BANKING = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Depositfeature/ActivateMobileBanking`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        message.success(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to activate mobile banking');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_KYM_MEMBER_DETAIL = async memberNO => {
    if (!memberNO) return;
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/KYM/KYM/${memberNO}`
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get KYM member detail');
        message.error('Something went wrong. Please try again!');
    }
};

export const SAVE_KYM = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/KYM/UpdateKYM`,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        message.success(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to save KYM form');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ACTIVE_SMS_ACCOUNTS_LIST = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Depositfeature/ActiveSMSAccounts
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get active sms accounts list');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ACTIVE_MOBILE_ACCOUNTS_LIST = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Depositfeature/ActiveMobileBankingAccounts
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get active mobile accounts list');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_EXPIRING_SMS_BANKING_ACCOUNTS_LIST = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Depositfeature/ExpiringSMSBanking
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get expiring sms banking accounts list');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_EXPIRING_MOBILE_BANKING_ACCOUNTS_LIST = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/Depositfeature/ExpiringMobileBanking
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(
            err,
            'Failed to get expiring mobile banking accounts list'
        );
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_NOTIFICATIONS = async (
    dateISO,
    branchID = JSON.parse(localStorage.getItem('USER_CREDENTIALS')).branchID
) => {
    const date =
        moment(dateISO).format('YYYY-MM-DD') || moment().format('YYYY-MM-DD');
    try {
        const res = await axios.get(
            `${process.env.REACT_APP_HOST}/api/Notification/${branchID}/${date}
            `
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get notifications');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_SAVINGS_STATEMENT_ALL = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/SavingsReport/DepositStatementAll
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get savings statement all');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_MATURITY_REPORT = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/SavingsReport/MaturityReport
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get maturity report');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_ACTIVE_INACTIVE_DEPOSIT_ACCOUNTS = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/SavingsReport/ActiveInActiveDeposit
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get active inactive deposit accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOCKED_DEPOSIT_ACCOUNTS = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/SavingsReport/LockedDeposit
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get locked deposit accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const DISABLE_DEPOSIT_ACCOUNTS = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/SavingsReport/DisableDepositAccounts
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        message.success(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to disable deposit accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const ENABLE_DEPOSIT_ACCOUNTS = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/SavingsReport/EnableDepositAccounts
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        message.success(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to disable deposit accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_DISABLED_DEPOSIT_ACCOUNTS = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/SavingsReport/DisabledDeposit
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get disabled deposit accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export const GET_LOAN_RECOVERY_DEPOSIT_ACCOUNTS = async payload => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_HOST}/api/LoanRecovery/DepositList
            `,
            payload
        );
        if (res.data.statusCode !== 0)
            return message.error(res.data.statusMessage);
        return res.data.data;
    } catch (err) {
        console.error(err, 'Failed to get loan recovery deposit accounts');
        message.error('Something went wrong. Please try again!');
    }
};

export default axios;
