import React from 'react';
import { Row, Col, Form, Image, Space, Button, Collapse } from 'antd';
import moment from 'moment';
import { GET_MEMBER_DEPOSIT_PROPERTIES } from '../../../../utils/axios';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';
import Loading from '../../../../components/Common/Loading/Loading';
import ReactToPrint from 'react-to-print';
import Print from '../../../../components/Common/Print/Print';

export default function MemberInfoDepositDetail(props) {
    // Destructuring
    const { viewValues } = props;

    // Local states
    const [depositProperties, setDepositProperties] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // On component mount
    React.useEffect(() => {
        if (!viewValues?.memberNO) return;
        setIsLoading(true);
        (async () => {
            const depositProperties = await GET_MEMBER_DEPOSIT_PROPERTIES(
                viewValues.memberNO
            );
            console.log('depositProperties: ', depositProperties);
            setDepositProperties(depositProperties);
            setIsLoading(false);
        })();
    }, [viewValues?.memberNO]);

    // Loading indicator
    if (isLoading) return <Loading />;

    return (
        <Collapse>
            {depositProperties?.map((item, i) => (
                <Collapse.Panel
                    header={`${item.acName} (${item.accountNO}) - [Balance: Rs ${item.balance} ]`}
                    key={i + 1}
                    style={{ fontSize: '13px' }}
                >
                    <DepositDetail key={i} data={item} />
                </Collapse.Panel>
            ))}
        </Collapse>
    );
}

export const DepositDetail = props => {
    // Destructuring
    const { data } = props;

    // Refs
    const printRef = React.useRef();
    return (
        <div>
            <Print
                ref={printRef}
                title="Deposit Detail"
                // footer={printSettingsFooter}
            >
                <Row gutter={20}>
                    <Col span={20}>
                        <Row gutter={20} style={{ fontWeight: 'bold' }}>
                            <Col span={10}>
                                <Form.Item
                                    label="Deposit Name"
                                    labelCol={{ span: 10 }}
                                    labelAlign="left"
                                >
                                    <span style={{ marginLeft: '-4px' }}>
                                        {data?.acName || '--'}
                                    </span>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item>{data?.acno || '--'}</Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Account No"
                                    // labelCol={{ span: 15 }}
                                    labelAlign="left"
                                >
                                    {/* <span style={{ marginLeft: '-3px' }}> */}
                                    {data?.accountNO || '--'}
                                    {/* </span> */}
                                </Form.Item>
                            </Col>
                            {data?.ownerNO && (
                                <Col span={6}>
                                    <Form.Item
                                        label="Owner No"
                                        labelCol={{ span: 8 }}
                                        labelAlign="left"
                                    >
                                        {data?.ownerNO}
                                    </Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Account Type"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.status || '--'}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Opening Date"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {moment(data?.entranceDate).isValid() &&
                                        ENG_ISO_TO_NEP(data?.entranceDate)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Term"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.savingPeriod || '--'}{' '}
                                    {data?.periodType || '--'}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Maturity Date"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {moment(data?.expireDate).isValid() &&
                                        ENG_ISO_TO_NEP(data?.expireDate)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Saving Rate"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.savingRate + '' || '--'}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Individual Int. Rate"
                                    labelCol={{ span: 17 }}
                                    labelAlign="left"
                                >
                                    {data?.individualRate + '' || '--'}
                                </Form.Item>
                            </Col>
                            {+data?.individualRate === 0 ||
                                (data?.individualRate !==
                                    data?.rateOfInterest && (
                                    <Col span={6}>
                                        <Form.Item
                                            label="Rate of Int"
                                            labelCol={{ span: 8 }}
                                            labelAlign="left"
                                        >
                                            {data?.rateOfInterest + '' || '--'}
                                        </Form.Item>
                                    </Col>
                                ))}
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Regular Interval"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.regSavingPeriod || '--'}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Interest Rate OD"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.odIntrate + '' || '--'}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item>
                                    {/* {memberProperties?.grandFather} */}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Int. Calc Method"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.interetCalcBasis || '--'}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Balance"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.balance + '' || '--'}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Interest Interval"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.interetCalcPeriod || '--'}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Accrued Interest"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.curint + '' || '--'}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Interest Account"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.transferInterestTo + '' || '--'}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Int. Accrued From"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.intStartDate
                                        ? moment(
                                              data?.intStartDate
                                          ).isValid() &&
                                          ENG_ISO_TO_NEP(data?.intStartDate)
                                        : '--'}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Int. A/C Type"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.intColDepType || '--'}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Last Active At"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    N/A
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="OD Facility"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.odFacility ? 'Yes' : 'No'}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Last Transaction"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    N/A
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="OD Limit"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.odLimit + '' || '--'}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={10}>
                                <Form.Item
                                    label="Collector"
                                    labelCol={{ span: 10 }}
                                    labelAlign="left"
                                >
                                    {data?.introducer || '--'}
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item>{data?.intACNO || '--'}</Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Mobile No"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.mobileNo || '--'}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    label="Guarantee"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.gall ? data?.guarantedAmount : 'No'}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Locked"
                                    labelCol={{ span: 8 }}
                                    labelAlign="left"
                                >
                                    {data?.locked ? data?.lockedAmount : 'No'}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label="Subscribed Options"
                            labelCol={{ span: 4 }}
                            labelAlign="left"
                        >
                            <Row gutter={20}>
                                <Col span={6}>
                                    <Space>
                                        <input
                                            id="mobileBankingStatus"
                                            type="checkbox"
                                            checked={data?.mobileBankingStatus}
                                            disabled
                                        />
                                        <label for="mobileBankingStatus">
                                            Mobile Banking
                                        </label>
                                    </Space>
                                </Col>
                                <Col span={6}>
                                    <Space>
                                        <input
                                            id="smsBankingSystem"
                                            type="checkbox"
                                            checked={data?.smsBankingSystem}
                                            disabled
                                        />
                                        <label for="smsBankingSystem">
                                            SMS Banking
                                        </label>
                                    </Space>
                                </Col>
                                <Col span={6}>
                                    <Space>
                                        <input
                                            id="chequeBookSystem"
                                            type="checkbox"
                                            checked={data?.chequeBookSystem}
                                            disabled
                                        />
                                        <label for="chequeBookSystem">
                                            Cheque Book System
                                        </label>
                                    </Space>
                                </Col>
                            </Row>
                        </Form.Item>
                        <Form.Item
                            label="Account Status"
                            labelCol={{ span: 4 }}
                            labelAlign="left"
                        >
                            <Row gutter={20}>
                                <Col span={6}>
                                    <Space>
                                        <input
                                            id="disabled"
                                            type="checkbox"
                                            checked={data?.disabled}
                                        />
                                        <label for="disabled">Disabled</label>
                                    </Space>
                                </Col>
                                <Col span={6}>
                                    <Space>
                                        <input
                                            id="dormant"
                                            type="checkbox"
                                            checked={data?.dormant}
                                        />
                                        <label for="dormant">Dormant</label>
                                    </Space>
                                </Col>
                                <Col span={6}>
                                    <Space>
                                        <input
                                            id="confidential"
                                            type="checkbox"
                                            checked={data?.confidential}
                                        />
                                        <label for="confidential">
                                            Confidential
                                        </label>
                                    </Space>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Image
                            width={150}
                            src={`${process.env.REACT_APP_STATIC_FILES}/${data?.signature}`}
                        />
                    </Col>
                </Row>
            </Print>
            <Space style={{ marginTop: '.5rem' }}>
                <Button type="default" size="small">
                    Edit Account
                </Button>
                <Button type="default" size="small">
                    Statement
                </Button>
                <Button type="default" size="small">
                    Deposit
                </Button>
                <Button type="default" size="small">
                    Withdraw
                </Button>
                <Button type="default" size="small">
                    Bal. Certificate
                </Button>
                <Button type="default" size="small">
                    FD Certificate
                </Button>
                <Button type="default" size="small">
                    Guarantee Info
                </Button>
                <Button type="default" size="small">
                    Close Account
                </Button>
                <ReactToPrint
                    trigger={() => (
                        <Button type="primary" size="small">
                            Print
                        </Button>
                    )}
                    content={() => printRef.current}
                />
            </Space>
        </div>
    );
};
