import React from 'react';
import axios, {
    GET_MEMBERS,
    GET_SHARE_CERTIFICATE,
    GET_SHARE_ISSUE,
} from '../../../utils/axios';
import moment from 'moment';
import { message } from 'antd';
import {
    ON_DATE_KEYUP,
    ON_DATE_CHANGE,
    DLT_KEYS_FROM_OBJ,
} from '../../../utils/functions';
// Components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import ShareCertificateForm from '../../../components/Membership/ShareCertificateForm/ShareCertificateForm';

export default function ShareCertificate() {
    // Local states
    const [members, setMembers] = React.useState([]);
    const [shareCertificate, setShareCertificate] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [shareMode, setShareMode] = React.useState('new');

    // Refs
    const shareCertificateFormRef = React.useRef();

    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];

        // Update values on state
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        if (key === 'memberNO' || key === 'memberName') {
            onShareIssueCancel();
            const member = members.find(member => member[key] === value);
            return updateValues(member);
        }

        // Update values
        updateValues(val);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // SHARE ISSUE
    const onShareIssueNewClick = async () => {
        setShareMode('loading');
        const shareIssue = await GET_SHARE_ISSUE();
        setViewValues(prevValues => ({
            ...prevValues,
            ...shareIssue,
            noOfShare: '',
            shareAmount: '',
            issueddate: moment().format('YYYY-MM-DD'),
        }));
        setShareMode('issue');
    };

    const onShareIssue = () => {
        shareCertificateFormRef.current
            .validateFields()
            .then(async () => {
                const payload = {
                    issueddate: viewValues?.issueddate,
                    certiNo: viewValues?.certiNo,
                    noOfShare: viewValues?.noOfShare,
                    from: viewValues?.from,
                    memberNO: viewValues?.memberNO,
                    user: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                        .userName,
                };

                try {
                    setShareMode('loading');

                    const res = await axios.post(
                        `${process.env.REACT_APP_HOST}/api/Share/IssueShareCertificate`,
                        payload
                    );
                    if (res.data.statusCode !== 0) {
                        message.error(res.data.statusMessage);
                        return setShareMode('issue');
                    }

                    message.success(res.data.statusMessage);
                    setShareCertificate(res.data.data);
                    onShareIssueCancel();
                } catch (err) {
                    console.log('err: ', err);
                    setShareMode('issue');
                }
            })
            .catch(() => {
                message.error('Invalid data');
            });
    };

    const onShareIssueCancel = () => {
        setViewValues(prevValues => ({
            ...DLT_KEYS_FROM_OBJ(prevValues, [
                'noOfShare',
                'certiNo',
                'from',
                'to',
                'shareAmount',
            ]),
        }));
        shareCertificateFormRef.current.resetFields();
        setShareMode('new');
    };

    const onShareRecordClick = record => {
        setShareMode('new');
        setViewValues(prevValues => ({
            ...prevValues,
            ...record,
        }));
    };

    const onShareDeleteConfirm = async () => {
        try {
            const res = await axios.delete(
                `${process.env.REACT_APP_HOST}/api/Share/DeleteShareCertificates/${viewValues?.memberNO}/${viewValues?.certiNo}`
            );
            setShareCertificate(res.data.data);
            onShareIssueCancel();
        } catch {
            message.error('Something went wrong. Please try again later!');
        }
    };

    // Get share certificate
    React.useEffect(() => {
        if (!viewValues?.memberNO) return;
        (async () => {
            const shareCertificate = await GET_SHARE_CERTIFICATE(
                viewValues.memberNO
            );
            setShareCertificate(shareCertificate);
        })();
    }, [viewValues?.memberNO]);

    // Get and set members
    React.useEffect(() => {
        (async () => {
            const members = await GET_MEMBERS();
            setMembers(members);
        })();
    }, []);

    // Set default values
    React.useEffect(() => {
        const defaultValues = {
            issueddate: moment().format('YYYY-MM-DD'),
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    }, []);

    // Set Share No To
    React.useEffect(() => {
        const noOfShare = +viewValues?.noOfShare || 1;
        const from = +viewValues?.from || 0;
        const to = noOfShare - 1 + from;
        setViewValues(prevValues => ({
            ...prevValues,
            to,
        }));
    }, [viewValues?.noOfShare, viewValues?.from]);

    // Set Share Amount
    React.useEffect(() => {
        const noOfShare = +viewValues?.noOfShare || 0;
        const shareAmount = noOfShare * 100;
        setViewValues(prevValues => ({
            ...prevValues,
            shareAmount,
        }));
    }, [viewValues?.noOfShare]);

    const formProps = {
        members,
        onValuesChange,
        shareCertificate,
        onDateKeyUp,
        onDateChange,
        shareMode,
        onShareIssueNewClick,
        onShareIssueCancel,
        onShareIssue,
        onShareRecordClick,
        onShareDeleteConfirm,
    };
    return (
        <PageWrapper>
            <ShareCertificateForm
                {...formProps}
                ref={shareCertificateFormRef}
                mode="view"
                values={viewValues}
            />
        </PageWrapper>
    );
}
