import React from 'react';
import { Row, Col, Form, Select, Input, Table } from 'antd';
import Branches from '../../../Common/Geography/Branches/Branches';

export default function BranchAssignmentForm(props) {
    // Local states

    // Destructuring
    const {
        users,
        values,
        onValuesChange,
        mode,
        setDefaultValues,
        branches,
        assignedBranches,
        setAssignedBranches,
    } = props;

    // Data source
    const dataSource = branches?.map(branch => {
        return {
            key: branch.branchID,
            ...branch,
        };
    });

    const columns = [
        {
            title: 'Branch ID',
            dataIndex: 'branchID',
            key: 'branchID',
            width: '150px',
        },
        {
            title: 'Branch Name',
            dataIndex: 'branchName',
            key: 'branchName',
        },
    ];

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [mode, setDefaultValues]);

    const onBranchesSelect = selectedBranches => {
        setAssignedBranches(selectedBranches);
    };

    const branchSelection = {
        selectedRowKeys: assignedBranches,
        onChange: onBranchesSelect,
    };

    return (
        <Form
            layout="vertical"
            onValuesChange={val => onValuesChange(val, mode)}
            fields={[
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'branchID',
                    value: values?.branchID,
                },
                {
                    name: 'userName',
                    value: values?.userName,
                },
                {
                    name: 'staffID',
                    value: values?.staffID,
                },
                {
                    name: 'staffName',
                    value: values?.staffName,
                },
                {
                    name: 'counterBranchID',
                    value: values?.counterBranchID,
                },
            ]}
        >
            <Row gutter={20}>
                <Col span={6}>
                    <Branches item="branchName" disabled={mode !== 'view'} />
                </Col>
                <Col span={4}>
                    <Branches item="branchID" disabled={mode !== 'view'} />
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label="User Name" name="userName">
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={mode !== 'view'}
                            value={null}
                        >
                            {users?.length &&
                                users.map((user, i) => (
                                    <Select.Option
                                        key={i}
                                        value={user.userName}
                                    >
                                        {user.userName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label="Staff Name" name="staffName">
                        <Input disabled />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label="Staff ID" name="staffID">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Table
                rowSelection={branchSelection}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                size="small"
            />
        </Form>
    );
}
