import React from 'react';
// Import from dependencies
import { Form, Select, Row, Col, Input, Radio } from 'antd';
import validator from 'validator';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../utils/functions';

const GeneralAccountForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        onValuesChange,
        values,
        mode,
        superAccounts,
        mainAccounts,
        generalAccounts,
        setDefaultValues,
        getMainAccountsBySuperAccount,
        getGeneralAccountsByMano,
        onDateChange,
        onDateKeyUp,
    } = props;

    // NEPALI DATEPICKER
    // Formed date
    const formedDateInput = document.getElementById(`formedDate__${mode}`);
    formedDateInput &&
        formedDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, formedDateInput, mode)
        );

    // Get main accounts by super account
    React.useEffect(() => {
        if (values?.saname) {
            getMainAccountsBySuperAccount(values.saname);
        }
    }, [values?.saname, getMainAccountsBySuperAccount]);

    // Get general accounts by main account
    React.useEffect(() => {
        if (values?.mano) {
            getGeneralAccountsByMano(values.mano);
        }
    }, [getGeneralAccountsByMano, values?.mano]);

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);

    return (
        <Form
            ref={ref}
            layout="vertical"
            onValuesChange={val => onValuesChange(val, mode)}
            fields={[
                {
                    name: ['saname'],
                    value: values?.saname,
                },
                {
                    name: ['maName'],
                    value: values?.maName,
                },
                {
                    name: ['mano'],
                    value: values?.mano,
                },
                {
                    name: ['acno'],
                    value: values?.acno,
                },
                {
                    name: ['acName'],
                    value: values?.acName,
                },
                {
                    name: ['nepName'],
                    value: values?.nepName,
                },
                {
                    name: [`formedDate__${mode}`],
                    value:
                        values?.formedDate && ENG_ISO_TO_NEP(values.formedDate),
                },
                {
                    name: ['govCode'],
                    value: values?.govCode,
                },
                {
                    name: ['isMemRel'],
                    value: values?.isMemRel,
                },
                {
                    name: ['description'],
                    value: values?.description,
                },
            ]}
        >
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Form.Item
                        label="Super account"
                        name="saname"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select a super account"
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={mode === 'edit'}
                        >
                            {superAccounts.map((acc, i) => (
                                <Select.Option key={i} value={acc.saname}>
                                    {acc.saname}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={1}></Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Formed date"
                        name={`formedDate__${mode}`}
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                            () => ({
                                validator(_, value) {
                                    if (!validator.isDate(value)) {
                                        return Promise.reject('Invalid');
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Form.Item
                        label="Main account"
                        name="maName"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            disabled={!mainAccounts.length || mode === 'edit'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {mainAccounts.map((acc, i) => (
                                <Select.Option key={i} value={acc.maName}>
                                    {acc.maName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={1}></Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Main account no"
                        name="mano"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            disabled={!mainAccounts.length || mode === 'edit'}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {mainAccounts.map((acc, i) => (
                                <Select.Option key={i} value={acc.mano}>
                                    {acc.mano}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Form.Item
                        label="Account no"
                        name="acno"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        {mode === 'view' ? (
                            <Select
                                placeholder="Select..."
                                disabled={!generalAccounts?.length}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {generalAccounts?.map((acc, i) => (
                                    <Select.Option key={i} value={acc.acno}>
                                        {acc.acno}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <Input />
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={1}></Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Government code" name="govCode">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Form.Item label="Account name" name="acName">
                        {mode === 'view' ? (
                            <Select
                                placeholder="Select..."
                                disabled={!generalAccounts?.length}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {generalAccounts?.map((acc, i) => (
                                    <Select.Option key={i} value={acc.acName}>
                                        {acc.acName}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <Input />
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={1}></Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Nepali name" name="nepName">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Member related" name="isMemRel">
                        <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={1}></Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Form.Item label="Description" name="description">
                        <Input.TextArea />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default GeneralAccountForm;
