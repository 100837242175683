import React from 'react';
import { Form, Select, Input, Row, Col } from 'antd';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../utils/functions';

const GeneralItemsForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        mode,
        superAccounts,
        mainAccounts,
        generalAccounts,
        branches,
        generalItems,
        values,
        onValuesChange,
        setDefaultValues,
        onDateChange,
        onDateKeyUp,
    } = props;

    // NEPALI DATEPICKER
    // Formed date
    const formedDateInput = document.getElementById(`formedDate__${mode}`);
    formedDateInput &&
        formedDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, formedDateInput, mode)
        );

    // On component mount
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);

    return (
        <Form
            ref={ref}
            layout="vertical"
            onValuesChange={val => onValuesChange(val, mode)}
            fields={[
                {
                    name: ['saname'],
                    value: values?.saname,
                },
                {
                    name: ['mano'],
                    value: values?.mano,
                },
                {
                    name: ['maName'],
                    value: values?.maName,
                },
                {
                    name: ['acno'],
                    value: values?.acno,
                },
                {
                    name: ['acName'],
                    value: values?.acName,
                },
                {
                    name: ['nepName'],
                    value: values?.nepName,
                },
                {
                    name: ['branchID'],
                    value: values?.branchID,
                },
                {
                    name: ['branchName'],
                    value: values?.branchName,
                },
                {
                    name: ['itemCode'],
                    value: values?.itemCode,
                },
                {
                    name: ['itemName'],
                    value: values?.itemName,
                },
                {
                    name: ['itemLocation'],
                    value: values?.itemLocation,
                },
                {
                    name: ['govCode'],
                    value: values?.govCode,
                },
                {
                    name: ['description'],
                    value: values?.description,
                },
                {
                    name: [`formedDate__${mode}`],
                    value:
                        values?.formedDate &&
                        ENG_ISO_TO_NEP(values?.formedDate),
                },
            ]}
        >
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={8}>
                    <Form.Item label="Super Account Name" name="saname">
                        {mode === 'edit' ? (
                            <p>{values?.saname}</p>
                        ) : (
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {superAccounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.saname}>
                                        {acc.saname}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Formed Date"
                        name={`formedDate__${mode}`}
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={5} xl={8}>
                    <Form.Item
                        label="Main Account Name"
                        name="maName"
                        rules={
                            mode === 'add' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        {mode === 'edit' ? (
                            <p>{values?.maName}</p>
                        ) : (
                            <Select
                                placeholder="Select..."
                                disabled={!values?.saname}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {mainAccounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.maName}>
                                        {acc.maName}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col xs={24} sm={12} md={8} lg={5} xl={4}>
                    <Form.Item
                        label="Main Account No."
                        name="mano"
                        rules={
                            mode === 'add' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        {mode === 'edit' ? (
                            <p>{values?.mano}</p>
                        ) : (
                            <Select
                                placeholder="Select..."
                                disabled={!values?.saname}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {mainAccounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.mano}>
                                        {acc.mano}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={5} xl={8}>
                    <Form.Item
                        label="Account Name"
                        name="acName"
                        rules={
                            mode === 'add' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        {mode === 'edit' ? (
                            <p>{values?.acName}</p>
                        ) : (
                            <Select
                                placeholder="Select..."
                                disabled={!values?.mano}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {generalAccounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.acName}>
                                        {acc.acName}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Account No."
                        name="acno"
                        rules={
                            mode === 'add' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        {mode === 'edit' ? (
                            <p>{values?.acno}</p>
                        ) : (
                            <Select
                                placeholder="Select..."
                                disabled={!values?.mano}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {generalAccounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.acno}>
                                        {acc.acno}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            {/* <Row gutter={20}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item label="Government Code">
                            <Checkbox
                                name="isGovCode"
                                checked={values?.isGovCode}
                                onChange={e => onCheckboxChange(e, mode)}
                                disabled={mode === 'view'}
                            />
                        </Form.Item>
                    </Col>
                </Row> */}

            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Branch ID" name="branchID">
                        <Select
                            placeholder="Select..."
                            // disabled={!values?.acno}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {branches.map((branch, i) => (
                                <Select.Option key={i} value={branch.branchID}>
                                    {branch.branchID}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Branch Name" name="branchName">
                        <Select
                            placeholder="Select..."
                            // disabled={!values?.acno}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {branches.map((branch, i) => (
                                <Select.Option
                                    key={i}
                                    value={branch.branchName}
                                >
                                    {branch.branchName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
            </Row>

            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={5} xl={4}>
                    <Form.Item
                        label="Item Code"
                        name="itemCode"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        {mode === 'view' ? (
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {generalItems.map((item, i) => (
                                    <Select.Option
                                        key={i}
                                        value={item.itemCode}
                                    >
                                        {item.itemCode}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <Input />
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={5} xl={4}>
                    <Form.Item label="Gov. Code" name="govCode">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="Item Name"
                        name="itemName"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        {mode === 'view' ? (
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {generalItems.map((item, i) => (
                                    <Select.Option
                                        key={i}
                                        value={item.itemName}
                                    >
                                        {item.itemName}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <Input />
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={5}>
                    <Form.Item label="Item Name Nepali">
                        <Input disabled={mode === 'view'} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Item Address" name="itemLocation">
                        <Input disabled={mode === 'view'} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={16} lg={18} xl={9}>
                    <Form.Item label="Description" name="description">
                        <Input disabled={mode === 'view'} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default GeneralItemsForm;
