import React from 'react';
import {
    Form,
    Row,
    Col,
    Radio,
    Space,
    Select,
    Checkbox,
    Table,
    Button,
    Pagination,
} from 'antd';
import ReactToPrint from 'react-to-print';
import ReactExport from 'react-export-excel';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../utils/functions';
import { JOURNAL_VOUCHER_TYPES } from '../../../../utils/helpers';
import Print from '../../../Common/Print/Print';
import PrintSettingsModal from '../../../Common/PrintSettingsModal/PrintSettingsModal';
import SettingsIconTrigger from '../../../Common/SettingsIconTrigger/SettingsIconTrigger';
import { PRINT_SETTINGS_FOOTER } from '../../../../utils/helpers';

export default function JournalVoucherForm(props) {
    // Local states
    const [printSettingsModal, setPrintSettingsModal] = React.useState(false);
    const [printSettingsFooter, setPrintSettingsFooter] = React.useState([
        'Prepared by',
    ]);
    const [printSettingsFooterTemp, setPrintSettingsFooterTemp] =
        React.useState(
            JSON.parse(localStorage.getItem('PRINT_SETTINGS_FOOTER')) || [
                PRINT_SETTINGS_FOOTER[0],
            ]
        );

    // Destructuring
    const {
        mode,
        branches,
        viewValues: values,
        onValuesChange,
        JournalVoucher,
        users,
        onPaginationChange,
        pageSize,
        isLoading,
        journalList,
        onDateChange,
        onDateKeyUp,
    } = props;

    // Refs
    const printRef = React.useRef();

    // NEPALI DATEPICKER
    // From date
    const fromDateInput = document.getElementById('fromDate');
    fromDateInput &&
        fromDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, fromDateInput, mode)
        );
    // Till date
    const tillDateInput = document.getElementById('tillDate');
    tillDateInput &&
        tillDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, tillDateInput, mode)
        );

    // Variables and constants

    // DATA SOURCE - START

    const columns = [
        {
            title: 'Journal No',
            dataIndex: 'snJournal',
            key: 'snJournal',
            width: '120px',
            render: (text, data) =>
                data.style === 'Bold' ? <strong>{text}</strong> : text,
        },
        {
            title: 'Particulars',
            dataIndex: 'particulars',
            key: 'particulars',
            render: (text, data) =>
                data.style === 'Bold' ? <strong>{text}</strong> : text,
        },
        {
            title: 'Account No',
            dataIndex: 'accountNo',
            key: 'accountNo',
            render: (text, data) =>
                data.style === 'Bold' ? <strong>{text}</strong> : text,
        },
        {
            title: 'Dr. Amount Rs',
            dataIndex: 'debit',
            key: 'debit',
            render: (text, data) =>
                data.style === 'Bold' ? <strong>{text}</strong> : text,
        },
        {
            title: 'Cr. Amount Rs',
            dataIndex: 'credit',
            key: 'credit',
            render: (text, data) =>
                data.style === 'Bold' ? <strong>{text}</strong> : text,
        },
    ];
    // DATA SOURCE - END

    // Print
    // On Ok
    const onPrintSettingsModalOk = () => {
        setPrintSettingsFooter(printSettingsFooterTemp);
        localStorage.setItem(
            'PRINT_SETTINGS_FOOTER',
            JSON.stringify(printSettingsFooterTemp)
        );
        setPrintSettingsModal(false);
    };
    // On Cancel
    const onPrintSettingsModalCancel = () => {
        console.log(printSettingsFooter);
        setPrintSettingsFooterTemp(printSettingsFooter);
        setPrintSettingsModal(false);
    };

    return (
        <>
            <Form
                layout="vertical"
                onValuesChange={onValuesChange}
                // onFinish={onFormSubmit}
                fields={[
                    {
                        name: 'fromDate',
                        value:
                            values?.fromDate && ENG_ISO_TO_NEP(values.fromDate),
                    },
                    {
                        name: 'tillDate',
                        value:
                            values?.tillDate && ENG_ISO_TO_NEP(values.tillDate),
                    },
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'suspenseAccount',
                        value: values?.suspenseAccount,
                    },
                    {
                        name: 'odLoanSide',
                        value: values?.odLoanSide,
                    },
                    {
                        name: 'acHeadTotal',
                        value: values?.acHeadTotal,
                    },
                    {
                        name: 'level',
                        value: values?.level,
                    },
                    {
                        name: 'fromjn',
                        value: values?.fromjn,
                    },
                    {
                        name: 'tojn',
                        value: values?.tojn,
                    },
                    {
                        name: 'baseon',
                        value: values?.baseon,
                    },
                    {
                        name: 'showSuspense',
                        value: values?.showSuspense,
                    },
                    {
                        name: 'hideSameACTransaction',
                        value: values?.hideSameACTransaction,
                    },
                    {
                        name: 'enteredBy',
                        value: values?.enteredBy,
                    },
                    {
                        name: 'journalType',
                        value: values?.journalType,
                    },
                ]}
            >
                <Row gutter={20}>
                    <Col span={3}>
                        <Form.Item label="Date From" name="fromDate">
                            <input
                                type="text"
                                className="ant-input"
                                onKeyUp={e => onDateKeyUp(e, mode)}
                            />
                        </Form.Item>
                        <Form.Item label="Date Till" name="tillDate">
                            <input
                                type="text"
                                className="ant-input"
                                onKeyUp={e => onDateKeyUp(e, mode)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Journal No From" name="fromjn">
                            <Select placeholder="Select...">
                                {journalList?.length &&
                                    journalList.map((item, i) => (
                                        <Select.Option value={item}>
                                            {item}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Journal No To" name="tojn">
                            <Select placeholder="Select...">
                                {journalList?.length &&
                                    journalList.map((item, i) => (
                                        <Select.Option value={item}>
                                            {item}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label="Level" name="baseon">
                            <Radio.Group>
                                <Space direction="vertical">
                                    <Radio value="main account">
                                        Main account
                                    </Radio>
                                    <Radio value="account">Account</Radio>
                                    <Radio value="sub account">
                                        Sub Account
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={9}>
                        <Space>
                            <Form.Item
                                name="showSuspense"
                                valuePropName="checked"
                            >
                                <Checkbox>Show Susp</Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="hideSameACTransaction"
                                valuePropName="checked"
                            >
                                <Checkbox>Hide same A/C transaction</Checkbox>
                            </Form.Item>
                            <Form.Item name="">
                                <Checkbox>Show user</Checkbox>
                            </Form.Item>
                        </Space>
                        <Space style={{ width: '100%' }}></Space>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Space>
                                    <Form.Item
                                        label="Branch ID"
                                        name="branchID"
                                    >
                                        <Select placeholder="Select...">
                                            <Select.Option value="all">
                                                All
                                            </Select.Option>
                                            {branches?.length &&
                                                branches.map((item, i) => (
                                                    <Select.Option
                                                        key={i}
                                                        value={item.branchID}
                                                    >
                                                        {item.branchID}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Branch Name"
                                        name="branchName"
                                    >
                                        <Select placeholder="Select...">
                                            <Select.Option value="all">
                                                All
                                            </Select.Option>
                                            {branches?.length &&
                                                branches.map((item, i) => (
                                                    <Select.Option
                                                        key={i}
                                                        value={item.branchName}
                                                    >
                                                        {item.branchName}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Space>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Posted by" name="enteredBy">
                                    <Select placeholder="Select...">
                                        <Select.Option value="all">
                                            All
                                        </Select.Option>
                                        {users?.length &&
                                            users.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.userName}
                                                >
                                                    {item.userName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Form.Item name="journalType">
                            <Radio.Group size="small" buttonStyle="solid">
                                {JOURNAL_VOUCHER_TYPES.map((item, i) => (
                                    <Radio.Button value={i + 1}>
                                        {item}
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </Form.Item>
                        <Space>
                            <div>
                                <ReactToPrint
                                    trigger={() => (
                                        <Button type="default" size="small">
                                            Print
                                        </Button>
                                    )}
                                    content={() => printRef.current}
                                />
                                <SettingsIconTrigger
                                    onClick={() => setPrintSettingsModal(true)}
                                />
                            </div>
                            <Excel data={JournalVoucher?.dataList} />
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Print
                ref={printRef}
                title={`Journal Voucher (${
                    JOURNAL_VOUCHER_TYPES[values?.journalType - 1]
                })`}
                fromDate={values?.fromDate}
                tillDate={values?.tillDate}
                footer={printSettingsFooter}
            >
                <Table
                    dataSource={JournalVoucher?.dataList}
                    columns={columns}
                    className="ant-table-wrapper--collapsed"
                    pagination={false}
                    bordered
                    loading={isLoading}
                />
                <Pagination
                    size="small"
                    total={JournalVoucher?.totalRecords}
                    showSizeChanger
                    onChange={onPaginationChange}
                    pageSize={pageSize}
                    style={{ marginTop: '.5rem' }}
                />
            </Print>
            {/* Print settings modal */}
            <PrintSettingsModal
                visible={printSettingsModal}
                onOk={onPrintSettingsModalOk}
                onCancel={onPrintSettingsModalCancel}
                footerTemp={printSettingsFooterTemp}
                setFooterTemp={setPrintSettingsFooterTemp}
            />
        </>
    );
}

export function Excel({ data }) {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
        <ExcelFile
            element={<Button size="small">Excel</Button>}
            filename="Journal Voucher"
        >
            <ExcelSheet data={data} name="Journal Voucher">
                <ExcelColumn label="Journal No" value="snJournal" />
                <ExcelColumn label="Particulars" value="particulars" />
                <ExcelColumn label="Account No" value="accountNo" />
                <ExcelColumn label="Account No" value="accountNo" />
                <ExcelColumn label="Dr. Amount Rs" value="debit" />
                <ExcelColumn label="Cr. Amount Rs" value="credit" />
            </ExcelSheet>
        </ExcelFile>
    );
}
