import {
    UserOutlined,
    MoneyCollectFilled,
    UsergroupAddOutlined,
    BarChartOutlined,
    SnippetsFilled,
    DollarOutlined,
    GoldOutlined,
    BookOutlined,
    TeamOutlined,
    PrinterOutlined,
    FilePdfOutlined,
    ApiOutlined,
    MessageOutlined,
    ToolOutlined,
} from '@ant-design/icons';

export const SIDE_NAV_MENU_ITEMS = [
    {
        icon: <UserOutlined />,
        title: 'Administrative Panel',
        items: [
            { title: 'Register user', to: '/admin-panel/user-registration' },
            { title: 'User Role', to: '/test' },
            { title: 'Change Password', to: '/change-password' },
            { title: 'Branch Assignment', to: '/branch-assignment' },
            { title: 'Counter Registration', to: '/counter-registration' },
            { title: 'Counter Assignment', to: '/counter-assignment' },
            { title: 'Official Detail', to: '/office-details' },
        ],
    },
    {
        icon: <SnippetsFilled />,
        title: 'System setup',
        items: [
            {
                title: 'Account Setup',
                subMenu: [
                    { title: 'General account', to: '/general-account' },
                    { title: 'Assets type', to: '/assets-type-account' },
                    { title: 'Deposit types', to: '/deposit-types-account' },
                    { title: 'Loan Type', to: '/loan-types-account' },
                ],
            },
            {
                title: 'Item Setup',
                subMenu: [
                    { title: 'General Items', to: '/general-items' },
                    { title: 'Bank Accounts', to: '/bank-registration' },
                    { title: 'Remit Account', to: '/test' },
                    { title: 'Assets Item', to: '/assets-registration' },
                ],
            },
            {
                title: 'Group Setup',
                subMenu: [
                    { title: 'Area', to: '/area' },
                    { title: 'Collection Group', to: '/collection-group' },
                    { title: 'Group Type', to: '/group-type' },
                    { title: 'Member Group', to: '/member-group' },
                ],
            },
            {
                title: 'System',
                subMenu: [
                    { title: 'Dash Board Setup', to: '/test' },
                    { title: 'Change Database', to: '/test' },
                    { title: 'Reset Password', to: '/test' },
                    { title: 'Application Settings', to: '/test' },
                ],
            },
            {
                title: 'Staff',
                subMenu: [
                    { title: 'Staff Registration', to: '/staff-registration' },
                    { title: 'Staff Types', to: '/staff-types' },
                    { title: 'Staff Posts', to: '/staff-posts' },
                ],
            },
        ],
    },

    {
        icon: <UsergroupAddOutlined />,
        title: 'Membership',
        items: [
            {
                title: 'Member Registration',
                to: '/member-registration',
            },

            {
                title: 'Account Owner',
                to: '/owner-details',
            },
            {
                title: 'Member Info',
                to: '/member-info',
            },
            {
                title: 'Group wise Member Report',
                to: '/group-wise-member-report',
            },
            {
                title: 'Members Full Statement',
                to: '/member-statement',
            },
            {
                title: 'Share Application',
                subMenu: [
                    { title: 'Share Addition', to: '/share-addition' },
                    { title: 'Transfer Share', to: '/test' },
                    {
                        title: 'Issue Share Certificates',
                        to: '/share-certificate',
                    },
                    {
                        title: 'Print Share Certificate',
                        to: '/print-share-certificate',
                    },
                    { title: 'Share Panjika', to: '/share-panjika' },
                    { title: 'Share Issued Report', to: '/test' },
                ],
            },
            {
                title: 'Active/Inactive Members',
                to: '/active-inactive-members',
            },
            {
                title: 'KYM',
                to: '/kym',
            },
            {
                title: 'Dissolution Of Members',
                to: '/test',
            },
        ],
    },
    {
        icon: <MoneyCollectFilled />,
        title: 'Savings',
        items: [
            { title: 'Deposit Registration', to: '/deposit-registration' },
            { title: 'Counter Deposit', to: '/counter-deposit' },
            { title: 'Collection Entry', to: '/collection-entry' },
            { title: 'Fixed Saving', to: '/fixed-deposit' },
            {
                title: 'Collection App Data Prepare',
                to: '/test',
            },
            {
                title: 'Withdraw',
                subMenu: [
                    { title: 'Counter Withdraw', to: '/counter-withdraw' },
                    { title: 'Overdraft Withdraw', to: '/overdraft-withdraw' },
                    { title: 'OD Withdraw deposit', to: '/test' },
                ],
            },
            {
                title: 'Deposit Feature',
                subMenu: [
                    { title: 'Activate', to: '/activate-deposit-features' },
                    {
                        title: 'SMS Banking Accounts',
                        to: '/sms-banking-accounts',
                    },
                    {
                        title: 'Mobile Banking Accounts',
                        to: '/mobile-banking-accounts',
                    },
                    {
                        title: 'Expiring SMS Banking Accounts',
                        to: '/expiring-sms-banking-accounts',
                    },
                    {
                        title: 'Expiring Mobile Banking Accounts',
                        to: '/expiring-mobile-banking-accounts',
                    },
                ],
            },
            {
                title: 'Show Statement',
                to: '/show-statement',
            },
            {
                title: 'Overdraft Statement',
                to: '/test',
            },
            {
                title: 'Deposit Report',
                subMenu: [
                    {
                        title: 'Statement All',
                        to: '/statement-all',
                    },
                    {
                        title: 'Maturity Report',
                        to: '/maturity-report',
                    },
                    {
                        title: 'Active Inactive Accounts',
                        to: '/active-inactive-accounts',
                    },
                    {
                        title: 'Locked Accounts',
                        to: '/locked-deposit-accounts',
                    },
                    {
                        title: 'Disabled Accounts',
                        to: '/disabled-deposit-accounts',
                    },
                ],
            },
            {
                title: 'Periodic Deposit Certificate',
                to: '/test',
            },

            {
                title: 'Cheque Book',
                subMenu: [
                    { title: 'Issue Cheque Book', to: '/test' },
                    { title: 'Print Cheque NO', to: '/test' },
                    { title: 'Cheque Setting', to: '/test' },
                    { title: 'Issued Cheque List', to: '/test' },
                    { title: 'Block Cheque No', to: '/test' },
                    { title: 'Cheque Setting', to: '/test' },
                    { title: 'Find Cheque', to: '/test' },
                ],
            },
            {
                title: 'Lock/Unlock Account',
                to: '/test',
            },
            { title: 'Calculate Interest', to: '/calculate-interest' },
            {
                title: 'Renew Account',
                to: '/test',
            },
            {
                title: 'Close Account',
                to: '/closing-account',
            },
        ],
    },

    {
        icon: <BarChartOutlined />,
        title: 'Credits',
        items: [
            {
                title: 'Loan Registration',
                to: '/loan-registration',
            },
            {
                title: 'Loan Granting',
                to: '/loan-granting',
            },
            {
                title: 'Edit Individual Installment',
                to: '/test',
            },
            {
                title: 'Loan Recovery',
                to: '/loan-recovery',
            },
            {
                title: 'Daily loan Collection ',
                to: '/test',
            },
            {
                title: 'Transfer Loan Balance',
                to: '/test',
            },
            {
                title: 'Close/Disable Loan Account',
                to: '/test',
            },
            {
                title: 'Loan Ledger',
                to: '/test',
            },
            {
                title: 'Loan Statement Individual',
                to: '/test',
            },

            {
                title: 'Loan Reports',
                subMenu: [
                    { title: 'Find Loan', to: '/test' },
                    { title: 'Loan Statement All', to: '/test' },
                    { title: 'Loan Aging', to: '/test' },
                    { title: 'Loan Delinquency', to: '/test' },
                    { title: 'Loan Recovery Report', to: '/test' },
                    { title: 'Kista Statement', to: '/test' },
                    { title: 'Loan Delinquency Short', to: '/test' },
                    { title: 'Loan Teris', to: '/test' },
                    { title: 'Collateral Reports', to: '/test' },
                ],
            },
        ],
    },

    {
        icon: <DollarOutlined />,
        title: 'Remittance',
        items: [
            {
                title: 'Create Remit Account',
                to: '/create-remit',
            },
            {
                title: 'Remit Payment',
                to: '/remit-payment',
            },
            {
                title: 'Remit Sending',
                to: '/remit-sending',
            },
            {
                title: 'Remit Dues Received',
                to: '/remit-dues-received',
            },
            {
                title: 'Remit Deposit/Due Paid',
                to: '/test',
            },
            {
                title: 'Remit Payment Report',
                to: '/test',
            },
            {
                title: 'Remit Sending Report',
                to: '/test',
            },
        ],
    },

    {
        icon: <GoldOutlined />,
        title: 'Mass Entries',
        items: [
            {
                title: 'Combine Collection',
                to: '/test',
            },
            {
                title: 'Microfinance Collection',
                to: '/test',
            },
        ],
    },

    {
        icon: <BookOutlined />,
        title: 'Accounts',
        items: [
            {
                title: 'Receipt Entry',
                to: '/receipt-entry',
            },
            {
                title: 'House Rent',
                to: '/house-rent',
            },
            {
                title: 'Stationeries',
                subMenu: [
                    { title: 'Use From Stock', to: '/test' },
                    { title: 'Direct Expense', to: '/test' },
                ],
            },
            {
                title: 'Assets Purchased',
                to: '/test',
            },
            {
                title: 'Banking',
                subMenu: [
                    { title: 'Deposit To Bank', to: '/deposit-to-bank' },
                    { title: 'Withdraw From Bank', to: '/withdraw-from-bank' },
                ],
            },
            {
                title: 'Sell/Use/Remove Assets',
                to: '/test',
            },
            {
                title: 'General Advance',
                subMenu: [
                    { title: 'Pay In Advance', to: '/pay-in-advance' },
                    {
                        title: 'Complete/Return For Advance',
                        to: '/advance-return',
                    },
                ],
            },
            {
                title: 'Pay For Payables',
                to: '/pay-for-payable',
            },
            {
                title: 'Investment',
                to: '/investment',
            },
            {
                title: 'Income',
                to: '/general-income',
            },
            {
                title: 'Expenditure',
                to: '/general-expenditure',
            },
            {
                title: 'General Transaction',
                to: '/test',
            },
        ],
    },
    {
        icon: <TeamOutlined />,
        title: 'HRM',
        items: [
            {
                title: 'Staff Registration',
                to: '/test',
            },
            {
                title: 'Attendance',
                to: '/test',
            },
            {
                title: 'Salary Sheet',
                to: '/test',
            },
            {
                title: 'Pay Salary',
                to: '/test',
            },
            {
                title: 'Staff Advance',
                to: '/test',
            },
            {
                title: 'User Management',
                subMenu: [
                    { title: 'User Registration', to: '/test' },
                    { title: 'Role Groups', to: '/test' },
                ],
            },
        ],
    },

    {
        icon: <PrinterOutlined />,
        title: 'Print',
        items: [
            {
                title: 'Receipt Voucher',
                to: '/test',
            },
            {
                title: 'Payment Slip',
                to: '/test',
            },
        ],
    },

    {
        icon: <FilePdfOutlined />,
        title: 'Report',
        items: [
            {
                title: 'Journal Voucher',
                to: '/journal-voucher',
            },
            {
                title: 'Day Book/Transaction Sheet',
                to: '/test',
            },
            {
                title: 'Receipt Detail',
                to: '/test',
            },
            {
                title: 'Receipts & Payments',
                to: '/test',
            },
            {
                title: 'Transaction Book',
                to: '/test',
            },
            {
                title: 'Cash In/Out Report',
                to: '/test',
            },
            {
                title: 'Denomination Report',
                to: '/test',
            },
            {
                title: 'Trial Balance',
                to: '/trial-balance',
            },
            {
                title: 'Item Wise Ledger',
                to: '/item-wise-ledger',
            },
            {
                title: 'Collectors Detail',
                to: '/test',
            },
            {
                title: 'Profit & Loss Account',
                to: '/profit-loss-account',
            },
            {
                title: 'Char Khata',
                to: '/test',
            },
            {
                title: 'Balance Sheet',
                to: '/balance-sheet',
            },
            {
                title: 'Balance Sheet 2',
                to: '/test',
            },
            {
                title: 'Cash Flow Statement',
                to: '/test',
            },
            {
                title: 'Progress Report',
                to: '/test',
            },
            {
                title: 'Division Office Report',
                to: '/test',
            },
            {
                title: 'PEARLS Analysis',
                to: '/test',
            },
            {
                title: 'Money Laundaring',
                to: '/test',
            },
            {
                title: 'Interest Rates',
                to: '/test',
            },

            {
                title: 'Membership And Staff',
                subMenu: [
                    { title: 'Members Book', to: '/test' },
                    { title: 'Members Book Nepali', to: '/test' },
                    { title: 'Members Tree', to: '/test' },
                    { title: 'Share Book', to: '/test' },
                    { title: 'Staffs Detail', to: '/test' },
                ],
            },
            {
                title: 'Balance Query',
                subMenu: [
                    {
                        title: 'Sub Account Balance',
                        to: '/sub-account-balance',
                    },
                    { title: 'Sub Account Detail', to: '/test' },
                    { title: 'Account Detail All', to: '/test' },
                    { title: 'Genderwise Balance', to: '/test' },
                    { title: 'Show Account Tree', to: '/test' },
                ],
            },
            {
                title: 'Periodic Transactions',
                subMenu: [
                    { title: 'Monthly Transaction', to: '/test' },
                    { title: 'Transaction in Days ', to: '/test' },
                ],
            },
            {
                title: 'Savings Report',
                subMenu: [
                    { title: 'Depositors Detail', to: '/test' },
                    { title: 'Guarantee Information ', to: '/test' },
                ],
            },
            {
                title: 'Emergency Reports',
                to: '/test',
            },

            {
                title: 'Assets',
                subMenu: [
                    { title: 'Bank Detail', to: '/test' },
                    {
                        title: 'Office Equipments Furniture and Vehicles',
                        to: '/test',
                    },
                    {
                        title: 'Other Assets',
                        to: '/test',
                    },
                ],
            },

            {
                title: 'Official Reports',
                subMenu: [
                    { title: 'Member Group Detail', to: '/test' },
                    { title: 'Area Detail ', to: '/test' },
                    { title: 'Society Detail ', to: '/test' },
                    { title: 'Official Detail ', to: '/test' },
                ],
            },
            {
                title: 'Profit Analysis',
                to: '/test',
            },
            {
                title: 'General Reports',
                to: '/test',
            },
        ],
    },
    {
        icon: <ApiOutlined />,
        title: 'Closings',
        items: [
            {
                title: 'Day Open',
                subMenu: [
                    { title: 'Set New Transaction Date', to: '/test' },
                    {
                        title: 'Counter Cash Distribution',
                        to: '/counter-cash-distribution',
                    },

                    { title: 'Opening Denomination ', to: '/test' },
                ],
            },
            {
                title: 'Day Close',
                subMenu: [
                    { title: 'Start Day End Process', to: '/test' },
                    { title: 'Membership Approval', to: '/test' },
                    { title: 'Deposit Account Approval ', to: '/test' },
                    { title: 'Cash Vault ', to: '/cash-vault' },
                    { title: 'Loan Account Approval ', to: '/test' },
                    { title: 'Other Account Approval ', to: '/test' },
                    { title: 'Transaction Approval ', to: '/test' },

                    { title: 'Denomination ', to: '/test' },
                ],
            },
            {
                title: 'Monthly End Closing',
                subMenu: [
                    { title: 'Interest Provision And Collection	', to: '/test' },
                    { title: 'Interest From Loans ', to: '/test' },
                    { title: 'Interest To General Type Savings	 ', to: '/test' },
                    { title: 'Interest To Fixed Savings	 ', to: '/test' },
                    { title: 'Transfer Fixed Deposits', to: '/test' },
                    { title: 'Depreciation Of Assets', to: '/test' },
                    { title: 'Provision For Bad Debts', to: '/test' },
                ],
            },
            {
                title: 'Yearly Closing',
                subMenu: [
                    { title: 'Return Capital Fund', to: '/test' },
                    { title: 'Prepare PL Account ', to: '/test' },
                    { title: 'Fiscal Year Closing ', to: '/test' },
                    { title: 'Pay Dividend', to: '/test' },
                    { title: 'Transfer Deposit', to: '/test' },
                ],
            },
        ],
    },

    {
        icon: <MessageOutlined />,
        title: 'SMS Banking',
        items: [
            {
                title: 'Prepare SMS',
                to: '/test',
            },
            {
                title: 'Send SMS',
                to: '/test',
            },
        ],
    },

    {
        icon: <ToolOutlined />,
        title: 'Tools',
        items: [
            {
                title: 'Copy',
                to: '/test',
            },
            {
                title: 'Copy Table',
                to: '/test',
            },
            {
                title: 'Convert Date',
                to: '/test',
            },
            {
                title: 'EMI Calclualtor',
                to: '/test',
            },
            {
                title: 'Find Interets Rate',
                to: '/test',
            },
            {
                title: 'Calculator',
                to: '/test',
            },
            {
                title: 'Deposit Interest Analysis',
                to: '/test',
            },
            {
                title: 'Remove Unecesary Items',
                to: '/test',
            },
            {
                title: 'Backup Database',
                to: '/test',
            },
            {
                title: 'Restore Database',
                to: '/test',
            },
        ],
    },
];
