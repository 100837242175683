import React from 'react';
import { message, Button, Modal } from 'antd';
import moment from 'moment';
// Import utils
import axios from '../../utils/axios';
import {
    UPDATE_AXIOS_AUTH,
    SET_DEFAULT_VALUES,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../utils/functions';
// Import components
import PageWrapper from '../../components/Common/PageWrapper/PageWrapper';
import {
    default as AreaFormView,
    default as AreaFormEdit,
} from '../../components/AreaForm/AreaForm';
import BottomActionBarFixed from '../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function GroupType() {
    // Local states
    const [areas, setAreas] = React.useState([]);
    const [staffs, setStaffs] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(
            key,
            date,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues, setEditValues, setAddValues);
    };

    // Get area detail
    const getAreaDetail = id => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/area/AreaDetail/${id}`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);

                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get area details');
                message.error('Something went wrong. Please try again!');
            });
    };

    // VIEW FORM
    const onViewValuesChange = val => {
        // const key = Object.keys(val)[0];
        // const value = val[key];
        if (val.hasOwnProperty('areaID')) {
            const area = areas.find(area => area.areaID === val.areaID);
            setViewValues(prevValues => ({
                ...prevValues,
                areaName: area.areaName,
            }));
            getAreaDetail(val.areaID);
        } else if (val.hasOwnProperty('areaName')) {
            const area = areas.find(area => area.areaID === val.areaName);
            setViewValues(prevValues => ({
                ...prevValues,
                areaID: area.areaID,
            }));
            getAreaDetail(area.areaID);
        } else if (
            val.hasOwnProperty('formedDate__view') ||
            val.hasOwnProperty('baseDate__view')
        ) {
            return;
        }
        setViewValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // EDIT ACCOUNT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/Area/UpdateArea/${editValues.areaID}`,
                        editValues
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);

                        setViewValues(res.data.data);
                        setEditModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Updated!');
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to update area type account'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setEditModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // On edit values change
    const onEditValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        if (
            val.hasOwnProperty('formedDate__edit') ||
            val.hasOwnProperty('baseDate__edit')
        ) {
            return;
        } else if (val.hasOwnProperty('areaName')) {
            return setEditValues(prevValues => ({
                ...prevValues,
                [key]: value.toUpperCase(),
            }));
        } else if (
            val.hasOwnProperty('staffID') ||
            val.hasOwnProperty('staffName')
        ) {
            const staff = staffs.find(staff => staff[key] === value);
            setEditValues(prevValues => ({
                ...prevValues,
                ...staff,
            }));
        }
        setEditValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // ADD ACCOUNT MODAL
    // On add modal open
    const onAddModalOpen = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On add modal ok
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/Area/AddArea`,
                        addValues
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        const data = res.data.data;
                        setAreas([
                            ...areas,
                            { areaID: data.areaID, areaName: data.areaName },
                        ]);
                        setViewValues(data);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        setAddValues(null);
                        message.success('New account created!');
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to create area type account'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };
    // On add values change
    const onAddValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        if (
            val.hasOwnProperty('formedDate__add') ||
            val.hasOwnProperty('baseDate__add')
        ) {
            return;
        } else if (val.hasOwnProperty('areaName')) {
            return setAddValues(prevValues => ({
                ...prevValues,
                [key]: value.toUpperCase(),
            }));
        } else if (
            val.hasOwnProperty('staffID') ||
            val.hasOwnProperty('staffName')
        ) {
            const staff = staffs.find(staff => staff[key] === value);
            setAddValues(prevValues => ({
                ...prevValues,
                ...staff,
            }));
        }
        setAddValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // DELETE ACCOUNT MODAL
    // On delete modal open
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On delete modal ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/Area/DeleteArea/${viewValues.areaID}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setViewValues(null);
                setAreas(
                    [...areas].filter(area => area.areaID !== viewValues.areaID)
                );
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Account deleted!');
            })
            .catch(err => {
                console.error(err, 'Failed to delete area type account');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };
    // On add modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // COMMON
    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            formedDate: moment().format(),
            baseDate: moment().format(),
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);
    // On checkbox change
    const onCheckboxChange = (e, mode) => {
        const key = e.target.name;
        const checked = e.target.checked;

        if (mode === 'edit') {
            setEditValues(prevValues => ({
                ...prevValues,
                [key]: checked,
            }));
        } else if (mode === 'add') {
            setAddValues(prevValues => ({
                ...prevValues,
                [key]: checked,
            }));
        }
    };
    // Form props
    const formProps = {
        ref: formRef,
        areas,
        staffs,
        onCheckboxChange,
        setDefaultValues,
        onDateChange,
        onDateKeyUp,
    };

    // On component mount
    React.useEffect(() => {
        UPDATE_AXIOS_AUTH();
        // Get areas
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Area`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setAreas(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get areas');
                message.error('Something went wrong. Please try again!');
            });
        // Get staffs
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Staff`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setStaffs(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get staffs');
                message.error('Something went wrong. Please try again!');
            });
    }, []);
    return (
        <PageWrapper>
            {/* View account */}
            <AreaFormView
                {...formProps}
                mode="view"
                values={viewValues}
                onValuesChange={onViewValuesChange}
            />
            {/* Edit account modal */}
            <Modal
                title="Edit area"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                onOk={onEditModalOk}
                onCancel={onEditModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: editModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <AreaFormEdit
                    {...formProps}
                    mode="edit"
                    values={editValues}
                    onValuesChange={onEditValuesChange}
                />
            </Modal>
            {/* Add account modal */}
            <Modal
                title="Add area"
                visible={addModal.visible}
                confirmLoading={addModal.confirmLoading}
                onOk={onAddModalOk}
                onCancel={onAddModalCancel}
                okText="Create"
                cancelButtonProps={{
                    disabled: addModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <AreaFormEdit
                    {...formProps}
                    mode="add"
                    values={addValues}
                    onValuesChange={onAddValuesChange}
                />
            </Modal>
            {/* Delete account modal */}
            <Modal
                title="Delete area?"
                visible={deleteModal.visible}
                confirmLoading={deleteModal.confirmLoading}
                onOk={onDeleteModalOk}
                onCancel={onDeleteModalCancel}
                okText="Delete"
                cancelButtonProps={{
                    disabled: deleteModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>The account and its data will be deleted forever</p>
            </Modal>
            {/* Actions bar */}
            <BottomActionBarFixed>
                <Button
                    type="primary"
                    onClick={onEditModalOpen}
                    disabled={!viewValues?.areaID}
                >
                    Edit
                </Button>
                <Button type="primary" onClick={onAddModalOpen}>
                    Add
                </Button>
                <Button
                    type="primary"
                    onClick={onDeleteModalOpen}
                    disabled={!viewValues?.areaID}
                >
                    Delete
                </Button>
            </BottomActionBarFixed>
        </PageWrapper>
    );
}
