import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
    GET_ACTIVE_INACTIVE_DEPOSIT_ACCOUNTS,
    GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS,
    DISABLE_DEPOSIT_ACCOUNTS,
} from '../../../utils/axios';
import {
    ON_COLUMNS_CHANGE,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../utils/functions';
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import ActiveInactiveAccountsForm from '../../../components/Savings/ActiveInactiveAccounts/ActiveInactiveAccountsForm/ActiveInactiveAccountsForm';
import moment from 'moment';

export default function ActiveInactiveAccounts() {
    // Local states
    const [viewValues, setViewValues] = React.useState(null);
    const [statement, setStatement] = React.useState(null);
    const [activeDepTypeAccounts, setActiveDepTypeAccounts] =
        React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [columns, setColumns] = React.useState([
        'Balance',
        'Total Int.',
        'Total Tax',
        'Withdrawable',
    ]);
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);

    // UTILS
    // On pagination change
    const onPaginationChange = (page, pageSize) => {
        console.log(page, pageSize);
        setPage(page);
        setPageSize(pageSize);
    };

    // On columns list change
    const onColumnsChange = e => {
        ON_COLUMNS_CHANGE(e, setColumns);
    };

    // On check all change
    const onCheckAllChange = e => {
        if (!statement?.dataList) return;
        const checked = e.target.checked;
        const statementNew = {
            ...statement,
            dataList: statement?.dataList.map(item => {
                return {
                    ...item,
                    disabled: checked,
                };
            }),
        };
        setStatement(statementNew);
        setIndeterminate(false);
        setCheckAll(checked);
    };

    // On Checkbox change
    const onCheckboxChange = e => {
        const id = e.target.id;
        const checked = e.target.checked;
        const i = id.split('_')[1];
        let statementNew = { ...statement };
        statementNew.dataList[i - 1].disabled = checked;
        setStatement(statementNew);

        // Set indeterminate
        const allChecked =
            statement?.dataList?.length ===
            statement?.dataList?.filter(item => item.disabled).length;

        setIndeterminate(
            statement?.dataList.find(item => item.disabled)
                ? !allChecked
                : false
        );
        setCheckAll(allChecked);
    };

    const showDisableAccountsConfirm = () => {
        Modal.confirm({
            title: 'Do you want to disable these account(s)?',
            icon: <ExclamationCircleOutlined />,
            content: 'The selected account(s) will be disabled.',
            okText: 'Disable',
            onOk() {
                console.log(statement, 'statement');
                const payload = statement.dataList
                    .filter(item => item.disabled)
                    .map(item => {
                        return {
                            accountNo: item.accountNo,
                            mainBookNo: item.mainBookNo,
                        };
                    });
                console.log('payload: ', payload);
                return new Promise(async (resolve, reject) => {
                    try {
                        await DISABLE_DEPOSIT_ACCOUNTS({
                            accountList: payload,
                        });
                        const dataListNew = statement.dataList.filter(
                            item => !item.disabled
                        );
                        setStatement(prevValues => ({
                            ...prevValues,
                            dataList: dataListNew,
                        }));
                        resolve();
                    } catch (err) {}
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {},
        });
    };

    // On form values change
    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];

        // Update values on state
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        if (key === 'acName' || key === 'acno') {
            if (value === 'All')
                return updateValues({ acName: 'All', acno: 'All' });
            const acc = activeDepTypeAccounts.find(item => item[key] === value);
            return updateValues(acc);
        } else if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(item => item[key] === value);
            return updateValues(branch);
        }

        // Update values
        updateValues(val);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            queryDate: moment().format('YYYY-MM-DD'),
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // Get active deposit type accounts
    React.useEffect(() => {
        (async () => {
            const activeDepTypeAccounts =
                await GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS();
            setActiveDepTypeAccounts(activeDepTypeAccounts);
        })();
    }, []);

    // Get statement
    React.useEffect(() => {
        if (
            !viewValues?.acno ||
            !viewValues?.queryDate ||
            !viewValues?.branchID ||
            !viewValues?.state
        )
            return;
        setIsLoading(true);
        const payload = {
            pageNO: page,
            rpp: pageSize,
            mainbookNo: viewValues?.acno,
            queryDate: viewValues?.queryDate,
            branchID: viewValues?.branchID,
            type: viewValues?.state,
            minBal: viewValues?.maxBal,
        };
        (async () => {
            const statement = await GET_ACTIVE_INACTIVE_DEPOSIT_ACCOUNTS(
                payload
            );
            console.log('statement: ', statement);
            setStatement(statement);
            setIsLoading(false);
        })();
    }, [
        page,
        pageSize,
        viewValues?.acno,
        viewValues?.queryDate,
        viewValues?.branchID,
        viewValues?.maxBal,
        viewValues?.state,
    ]);

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    // Form props
    const formProps = {
        statement,
        isLoading,
        onPaginationChange,
        columnsList: columns,
        onColumnsChange,
        onValuesChange,
        values: viewValues,
        onDateChange,
        onDateKeyUp,
        activeDepTypeAccounts,
        branches,
        indeterminate,
        onCheckAllChange,
        checkAll,
        onCheckboxChange,
        showDisableAccountsConfirm,
    };
    return (
        <PageWrapper>
            <ActiveInactiveAccountsForm {...formProps} />
        </PageWrapper>
    );
}
