import React from 'react';
import { message, Modal } from 'antd';
import axios from '../../../utils/axios';
import { useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { verificationStatus as verificationStatusAtom } from '../../../recoil/atoms';
import ChangePasswordForm from '../../../components/ChangePassowrdForm/ChangePasswordForm';
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';

export default function PasswordRest() {
    // Local states
    const [values, setValues] = React.useState(null);
    const [confirmModal, setConfirmModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [isChangingPassword, setIsChangingPassword] = React.useState(false);
    // Global states
    const setVerificationStatus = useSetRecoilState(verificationStatusAtom);

    // Variables and constants
    const history = useHistory();

    // Refs
    const formRef = React.useRef();
    // On values change
    const onValuesChange = val => {
        setValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // Handle logout
    const handleLogout = () => {
        // Clear local storage
        localStorage.clear();
        // Set verification status
        setVerificationStatus('unauthorized');
        // Return to login screen
        history.push('/auth/login');
    };

    // Handle password confirm
    const handlePasswordConfirm = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setConfirmModal(prevValues => ({
                    ...prevValues,
                    visible: true,
                }));
            })
            .catch(() => {});
    };

    // Handle password change
    const handlePasswordChange = () => {
        setIsChangingPassword(true);
        axios
            .patch(
                `${process.env.REACT_APP_HOST}/api/Users/ChangePasssword`,
                values
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setConfirmModal({
                    visible: false,
                    confirmLoading: false,
                });
                handleLogout();
                message.success(
                    'Your password has been changed successfully. Please login using your new password.'
                );
            })
            .catch(err => {
                console.error(err, 'Failed to change password');
            })
            .finally(() => {
                setIsChangingPassword(false);
            });
    };

    // Set default values
    React.useEffect(() => {
        setValues({
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            userName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .userName,
            user: JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userName,
            userID: JSON.parse(localStorage.getItem('USER_CREDENTIALS')).userID,
        });
    }, []);
    return (
        <PageWrapper>
            <ChangePasswordForm
                ref={formRef}
                values={values}
                onValuesChange={onValuesChange}
                handlePasswordConfirm={handlePasswordConfirm}
                isChangingPassword={isChangingPassword}
            />
            <Modal
                title="Change password?"
                visible={confirmModal.visible}
                okText="Change"
                onOk={handlePasswordChange}
                onCancel={() =>
                    setConfirmModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }))
                }
                maskClosable={false}
                confirmLoading={isChangingPassword}
            >
                <p>
                    You will be logged out as soon as your password has been
                    changed.
                </p>
                <p>Please re-login using your new password again.</p>
            </Modal>
        </PageWrapper>
    );
}
