import axios from './axios';
import adbs from 'ad-bs-converter';
import PhotoPlaceholder from '../assets/images/placeholder-image.png';
import moment from 'moment';
import { ToWords } from 'to-words';
import { message } from 'antd';
import validator from 'validator';

// Update axios headers
export const UPDATE_AXIOS_AUTH = (
    token = localStorage.getItem('USER_ACCESS')
) =>
    (axios.defaults.headers = {
        Authorization: `Bearer ${token}`,
    });

// JSON to Form data
function buildFormData(formData, data, parentKey) {
    if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
    ) {
        Object.keys(data).forEach(key => {
            buildFormData(
                formData,
                data[key],
                parentKey ? `${parentKey}[${key}]` : key
            );
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}
export const JSON_TO_FORM_DATA = data => {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
};

export const DLT_KEYS_FROM_OBJ = (obj, keys) => {
    keys.forEach(key => delete obj[key]);
    return obj;
};

export const DLT_KEYS_FROM_OBJ_EXCEPT = (obj, keys) => {
    const objKeys = Object.keys(obj);
    objKeys.forEach(key => {
        if (!keys.includes(key)) delete obj[key];
    });
    return obj;
};

// On file change
export const ON_FILE_CHANGE = (e, mode, cbEdit, cbAdd) => {
    const file = e.target.files[0];
    const key = e.target.name;

    if (mode === 'edit') {
        cbEdit(prevValues => ({
            ...prevValues,
            [key]: file,
        }));
    } else if (mode === 'add') {
        cbAdd(prevValues => ({
            ...prevValues,
            [key]: file,
        }));
    }
};

// On checkbox change
export const ON_CHECKBOX_CHANGE = (e, mode, cbEdit, cbAdd) => {
    const key = e.target.name;
    const checked = e.target.checked;
    if (mode === 'edit') {
        cbEdit(prevValues => ({
            ...prevValues,
            [key]: checked,
        }));
    } else if (mode === 'add') {
        cbAdd(prevValues => ({
            ...prevValues,
            [key]: checked,
        }));
    }
};

// Set profile pic
export const SET_PROFILE_PIC = (photo, photoLoc, cb) => {
    if (photo) {
        let file = photo;
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
            cb(e.target.result);
        };
        return;
    }

    // Display available photo
    if (photoLoc) {
        return cb(`${process.env.REACT_APP_STATIC_FILES}/${photoLoc}`);
    }

    // Display placeholder photo
    if (!photoLoc) {
        return cb(PhotoPlaceholder);
    }
};

export const COMPARE_ARRAYS = (arr1, arr2) => {
    return arr1.some(item => arr2.includes(item));
};

// Check if a key of an object contains in both arrays
export const COMPARE_OBJECT_IN_ARRAYS = (arr1, arr2, key) => {
    const arr1Found = arr1.find(item => item[key]);
    const arr2Found = arr2.find(item => item[key]);
    if (!arr1Found || !arr2Found) return false;
    return arr1Found[key] === arr2Found[key];
};

export const SET_DEFAULT_VALUES = (
    defaultValues,
    mode,
    cbView,
    cbEdit,
    cbAdd
) => {
    if (mode === 'view' || !mode)
        cbView(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    else if (mode === 'edit')
        cbEdit(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    else if (mode === 'add')
        cbAdd(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
};

export const NEP_TO_ENG_ISO = dateString => {
    const dateNep = dateString.replace(/-/g, '/');
    const dateEng = adbs.bs2ad(dateNep);

    const yearEng = dateEng.year;
    const monthEng = dateEng.month;
    const dayEng = dateEng.day;

    const dateISO = moment(`${yearEng}-${monthEng}-${dayEng}`).format();
    return dateISO;
};

export const ENG_ISO_TO_NEP = (dateISO, lng = 'en') => {
    const year = +moment(dateISO).format('YYYY');
    const month = +moment(dateISO).format('M');
    const day = +moment(dateISO).format('D');

    const dateNep = adbs.ad2bs(`${year}/${month}/${day}`);
    const yearNep = dateNep[lng].year;
    let monthNep = dateNep[lng].month;
    monthNep = ('0' + monthNep).slice(-2);
    let dayNep = dateNep[lng].day;
    dayNep = ('0' + dayNep).slice(-2);

    const dateNepaliFull = `${yearNep}-${monthNep}-${dayNep}`;
    return dateNepaliFull;
};

export const CONTAINS_DIGITS_ONLY = value => {
    const string = value + '';
    if (!string) return true;
    if (string.match(/^[0-9]+$/) != null) return true;
    else return false;
};

export const CONTAINS_DIGITS_OR_DECIMAL_ONLY = value => {
    const string = value + '';
    if (!string) return true;
    if (/^\d+\.\d+$|^\d+$/.test(string)) return true;
    else return false;
};

export const CONTAINS_DIGITS_COMMA_OR_DECIMAL_ONLY = value => {
    const string = value + '';
    if (!string) return true;
    if (/^[0-9,.]+$/.test(string)) return true;
    else return false;
};

// PAYMENT SOURCE
// Set cash amount
export const SET_CASH_AMOUNT = (
    values,
    isCash,
    cb,
    preTotalKey = 'preTotal'
) => {
    const preTotal = +values[preTotalKey] || 0;
    const bankAmount = +values?.bankAmount || 0;
    const otherAmount = +values?.otherAmount || 0;
    if (isCash) {
        cb({ cashAmount: preTotal - bankAmount - otherAmount });
    } else {
        cb({
            ...DLT_KEYS_FROM_OBJ(values, ['cashNo', 'cashName']),
            cashAmount: null,
        });
    }
};
// Set bank amount
export const SET_BANK_AMOUNT = (
    values,
    isBank,
    cb,
    preTotalKey = 'preTotal'
) => {
    const preTotal = +values[preTotalKey] || 0;
    const cashAmount = +values?.cashAmount || 0;
    const otherAmount = +values?.otherAmount || 0;
    if (isBank) {
        cb({ bankAmount: preTotal - cashAmount - otherAmount });
    } else {
        cb({
            ...DLT_KEYS_FROM_OBJ(values, ['bankNo', 'bankName']),
            bankAmount: null,
        });
    }
};
// Set other amount
export const SET_OTHER_AMOUNT = (
    values,
    isOther,
    cb,
    preTotalKey = 'preTotal'
) => {
    const preTotal = +values[preTotalKey] || 0;
    const cashAmount = +values?.cashAmount || 0;
    const bankAmount = +values?.bankAmount || 0;
    if (isOther) {
        cb({ otherAmount: preTotal - cashAmount - bankAmount });
    } else {
        cb({
            ...DLT_KEYS_FROM_OBJ(values, [
                'otherNo',
                'otherName',
                'otherAcno',
                'otherAcName',
            ]),
            otherAmount: null,
        });
    }
};
// Set total amount
export const SET_TOTAL_AMOUNT = (cashAmount, bankAmount, otherAmount, cb) => {
    const totalAmount = cashAmount + bankAmount + otherAmount;
    const toWords = new ToWords({
        converterOptions: {
            currency: true,
        },
    });
    let words = toWords.convert(totalAmount);
    cb(prevValues => ({
        ...prevValues,
        totalAmount,
        totalAmountInWords: words,
    }));
};

// Is valid date
export const IS_VALID_DATE = (dateISO, msg = 'date') => {
    const date = moment(dateISO);
    const isValidDate = date.isValid();
    // console.log('isValidDate: ', isValidDate);
    // if (!isValidDate) message.error(`Invalid ${msg}`);
    return isValidDate;
};

// Add .00 to numbers
export const ADD_ZEROS = num => {
    if (!num) return;
    let value = +num;
    const res = num.split('.');
    if (res.length === 1 || res[1].length < 3) {
        value = value.toFixed(2);
    }
    return value;
};

export const UPDATE_VALUES_IN_STATE = (
    mode,
    valObj,
    setViewValues,
    setEditValues,
    setAddValues
) => {
    if (!mode || mode === 'view')
        setViewValues(prevValues => ({
            ...prevValues,
            ...valObj,
        }));
    else if (mode === 'edit')
        setEditValues(prevValues => ({
            ...prevValues,
            ...valObj,
        }));
    else if (mode === 'add')
        setAddValues(prevValues => ({
            ...prevValues,
            ...valObj,
        }));
};

// On date change
export const ON_DATE_CHANGE = (
    key,
    date,
    mode,
    setViewValues,
    setEditValues,
    setAddValues
) => {
    const changes = { [key]: moment(date.ad).format() };
    UPDATE_VALUES_IN_STATE(
        mode,
        changes,
        setViewValues,
        setEditValues,
        setAddValues
    );
};

// On date keyup
export const ON_DATE_KEYUP = (
    e,
    mode,
    setViewValues,
    setEditValues,
    setAddValues
) => {
    const date = e.target.defaultValue;
    if (e.keyCode === 13) {
        const key = e.target.id.split('__')[0];

        // Validation
        const dateArr = date.split('-');
        if (!dateArr[0] || !dateArr[1] || !dateArr[2])
            return message.error('Invalid date');
        const hasAlpha = dateArr.find(item => validator.isAlpha(item));
        if (hasAlpha) return message.error('Invalid date');

        // Remove datepicker
        document.getElementById('ndp-nepali-box').remove();
        document.getElementById(e.target.id).blur();

        // Update in state
        const changes = { [key]: NEP_TO_ENG_ISO(date) };
        UPDATE_VALUES_IN_STATE(
            mode,
            changes,
            setViewValues,
            setEditValues,
            setAddValues
        );
    } else if (e.keyCode !== 8) {
        const id = e.target.id;
        if (date.length === 4 || date.length === 7) {
            document.getElementById(id).value = date + '-';
        }
    }
};

// Get datepicker init config
export const GET_DATEPICKER_CONFIG = (
    onDateChange,
    dateInput,
    mode = 'view'
) => {
    return {
        ndpYear: true,
        ndpMonth: true,
        language: 'english',
        ...(onDateChange
            ? {
                  onChange: date =>
                      onDateChange(dateInput.id.split('__')[0], date, mode),
              }
            : {}),
    };
};

// Get Breadcrumb path
export const GET_BREADCRUMB = location => {
    const path = location.pathname
        .split('/')
        .filter(item => item)
        .map(item => {
            const path = item.split('-').join(' ');
            const pathCapital = path.charAt(0).toUpperCase() + path.slice(1);
            return pathCapital;
        });
    return path;
};

// Get page title
export const GET_PAGE_TITLE = location => {
    const path = location.pathname.split('/').filter(item => item);
    const pageTitleRaw = path[path.length - 1];
    const pageTitle = pageTitleRaw.split('-').join(' ');
    const pageTitleCapital =
        pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1);
    return pageTitleCapital;
};

export const GENERATE_RANDOM_NUMBER = (min = 1, max = 100000) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const ON_COLUMNS_CHANGE = (e, setColumns) => {
    const checked = e.target.checked;
    const value = e.target.value;

    if (checked) setColumns(prevValues => [...prevValues, value]);
    else setColumns(prevValues => prevValues.filter(item => item !== value));
};
