import React from 'react';
import { Form, Row, Col, Input, Radio } from 'antd';
import validator from 'validator';
// Import components
import Branches from '../Common/Geography/Branches/Branches';
import Users from '../Common/User/Users/Users';
import Staffs from '../Common/User/Staffs/Staffs';

const UserRegisterForm = React.forwardRef((props, ref) => {
    const {
        values,
        mode,
        users,
        staffs,
        getStaffs,
        onValuesChange,
        setDefaultValues,
    } = props;

    // On component mount
    React.useEffect(() => {
        if (mode !== 'view') {
            getStaffs();
            setDefaultValues(mode);
        }
    }, [mode, getStaffs, setDefaultValues]);
    return (
        <Form
            ref={ref}
            layout="vertical"
            onValuesChange={val => onValuesChange(val, mode)}
            fields={[
                { name: 'branchID', value: values?.branchID },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'userID',
                    value: values?.userID,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'userID',
                    value: values?.userID,
                },
                {
                    name: 'userName',
                    value: values?.userName,
                },
                {
                    name: 'isStaff',
                    value: values?.isStaff,
                },
                {
                    name: 'staffID',
                    value: values?.staffID,
                },
                {
                    name: 'administrator',
                    value: values?.administrator,
                },
                {
                    name: 'setBoundry',
                    value: values?.setBoundry,
                },
                {
                    name: 'transactionLimit',
                    value: values?.transactionLimit,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'password',
                    value: values?.password,
                },
                {
                    name: 'staffNo',
                    value: values?.staffNo,
                },
                {
                    name: 'staffName',
                    value: values?.staffName,
                },
            ]}
        >
            <Row gutter={20}>
                <Col xs={24} sm={12} md={7}>
                    <Branches item="branchID" />
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Branches item="branchName" />
                </Col>
                <Col xs={24} sm={12} md={9}></Col>
                {mode === 'view' && (
                    <Col xs={24} sm={12} md={7}>
                        <Users users={users} item="userID" />
                    </Col>
                )}

                <Col xs={24} sm={12} md={mode === 'view' ? 8 : 7}>
                    {mode === 'view' ? (
                        <Users users={users} item="userName" required />
                    ) : (
                        <Form.Item
                            label="User name"
                            name="userName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]}
                        >
                            <Input placeholder="Enter..." />
                        </Form.Item>
                    )}
                </Col>
                {mode !== 'view' && <Col xs={24} sm={12} md={7}></Col>}
                <Col xs={24} sm={12} md={9}></Col>

                <Col xs={24} sm={12} md={4}>
                    <Form.Item label="Is Staff" name="isStaff">
                        <Radio.Group name="isStaff">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={3}>
                    <Staffs
                        staffs={staffs}
                        item="staffID"
                        disabled={!values?.isStaff}
                        required={values?.isStaff}
                    />
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Staffs
                        staffs={staffs}
                        item="staffName"
                        disabled={!values?.isStaff}
                        required={values?.isStaff}
                    />
                </Col>
                <Col xs={24} sm={12} md={9}></Col>
                <Col xs={24} sm={12} md={7}>
                    <Form.Item label="Is Administrator" name="administrator">
                        <Radio.Group name="isStaff">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Form.Item label="Set Boundry" name="setBoundry">
                        <Radio.Group name="setBoundry">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={9}></Col>

                {mode !== 'view' && (
                    <>
                        <Col xs={24} sm={12} md={7}>
                            <Form.Item
                                label=" Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                    {
                                        min: 6,
                                        message: 'Minimum 6 characters',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password placeholder="Enter password" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item
                                label=" Confirm Password"
                                name="confirmPassword"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                    {
                                        min: 6,
                                        message: 'Minimum 6 characters',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue('password') ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'Passwords do not match'
                                                )
                                            );
                                        },
                                    }),
                                ]}
                                hasFeedback
                            >
                                <Input.Password placeholder="Confirm password" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={9}></Col>
                    </>
                )}

                <Col xs={24} sm={12} md={7}>
                    <Form.Item
                        label=" Transaction Limit"
                        name="transactionLimit"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    if (!value || validator.isInt(value)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input
                            placeholder="Enter..."
                            disabled={!values?.setBoundry}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default UserRegisterForm;
