import React from 'react';
import moment from 'moment';
import axios from '../../../../utils/axios';
import { useLocation, useHistory } from 'react-router-dom';
// Import components
import {
    DLT_KEYS_FROM_OBJ,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../../utils/functions';
import { message } from 'antd';
import { GET_MAIN_ACCOUNTS, GET_SUB_ACCOUNTS } from '../../../../utils/axios';
import PageWrapper from '../../../../components/Common/PageWrapper/PageWrapper';
import SubAccountBalanceForm from '../../../../components/Reports/BalanceQuery/SubAccountBalance/SubAccountBalanceForm/SubAccountBalanceForm';

export default function SubAccountBalance() {
    // Local states
    const branches = JSON.parse(localStorage.getItem('BRANCHES'));
    const [viewValues, setViewValues] = React.useState(null);
    const [mainAccounts, setMainAccounts] = React.useState([]);
    const [subAccounts, setSubAccounts] = React.useState([]);
    const [report, setReport] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // Variables and constants
    const location = useLocation();
    const history = useHistory();

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            fromDate: moment().format(),
            tillDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            showSubTotals: true,
            hideZeros: true,
            level: 'accountLevel',
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On values change
    const onValuesChange = val => {
        // console.log('val: ', val);
        const key = Object.keys(val)[0];
        const value = val[key];
        const values = viewValues;
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'fromDate' || key === 'tillDate') {
            return;
        } else if (key === 'branchID' || key === 'branchName') {
            if (value === 'all') {
                return updateValues({
                    branchID: 'all',
                    branchName: 'all',
                });
            }
            const branch = branches.find(item => item[key] === value);
            updateValues(branch);
        } else if (key === 'mano' || key === 'maName') {
            const acc = mainAccounts.find(item => item[key] === value);
            setReport([]);
            updateValues({
                ...DLT_KEYS_FROM_OBJ(values, ['acno', 'acName']),
                ...acc,
            });
        } else if (key === 'acno' || key === 'acName') {
            const acc = subAccounts.find(item => item[key] === value);
            updateValues(acc);
        }
        // Update values
        updateValues(val);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Form props
    const formProps = {
        branches,
        viewValues,
        onValuesChange,
        mainAccounts,
        subAccounts,
        report,
        isLoading,
        onDateChange,
        onDateKeyUp,
    };

    // Get sub accounts
    React.useEffect(() => {
        if (viewValues?.mano) {
            setSubAccounts([]);
            (async () => {
                const subAccounts = await GET_SUB_ACCOUNTS(viewValues.mano);
                setSubAccounts(subAccounts);
            })();
        }
    }, [viewValues?.mano]);

    // On component mount
    React.useEffect(() => {
        // Set default values
        setDefaultValues();
        // Get main accounts
        (async () => {
            const mainAccounts = await GET_MAIN_ACCOUNTS();
            setMainAccounts(mainAccounts);
        })();
    }, []);

    // Get report
    React.useEffect(() => {
        if (
            viewValues?.tillDate &&
            viewValues?.mano &&
            // viewValues?.acno &&
            viewValues?.branchID
        ) {
            if (viewValues?.level === 'accountLevel' && !viewValues?.acno)
                return;
            (async () => {
                let tillDate = viewValues.tillDate;
                tillDate = moment(tillDate).format('YYYY-MM-DD');
                const mano = viewValues.mano;
                const acno = viewValues.acno;
                const branchID = viewValues.branchID;

                setIsLoading(true);
                try {
                    let url;
                    if (viewValues?.level === 'accountLevel') {
                        url = `api/AccountBalance/ItemAccountBalance/${tillDate}/${tillDate}/${mano}/${acno}?branchID=${branchID}`;
                    } else if (viewValues?.level === 'mainAccountLevel') {
                        url = `api/AccountBalance/AccountBalance/${tillDate}/${tillDate}/${mano}?branchID=${branchID}`;
                    }
                    const res = await axios.get(
                        `${process.env.REACT_APP_HOST}/${url}`
                    );

                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    console.log(res.data.data);
                    setReport(res.data.data);
                } catch (err) {
                    console.error(err, 'Failed to get report');
                    message.error('Something went wrong. Please try again!');
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [
        viewValues?.tillDate,
        viewValues?.mano,
        viewValues?.acno,
        viewValues?.branchID,
        viewValues?.level,
    ]);

    // Get acno from url
    React.useEffect(() => {
        const search = location.search;
        if (!search) return;
        const acno = new URLSearchParams(search).get('acno');
        const level = new URLSearchParams(search).get('level');
        const mano = acno?.substring(0, 3);
        const mainAcc = mainAccounts?.find(item => item.mano === mano);

        if (mainAcc) {
            setViewValues(prevValues => ({
                ...prevValues,
                ...mainAcc,
                level,
            }));
        }

        if (subAccounts.length) {
            const acc = subAccounts?.find(item => item.acno === acno);
            setViewValues(prevValues => ({
                ...prevValues,
                ...acc,
            }));
            history.push('/sub-account-balance');
        }
    }, [location.search, mainAccounts, subAccounts, history]);

    return (
        <PageWrapper>
            <SubAccountBalanceForm {...formProps} />
        </PageWrapper>
    );
}
