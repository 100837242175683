import React from 'react';
import { Row, Col, Form, Space, Button, Collapse } from 'antd';
import moment from 'moment';
import { GET_MEMBER_LOAN_PROPERTIES } from '../../../../utils/axios';
import { ENG_ISO_TO_NEP } from '../../../../utils/functions';
import Loading from '../../../../components/Common/Loading/Loading';

export default function MemberInfoLoanDetail(props) {
    // Destructuring
    const { viewValues } = props;

    // Local states
    const [loanProperties, setLoanProperties] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // On component mount
    React.useEffect(() => {
        if (!viewValues?.memberNO) return;
        setIsLoading(true);
        (async () => {
            const loanProperties = await GET_MEMBER_LOAN_PROPERTIES(
                viewValues.memberNO
            );
            console.log('loanProperties: ', loanProperties);
            setLoanProperties(loanProperties);
            setIsLoading(false);
        })();
    }, [viewValues?.memberNO]);

    // Loading indicator
    if (isLoading) return <Loading />;

    return (
        <Collapse>
            {loanProperties?.map((item, i) => (
                <Collapse.Panel
                    header={`${item.acName} (${item.loanNo}) - [Current Balance: Rs ${item.currentBalance} ]`}
                    key={i + 1}
                    style={{ fontSize: '13px' }}
                >
                    <DepositDetail key={i} data={item} />
                </Collapse.Panel>
            ))}
        </Collapse>
    );
}

export const DepositDetail = props => {
    // Destructuring
    const { data } = props;
    return (
        <div>
            <Row gutter={20}>
                <Col span={20}>
                    <Row gutter={20} style={{ fontWeight: 'bold' }}>
                        <Col span={10}>
                            <Form.Item
                                label="Account Name"
                                labelCol={{ span: 10 }}
                                labelAlign="left"
                            >
                                <span style={{ marginLeft: '-4px' }}>
                                    {data?.acName || '--'}
                                </span>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item>{data?.acno || '--'}</Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Loan No"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {/* <span style={{ marginLeft: '-3px' }}> */}
                                {data?.loanNo || '--'}
                                {/* </span> */}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Project Name"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.projectName || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Entrance Date"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {moment(data?.entranceDate).isValid() &&
                                    ENG_ISO_TO_NEP(data?.entranceDate)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Agreed Loan"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.agreedLoan || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Agreement Date"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {moment(data?.agreementDate).isValid() &&
                                    ENG_ISO_TO_NEP(data?.agreementDate)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Final Date"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.finalDate || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Disabled"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.disabled ? 'Yes' : 'No'}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Rate of Interest"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.rateOfInterest + '' || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Interest Start Date"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {moment(data?.interestStartDate).isValid() &&
                                    ENG_ISO_TO_NEP(data?.interestStartDate)}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="On Saving"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.onSaving ? 'Yes' : 'No'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Saving Type"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.savingType || '--'}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Installment Start Date"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {moment(data?.installmentStartDate).isValid() &&
                                    ENG_ISO_TO_NEP(data?.installmentStartDate)}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Mobile No"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.mobileNO || '--'}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Address"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.address || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Loan Payment System"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.lps || '--'}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Interest Payment System"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.ips || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Current Balance"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.currentBalance + '' || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Due Balance"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.dueBalance + '' || '--'}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Due Interest"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.dueInterest + '' || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Due Penalty"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.duePenalty + '' || '--'}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Delinquency Status"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.delinquencyStatus || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Total Due Days"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.totalDuedays + '' || '--'}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Installment Due Days"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.installmentDueDays + '' || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Kista Due From"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {moment(data?.kistaDueFrom).isValid() &&
                                moment(data?.kistaDueFrom).year() > 1990
                                    ? ENG_ISO_TO_NEP(data?.kistaDueFrom)
                                    : '--'}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Installment Period"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.instalmentPeriod || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Loan Payment Fund"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.loanPaymentFund + '' || '--'}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Paid Amount"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.pbdAmount + '' || '--'}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Kista Rate"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                {data?.kistaRate + '' || '--'}
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={4}></Col>
            </Row>
            <Space style={{ marginTop: '.5rem' }}>
                <Button type="default" size="small">
                    Edit Account
                </Button>
                <Button type="default" size="small">
                    Statement
                </Button>
                <Button type="default" size="small">
                    Deposit
                </Button>
                <Button type="default" size="small">
                    Withdraw
                </Button>
                <Button type="default" size="small">
                    Bal. Certificate
                </Button>
                <Button type="default" size="small">
                    FD Certificate
                </Button>
                <Button type="default" size="small">
                    Guarantee Info
                </Button>
                <Button type="default" size="small">
                    Close Account
                </Button>
            </Space>
        </div>
    );
};
