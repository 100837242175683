import React from 'react';
import moment from 'moment';
import { Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
    GET_MEMBERS_BY_BRANCH,
    GET_KYM_MEMBER_DETAIL,
    GET_RELATIONS_LIST,
    SAVE_KYM,
} from '../../utils/axios';
// Component
import PageWrapper from '../../components/Common/PageWrapper/PageWrapper';
import KYMForm from '../../components/KYM/KYMForm/KYMForm';

export default function KYM() {
    // Local states
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [viewValues, setViewValues] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [relations, setRelations] = React.useState([]);

    // Destructuring
    const { confirm } = Modal;

    // Utils
    const onKymSubmit = () => {
        confirm({
            title: 'Do you want to save the KYM form?',
            icon: <ExclamationCircleOutlined />,
            // content:
            //     'When clicked the OK button, this dialog will be closed after 1 second',

            onOk() {
                return new Promise((resolve, reject) => {
                    const payload = {
                        enteredBy: JSON.parse(
                            localStorage.getItem('USER_CREDENTIALS')
                        ).userName,
                        ...viewValues,
                    };
                    console.log('payload: ', payload);
                    (async () => {
                        const res = await SAVE_KYM(payload);
                        console.log('res: ', res);
                        resolve();
                    })();
                    // setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('Oops errors!'));
            },

            onCancel() {},
        });
    };

    // On values change
    const onValuesChange = val => {
        console.log('val: ', val);
        const key = Object.keys(val)[0];
        const value = val[key];
        // const values = viewValues;

        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        if (key === 'branchName' || key === 'branchID') {
            const branch = branches.find(item => item[key] === value);
            return updateValues(branch);
        }
        // Update values
        updateValues(val);
    };

    const setDefaultValues = () => {
        const defaultValues = {
            kymDate: moment().format(),
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // Get members
    React.useEffect(() => {
        if (!viewValues?.branchID) return;
        setMembers([]);
        (async () => {
            const members = await GET_MEMBERS_BY_BRANCH(viewValues?.branchID);
            setMembers(members);
        })();
    }, [viewValues?.branchID]);

    // Get KYM member detail
    React.useEffect(() => {
        (async () => {
            const memberDetail = await GET_KYM_MEMBER_DETAIL(
                viewValues?.memberNO
            );

            if (!moment(memberDetail?.dateOfBirth).isValid()) {
                message.error('Invalid date of birth');
            }

            setViewValues(prevValues => ({
                ...prevValues,
                ...memberDetail,
            }));
        })();
    }, [viewValues?.memberNO]);

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
        // Get and set relations
        (async () => {
            const relations = await GET_RELATIONS_LIST();
            console.log('relations: ', relations);
            setRelations(relations);
        })();
    }, []);

    // Form props
    const formProps = {
        branches,
        members,
        onValuesChange,
        values: viewValues,
        relations,
        onKymSubmit,
    };
    return (
        <PageWrapper>
            <KYMForm {...formProps} />
        </PageWrapper>
    );
}
