import React from 'react';
// Import from dependencies
import { message } from 'antd';
import { useRecoilValue } from 'recoil';
import {
    cashCounters as cashCountersAtom,
    banks as banksAtom,
    otherAccounts as otherAccountsAtom,
    otherItems as otherItemsAtom,
} from '../../../recoil/atoms';
// Import utils
import { ToWords } from 'to-words';
import axios, {
    GET_MEMBERS_BY_BRANCH,
    GET_MEMBER_DEPOSIT_INFO_SHORT,
    GET_REMITS,
} from '../../../utils/axios';
import moment from 'moment';
import {
    SET_DEFAULT_VALUES,
    SET_CASH_AMOUNT,
    SET_BANK_AMOUNT,
    SET_OTHER_AMOUNT,
    DLT_KEYS_FROM_OBJ,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../utils/functions';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import RemitDuesReceivedForm from '../../../components/Remittance/RemitDuesReceived/RemitDuesReceivedForm/RemitDuesReceivedForm';

export default function RemitPayment() {
    // Global states
    const [remits, setRemits] = React.useState([]);
    const cashCounters = useRecoilValue(cashCountersAtom);
    const banks = useRecoilValue(banksAtom);
    const otherAccounts = useRecoilValue(otherAccountsAtom);
    const otherItems = useRecoilValue(otherItemsAtom);
    // Local states
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [members, setMembers] = React.useState([]);
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);

    // Refs
    const formRef = React.useRef();

    // On view form values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];
        let values;
        if (mode === 'view') values = viewValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (key === 'remitID' || key === 'remitName') {
            const remit = remits.find(remit => remit[key] === value);
            console.log('remit: ', remit);
            updateValues({
                remitID: remit.remitID,
                remitName: remit.remitName,
                remitAddress: remit.address,
                senderName: remit.remitName,
                paidBy: remit.remitName,
                mobileNo: remit.contactNo,
            });
            // handleNew();
        } else if (key === 'memberNO' || key === 'memberName') {
            const member = members.find(member => member[key] === value);
            console.log('member: ', member);
            updateValues(member);
        } else if (key === 'cashNo' || key === 'cashName') {
            const counterKey = key === 'cashNo' ? 'itemCode' : 'itemName';
            const counter = cashCounters.find(
                counter => counter[counterKey] === value
            );
            return updateValues({
                cashNo: counter.itemCode,
                cashName: counter.itemName,
            });
        } else if (key === 'bankNo' || key === 'bankName') {
            const bankKey = key === 'bankNo' ? 'itemCode' : 'itemName';
            const bank = banks.find(bank => bank[bankKey] === value);

            return updateValues({
                bankNo: bank.itemCode,
                bankName: bank.itemName,
            });
        } else if (key === 'otherAcno' || key === 'otherAcName') {
            const accKey = key === 'otherAcno' ? 'acno' : 'acName';
            const acc = otherAccounts.find(acc => acc[accKey] === value);
            console.log('acc: ', acc);

            return updateValues({
                ...DLT_KEYS_FROM_OBJ(viewValues, ['otherName', 'otherNo']),
                otherAcno: acc.acno,
                otherAcName: acc.acName,
                otherMano: acc.mano,
            });
        } else if (key === 'otherNo' || key === 'otherName') {
            const otherKey = key === 'otherNo' ? 'itemCode' : 'itemName';
            const other = otherItems.find(item => item[otherKey] === value);

            return updateValues({
                otherNo: other.itemCode,
                otherName: other.itemName,
            });
        } else if (key === 'transDate') {
            return;
        } else if (key === 'isCash') {
            SET_CASH_AMOUNT(values, value, updateValues);
        } else if (key === 'isBank') {
            SET_BANK_AMOUNT(values, value, updateValues);
        } else if (key === 'isOther') {
            SET_OTHER_AMOUNT(values, value, updateValues);
        }
        // Update values
        updateValues(val);
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            transDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            isClearance: false,
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            undefined,
            undefined
        );
    }, []);

    // On form submit
    const onFormSubmit = val => {
        console.log(viewValues, 'viewValues');
        // console.log(val, 'val');
        setIsProcessing(true);
        const payload = {
            transDate: viewValues.transDate,
            enteredBy: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .userName,
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            transactionType: 'Remittance Transaction',
            description: val.description,
            creditTrans: [
                ...(viewValues?.dueReceived
                    ? [
                          {
                              mano: '120',
                              acno: '120.15',
                              bvrcno: '',
                              itemCode: viewValues.remitID,
                              itemName: viewValues.remitName,
                              itemLocation: viewValues?.remitAddress,
                              receivedPaidBy: viewValues?.paidBy,
                              remarks1: '',
                              remarks3: viewValues?.mobileNo,
                              rate: viewValues.dueReceived,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: viewValues.dueReceived,
                              quantity: '1',
                              particulars: `Remit Due Paid By ${viewValues.remitName}`,
                          },
                      ]
                    : []),
                ...(viewValues?.commissionReceived
                    ? [
                          {
                              mano: '160',
                              acno: '160.30',
                              bvrcno: '',
                              itemCode: viewValues.remitID,
                              itemName: viewValues?.remitName,
                              itemLocation: viewValues?.remitAddress,
                              receivedPaidBy: viewValues?.paidBy,
                              remarks1: '',
                              remarks3: viewValues?.mobileNo,
                              rate: viewValues?.commissionReceived,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: viewValues?.commissionReceived,
                              quantity: '1',
                              particulars: `Commission Received From ${viewValues?.remitName}`,
                          },
                      ]
                    : []),
            ],
            debitTrans: [
                ...(viewValues?.isCash
                    ? [
                          {
                              mano: '080',
                              acno: '080.01',
                              bvrcno: viewValues?.cashBvrcno,
                              itemCode: viewValues?.cashNo,
                              itemName: viewValues?.cashName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues?.paidBy,
                              remarks1: '0',
                              rate: viewValues?.cashAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: viewValues?.cashAmount,
                              quantity: '1',
                              particulars: `Remit Due Received By Cash`,
                          },
                      ]
                    : []),
                ...(viewValues?.isBank
                    ? [
                          {
                              mano: bankAccounts[0].mano,
                              acno: bankAccounts[0].acno,
                              bvrcno: viewValues?.bankBvrcno,
                              itemCode: viewValues?.bankNo,
                              itemName: viewValues?.bankName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues?.senderName,
                              remarks1: '0',
                              rate: viewValues?.bankAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: viewValues?.bankAmount,
                              quantity: '1',
                              particulars: `Remit Due Received By - ${viewValues?.bankName}`,
                          },
                      ]
                    : []),
                ...(viewValues?.isOther
                    ? [
                          {
                              mano: viewValues.otherMano,
                              acno: viewValues.otherAcno,
                              bvrcno: '',
                              itemCode: viewValues?.otherNo,
                              itemName: viewValues?.otherName,
                              itemLocation: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              receivedPaidBy: viewValues?.senderName,
                              remarks1: '0',
                              rate: viewValues?.otherAmount,
                              interBranchID: JSON.parse(
                                  localStorage.getItem('USER_CREDENTIALS')
                              ).branchID,
                              amount: viewValues?.otherAmount,
                              quantity: '1',
                              particulars: `Remit Due Received By - ${viewValues?.otherAcName}`,
                          },
                      ]
                    : []),
            ],
        };
        console.log('payload: ', payload);
        axios
            .post(
                `${process.env.REACT_APP_HOST}/api/AllTransactions/DueReceived`,
                payload
            )
            .then(res => {
                if (res.data.statusCode !== 0) {
                    setIsProcessing(false);
                    return message.error(res.data.statusMessage);
                }
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...res.data.data,
                }));
                message.success(res.data.statusMessage);
            })
            .catch(err => {
                console.error(err, 'Failed to process remit dues received');
                message.error('Something went wrong. Please try again!');
                setIsProcessing(false);
            });
    };

    // Handle new
    const handleNew = () => {
        setViewValues(null);
        setDefaultValues('view');
        setIsProcessing(false);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Form props
    const formProps = {
        ref: formRef,
        onValuesChange,
        setDefaultValues,
        members,
        cashCounters,
        banks,
        otherAccounts,
        otherItems,
        onFormSubmit,
        isProcessing,
        handleNew,
        remits,
        onDateChange,
        onDateKeyUp,
    };

    // Get member detail
    React.useEffect(() => {
        if (viewValues?.memberNO) {
            (async () => {
                const memberDetail = await GET_MEMBER_DEPOSIT_INFO_SHORT(
                    viewValues?.memberNO
                );
                console.log('memberDetail: ', memberDetail);
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...memberDetail,
                }));
            })();
        }
    }, [viewValues?.memberNO]);

    // Get members by branch
    React.useEffect(() => {
        if (viewValues?.branchID) {
            // Get members by branch
            (async () => {
                const members = await GET_MEMBERS_BY_BRANCH(
                    viewValues?.branchID
                );
                setMembers(members);
            })();
        }
    }, [viewValues?.branchID]);

    // Get Current share
    React.useEffect(() => {
        if (viewValues?.remitID) {
            axios
                .get(
                    `${
                        process.env.REACT_APP_HOST
                    }/api/CheckBalance/ItemBalance/${
                        JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                            .branchID
                    }/120/120.15/${viewValues.remitID}`
                )
                .then(res => {
                    console.log(res);
                    setViewValues(prevValues => ({
                        ...prevValues,
                        oldBalance: res.data.data,
                    }));
                })
                .catch(err => {
                    console.error(err, 'Failed to get Current share amount');
                    message.error('Failed to get Current share amount');
                });
        }
    }, [viewValues?.remitID]);

    // Set total amount
    React.useEffect(() => {
        const dueReceived = +viewValues?.dueReceived || 0;
        const commissionReceived = +viewValues?.commissionReceived || 0;
        const preTotal = dueReceived + commissionReceived;
        const toWords = new ToWords({
            converterOptions: {
                currency: true,
            },
        });
        let totalAmountInWords = toWords.convert(preTotal);
        setViewValues(prevValues => ({
            ...prevValues,
            preTotal,
            totalAmountInWords,
        }));
    }, [
        viewValues?.dueReceived,
        viewValues?.commissionReceived,
        setViewValues,
    ]);

    // Get and set pay receipt bank accounts
    React.useEffect(() => {
        if (viewValues?.isClearance !== undefined && viewValues?.branchID) {
            axios
                .get(
                    `${
                        process.env.REACT_APP_HOST
                    }/api/PayReceipt/BankAccounts/${viewValues?.branchID}/${
                        JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                            .userName
                    }}?isClearance=${viewValues?.isClearance}`
                )
                .then(res => {
                    setBankAccounts(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get banks');
                    message.error('Failed to get banks');
                });
        }
    }, [viewValues?.isClearance, viewValues?.branchID]);

    // Get and set Remits
    React.useEffect(() => {
        (async () => {
            const remits = await GET_REMITS(viewValues?.branchID);
            setRemits(remits);
        })();
    }, [setRemits, viewValues?.branchID]);

    return (
        <PageWrapper>
            <RemitDuesReceivedForm
                {...formProps}
                mode="view"
                values={viewValues}
            />
        </PageWrapper>
    );
}
