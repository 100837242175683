import React from 'react';
import {
    Table,
    Radio,
    Row,
    Col,
    Form,
    Input,
    Checkbox,
    Pagination,
    Button,
    Dropdown,
    Menu,
    Space,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../utils/functions';

const ActiveInactiveMembersForm = React.forwardRef((props, ref) => {
    // Destructure props
    const {
        tableData,
        onValuesChange,
        onDateChange,
        onDateKeyUp,
        onPaginationChange,
        isLoading,
        values,
        onCheckboxChange,
        showSaveStateConfirm,
        indeterminate,
        onCheckAllChange,
        checkAll,
    } = props;

    // Local states
    const [columnsDropdown, setColumnsDropdown] = React.useState(false);
    const [columnsList, setColumnsList] = React.useState(['']);

    // Variables and Constants
    // Table columns
    const columnsDropdownMenu = (
        <Menu onClick={() => setColumnsDropdown(true)}>
            <Menu.Item key="0">
                <Checkbox onChange={onColumnsListChange} value="address">
                    Address
                </Checkbox>
            </Menu.Item>
            <Menu.Item key="1">
                <Checkbox onChange={onColumnsListChange} value="mobileNo">
                    Mobile No
                </Checkbox>
            </Menu.Item>
            <Menu.Item key="2">
                <Checkbox onChange={onColumnsListChange} value="depositBalance">
                    Deposit Balance
                </Checkbox>
            </Menu.Item>
            <Menu.Item key="3">
                <Checkbox onChange={onColumnsListChange} value="loanBalance">
                    Loan Balance
                </Checkbox>
            </Menu.Item>
            <Menu.Item key="3">
                <Checkbox onChange={onColumnsListChange} value="shareBalance">
                    Share Balance
                </Checkbox>
            </Menu.Item>
        </Menu>
    );

    // NEPALI DATEPICKER
    // From date
    const fromDateInput = document.getElementById('fromDate');
    fromDateInput &&
        fromDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, fromDateInput)
        );

    // To date
    const toDateInput = document.getElementById('toDate');
    toDateInput &&
        toDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, toDateInput)
        );

    // TABLE
    const dataSource =
        tableData?.dataList?.length &&
        tableData.dataList.map((member, i) => {
            return {
                key: i + '',
                ...member,
            };
        });

    // UTILS
    // On Columns list change
    function onColumnsListChange(e) {
        const checked = e.target.checked;
        const value = e.target.value;

        if (checked) setColumnsList(prevValues => [...prevValues, value]);
        else
            setColumnsList(prevValues =>
                prevValues.filter(item => item !== value)
            );
    }

    const columns = [
        {
            title: () => (
                <>
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                    >
                        Disabled
                    </Checkbox>
                </>
            ),
            dataIndex: 'disabled',
            key: 'disabled',
            render: (bool, data) => (
                <Checkbox
                    id={`stateCheckbox_${+data.key}`}
                    checked={bool}
                    onChange={onCheckboxChange}
                ></Checkbox>
            ),
            width: '100px',
        },
        {
            title: 'Member No',
            dataIndex: 'memberNo',
            key: 'memberNo',
        },
        {
            title: 'Member Name',
            dataIndex: 'memberName',
            key: 'memberName',
        },
        ...(columnsList.includes('address')
            ? [
                  {
                      title: 'Address',
                      dataIndex: 'address',
                      key: 'address',
                  },
              ]
            : []),
        ...(columnsList.includes('mobileNo')
            ? [
                  {
                      title: 'Mobile No',
                      dataIndex: 'mobileNo',
                      key: 'mobileNo',
                  },
              ]
            : []),
        ...(columnsList.includes('depositBalance')
            ? [
                  {
                      title: 'Deposit Balance',
                      dataIndex: 'depositBalance',
                      key: 'depositBalance',
                  },
              ]
            : []),
        ...(columnsList.includes('loanBalance')
            ? [
                  {
                      title: 'Loan Balance',
                      dataIndex: 'loanBalance',
                      key: 'loanBalance',
                  },
              ]
            : []),
        ...(columnsList.includes('shareBalance')
            ? [
                  {
                      title: 'Share Balance',
                      dataIndex: 'shareBalance',
                      key: 'shareBalance',
                  },
              ]
            : []),
        {
            title: 'Total Balance',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: 'Trans Date',
            dataIndex: 'transdate',
            key: 'transdate',
            render: text => ENG_ISO_TO_NEP(text),
        },
    ];
    return (
        <>
            <Form
                ref={ref}
                onValuesChange={onValuesChange}
                fields={[
                    {
                        name: 'fromDate',
                        value: ENG_ISO_TO_NEP(values?.fromDate),
                    },
                    {
                        name: 'toDate',
                        value: ENG_ISO_TO_NEP(values?.toDate),
                    },
                ]}
            >
                <div className="d-flex-center">
                    <Form.Item name="state">
                        <Radio.Group
                            buttonStyle="solid"
                            style={{
                                marginTop: '.5rem',
                                marginBottom: '.5rem',
                            }}
                            size="small"
                        >
                            <Radio.Button value="ActiveMember">
                                Active
                            </Radio.Button>
                            <Radio.Button value="InActiveMember">
                                Inactive
                            </Radio.Button>
                            <Radio.Button value="DisabledMember">
                                Disabled
                            </Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Button
                        type="primary"
                        size="small"
                        onClick={showSaveStateConfirm}
                        style={{ marginLeft: 'auto' }}
                    >
                        Save state
                    </Button>
                </div>
                <Row gutter={20}>
                    <Col span={6}>
                        <Form.Item label="Min Balance" name="Minibalance">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Max Balance" name="MaxBalance">
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="From" name="fromDate">
                            <input
                                type="text"
                                className="ant-input"
                                onKeyUp={e => onDateKeyUp(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="To" name="toDate">
                            <input
                                type="text"
                                className="ant-input"
                                onKeyUp={e => onDateKeyUp(e)}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="align-right">
                    <Dropdown
                        className="fake-link"
                        visible={columnsDropdown}
                        overlay={columnsDropdownMenu}
                        trigger={['click']}
                        onVisibleChange={flag => setColumnsDropdown(flag)}
                    >
                        <Space>
                            Columns
                            <DownOutlined />
                        </Space>
                    </Dropdown>
                </div>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    size="small"
                    pagination={false}
                    loading={isLoading}
                />
            </Form>
            <Pagination
                style={{ marginTop: '.5rem' }}
                size="small"
                total={tableData?.totalRecords}
                showSizeChanger
                onChange={onPaginationChange}
            />
        </>
    );
});

export default ActiveInactiveMembersForm;
