import React from 'react';
import { message, Button, Modal } from 'antd';
// Import utils
import axios from '../../utils/axios';
import { UPDATE_AXIOS_AUTH } from '../../utils/functions';
// Import components
import PageWrapper from '../../components/Common/PageWrapper/PageWrapper';
import {
    default as StaffTypesFormView,
    default as StaffTypesFormEdit,
    default as StaffTypesFormAdd,
} from '../../components/StaffTypes/StaffTypesForm';
import BottomActionBarFixed from '../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function StaffTypes() {
    // Local states
    const [staffTypes, setStaffTypes] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Get staff types
    const getStaffTypes = () => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/StaffTypePost/GetStaffType`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setStaffTypes(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get staff types');
                message.error('Something went wrong. Please try again!');
            });
    };

    // VIEW FORM
    const onValuesChange = (val, mode) => {
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (val.hasOwnProperty('staffTypeID')) {
            const type = staffTypes.find(
                type => type.staffTypeID === val.staffTypeID
            );

            updateValues(type);
        } else if (val.hasOwnProperty('staffType')) {
            const type = staffTypes.find(
                type => type.staffType === val.staffType
            );
            updateValues(type);
        }
        updateValues(val);
    };

    // EDIT ACCOUNT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current.validateFields().then(() => {
            console.log(editValues);
            setEditModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));
            axios
                .patch(
                    `${process.env.REACT_APP_HOST}/api/StaffTypePost/UpdatePost/${editValues?.staffTypeID}`,
                    editValues
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);

                    setViewValues(res.data.data);
                    setEditModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    message.success('Updated!');
                })
                .catch(err => {
                    console.error(err, 'Failed to update staff type');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setEditModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // On edit values change
    const onEditValuesChange = val => {
        console.log(val);
        setEditValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // ADD ACCOUNT MODAL
    // On add modal open
    const onAddModalOpen = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On add modal ok
    const onAddModalOk = () => {
        formRef.current.validateFields().then(() => {
            setAddModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));
            axios
                .post(
                    `${process.env.REACT_APP_HOST}/api/StaffTypePost/InsertStaffType`,
                    addValues
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    const data = res.data.data;
                    setStaffTypes([...staffTypes, ...data]);
                    setViewValues(data);
                    setAddModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    setAddValues(null);
                    message.success('New staff type created!');
                })
                .catch(err => {
                    console.error(err, 'Failed to create staff type');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setAddModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };
    // On add values change
    const onAddValuesChange = val => {
        setAddValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // DELETE ACCOUNT MODAL
    // On delete modal open
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On delete modal ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/StaffTypePost/DeletePost/${viewValues?.staffTypeID}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setStaffTypes(
                    [...staffTypes].filter(
                        type => type.staffTypeID !== viewValues?.staffTypeID
                    )
                );
                setViewValues(null);
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Staff type deleted!');
            })
            .catch(err => {
                console.error(err, 'Failed to delete staff type');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };
    // On add modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // COMMON
    // On checkbox change
    const onCheckboxChange = (e, mode) => {
        const key = e.target.name;
        const checked = e.target.checked;

        if (mode === 'edit') {
            setEditValues(prevValues => ({
                ...prevValues,
                [key]: checked,
            }));
        } else if (mode === 'add') {
            setAddValues(prevValues => ({
                ...prevValues,
                [key]: checked,
            }));
        }
    };

    // On component mount
    React.useEffect(() => {
        UPDATE_AXIOS_AUTH();
        // Get staff types
        getStaffTypes();
    }, []);

    return (
        <PageWrapper>
            {/* View account */}
            <StaffTypesFormView
                ref={formRef}
                mode="view"
                staffTypes={staffTypes}
                values={viewValues}
                onValuesChange={val => onValuesChange(val, 'view')}
            />
            {/* Edit modal */}
            <Modal
                title="Edit staff type"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                onOk={onEditModalOk}
                onCancel={onEditModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: editModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <StaffTypesFormEdit
                    ref={formRef}
                    mode="edit"
                    staffTypes={staffTypes}
                    values={editValues}
                    onValuesChange={onEditValuesChange}
                    onCheckboxChange={onCheckboxChange}
                />
            </Modal>
            {/* Add modal */}
            <Modal
                title="Add staff type"
                visible={addModal.visible}
                confirmLoading={addModal.confirmLoading}
                onOk={onAddModalOk}
                onCancel={onAddModalCancel}
                okText="Create"
                cancelButtonProps={{
                    disabled: addModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <StaffTypesFormAdd
                    ref={formRef}
                    mode="add"
                    staffTypes={staffTypes}
                    values={addValues}
                    onValuesChange={onAddValuesChange}
                    onCheckboxChange={onCheckboxChange}
                />
            </Modal>
            {/* Delete modal */}
            <Modal
                title="Delete staff type?"
                visible={deleteModal.visible}
                confirmLoading={deleteModal.confirmLoading}
                onOk={onDeleteModalOk}
                onCancel={onDeleteModalCancel}
                okText="Delete"
                cancelButtonProps={{
                    disabled: deleteModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>The staff type and its data will be deleted forever</p>
            </Modal>
            {/* Actions bar */}
            <BottomActionBarFixed>
                <Button
                    type="primary"
                    onClick={onEditModalOpen}
                    disabled={!viewValues?.staffTypeID}
                >
                    Edit
                </Button>
                <Button type="primary" onClick={onAddModalOpen}>
                    Add
                </Button>
                <Button
                    type="primary"
                    onClick={onDeleteModalOpen}
                    disabled={!viewValues?.staffTypeID}
                >
                    Delete
                </Button>
            </BottomActionBarFixed>
        </PageWrapper>
    );
}
