import React from 'react';
// Import from dependencies
import {
    Form,
    Select,
    Row,
    Col,
    Input,
    Table,
    Button,
    Spin,
    Space,
    Popconfirm,
    Image,
} from 'antd';
import { Link } from 'react-router-dom';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../utils/functions';

const ShareCertificateForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        onValuesChange,
        values,
        mode,
        members,
        onDateChange,
        onDateKeyUp,
        shareCertificate,
        shareMode,
        onShareIssueNewClick,
        onShareIssueCancel,
        onShareIssue,
        onShareRecordClick,
        onShareDeleteConfirm,
    } = props;

    // Variables and Constants
    const dataSource = shareCertificate?.shareInfo?.certificateList.map(
        (item, i) => {
            return {
                key: i + 1 + '',
                ...item,
            };
        }
    );

    const columns = [
        {
            title: 'Certificate No',
            dataIndex: 'certiNo',
            key: 'certiNo',
            width: '150px',
            render: (text, record) => (
                <Link onClick={() => onShareRecordClick(record)}>{text}</Link>
            ),
        },
        {
            title: 'No. of Shares',
            dataIndex: 'noOfShare',
            key: 'noOfShare',
            width: '150px',
            render: (text, record) => (
                <Link onClick={() => onShareRecordClick(record)}>{text}</Link>
            ),
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
            render: (text, record) => (
                <Link onClick={() => onShareRecordClick(record)}>{text}</Link>
            ),
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            render: (text, record) => (
                <Link onClick={() => onShareRecordClick(record)}>{text}</Link>
            ),
        },
        {
            title: 'Issued Date',
            dataIndex: 'issueddate',
            key: 'issueddate',
            render: (text, record) => (
                <Link onClick={() => onShareRecordClick(record)}>
                    {ENG_ISO_TO_NEP(text)}
                </Link>
            ),
        },
    ];

    // NEPALI DATEPICKER
    // Formed date
    const issueddateInput = document.getElementById('issueddate');
    issueddateInput &&
        issueddateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, issueddateInput, mode)
        );

    return (
        <Form
            ref={ref}
            layout="horizontal"
            onValuesChange={onValuesChange}
            fields={[
                {
                    name: 'memberNO',
                    value: values?.memberNO,
                },
                {
                    name: 'memberName',
                    value: values?.memberName,
                },
                {
                    name: 'certiNo',
                    value: values?.certiNo,
                },
                {
                    name: 'from',
                    value: values?.from,
                },
                {
                    name: 'to',
                    value: values?.to,
                },
                {
                    name: 'noOfShare',
                    value: values?.noOfShare,
                },
                {
                    name: ['issueddate'],
                    value:
                        values?.issueddate && ENG_ISO_TO_NEP(values.issueddate),
                },
            ]}
        >
            <Row gutter={20}>
                <Col span={16}>
                    <Row gutter={20}>
                        <Col span={14}>
                            <Form.Item
                                label="Member ID"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                                name="memberNO"
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members?.length &&
                                        members?.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.memberNO}
                                            >
                                                {item.memberNO}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item name="memberName">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members?.length &&
                                        members?.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.memberName}
                                            >
                                                {item.memberName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={14}>
                            <Form.Item
                                label="Member's Name"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {shareCertificate?.memberName}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item>
                                <div className="ant-input ant-input--fake">
                                    {shareCertificate?.unName}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={14}>
                            <Form.Item
                                label="Father/Husband"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {shareCertificate?.fatherName}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item>
                                <div className="ant-input ant-input--fake">
                                    {shareCertificate?.unFather}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={14}>
                            <Form.Item
                                label="Address"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {shareCertificate?.memAddress}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item>
                                <div className="ant-input ant-input--fake">
                                    {shareCertificate?.unmemAddress}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={14}>
                            <Form.Item
                                label="Total No. of Shares"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {shareCertificate?.shareInfo?.totalShareNo}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item label="Total Share Amount">
                                <div className="ant-input ant-input--fake">
                                    {shareCertificate?.shareInfo?.sharAmount}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Image
                        width={150}
                        src={`${process.env.REACT_APP_STATIC_FILES}/${shareCertificate?.photoloc}`}
                    />
                </Col>
            </Row>

            <div style={{ marginTop: '1rem' }}>
                <Row gutter={20}>
                    <Col span={8}>
                        <h4
                            style={{
                                marginBottom: '1rem',
                            }}
                        >
                            <strong>Add Shares in Certificate</strong>
                        </h4>
                        <Spin spinning={shareMode === 'loading'}>
                            <Form.Item
                                label="No. of Shares in Certificate"
                                labelCol={{ span: 11 }}
                                labelAlign="left"
                                name="noOfShare"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                value <=
                                                    shareCertificate?.shareInfo
                                                        ?.totalUndefiend
                                            ) {
                                                if (value) {
                                                    if (+value === 0)
                                                        return Promise.reject(
                                                            new Error(
                                                                'Invalid number of shares'
                                                            )
                                                        );
                                                    return Promise.resolve();
                                                }
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(
                                                new Error('Amount overflow')
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    disabled={
                                        !values?.memberNO ||
                                        shareMode !== 'issue'
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Share Certificate No."
                                labelCol={{ span: 11 }}
                                labelAlign="left"
                                name="certiNo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Input
                                    disabled={
                                        !values?.memberNO ||
                                        shareMode !== 'issue'
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Share No. From"
                                labelCol={{ span: 11 }}
                                labelAlign="left"
                                name="from"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Input
                                    disabled={
                                        !values?.memberNO ||
                                        shareMode !== 'issue'
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Share No. To"
                                labelCol={{ span: 11 }}
                                labelAlign="left"
                                name="to"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <Input
                                    disabled={
                                        !values?.memberNO ||
                                        shareMode !== 'issue'
                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Share Amount"
                                labelCol={{ span: 11 }}
                                labelAlign="left"
                            >
                                <div className="ant-input ant-input--fake">
                                    {values?.shareAmount}
                                </div>
                            </Form.Item>
                            <Form.Item
                                label="Issued On"
                                labelCol={{ span: 11 }}
                                labelAlign="left"
                                name="issueddate"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required',
                                    },
                                ]}
                            >
                                <input
                                    type="text"
                                    className="ant-input"
                                    onKeyUp={e => onDateKeyUp(e, mode)}
                                    disabled={
                                        !values?.memberNO ||
                                        shareMode !== 'issue'
                                    }
                                />
                            </Form.Item>
                            {shareMode === 'new' && (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={onShareIssueNewClick}
                                        disabled={!values?.memberNO}
                                    >
                                        New
                                    </Button>
                                    <div style={{ marginLeft: 'auto' }}>
                                        <Popconfirm
                                            title="Are you sure to delete this certificate?"
                                            onConfirm={onShareDeleteConfirm}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                type="default"
                                                size="small"
                                                disabled={
                                                    !values?.noOfShare &&
                                                    !values?.certiNo
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                </div>
                            )}
                            {shareMode === 'issue' && (
                                <Space>
                                    <Button
                                        type="primary"
                                        size="small"
                                        onClick={onShareIssue}
                                    >
                                        Issue
                                    </Button>
                                    <Button
                                        type="default"
                                        size="small"
                                        onClick={onShareIssueCancel}
                                    >
                                        Cancel
                                    </Button>
                                </Space>
                            )}
                        </Spin>
                    </Col>
                    <Col span={16}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginBottom: '1rem',
                            }}
                        >
                            <h4>
                                <strong>List of Certificate</strong>
                            </h4>
                            <Space style={{ marginLeft: 'auto' }}>
                                <Link
                                    to={`/print-share-certificate?memberNo=${values?.memberNO}&memberName=${values?.memberName}&certiNo=${values?.certiNo}`}
                                    className="ant-btn ant-btn-primary ant-btn-sm"
                                    disabled={!values?.memberNO}
                                >
                                    Print
                                </Link>
                                <Button type="primary" size="small">
                                    Print in format
                                </Button>
                            </Space>
                        </div>
                        <Table
                            className="ant-table-wrapper--link"
                            dataSource={dataSource}
                            columns={columns}
                            size="small"
                            pagination={false}
                            scroll={{
                                y: 120,
                            }}
                        />
                        <div style={{ marginTop: '1rem' }}>
                            <Row gutter={20}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Defined"
                                        labelCol={{ span: 8 }}
                                        labelAlign="left"
                                    >
                                        <div className="ant-input ant-input--fake">
                                            {
                                                shareCertificate?.shareInfo
                                                    ?.totalDefined
                                            }
                                        </div>
                                    </Form.Item>
                                    <Form.Item
                                        label="Remaining"
                                        labelCol={{ span: 8 }}
                                        labelAlign="left"
                                    >
                                        <div className="ant-input ant-input--fake">
                                            {
                                                shareCertificate?.shareInfo
                                                    ?.totalUndefiend
                                            }
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </Form>
    );
});

export default ShareCertificateForm;
