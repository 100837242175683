import React from 'react';
import { GET_EXPIRING_SMS_BANKING_ACCOUNTS_LIST } from '../../../utils/axios';
import moment from 'moment';
import {
    ON_COLUMNS_CHANGE,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../utils/functions';
// Components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import ExpiringSmsBankingAccountsForm from '../../../components/Savings/ExpiringSmsBankingAccounts/ExpiringSmsBankingAccountsForm/ExpiringSmsBankingAccountsForm';

export default function ExpiringSmsBankingAccounts() {
    // Local states
    const [viewValues, setViewValues] = React.useState(null);
    const [accountsList, setAccountsList] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [columns, setColumns] = React.useState([]);

    // UTILS
    // On pagination change
    const onPaginationChange = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    // On columns list change
    const onColumnsChange = e => {
        ON_COLUMNS_CHANGE(e, setColumns);
    };

    // On form values change
    const onValuesChange = val => {
        // const key = Object.keys(val)[0];
        // const value = val[key];

        // Update values on state
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        // Update values
        updateValues(val);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            from: moment().format('YYYY-MM-DD'),
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // Get accounts list
    React.useEffect(() => {
        if (!viewValues?.from || !viewValues?.to) return;
        setIsLoading(true);
        const payload = {
            from: viewValues?.from,
            to: viewValues?.to,
            pageNO: page,
            rpp: pageSize,
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
        };

        (async () => {
            const accountsList = await GET_EXPIRING_SMS_BANKING_ACCOUNTS_LIST(
                payload
            );
            setAccountsList(accountsList);
            setIsLoading(false);
        })();
    }, [page, pageSize, viewValues?.from, viewValues?.to]);

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    // Form props
    const formProps = {
        accountsList,
        isLoading,
        onPaginationChange,
        columnsList: columns,
        onColumnsChange,
        onValuesChange,
        values: viewValues,
        onDateChange,
        onDateKeyUp,
    };
    return (
        <PageWrapper>
            <ExpiringSmsBankingAccountsForm {...formProps} />
        </PageWrapper>
    );
}
