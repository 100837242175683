import React from 'react';
import { Row, Table, Col } from 'antd';
import { GET_MEMBER_LP_FUND_PROPERTIES } from '../../../../utils/axios';
import Loading from '../../../../components/Common/Loading/Loading';

export default function MemberInfoLPFundDetail(props) {
    // Destructuring
    const { viewValues } = props;

    // Local states
    const [lpFundProperties, setLpFundProperties] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // Variables and constants
    const columns = [
        {
            title: 'Account Name',
            dataIndex: 'acName',
            key: 'acName',
        },
        {
            title: 'ACNO',
            dataIndex: 'acno',
            key: 'acno',
        },
        {
            title: 'Loan No',
            dataIndex: 'loanno',
            key: 'loanno',
        },
        {
            title: 'LP ACNO',
            dataIndex: 'lpACNO',
            key: 'lpACNO',
        },
        {
            title: 'LP Balance',
            dataIndex: 'lPBalance',
            key: 'lPBalance',
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
        },
    ];

    // On component mount
    React.useEffect(() => {
        if (!viewValues?.memberNO) return;
        setIsLoading(true);
        (async () => {
            const lpFundProperties = await GET_MEMBER_LP_FUND_PROPERTIES(
                viewValues.memberNO
            );
            console.log('lpFundProperties: ', lpFundProperties);
            setLpFundProperties(lpFundProperties);
            setIsLoading(false);
        })();
    }, [viewValues?.memberNO]);

    // Loading indicator
    if (isLoading) return <Loading />;

    return (
        <div>
            <Row gutter={20}>
                <Col span={16}>
                    <Table
                        dataSource={lpFundProperties}
                        columns={columns}
                        size="small"
                        pagination={false}
                    />
                </Col>
            </Row>
        </div>
    );
}
