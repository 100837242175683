import React from 'react';
// Import from dependencies
import { Form, Row, Col, Tabs } from 'antd';
// Import utils
// import axios from '../../utils/axios';
import { ENG_ISO_TO_NEP, GET_DATEPICKER_CONFIG } from '../../utils/functions';
// Import components
import AccountDetail from './AccountDetail/AccountDetail';
import InterestAndKista from './InterestAndKista/InterestAndKista';
import FineAndInterest from './FineAndInterest/FineAndInterest';

const LoanTypesForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        mode,
        kistaPeriods,
        setKistaPeriods,
        accounts,
        values,
        viewValues,
        onValuesChange,
        onCheckboxChange,
        setDefaultValues,
        onDateChange,
        onDateKeyUp,
    } = props;

    // NEPALI DATEPICKER
    // Formed date
    const formedDateInput = document.getElementById(`formedDate__${mode}`);
    formedDateInput &&
        formedDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, formedDateInput, mode)
        );

    // On component mount
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);
    return (
        <Row gutter={20}>
            <Col span={18}>
                <Form
                    ref={ref}
                    layout="vertical"
                    onValuesChange={val => onValuesChange(val, mode)}
                    fields={[
                        {
                            name: 'acno',
                            value: values?.acno,
                        },
                        {
                            name: 'govCode',
                            value: values?.govCode,
                        },
                        {
                            name: 'typeCode',
                            value: values?.typeCode,
                        },
                        {
                            name: `formedDate__${mode}`,
                            value:
                                values?.formedDate &&
                                ENG_ISO_TO_NEP(values?.formedDate),
                        },
                        {
                            name: 'acName',
                            value: values?.acName,
                        },
                        {
                            name: 'nepName',
                            value: values?.nepName,
                        },
                        {
                            name: 'serviceCharge',
                            value: values?.serviceCharge,
                        },
                        {
                            name: 'interestRate',
                            value: values?.interestRate,
                        },
                        {
                            name: 'passingRate',
                            value: values?.passingRate,
                        },
                        {
                            name: 'rebateSystem',
                            value: values?.rebateSystem,
                        },
                        {
                            name: 'rebateRate',
                            value: values?.rebateRate,
                        },
                        {
                            name: 'deductRebate',
                            value: values?.deductRebate,
                        },
                        {
                            name: 'interestRate',
                            value: values?.interestRate,
                        },
                        {
                            name: 'ips',
                            value: values?.ips,
                        },
                        {
                            name: 'lps',
                            value: values?.lps,
                        },
                        {
                            name: 'totalKista',
                            value: values?.totalKista,
                        },
                        {
                            name: 'kistaPeriod',
                            value: values?.kistaPeriod,
                        },
                        {
                            name: 'fineOrInt',
                            value: values?.fineOrInt,
                        },
                        {
                            name: 'directFineSystem',
                            value: values?.directFineSystem,
                        },
                        {
                            name: 'calcFineOn',
                            value: values?.calcFineOn,
                        },
                        {
                            name: 'typecode',
                            value: values?.typecode,
                        },
                        {
                            name: 'dd01',
                            value: values?.dd01,
                        },
                        {
                            name: 'dd02',
                            value: values?.dd02,
                        },
                        {
                            name: 'dd03',
                            value: values?.dd03,
                        },
                        {
                            name: 'dd04',
                            value: values?.dd04,
                        },
                        {
                            name: 'dd05',
                            value: values?.dd05,
                        },
                        {
                            name: 'dd06',
                            value: values?.dd06,
                        },
                        {
                            name: 'dd07',
                            value: values?.dd07,
                        },
                        {
                            name: 'dd08',
                            value: values?.dd08,
                        },
                        {
                            name: 'dd09',
                            value: values?.dd09,
                        },
                        {
                            name: 'ai001',
                            value: values?.ai001,
                        },
                        {
                            name: 'ai002',
                            value: values?.ai002,
                        },
                        {
                            name: 'ai003',
                            value: values?.ai003,
                        },
                        {
                            name: 'ai004',
                            value: values?.ai004,
                        },
                        {
                            name: 'ai005',
                            value: values?.ai005,
                        },
                        {
                            name: 'ai006',
                            value: values?.ai006,
                        },
                        {
                            name: 'ai007',
                            value: values?.ai007,
                        },
                        {
                            name: 'ai008',
                            value: values?.ai008,
                        },
                        {
                            name: 'ai009',
                            value: values?.ai009,
                        },
                        {
                            name: 'aA001',
                            value: values?.aA001,
                        },
                        {
                            name: 'aA002',
                            value: values?.aA002,
                        },
                        {
                            name: 'aA003',
                            value: values?.aA003,
                        },
                        {
                            name: 'aA004',
                            value: values?.aA004,
                        },
                        {
                            name: 'aA005',
                            value: values?.aA005,
                        },
                        {
                            name: 'aA006',
                            value: values?.aA006,
                        },
                        {
                            name: 'aA007',
                            value: values?.aA007,
                        },
                        {
                            name: 'aA008',
                            value: values?.aA008,
                        },
                        {
                            name: 'aA009',
                            value: values?.aA009,
                        },
                        {
                            name: 'adi001',
                            value: values?.adi001,
                        },
                        {
                            name: 'adi002',
                            value: values?.adi002,
                        },
                        {
                            name: 'adi003',
                            value: values?.adi003,
                        },
                        {
                            name: 'adi004',
                            value: values?.adi004,
                        },
                        {
                            name: 'adi005',
                            value: values?.adi005,
                        },
                        {
                            name: 'adi006',
                            value: values?.adi006,
                        },
                        {
                            name: 'adi007',
                            value: values?.adi007,
                        },
                        {
                            name: 'adi008',
                            value: values?.adi008,
                        },
                        {
                            name: 'adi009',
                            value: values?.adi009,
                        },
                    ]}
                >
                    <h3 className="colored-title" style={{ marginTop: '0' }}>
                        Account Detail
                    </h3>

                    <AccountDetail
                        mode={mode}
                        accounts={accounts}
                        values={values}
                        viewValues={viewValues}
                        onDateKeyUp={onDateKeyUp}
                    />

                    <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="Interest and Kista Detail  " key="1">
                            <InterestAndKista
                                mode={mode}
                                kistaPeriods={kistaPeriods}
                                setKistaPeriods={setKistaPeriods}
                                values={values}
                                onCheckboxChange={onCheckboxChange}
                            />
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            tab="Fine and Additional Interest Setting"
                            key="2"
                        >
                            <FineAndInterest />
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </Col>
        </Row>
    );
});

export default LoanTypesForm;
