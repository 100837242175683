import React from 'react';
import { Row, Col, Form, Input, Select, Checkbox } from 'antd';

const StaffTypesForm = React.forwardRef((props, ref) => {
    // Destructuring
    const { staffTypes, values, onValuesChange, onCheckboxChange, mode } =
        props;

    return (
        <Form
            ref={ref}
            layout="vertical"
            onValuesChange={onValuesChange}
            fields={[
                {
                    name: ['staffTypeID'],
                    value: values?.staffTypeID,
                },
                {
                    name: ['staffType'],
                    value: values?.staffType,
                },
            ]}
        >
            <Row gutter={20}>
                {mode !== 'add' && (
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item label="Staff Type ID" name="staffTypeID">
                            {mode === 'view' ? (
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {staffTypes.map((type, i) => (
                                        <Select.Option
                                            key={i}
                                            value={type.staffTypeID}
                                        >
                                            {type.staffTypeID}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ) : (
                                <p>{values?.staffTypeID}</p>
                            )}
                        </Form.Item>
                    </Col>
                )}

                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Staff Type"
                        name="staffType"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        {mode === 'view' ? (
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {staffTypes.map((type, i) => (
                                    <Select.Option
                                        key={i}
                                        value={type.staffType}
                                    >
                                        {type.staffType}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <Input />
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Is Active">
                        <Checkbox
                            checked={values?.isActive}
                            name="isActive"
                            onChange={e => onCheckboxChange(e, mode)}
                            disabled={mode === 'view'}
                        >
                            Is Active
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default StaffTypesForm;
