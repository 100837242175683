import React from 'react';
// Import from dependencies
import { Row, Col, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import {
    PieChart,
    Pie,
    Legend,
    Tooltip,
    ResponsiveContainer,
    Cell,
    XAxis,
    YAxis,
    Bar,
    CartesianGrid,
    BarChart,
} from 'recharts';
// Import utils
import axios, { GET_NOTIFICATIONS } from '../../utils/axios';
import { useSetRecoilState } from 'recoil';
import { notifications as notificationsAtom } from '../../recoil/atoms';
import { GUTTER } from '../../utils/constants';
// Components
import DashboardStat from '../../components/Dashboard/DashboardStat/DashboardStat';
import UserDataOneLine from '../../components/Common/UserDataOneLine/UserDataOneLine';
import DashboardCompanyInfo from '../../components/Dashboard/DashboardCompanyInfo/DashboardCompanyInfo';
import DashboardHeader from '../../components/Dashboard/DashboardHeader/DashboardHeader';
import DashboardSubheader from '../../components/Dashboard/DashboardSubheader/DashboardSubheader';

export default function Dashboard() {
    // Local states
    const [pieCharts, setPieCharts] = React.useState([]);
    const [barCharts, setBarCharts] = React.useState([]);
    const [assignedCharts, setAssignedCharts] = React.useState([]);
    // Global states
    const setNotifications = useSetRecoilState(notificationsAtom);

    // Dashboard stats
    const dashboardStats = [
        { icon: <UserOutlined />, unit: 23, title: 'New members' },
        { icon: <UserOutlined />, unit: 200, title: 'Recent transactions' },
        { icon: <UserOutlined />, unit: 50, title: "Today's collection" },
        { icon: <UserOutlined />, unit: 2500, title: 'Total members' },
    ];

    // Birthdays
    const birthdays = [
        {
            avatar: 'https://joeschmoe.io/api/v1/random',
            date: 'Today',
            name: 'Sunita Magar',
        },
        {
            avatar: 'https://joeschmoe.io/api/v1/random',
            date: '22th Nov 2021',
            name: 'Roshan Tamang',
        },
        {
            avatar: 'https://joeschmoe.io/api/v1/random',
            date: '28th Nov 2021',
            name: 'Kritika Thapa',
        },
        {
            avatar: 'https://joeschmoe.io/api/v1/random',
            date: '2nd Dec 2021',
            name: 'Rojina Shrestha',
        },
        {
            avatar: 'https://joeschmoe.io/api/v1/random',
            date: '12nd Dec 2021',
            name: 'Alina Lama',
        },
    ];
    // const colors = [
    //     '#003f5c',
    //     '#2f4b7c',
    //     '#665191',
    //     '#a05195',
    //     '#d45087',
    //     '#f95d6a',
    //     '#ff7c43',
    //     '#ffa600',
    // ];
    const colors = [
        '#F66D44',
        '#FEAE65',
        '#AADEA7',
        '#64C2A6',
        '#2D87BB',
        '#E6F69D',
    ];

    // Get assigned charts
    React.useEffect(() => {
        const userID = JSON.parse(
            localStorage.getItem('USER_CREDENTIALS')
        ).userID;
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/Charts/AssignedCharts/${userID}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                // console.log(res.data.data);
                setAssignedCharts(res.data.data);
            });
    }, []);

    // Get charts
    React.useEffect(() => {
        if (assignedCharts.length) {
            assignedCharts.forEach(item => {
                axios
                    .get(
                        `${process.env.REACT_APP_HOST}/api/Charts/ChartsData/${
                            item.cardType
                        }/${item.chartID}/${
                            JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                                .userID
                        }`
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        // console.log(res.data.data);
                        if (item.cardType === 'Pie')
                            setPieCharts(prevValues => [
                                ...prevValues,
                                res.data.data,
                            ]);
                        else if (item.cardType === 'Bar')
                            setBarCharts(prevValues => [
                                ...prevValues,
                                res.data.data,
                            ]);
                    });
            });
        }
    }, [assignedCharts]);

    // Get notifications
    React.useEffect(() => {
        (async () => {
            const notifications = await GET_NOTIFICATIONS();
            setNotifications(notifications);
        })();
    }, [setNotifications]);

    return (
        <div className="dashboard">
            <div className="dashboard__each">
                <div className="dashboard__stat">
                    <Row gutter={GUTTER}>
                        {dashboardStats.map((stat, i) => (
                            <Col key={i} xs={24} md={12} lg={6}>
                                <div className="block-general">
                                    <DashboardStat stat={stat} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            {/* Pie charts */}
            <div className="dashboard__each">
                <Row gutter={20}>
                    {pieCharts?.map((item, i) => (
                        <Col key={i} span={6}>
                            <div className="block-general">
                                <small
                                    style={{
                                        display: 'block',
                                        marginBottom: '.5rem',
                                    }}
                                >
                                    <strong>{item.graphLabel}</strong>
                                </small>
                                <ResponsiveContainer width="100%" height={260}>
                                    <PieChart>
                                        <Pie
                                            nameKey="label"
                                            dataKey="unit"
                                            isAnimationActive={true}
                                            data={item.dataList}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={80}
                                            fill="#8884d8"
                                            label={true}
                                            legendType="circle"
                                        >
                                            {item.dataList.map(
                                                (entry, index) => (
                                                    <Cell
                                                        key={`cell-${index}`}
                                                        fill={colors[index]}
                                                    />
                                                )
                                            )}
                                        </Pie>
                                        <Tooltip />
                                        <Legend verticalAlign="top" />
                                    </PieChart>
                                </ResponsiveContainer>
                                <small>
                                    <strong>{item.sumLabel}: </strong>
                                    {item.sumUnit}
                                </small>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            {/* Bar charts */}
            <div className="dashboard__each">
                <Row gutter={20}>
                    {barCharts?.map((item, i) => (
                        <Col key={i} span={6}>
                            <div className="block-general">
                                <small
                                    style={{
                                        display: 'block',
                                        marginBottom: '.5rem',
                                    }}
                                >
                                    <strong>{item.graphLabel}</strong>
                                </small>
                                <ResponsiveContainer width="100%" height={200}>
                                    <BarChart
                                        data={item.dataList}
                                        margin={{
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            top: 0,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="label"
                                            angle={90}
                                            interval="preserveStartEnd"
                                            hide
                                        />
                                        <YAxis width={40} fontSize={12} />
                                        <Tooltip />
                                        {/* <Legend /> */}
                                        <Bar dataKey="unit" fill="#8884d8" />
                                    </BarChart>
                                </ResponsiveContainer>
                                <small
                                    style={{
                                        display: 'block',
                                        marginTop: '.5rem',
                                    }}
                                >
                                    <strong>{item.sumLabel}: </strong>
                                    {item.sumUnit}
                                </small>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            <div className="dashboard__each">
                <DashboardHeader />
            </div>
            <div className="dashboard__each">
                <DashboardSubheader />
            </div>

            <div className="dashboard__each">
                <Row gutter={20}>
                    <Col xs={24} md={12}>
                        <div className="dashboard__birthdays">
                            <div className="block-general mb-0">
                                <p className="dashboard__birthdays-title">
                                    Upcoming birthdays
                                </p>
                                {birthdays.map((birthday, i) => (
                                    <UserDataOneLine key={i} stat={birthday} />
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <DashboardCompanyInfo />
                    </Col>
                </Row>
            </div>
        </div>
    );
}
