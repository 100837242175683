import React from 'react';
import moment from 'moment';
import { GET_JOURNAL_LIST } from '../../../utils/axios';
import { GET_JOURNAL_VOUCHER, GET_USERS } from '../../../utils/axios';

// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import JournalVoucherForm from '../../../components/Reports/JournalVoucher/JournalVoucherForm/JournalVoucherForm';
import {
    DLT_KEYS_FROM_OBJ,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../utils/functions';

export default function JournalVoucher() {
    // Local states
    const branches = JSON.parse(localStorage.getItem('BRANCHES'));
    const [viewValues, setViewValues] = React.useState(null);
    const [JournalVoucher, setJournalVoucher] = React.useState(null);
    const [users, setUsers] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [pageNo, setPageNo] = React.useState(1);
    const [isLoading, setIsLoading] = React.useState(false);
    const [journalList, setJournalList] = React.useState([]);

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            fromDate: moment().format(),
            tillDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            acHeadTotal: true,
            level: 'accountLevel',
            enteredBy: 'all',
            journalType: 2,
            baseon: 'main account',
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On values change
    const onValuesChange = val => {
        console.log('val: ', val);
        const key = Object.keys(val)[0];
        const value = val[key];
        // const values = viewValues;
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'fromDate' || key === 'tillDate') {
            return;
        } else if (key === 'branchID' || key === 'branchName') {
            if (value === 'all') {
                return updateValues({
                    branchID: 'all',
                    branchName: 'all',
                });
            }
            const branch = branches.find(item => item[key] === value);
            updateValues(branch);
        }
        // Update values
        updateValues(val);
    };

    // On pagination change
    const onPaginationChange = (page, pageSize) => {
        setPageNo(page);
        setPageSize(pageSize);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
        if (key === 'fromDate' || key === 'tillDate') {
            setViewValues(prevValues => ({
                ...DLT_KEYS_FROM_OBJ(prevValues, ['fromjn', 'tojn']),
            }));
        }
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
        if (
            e.keyCode === 13 &&
            (e.target.id === 'fromDate' || e.target.id === 'tillDate')
        ) {
            setViewValues(prevValues => ({
                ...DLT_KEYS_FROM_OBJ(prevValues, ['fromjn', 'tojn']),
            }));
        }
    };

    // Form props
    const formProps = {
        branches,
        viewValues,
        onValuesChange,
        JournalVoucher,
        users,
        onPaginationChange,
        pageSize,
        isLoading,
        journalList,
        onDateChange,
        onDateKeyUp,
    };

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    // Get users
    React.useEffect(() => {
        (async () => {
            const users = await GET_USERS();
            setUsers(users);
        })();
    }, []);

    // Get Journal Voucher
    React.useEffect(() => {
        if (
            viewValues?.fromDate &&
            viewValues?.tillDate &&
            viewValues?.baseon &&
            viewValues?.branchID &&
            viewValues?.enteredBy &&
            viewValues?.journalType
        ) {
            setJournalVoucher([]);
            setIsLoading(true);
            const payload = {
                fromDate: viewValues.fromDate,
                tillDate: viewValues.tillDate,
                baseon: viewValues.baseon,
                showSuspense: viewValues.showSuspense || false,
                hideSameACTransaction:
                    viewValues.hideSameACTransaction || false,
                branchID: viewValues.branchID,
                enteredBy: viewValues.enteredBy,
                journalType: viewValues.journalType,
                pageNo: pageNo,
                rrp: pageSize,
                fromjn: viewValues.fromjn,
                tojn: viewValues.tojn,
            };

            (async () => {
                const data = await GET_JOURNAL_VOUCHER(payload);
                console.log('data: ', data);
                setJournalVoucher(data);
                setIsLoading(false);
            })();
        }
    }, [
        viewValues?.fromDate,
        viewValues?.tillDate,
        viewValues?.baseon,
        viewValues?.showSuspense,
        viewValues?.hideSameACTransaction,
        viewValues?.branchID,
        viewValues?.enteredBy,
        viewValues?.journalType,
        viewValues?.fromjn,
        viewValues?.tojn,
        pageSize,
        pageNo,
    ]);

    // Get journal list
    React.useEffect(() => {
        if (viewValues?.fromDate && viewValues?.tillDate) {
            setJournalList([]);
            (async () => {
                const journalList = await GET_JOURNAL_LIST(
                    viewValues.fromDate,
                    viewValues.tillDate
                );
                console.log('journalList: ', journalList);
                setJournalList(journalList);
            })();
        }
    }, [viewValues?.fromDate, viewValues?.tillDate]);

    return (
        <PageWrapper>
            <JournalVoucherForm {...formProps} />
        </PageWrapper>
    );
}
