import { Row, Col, Form, Input, Select } from 'antd';
import React from 'react';
import 'nepali-datepicker-reactjs/dist/index.css';

export default function AccountDetail(props) {
    // Local states
    const [disabledPrefix, setDisablePrefix] = React.useState(false);
    // Destructuring
    const { mode, accounts, values, viewValues, onDateKeyUp } = props;

    let acnoInput;
    if (mode === 'view') {
        acnoInput = (
            <Select
                placeholder="Select..."
                showSearch
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }
            >
                {accounts.map((acc, i) => (
                    <Select.Option key={i} value={acc.acno}>
                        {acc.acno}
                    </Select.Option>
                ))}
            </Select>
        );
    } else if (mode === 'edit') {
        acnoInput = values?.acno;
    } else if (mode === 'add') {
        acnoInput = <Input />;
    }

    // On component mount
    React.useEffect(() => {
        // Disable Loan type prefix if value available
        setDisablePrefix(viewValues?.typecode && mode !== 'view');
    }, [setDisablePrefix, viewValues?.typecode, mode]);

    return (
        <div>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Account No."
                        name="acno"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        {acnoInput}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={6}>
                    <Form.Item label="Gov Code." name="govCode">
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item label="Loan Type Prefix" name="typecode">
                        {disabledPrefix ? values?.typecode : <Input />}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                    <Form.Item
                        label="Formed Date."
                        name={`formedDate__${mode}`}
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12}>
                    <Form.Item
                        label="Loan Type Name"
                        name="acName"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        {mode === 'view' ? (
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {accounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.acName}>
                                        {acc.acName}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <Input />
                        )}
                        {/* <Input /> */}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={12}>
                    <Form.Item label="Loan Type Nepali" name="nepName">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
