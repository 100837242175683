import React from 'react';
// Import from dependencies
import { Form, Select, Row, Col, Table, Button, Spin, Image, Card } from 'antd';
import moment from 'moment';
import { GET_DATEPICKER_CONFIG } from '../../../../utils/functions';
import Print from '../../../Common/Print/Print';
import ReactToPrint from 'react-to-print';

const MemberStatementForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        onValuesChange,
        values,
        mode,
        members,
        onDateChange,
        fullStatement,
        isLoading,
    } = props;

    // Refs
    const printRef = React.useRef();

    // Variables and Constants
    const dataSourceShareInfo = fullStatement?.share?.certificateList.map(
        (item, i) => {
            return {
                key: i + 1 + '',
                ...item,
            };
        }
    );

    const dataSourceDepositInfo = fullStatement?.deposit?.map((item, i) => {
        return {
            key: i + 1 + '',
            ...item,
        };
    });

    const dataSourceLoanInfo = fullStatement?.loan?.map((item, i) => {
        return {
            key: i + 1 + '',
            ...item,
        };
    });

    const columnsShareInfo = [
        {
            title: 'Certificate No',
            dataIndex: 'certiNo',
            key: 'certiNo',
        },
        {
            title: 'No. of Shares',
            dataIndex: 'noOfShare',
            key: 'noOfShare',
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
        },
        {
            title: 'Issued Date',
            dataIndex: 'issueddate',
            render: text => moment(text).format('YYYY-MM-DD'),
        },
    ];

    const columnsDepositInfo = [
        {
            title: 'Account No',
            dataIndex: 'acno',
            key: 'acno',
        },
        {
            title: 'Account Name',
            dataIndex: 'acName',
            key: 'acName',
        },
        {
            title: 'Account No',
            dataIndex: 'accountNO',
            key: 'accountNO',
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: 'Interest',
            dataIndex: 'interest',
            key: 'interest',
        },
        {
            title: 'Owner No',
            dataIndex: 'ownerNO',
            key: 'ownerNO',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const columnsLoanInfo = [
        {
            title: 'Account No',
            dataIndex: 'acno',
            key: 'acno',
        },
        {
            title: 'Account Name',
            dataIndex: 'acName',
            key: 'acName',
        },
        {
            title: 'Loan No',
            dataIndex: 'loanno',
            key: 'loanno',
        },
        {
            title: 'Loan Balance',
            dataIndex: 'loanBalance',
            key: 'loanBalance',
        },
        {
            title: 'Interest',
            dataIndex: 'interest',
            key: 'interest',
        },
        {
            title: 'Penalty',
            dataIndex: 'penalty',
            key: 'penalty',
        },
    ];

    // NEPALI DATEPICKER
    // Formed date
    const issueddateInput = document.getElementById('issueddate');
    issueddateInput &&
        issueddateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, issueddateInput, mode)
        );

    return (
        <Form
            ref={ref}
            layout="horizontal"
            onValuesChange={onValuesChange}
            fields={[
                {
                    name: 'memberNO',
                    value: values?.memberNO,
                },
                {
                    name: 'memberName',
                    value: values?.memberName,
                },
            ]}
        >
            <Row gutter={20}>
                <Col span={20}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Member No"
                                name="memberNO"
                                labelCol={{ span: 10 }}
                                labelAlign="left"
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members?.length &&
                                        members?.map((member, i) => (
                                            <Select.Option
                                                key={i}
                                                value={member.memberNO}
                                            >
                                                {member.memberNO}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Member Name"
                                name="memberName"
                                labelCol={{ span: 8 }}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members?.length &&
                                        members?.map((member, i) => (
                                            <Select.Option
                                                key={i}
                                                value={member.memberName}
                                            >
                                                {member.memberName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <ReactToPrint
                        trigger={() => <Button type="default">Print</Button>}
                        content={() => printRef.current}
                    />
                </Col>
            </Row>
            <Spin spinning={isLoading}>
                <Print ref={printRef} title="Member Full Statement">
                    <Card
                        size="small"
                        title="Personal Information"
                        style={{ marginTop: '1rem' }}
                        className="ant-card--header-bg"
                    >
                        <Row gutter={20}>
                            <Col span={20}>
                                <Row gutter={20}>
                                    <Col span={14}>
                                        <Form.Item
                                            label="Member No"
                                            labelCol={{ span: 8 }}
                                            labelAlign="left"
                                        >
                                            <div className="ant-input ant-input--fake">
                                                {
                                                    fullStatement?.memberInfo
                                                        ?.memberNO
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}></Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={14}>
                                        <Form.Item
                                            label="Member's Name"
                                            labelCol={{ span: 8 }}
                                            labelAlign="left"
                                        >
                                            <div className="ant-input ant-input--fake">
                                                {
                                                    fullStatement?.memberInfo
                                                        ?.memberName
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item>
                                            <div className="ant-input ant-input--fake">
                                                {
                                                    fullStatement?.memberInfo
                                                        ?.unName
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={14}>
                                        <Form.Item
                                            label="Father/Husband"
                                            labelCol={{ span: 8 }}
                                            labelAlign="left"
                                        >
                                            <div className="ant-input ant-input--fake">
                                                {
                                                    fullStatement?.memberInfo
                                                        ?.fatherName
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item>
                                            <div className="ant-input ant-input--fake">
                                                {
                                                    fullStatement?.memberInfo
                                                        ?.unFather
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={14}>
                                        <Form.Item
                                            label="Address"
                                            labelCol={{ span: 8 }}
                                            labelAlign="left"
                                        >
                                            <div className="ant-input ant-input--fake">
                                                {
                                                    fullStatement?.memberInfo
                                                        ?.memAddress
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item>
                                            <div className="ant-input ant-input--fake">
                                                {
                                                    fullStatement?.memberInfo
                                                        ?.unmemAddress
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={4}>
                                <Image
                                    width={120}
                                    src={`${process.env.REACT_APP_STATIC_FILES}/${fullStatement?.memberInfo?.photoloc}`}
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card
                        size="small"
                        title="Share Information"
                        style={{ marginTop: '1rem' }}
                        className="ant-card--header-bg"
                    >
                        <Row gutter={20}>
                            <Col span={20}>
                                <Row gutter={20}>
                                    <Col span={14}>
                                        <Form.Item
                                            label="Total No. of Shares"
                                            labelCol={{
                                                md: {
                                                    span: 12,
                                                },
                                                lg: { span: 8 },
                                            }}
                                            labelAlign="left"
                                        >
                                            <div className="ant-input ant-input--fake">
                                                {
                                                    fullStatement?.share
                                                        ?.totalShareNo
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            label="Total Share Amount"
                                            labelCol={{
                                                md: {
                                                    span: 12,
                                                },
                                                lg: { span: 10 },
                                            }}
                                            labelAlign="left"
                                        >
                                            <div className="ant-input ant-input--fake">
                                                {
                                                    fullStatement?.share
                                                        ?.sharAmount
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={20}>
                                    <Col span={14}>
                                        <Form.Item
                                            label="Total Defined"
                                            labelCol={{
                                                md: {
                                                    span: 12,
                                                },
                                                lg: { span: 8 },
                                            }}
                                            labelAlign="left"
                                        >
                                            <div className="ant-input ant-input--fake">
                                                {
                                                    fullStatement?.share
                                                        ?.totalDefined
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item
                                            label="Total Undefined"
                                            labelCol={{
                                                md: {
                                                    span: 12,
                                                },
                                                lg: { span: 10 },
                                            }}
                                            labelAlign="left"
                                        >
                                            <div className="ant-input ant-input--fake">
                                                {
                                                    fullStatement?.share
                                                        ?.totalUndefiend
                                                }
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {dataSourceShareInfo?.length ? (
                            <Table
                                dataSource={dataSourceShareInfo}
                                columns={columnsShareInfo}
                                pagination={false}
                                size="small"
                            />
                        ) : null}
                    </Card>
                    <Card
                        size="small"
                        title="Deposit Information"
                        style={{ marginTop: '1rem' }}
                        className="ant-card--header-bg"
                    >
                        <Table
                            dataSource={dataSourceDepositInfo}
                            columns={columnsDepositInfo}
                            pagination={false}
                            size="small"
                        />
                    </Card>
                    <Card
                        size="small"
                        title="Loan Information"
                        style={{ marginTop: '1rem' }}
                        className="ant-card--header-bg"
                    >
                        <Table
                            dataSource={dataSourceLoanInfo}
                            columns={columnsLoanInfo}
                            pagination={false}
                            size="small"
                        />
                    </Card>
                </Print>
            </Spin>
        </Form>
    );
});

export default MemberStatementForm;
