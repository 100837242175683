import React from 'react';
import { Dropdown, Space, Menu, Checkbox } from 'antd';
import { DownOutlined } from '@ant-design/icons';

export default function ColumnsSelection(props) {
    // Destructuring
    const { columns, selectedColumns, onColumnsChange } = props;

    // Local states
    const [columnsDropdown, setColumnsDropdown] = React.useState(false);

    // Table columns
    const columnsDropdownMenu = (
        // <div className="">
        <Menu onClick={() => setColumnsDropdown(true)}>
            {columns?.map((item, i) => (
                <Menu.Item key={i}>
                    <Checkbox
                        onChange={onColumnsChange}
                        value={item}
                        checked={selectedColumns?.includes(item)}
                    >
                        {item}
                    </Checkbox>
                </Menu.Item>
            ))}
        </Menu>
        // </div>
    );
    return (
        <div className="align-right">
            <Dropdown
                className="fake-link"
                visible={columnsDropdown}
                overlay={columnsDropdownMenu}
                trigger={['click']}
                onVisibleChange={flag => setColumnsDropdown(flag)}
                overlayClassName="ant-dropdown--fixed-height"
            >
                <Space>
                    Columns
                    <DownOutlined />
                </Space>
            </Dropdown>
        </div>
    );
}
