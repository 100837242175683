import React from 'react';
import { message } from 'antd';
import { useRecoilValue } from 'recoil';
import {
    cashCounters as cashCountersAtom,
    banks as banksAtom,
    otherItems as otherItemsAtom,
    otherAccounts as otherAccountsAtom,
} from '../../../recoil/atoms';
import moment from 'moment';
import {
    SET_CASH_AMOUNT,
    SET_BANK_AMOUNT,
    SET_OTHER_AMOUNT,
    SET_TOTAL_AMOUNT,
    DLT_KEYS_FROM_OBJ,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../utils/functions';
import axios, {
    GET_HOUSE_ITEMS,
    GET_MONTHS,
    GET_NEP_MONTH,
} from '../../../utils/axios';
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import HouseRentForm from '../../../components/Accounts/HouseRent/HouseRentForm/HouseRentForm';

export default function HouseRent() {
    // Global states
    const cashCounters = useRecoilValue(cashCountersAtom);
    const banks = useRecoilValue(banksAtom);
    const otherAccounts = useRecoilValue(otherAccountsAtom);
    const otherItems = useRecoilValue(otherItemsAtom);
    // Local states
    const [months, setMonths] = React.useState([]);
    const [payableItems, setPayableItems] = React.useState([]);
    const [bankAccounts, setBankAccounts] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [isProcessing, setIsProcessing] = React.useState(false);

    // Ref
    const formRef = React.createRef();

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            transDate: moment().format(),
            tds: '10',
            excOrInc: 'inclusive',
            paidBy: 'Office',
            isClearance: false,
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On values change
    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        const values = viewValues;
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'isCash') {
            SET_CASH_AMOUNT(values, value, updateValues, 'paid');
        } else if (key === 'isBank') {
            SET_BANK_AMOUNT(values, value, updateValues, 'paid');
        } else if (key === 'isOther') {
            SET_OTHER_AMOUNT(values, value, updateValues, 'paid');
        } else if (key === 'transDate') {
            return;
        } else if (key === 'cashNo' || key === 'cashName') {
            const counterKey = key === 'cashNo' ? 'itemCode' : 'itemName';
            const counter = cashCounters.find(
                counter => counter[counterKey] === value
            );
            return updateValues({
                cashNo: counter.itemCode,
                cashName: counter.itemName,
            });
        } else if (key === 'bankNo' || key === 'bankName') {
            const bankKey = key === 'bankNo' ? 'itemCode' : 'itemName';
            const bank = banks.find(bank => bank[bankKey] === value);

            return updateValues({
                bankNo: bank.itemCode,
                bankName: bank.itemName,
            });
        } else if (key === 'otherNo' || key === 'otherName') {
            const otherKey = key === 'otherNo' ? 'itemCode' : 'itemName';
            const other = otherItems.find(item => item[otherKey] === value);

            return updateValues({
                otherNo: other.itemCode,
                otherName: other.itemName,
            });
        } else if (key === 'otherAcno' || key === 'otherAcName') {
            const accKey = key === 'otherAcno' ? 'acno' : 'acName';
            const acc = otherAccounts.find(acc => acc[accKey] === value);

            return updateValues({
                ...DLT_KEYS_FROM_OBJ(viewValues, ['otherNo', 'otherName']),
                otherMano: acc.mano,
                otherAcno: acc.acno,
                otherAcName: acc.acName,
            });
        } else if (key === 'itemCode' || key === 'itemName') {
            const item = payableItems.find(item => item[key] === value);
            return updateValues(item);
        }
        // Update values
        updateValues(val);
    };

    // On form submit
    const onFormSubmit = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setIsProcessing(true);
                const payload = {
                    transDate: viewValues?.transDate,
                    enteredBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                    branchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    transactionType: 'House Rent Paid',
                    description: viewValues?.description,
                    debitTrans: [
                        {
                            mano: '150',
                            acno: '150.04',
                            bvrcno: viewValues?.bvrcno || null,
                            itemCode: viewValues?.itemCode,
                            itemName: viewValues?.itemName,
                            itemLocation: JSON.parse(
                                localStorage.getItem('USER_CREDENTIALS')
                            ).branchName,
                            receivedPaidBy: viewValues?.paidBy,
                            remarks1: viewValues?.nepMonth,
                            rate:
                                viewValues?.excOrInc === 'exclusive'
                                    ? viewValues?.paid
                                    : viewValues?.preTotal,
                            interBranchID: JSON.parse(
                                localStorage.getItem('USER_CREDENTIALS')
                            ).branchID,
                            amount:
                                viewValues?.excOrInc === 'exclusive'
                                    ? viewValues?.paid.toFixed(2)
                                    : viewValues?.basePaid.toFixed(2),
                            quantity: 1,
                            particulars: `${viewValues.itemName} PAID`,
                        },
                    ],
                    creditTrans: [
                        ...(viewValues?.isCash
                            ? [
                                  {
                                      mano: '080',
                                      acno: '080.01',
                                      bvrcno: viewValues?.cashBvrcno,
                                      itemCode: viewValues?.cashNo,
                                      itemName: viewValues?.cashName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      receivedPaidBy: viewValues?.paidBy,
                                      remarks1: '0',
                                      rate: viewValues?.cashAmount,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues.cashAmount,
                                      quantity: '1',
                                      particulars: `Cash Paid For House Rent`,
                                  },
                              ]
                            : []),
                        ...(viewValues.isBank
                            ? [
                                  {
                                      mano: bankAccounts[0].mano,
                                      acno: bankAccounts[0].acno,
                                      bvrcno: viewValues?.bankBvrcno,
                                      itemCode: viewValues?.bankNo,
                                      itemName: viewValues?.bankName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      receivedPaidBy: viewValues?.paidBy,
                                      remarks1: '0',
                                      rate: viewValues?.bankAmount,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.bankAmount,
                                      quantity: '1',
                                      particulars: `By ${viewValues?.bankName}`,
                                  },
                              ]
                            : []),
                        ...(viewValues?.isOther
                            ? [
                                  {
                                      mano: viewValues.otherMano,
                                      acno: viewValues.otherAcno,
                                      bvrcno: '',
                                      itemCode: viewValues?.otherNo,
                                      itemName: viewValues?.otherName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      receivedPaidBy: viewValues?.paidBy,
                                      remarks1: '0',
                                      rate: viewValues?.otherAmount,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.otherAmount,
                                      quantity: '1',
                                      particulars: `By ${viewValues?.otherName}`,
                                  },
                              ]
                            : []),
                        ...(+viewValues?.tdsAmount
                            ? [
                                  {
                                      mano: '060',
                                      acno: '060.14',
                                      bvrcno: viewValues?.bvrcno || null,
                                      itemCode: viewValues?.itemCode,
                                      itemName: viewValues?.itemName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchName,
                                      receivedPaidBy: viewValues?.paidBy,
                                      remarks1: viewValues?.nepMonth,
                                      rate: viewValues?.tdsAmount,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.tdsAmount,
                                      quantity: 1,
                                      particulars: `TDS on House Rent`,
                                  },
                              ]
                            : []),
                    ],
                };
                console.log(payload, 'payload');
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/AllTransactions/HouseRent`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0) {
                            setIsProcessing(false);
                            return message.error(res.data.statusMessage);
                        }
                        setViewValues(prevValues => ({
                            ...prevValues,
                            ...res.data.data,
                        }));
                        message.success(res.data.statusMessage);
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to process house rent payment'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                        setIsProcessing(false);
                    });
            })
            .catch(err => {
                console.error(err);
                message.error('Please fill all required fields!');
            });
    };

    // Set total amount
    React.useEffect(() => {
        const cashAmount = +viewValues?.cashAmount || 0;
        const bankAmount = +viewValues?.bankAmount || 0;
        const otherAmount = +viewValues?.otherAmount || 0;
        SET_TOTAL_AMOUNT(cashAmount, bankAmount, otherAmount, setViewValues);
    }, [
        viewValues?.cashAmount,
        viewValues?.bankAmount,
        viewValues?.otherAmount,
        setViewValues,
    ]);

    // Handle new
    const handleNew = () => {
        setViewValues(null);
        setDefaultValues();
        setIsProcessing(false);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Form props
    const formProps = {
        ref: formRef,
        months,
        payableItems,
        onValuesChange,
        isProcessing,
        handleNew,
        onFormSubmit,
        onDateChange,
        onDateKeyUp,
    };

    // Get payable items
    React.useEffect(() => {
        setPayableItems([]);
        (async () => {
            const items = await GET_HOUSE_ITEMS();
            setPayableItems(items);
        })();
    }, []);

    // Get and set pay receipt bank accounts
    React.useEffect(() => {
        if (viewValues?.isClearance !== undefined && viewValues?.branchID) {
            axios
                .get(
                    `${
                        process.env.REACT_APP_HOST
                    }/api/PayReceipt/BankAccounts/${viewValues?.branchID}/${
                        JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                            .userName
                    }}?isClearance=${viewValues?.isClearance}`
                )
                .then(res => {
                    setBankAccounts(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get banks');
                    message.error('Failed to get banks');
                });
        }
    }, [viewValues?.isClearance, viewValues?.branchID]);

    // Calculate pre total
    // React.useEffect(() => {
    //     const preTotal = tableData.reduce(
    //         (prevVal, curVal) => +prevVal + +curVal.paid,
    //         0
    //     );
    //     setViewValues(prevValues => ({
    //         ...prevValues,
    //         preTotal,
    //     }));
    // }, [tableData]);

    // Set default values
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    // Calculate TDS Amount
    React.useEffect(() => {
        if (viewValues?.paid && viewValues?.excOrInc && viewValues?.tds) {
            const paid = +viewValues.paid;
            const excOrInc = viewValues.excOrInc;
            const tds = +viewValues?.tds;
            let tdsAmount, basePaid, preTotal;
            if (excOrInc === 'inclusive') {
                basePaid = (paid * 100) / (100 - tds);
                tdsAmount = basePaid - paid;
                preTotal = basePaid.toFixed(2);
            } else {
                tdsAmount = (paid * tds) / 100;
                preTotal = paid + tdsAmount;
            }
            tdsAmount = tdsAmount.toFixed(2);
            setViewValues(prevValues => ({
                ...prevValues,
                basePaid,
                tdsAmount,
                preTotal,
            }));
        }
    }, [viewValues?.paid, viewValues?.excOrInc, viewValues?.tds]);

    // Get months
    React.useEffect(() => {
        (async () => {
            const months = await GET_MONTHS();
            setMonths(months);
        })();
    }, []);

    // GET nepali month
    React.useEffect(() => {
        if (viewValues?.transDate) {
            (async () => {
                const nepMonth = await GET_NEP_MONTH(viewValues.transDate);
                setViewValues(prevValues => ({
                    ...prevValues,
                    nepMonth,
                }));
            })();
        }
    }, [viewValues?.transDate]);

    return (
        <PageWrapper>
            <HouseRentForm {...formProps} values={viewValues} />
        </PageWrapper>
    );
}
