import React from 'react';
import { Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { GET_MEMBERS_BY_STATE, SAVE_MEMBERS_STATE } from '../../../utils/axios';
import { ON_DATE_CHANGE, ON_DATE_KEYUP } from '../../../utils/functions';
import moment from 'moment';
// Components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import ActiveInactiveMembersForm from '../../../components/Membership/ActiveInactiveMembers/ActiveInactiveMembersForm/ActiveInactiveMembersForm';

export default function ActiveInactiveMembers() {
    // Local states
    const [tableData, setTableData] = React.useState(null);
    const [viewValues, setViewValues] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const [isLoading, setIsLoading] = React.useState(false);
    const [indeterminate, setIndeterminate] = React.useState(false);
    const [checkAll, setCheckAll] = React.useState(false);

    // Destructuring
    const { confirm } = Modal;

    // Refs
    const formRef = React.useRef();

    // Utils
    // On values change
    const onValuesChange = val => {
        // const key = Object.keys(val)[0];
        // const value = val[key];
        // const values = viewValues;

        // Update values on state
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        // Update values
        updateValues(val);
    };

    // On pagination change
    const onPaginationChange = (page, pageSize) => {
        setPage(page);
        setPageSize(pageSize);
    };

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            fromDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On Checkbox change
    const onCheckboxChange = e => {
        const id = e.target.id;
        const checked = e.target.checked;
        const i = id.split('_')[1];
        let tableDataNew = { ...tableData };
        tableDataNew.dataList[i].disabled = checked;
        setTableData(tableDataNew);

        // Set indeterminate
        const allChecked =
            tableData?.dataList?.length ===
            tableData?.dataList?.filter(item => item.disabled).length;

        setIndeterminate(
            tableData?.dataList.find(item => item.disabled)
                ? !allChecked
                : false
        );
        setCheckAll(allChecked);
    };

    // Show save state confirm
    const showSaveStateConfirm = () => {
        confirm({
            title: 'Save states of the members?',
            icon: <ExclamationCircleOutlined />,
            content: <SaveStateContent />,
            okText: 'Save',
            onOk() {
                const checkedList = tableData.dataList
                    .filter(item => item.disabled)
                    .map(item => item.memberNo);

                const unCheckedList = tableData.dataList
                    .filter(item => !item.disabled)
                    .map(item => item.memberNo);

                const payload = {
                    enableMembernos: unCheckedList,
                    disabledMembernos: checkedList,
                };

                return new Promise((resolve, reject) => {
                    (async () => {
                        await SAVE_MEMBERS_STATE(payload);
                        if (viewValues?.state === 'ActiveMember') {
                            const tableDataNew = [...tableData.dataList].filter(
                                item => !item.disabled
                            );
                            setTableData(prevValues => ({
                                ...prevValues,
                                dataList: tableDataNew,
                            }));
                        } else if (viewValues?.state === 'DisabledMember') {
                            const tableDataNew = [...tableData.dataList].filter(
                                item => item.disabled
                            );
                            setTableData(prevValues => ({
                                ...prevValues,
                                dataList: tableDataNew,
                            }));
                        }
                        resolve();
                    })();
                }).catch(() => console.log('Oops errors!'));
            },
            onCancel() {
                message.error('Canceled');
            },
        });
    };

    // On check all change
    const onCheckAllChange = e => {
        if (!tableData?.dataList) return;
        const checked = e.target.checked;
        const tableDataNew = {
            ...tableData,
            dataList: tableData?.dataList.map(item => {
                return {
                    ...item,
                    disabled: checked,
                };
            }),
        };
        setTableData(tableDataNew);
        setIndeterminate(false);
        setCheckAll(checked);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Get table data
    React.useEffect(() => {
        if (viewValues?.fromDate && viewValues?.toDate && viewValues?.state) {
            setIsLoading(true);
            const payload = {
                branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                    .branchID,
                Minibalance: viewValues?.Minibalance,
                MaxBalance: viewValues?.MaxBalance,
                fromDate: viewValues?.fromDate,
                toDate: viewValues?.toDate,
                pageNO: page,
                rpp: pageSize,
            };
            (async () => {
                const members = await GET_MEMBERS_BY_STATE(
                    payload,
                    viewValues?.state
                );
                setTableData(members);
                setIsLoading(false);
            })();
        }
    }, [
        viewValues?.fromDate,
        viewValues?.toDate,
        viewValues?.state,
        viewValues?.Minibalance,
        viewValues?.MaxBalance,
        page,
        pageSize,
    ]);

    // Set default values
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    // Set Intermediate
    React.useEffect(() => {
        const allChecked =
            tableData?.dataList?.length ===
            tableData?.dataList?.filter(item => item.disabled).length;

        setIndeterminate(
            allChecked
                ? false
                : tableData?.dataList?.find(item => item.disabled)
        );

        setCheckAll(tableData?.dataList?.length && allChecked);
    }, [tableData?.dataList]);

    // Form props
    const formProps = {
        tableData,
        ref: formRef,
        onValuesChange,
        onDateChange,
        onDateKeyUp,
        onPaginationChange,
        isLoading,
        values: viewValues,
        onCheckboxChange,
        showSaveStateConfirm,
        indeterminate,
        onCheckAllChange,
        checkAll,
    };
    return (
        <PageWrapper>
            <ActiveInactiveMembersForm {...formProps} />
        </PageWrapper>
    );
}

export const SaveStateContent = () => {
    return (
        <>
            <p>
                <strong>
                    <u>Checked</u>
                </strong>{' '}
                members will be saved as{' '}
                <strong>
                    <u>Disabled</u>
                </strong>
                .
            </p>
            <p>
                <strong>
                    <u>Unchecked</u>
                </strong>{' '}
                members will be saved as{' '}
                <strong>
                    <u>Enabled</u>
                </strong>
                .
            </p>
        </>
    );
};
