import React from 'react';
import { Button, Modal, message } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
// Import utils
import axios, {
    GET_CASTES,
    GET_DISTRICTS,
    GET_MUNICIPALITIES,
    GET_OWNERS,
    GET_OWNER_DETAIL,
    GET_GUARDIAN_DETAIL,
} from '../../utils/axios';
import { englishToNepaliNumber, nepaliToEnglishNumber } from 'nepali-number';
import {
    membershipFirstPanelNames,
    membershipSecondPanelNames,
    membershipThirdPanelNames,
} from '../../utils/helpers';
import moment from 'moment';
import {
    JSON_TO_FORM_DATA,
    ON_FILE_CHANGE,
    COMPARE_ARRAYS,
    SET_DEFAULT_VALUES,
    DLT_KEYS_FROM_OBJ,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
    IS_VALID_DATE,
} from '../../utils/functions';
// Import components
import PageWrapper from '../../components/Common/PageWrapper/PageWrapper';
import OwnerDetailsForm from '../../components/OwnerDetails/OwnerDetailsForm';
import BottomActionBarFixed from '../../components/Common/BottomActionBarFixed/BottomActionBarFixed';
import PersonalDetailForm from '../../components/OwnerDetails/PersonalDetailForm/PersonalDetailForm';
import AddressDetailForm from '../../components/OwnerDetails/AddressDetailForm/AddressDetailForm';
import FamilyDetailForm from '../../components/OwnerDetails/FamilyDetailForm/FamilyDetailForm';
import OtherDetailForm from '../../components/OwnerDetails/OtherDetailForm/OtherDetailForm';
import DocumentsForm from '../../components/OwnerDetails/DocumentsForm/DocumentsForm';

export default function OwnerDetails() {
    // Local states
    const [owners, setOwners] = React.useState([]);
    const [ownerTypes, setOwnerTypes] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [provinces, setProvinces] = React.useState([]);
    const [districtsTmp, setDistrictsTmp] = React.useState([]);
    const [districtsPer, setDistrictsPer] = React.useState([]);
    const [municipalitiesTmp, setMunicipalitiesTmp] = React.useState([]);
    const [municipalitiesPer, setMunicipalitiesPer] = React.useState([]);
    const [casteGroups, setCasteGroups] = React.useState([]);
    const [castes, setCastes] = React.useState([]);
    const [religions, setReligions] = React.useState([]);
    const [maritalStatuses, setMaritalStatuses] = React.useState([]);
    const [education, setEducation] = React.useState([]);
    const [occupation, setOccupation] = React.useState([]);
    const [relations, setRelations] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [areas, setAreas] = React.useState([]);
    const [staffs, setStaffs] = React.useState([]);
    const [activePanelKey, setActivePanelKey] = React.useState('0');
    const [ownerNOValidationStatus, setOwnerNOValidationStatus] =
        React.useState(null); // validating | success | error
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Ref
    const formRef = React.useRef();

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(
            key,
            date,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues, setEditValues, setAddValues);
    };

    // Variables and constants
    const location = useLocation();
    const history = useHistory();
    const tabPanes = [
        {
            title: 'Personal Detail',
            component: <PersonalDetailForm />,
        },
        {
            title: 'Address Detail',
            component: <AddressDetailForm />,
        },
        {
            title: 'Family Detail',
            component: <FamilyDetailForm />,
        },
        {
            title: 'Other Details',
            component: <OtherDetailForm />,
        },
        {
            title: 'Documents',
            component: <DocumentsForm />,
        },
    ];
    const lastPanelKey = tabPanes.length - 1 + '';

    // Utility functions
    // Check if panel is first
    const isPanelFirst = () => {
        return activePanelKey === '0';
    };
    // Check if panel is last
    const isPanelLast = () => {
        return activePanelKey === lastPanelKey;
    };
    // On tabs change
    const onPanelChange = activeKey => {
        setActivePanelKey(activeKey);
    };
    // On Panel Prev
    const onPanelPrev = () => {
        setActivePanelKey(+activePanelKey - 1 + '');
    };
    // Go to next panel
    const goToNextPanel = () => {
        setActivePanelKey(+activePanelKey + 1 + '');
    };
    // On Panel Next
    const onPanelNext = () => {
        formRef.current
            .validateFields()
            .then(() => {
                goToNextPanel();
            })
            .catch(() => {
                let errorFields = [];
                formRef.current
                    .getFieldsError()
                    .forEach(
                        field =>
                            field.errors.length &&
                            errorFields.push(field.name[0])
                    );
                if (activePanelKey === '0') {
                    if (!COMPARE_ARRAYS(errorFields, membershipFirstPanelNames))
                        goToNextPanel();
                } else if (activePanelKey === '1') {
                    if (
                        !COMPARE_ARRAYS(errorFields, membershipSecondPanelNames)
                    )
                        goToNextPanel();
                } else if (activePanelKey === '2') {
                    if (!COMPARE_ARRAYS(errorFields, membershipThirdPanelNames))
                        goToNextPanel();
                }
            });
    };
    // Get owner detail
    const getOwnerDetail = React.useCallback(
        ownerNO => {
            (async () => {
                const ownerDetail = await GET_OWNER_DETAIL(ownerNO);
                console.log('ownerDetail: ', ownerDetail);
                if (!IS_VALID_DATE(ownerDetail.dateOfBirth)) {
                    message.error('Invalid date of birth');
                    delete ownerDetail.dateOfBirth;
                }
                setViewValues(ownerDetail);
                if (location.search.includes('edit=true')) {
                    setEditValues(ownerDetail);
                    setEditModal(prevValues => ({
                        ...prevValues,
                        visible: true,
                    }));
                    history.push('/owner-details');
                }
            })();
        },
        [history, location.search]
    );
    // Get districts
    const getDistricts = async (province, type) => {
        const districts = await GET_DISTRICTS(province);
        if (type === 'tmp') setDistrictsTmp(districts);
        else if (type === 'per') setDistrictsPer(districts);
    };
    // Get districts
    const getMunicipalities = async (district, type) => {
        const municipalities = await GET_MUNICIPALITIES(district);
        if (type === 'tmp') setMunicipalitiesTmp(municipalities);
        if (type === 'per') setMunicipalitiesPer(municipalities);
    };
    // Check owner num availability
    const checkOwnerNOAvailability = async ownerNO => {
        if (ownerNO) {
            setOwnerNOValidationStatus('validating');
            try {
                const res = await axios.get(
                    `${process.env.REACT_APP_HOST}/api/Owner/IsOwnerNoValid/${ownerNO}`
                );
                const isAvailable = res.data.data;
                if (isAvailable) setOwnerNOValidationStatus('success');
                else setOwnerNOValidationStatus('error');
            } catch (err) {
                console.error(err, 'Failed to check owner no availability');
                message.error('Something went wrong. Please try again!');
            }
        } else {
            setOwnerNOValidationStatus(null);
        }
    };
    // Get new IDS
    const getNewIDs = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_HOST}/api/Owner/GetNewIDs/${
                    JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                        .branchID
                }`
            );
            setAddValues(prevValues => ({
                ...prevValues,
                ...{
                    ownerNO: res.data.data,
                },
            }));
        } catch (err) {
            console.error(err, 'Failed to get new owner IDs');
            message.error('Something went wrong. Please try again!');
        }
    };
    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            area: 'CENTER',
            group: 'COMMON',
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    // EDIT ACCOUNT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current
            .validateFields()
            .then(res => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));

                const payload = JSON_TO_FORM_DATA(editValues);

                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/Owner/Update/${editValues?.ownerNO}`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0) {
                            return message.error(res.data.statusMessage);
                        }
                        setViewValues(res.data.data);
                        setEditModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Updated!');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to member details');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setEditModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => message.error('Please fill all required fields'));
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD ACCOUNT MODAL
    // On add modal open
    const onAddModalOpen = () => {
        // Get new ids conditionally
        if (
            JSON.parse(localStorage.getItem('ENTERPRISE_DETAIL'))
                .fetchIDbeforeInsert
        ) {
            getNewIDs();
        }
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On add modal ok
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                const payload = {
                    ...addValues,
                    lastModifiedBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                };
                const formData = JSON_TO_FORM_DATA(payload);
                axios
                    .post(
                        `${
                            process.env.REACT_APP_HOST
                        }/api/Owner/Insert?OwnerNO=${
                            addValues?.ownerNO || null
                        }`,
                        formData
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        const data = res.data.data;
                        setViewValues(data);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        setMembers([
                            ...members,
                            {
                                memberNO: data.memberNO,
                                memberName: data.memberName,
                            },
                        ]);
                        setAddValues(null);
                        message.success('New member registered!');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to register member');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // On values change
    const onValuesChange = (val, mode) => {
        // const key = Object.keys(val)[0];
        // const value = val[key];

        let values;
        if (mode === 'view') values = viewValues;
        else if (mode === 'edit') values = editValues;
        else if (mode === 'add') values = addValues;

        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };

        if (val.hasOwnProperty('ownerNO')) {
            if (mode === 'add') checkOwnerNOAvailability(val.ownerNO);
        } else if (val.hasOwnProperty('memberName')) {
            return updateValues({
                memberName: val.memberName.toUpperCase(),
            });
        } else if (val.hasOwnProperty('tmpProvince')) {
            const province = provinces.find(
                province => province.provinceName === val.tmpProvince
            );
            getDistricts(province.provinceName, 'tmp');

            updateValues({
                tmpProvince: province.provinceName,
                untProvince: province.provinceNepali,
            });
        } else if (val.hasOwnProperty('untProvince')) {
            const province = provinces.find(
                province => province.provinceNepali === val.untProvince
            );
            getDistricts(province.provinceName, 'tmp');
            updateValues({
                tmpProvince: province.provinceName,
                untProvince: province.provinceNepali,
            });
        } else if (val.hasOwnProperty('tmpDistrict')) {
            const district = districtsTmp.find(
                district => district.districtName === val.tmpDistrict
            );
            getMunicipalities(district.districtName, 'tmp');
            updateValues({
                tmpDistrict: district.districtName,
                untDistrict: district.districtNepali,
            });
        } else if (val.hasOwnProperty('untDistrict')) {
            const district = districtsTmp.find(
                district => district.districtNepali === val.untDistrict
            );
            getMunicipalities(district.districtName, 'tmp');
            updateValues({
                tmpDistrict: district.districtName,
                untDistrict: district.districtNepali,
            });
        } else if (val.hasOwnProperty('tmpVDCMun')) {
            const municipality = municipalitiesTmp.find(
                mun => mun.municipalName === val.tmpVDCMun
            );
            updateValues({
                tmpVDCMun: municipality.municipalName,
                untvdc: municipality.municipalNepali,
            });
        } else if (val.hasOwnProperty('untvdc')) {
            const municipality = municipalitiesTmp.find(
                mun => mun.municipalNepali === val.untvdc
            );
            updateValues({
                tmpVDCMun: municipality.municipalName,
                untvdc: municipality.municipalNepali,
            });
        } else if (
            val.hasOwnProperty('provinceName') ||
            val.hasOwnProperty('provinceNepali')
        ) {
            const key = Object.keys(val)[0];
            const province = provinces.find(
                province => province[key] === val[key]
            );
            getDistricts(province.provinceName, 'per');
            const updatedValues = DLT_KEYS_FROM_OBJ(values, [
                'districtName',
                'districtNepali',
                'municipalName',
                'municipalNepali',
            ]);
            updateValues({
                ...updatedValues,
                ...province,
            });
        } else if (
            val.hasOwnProperty('districtName') ||
            val.hasOwnProperty('districtNepali')
        ) {
            const key = Object.keys(val)[0];
            const district = districtsPer.find(
                district => district[key] === val[key]
            );
            getMunicipalities(district.districtName, 'per');
            const updatedValues = DLT_KEYS_FROM_OBJ(values, [
                'municipalName',
                'municipalNepali',
            ]);
            updateValues({
                ...updatedValues,
                ...district,
            });
        } else if (
            val.hasOwnProperty('municipalName') ||
            val.hasOwnProperty('municipalNepali')
        ) {
            const key = Object.keys(val)[0];
            const municipality = municipalitiesPer.find(
                mun => mun[key] === val[key]
            );
            updateValues(municipality);
        } else if (val.hasOwnProperty('casteGroup')) {
            (async () => {
                const castes = await GET_CASTES(val.casteGroup);
                setCastes(castes);
            })();
        } else if (val.hasOwnProperty('headName')) {
            return updateValues({ headName: val.headName.toUpperCase() });
        } else if (val.hasOwnProperty('motherName')) {
            return updateValues({ motherName: val.motherName.toUpperCase() });
        } else if (val.hasOwnProperty('grandHeadName')) {
            return updateValues({
                grandHeadName: val.grandHeadName.toUpperCase(),
            });
        } else if (val.hasOwnProperty('spouse')) {
            return updateValues({
                spouse: val.spouse.toUpperCase(),
            });
        } else if (val.hasOwnProperty('staffName')) {
            const staff = staffs.find(
                staff => staff.staffName === val.staffName
            );
            updateValues(staff);
        } else if (val.hasOwnProperty('staffID')) {
            const staff = staffs.find(staff => staff.staffID === val.staffID);
            updateValues(staff);
        } else if (val.hasOwnProperty('wardNO')) {
            updateValues({ unWardNO: englishToNepaliNumber(val.wardNO) });
        } else if (val.hasOwnProperty('unWardNO')) {
            updateValues({ wardNO: nepaliToEnglishNumber(val.unWardNO) });
        } else if (val.hasOwnProperty('memberNO')) {
            (async () => {
                const guardianDetail = await GET_GUARDIAN_DETAIL(val.memberNO);
                console.log('guardianDetail: ', guardianDetail);
                const updatedValues = DLT_KEYS_FROM_OBJ(
                    { values, ...guardianDetail },
                    ['nomRel']
                );
                updateValues({
                    ...guardianDetail,
                    ...updatedValues,
                });
            })();
        } else if (
            val.hasOwnProperty(`entryDate__${mode}`) ||
            val.hasOwnProperty(`dateOfBirth__${mode}`) ||
            val.hasOwnProperty(`citDate__${mode}`)
        ) {
            return;
        }
        updateValues(val);
    };

    // On date change
    const onDateChangeEng = (date, key, mode) => {
        const dateISO = moment(date).format();
        if (mode === 'edit')
            setEditValues(prevValues => ({
                ...prevValues,
                [key]: dateISO,
                dateOfBirth: dateISO,
            }));
        else if (mode === 'add')
            setAddValues(prevValues => ({
                ...prevValues,
                [key]: dateISO,
                dateOfBirth: dateISO,
            }));
    };

    // Handle address same as permanent
    const handleAddressSameAsPermanent = mode => {
        let values;
        if (mode === 'edit') values = editValues;
        else if (mode === 'add') values = addValues;
        const updatedValues = {
            ...values,
            tmpProvince: values.provinceName,
            tmpDistrict: values.districtName,
            tmpVDCMun: values.municipalName,
            tmpWardNO: values.wardNO,
            untProvince: values.provinceNepali,
            untDistrict: values.districtNepali,
            untvdc: values.municipalNepali,
            untWardNO: values.unWardNO,
            tmpTole: values.tole,
            untTole: values.unTole,
            tmpBlockNO: values.blockNO,
        };
        if (mode === 'edit') {
            setEditValues(updatedValues);
        } else if (mode === 'add') setAddValues(updatedValues);
    };

    // On file change
    const onFileChange = (e, mode) => {
        ON_FILE_CHANGE(e, mode, setEditValues, setAddValues);
    };

    // Get owner detail
    React.useEffect(() => {
        if (viewValues?.ownerNO) {
            getOwnerDetail(viewValues.ownerNO);
        }
    }, [viewValues?.ownerNO, getOwnerDetail]); // Do not include getMemberDetail dependency

    // Form props
    const formProps = {
        ref: formRef,
        tabPanes,
        onPanelChange,
        activePanelKey,
        members,
        setMembers,
        owners,
        ownerTypes,
        setOwnerTypes,
        provinces,
        setProvinces,
        districtsTmp,
        districtsPer,
        municipalitiesTmp,
        municipalitiesPer,
        casteGroups,
        setCasteGroups,
        castes,
        religions,
        setReligions,
        maritalStatuses,
        setMaritalStatuses,
        relations,
        setRelations,
        education,
        setEducation,
        occupation,
        setOccupation,
        groups,
        setGroups,
        areas,
        setAreas,
        staffs,
        setStaffs,
        onValuesChange,
        handleAddressSameAsPermanent,
        onFileChange,
        setDefaultValues,
        ownerNOValidationStatus,
        onDateChange,
        onDateChangeEng,
        onDateKeyUp,
    };

    // On component mount
    React.useEffect(() => {
        // GET MEMBERS
        (async () => {
            const branchID = JSON.parse(
                localStorage.getItem('USER_CREDENTIALS')
            ).branchID;
            const members = await GET_OWNERS(branchID);
            setOwners(members);
        })();
    }, []);

    // Extract owner no from query param
    React.useEffect(() => {
        if (location.search) {
            // setIsPageLoading(true);
            const string = location.search;
            const ownerNO = string.slice(
                string.indexOf('=') + 1,
                string.lastIndexOf('?')
            );
            if (viewValues?.ownerNO === ownerNO) {
                return getOwnerDetail(ownerNO);
            }
            setViewValues(prevValues => ({
                ...prevValues,
                ownerNO,
            }));
            getOwnerDetail(ownerNO);
        }
    }, [location.search, getOwnerDetail, viewValues?.ownerNO]);

    return (
        <PageWrapper>
            <OwnerDetailsForm {...formProps} mode="view" values={viewValues} />
            {/* Edit account modal */}
            <Modal
                title="Owner Registration"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                footer={[
                    <Button
                        type="default"
                        onClick={isPanelFirst() ? undefined : onPanelPrev}
                        disabled={isPanelFirst() || editModal.confirmLoading}
                    >
                        Prev
                    </Button>,
                    <Button
                        type="primary"
                        onClick={isPanelLast() ? onEditModalOk : onPanelNext}
                    >
                        {isPanelLast() ? 'Update' : 'Next'}
                    </Button>,
                ]}
                onCancel={onEditModalCancel}
                destroyOnClose
                maskClosable={false}
                width={1200}
            >
                <OwnerDetailsForm
                    {...formProps}
                    mode="edit"
                    values={editValues}
                />
            </Modal>
            {/* Add account modal */}
            <Modal
                title="Owner Registration"
                visible={addModal.visible}
                confirmLoading={addModal.confirmLoading}
                footer={[
                    <Button
                        type="default"
                        onClick={isPanelFirst() ? undefined : onPanelPrev}
                        disabled={isPanelFirst() || addModal.confirmLoading}
                    >
                        Prev
                    </Button>,
                    <Button
                        type="primary"
                        onClick={isPanelLast() ? onAddModalOk : onPanelNext}
                    >
                        {isPanelLast() ? 'Create' : 'Next'}
                    </Button>,
                ]}
                onCancel={onAddModalCancel}
                destroyOnClose
                maskClosable={false}
                width={1200}
            >
                <OwnerDetailsForm
                    {...formProps}
                    mode="add"
                    values={addValues}
                />
            </Modal>
            <BottomActionBarFixed>
                <Button
                    type="primary"
                    onClick={onEditModalOpen}
                    disabled={!viewValues?.ownerNO}
                >
                    Edit
                </Button>
                <Button type="primary" onClick={onAddModalOpen}>
                    Add
                </Button>
            </BottomActionBarFixed>
        </PageWrapper>
    );
}
