import React from 'react';
import { message, Button, Modal } from 'antd';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { remits as remitsAtom } from '../../../recoil/atoms';
// Import utils
import axios from '../../../utils/axios';
import {
    SET_DEFAULT_VALUES,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../utils/functions';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import CreateRemitForm from '../../../components/Remittance/CreateRemit/CreateRemitForm/CreateRemitForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function CreateRemit() {
    // Global states
    const [remits, setRemits] = useRecoilState(remitsAtom);
    // Local states
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    const [viewValues, setViewValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [asyncUpdateModal, setAsyncUpdateModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Get new Remit ID
    React.useEffect(() => {
        if (addValues?.branchID) {
            (async () => {
                axios
                    .get(
                        `${process.env.REACT_APP_HOST}/api/Remit/NewID/${addValues?.branchID}`
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        setAddValues(prevValues => ({
                            ...prevValues,
                            remitID: res.data.data,
                        }));
                    })
                    .catch(err => {
                        console.error(err, 'Failed to get new remit ID');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    });
            })();
        }
    }, [addValues?.branchID]);

    // VIEW FORM
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];

        // let values;
        // if (mode === 'view') values = viewValues;
        // else if (mode === 'edit') values = editValues;
        // else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };

        if (key === `entryDate__${mode}`) {
            return;
        } else if (key === 'remitID' || key === 'remitName') {
            if (mode === 'view') {
                const remit = remits.find(remit => remit[key] === value);
                return updateValues(remit);
            }
        } else if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(branch => branch[key] === value);
            updateValues(branch);
        }
        // Update values
        updateValues(val);
    };

    // EDIT ACCOUNT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/Remit/UpdateRemit`,
                        editValues
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0) {
                            if (res.data.statusCode === 2) {
                                return setAsyncUpdateModal(prevValues => ({
                                    ...prevValues,
                                    visible: true,
                                }));
                            } else {
                                return message.error(res.data.statusMessage);
                            }
                        }
                        setViewValues(res.data.data);
                        setEditModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success('Updated!');
                    })
                    .catch(err => {
                        console.error(err, 'Failed to update remit');
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setEditModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ASYNC UPDATE MODAL
    // On async update modal ok
    const onAsyncUpdateModalOk = () => {
        setAsyncUpdateModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .patch(
                `${process.env.REACT_APP_HOST}/api/Remit/UpdateRemitAsync`,
                editValues
            )
            .then(res => {
                if (res.data.statusCode !== 0) {
                    if (res.data.statusCode === 2) {
                        setAsyncUpdateModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    } else return message.error(res.data.statusMessage);
                }
                setViewValues(editValues);
                const remitsUpdated = [...remits];
                const i = remitsUpdated.findIndex(
                    remit => remit.remitID === editValues.remitID
                );
                remitsUpdated[i] = res.data.data;
                setRemits(remitsUpdated);
                setAsyncUpdateModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                setEditModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success(res.data.statusMessage);
            })
            .catch(err => {
                console.error(err, 'Failed to update remit asynchronously');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setAsyncUpdateModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // On async update modal cancel
    const onAsyncUpdateModalCancel = () => {
        setAsyncUpdateModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD ACCOUNT MODAL
    // On add modal open
    const onAddModalOpen = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On add modal ok
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/Remit/InsertRemit`,
                        addValues
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        const data = res.data.data;
                        setRemits([...remits, res.data.data]);
                        setViewValues(data);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        setAddValues(null);
                        message.success('New Remit created!');
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to create area type account'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };
    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // DELETE ACCOUNT MODAL
    // On delete modal open
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On delete modal ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/Remit/DeleteRemit/${viewValues?.remitID}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setRemits(
                    [...remits].filter(
                        remit => remit.remitID !== viewValues.remitID
                    )
                );
                setViewValues(null);
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success('Remit deleted!');
            })
            .catch(err => {
                console.error(err, 'Failed to delete remit');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };
    // On add modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // COMMON
    // Set default values
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: '00',
            branchName: 'HEAD OFFICE',
            entryDate: moment().format(),
            baseDate: moment().format(),
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(
            key,
            date,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues, setEditValues, setAddValues);
    };

    // Form props
    const formProps = {
        ref: formRef,
        setDefaultValues,
        onValuesChange,
        onDateChange,
        onDateKeyUp,
    };

    return (
        <PageWrapper>
            {/* View account */}
            <CreateRemitForm {...formProps} mode="view" values={viewValues} />

            {/* Edit account modal */}
            <Modal
                title="Edit Remit"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                onOk={onEditModalOk}
                onCancel={onEditModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: editModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <CreateRemitForm
                    {...formProps}
                    mode="edit"
                    values={editValues}
                />
            </Modal>
            {/* Async edit modal */}
            <Modal
                title="Update remit?"
                visible={asyncUpdateModal.visible}
                confirmLoading={asyncUpdateModal.confirmLoading}
                onOk={onAsyncUpdateModalOk}
                onCancel={onAsyncUpdateModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: asyncUpdateModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>
                    The update may take a while. Please be patient during the
                    operation.
                </p>
            </Modal>
            {/* Add account modal */}
            <Modal
                title="Add Remit"
                visible={addModal.visible}
                confirmLoading={addModal.confirmLoading}
                onOk={onAddModalOk}
                onCancel={onAddModalCancel}
                okText="Create"
                cancelButtonProps={{
                    disabled: addModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <CreateRemitForm {...formProps} mode="add" values={addValues} />
            </Modal>
            {/* Delete account modal */}
            <Modal
                title="Delete Remit?"
                visible={deleteModal.visible}
                confirmLoading={deleteModal.confirmLoading}
                onOk={onDeleteModalOk}
                onCancel={onDeleteModalCancel}
                okText="Delete"
                cancelButtonProps={{
                    disabled: deleteModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>The account and its data will be deleted forever</p>
            </Modal>
            {/* Actions bar */}
            <BottomActionBarFixed>
                <Button
                    type="primary"
                    onClick={onEditModalOpen}
                    disabled={!viewValues?.remitID}
                >
                    Edit
                </Button>
                <Button type="primary" onClick={onAddModalOpen}>
                    Add
                </Button>
                <Button
                    type="primary"
                    onClick={onDeleteModalOpen}
                    disabled={!viewValues?.remitID}
                >
                    Delete
                </Button>
            </BottomActionBarFixed>
        </PageWrapper>
    );
}
