import React from 'react';
// Import from dependencies
import { message, Button, Modal } from 'antd';
// Import utils
import axios from '../../../utils/axios';
import moment from 'moment';
import {
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
    SET_DEFAULT_VALUES,
} from '../../../utils/functions';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import AssetsTypeAccountForm from '../../../components/AssetsTypeAccount/AssetsTypeAccountForm/AssetsTypeAccountForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function AssetsTypeAccount() {
    // LOCAL STATES
    // Accounts
    const [mainAccountType, setMainAccountType] = React.useState(null);
    const [mainAccounts, setMainAccounts] = React.useState([]);
    const [accounts, setAccounts] = React.useState([]);
    // Form values
    const [viewFormValues, setViewFormValues] = React.useState(null);
    const [editFormValues, setEditFormValues] = React.useState(null);
    const [addFormValues, setAddFormValues] = React.useState(null);
    // Modals
    const [editAccountModal, setEditAccountModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addAccountModal, setAddAccountModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteAccountModal, setDeleteAccountModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Get assets accounts by main account
    const getAssetsAccountsByMANO = async mano => {
        setAccounts([]);
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_HOST}/api/AssetsType/AssetsAccount/${mano}`
            );
            if (res.data.statusCode !== 0)
                return message.error(res.data.statusMessage);

            setAccounts(res.data.data);
        } catch (err) {
            console.error(err, 'Failed to get accounts by mano');
            message.error('Something went wrong. Please try again!');
        }
    };

    // Get account detail
    const getAccountDetailByACNO = acno => {
        let assetType;
        switch (mainAccountType) {
            case 'FIXED ASSETS':
                assetType = 'FixedAssetsType';
                break;
            case 'OTHER ASSETS':
                assetType = 'OtherAssetsType';
                break;
            default:
                assetType = '';
        }
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/AssetsType/${assetType}/${acno}`
            )
            .then(res => {
                if (res.data.statusCode === 1)
                    return message.error(res.data.statusMessage);
                const data = res.data.data;

                setViewFormValues(prevValues => ({
                    ...prevValues,
                    ...data,
                }));
            });
    };

    // VIEW FORM
    // On view form values change
    const onViewFormValuesChange = val => {
        if (val.hasOwnProperty('maName')) {
            setMainAccountType(val.maName);
            const viewFormValuesUpdated = { ...viewFormValues };
            delete viewFormValuesUpdated?.acno;
            delete viewFormValuesUpdated?.acName;
            delete viewFormValuesUpdated?.nepName;
            delete viewFormValuesUpdated?.depreciationRate;
            delete viewFormValuesUpdated?.description;
            delete viewFormValuesUpdated?.formedDate;
            delete viewFormValuesUpdated?.govCode;
            setViewFormValues(viewFormValuesUpdated);
            const mano = mainAccounts.find(
                acc => acc.maName === val.maName
            ).mano;
            getAssetsAccountsByMANO(mano);
        } else if (
            val.hasOwnProperty('acno') ||
            val.hasOwnProperty('acName') ||
            val.hasOwnProperty('nepName')
        ) {
            const key = Object.keys(val)[0];
            const acc = accounts.find(acc => acc[key] === val[key]);

            getAccountDetailByACNO(acc.acno);
            setViewFormValues(prevValues => ({
                ...prevValues,
                ...acc,
            }));
        } else if (val.hasOwnProperty('formedDate__view')) {
            return;
        }
        setViewFormValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // EDIT FORM
    const onEditFormValuesChange = val => {
        if (val.hasOwnProperty('acName'))
            return setEditFormValues({
                ...editFormValues,
                acName: val.acName.toUpperCase(),
            });
        else if (val.hasOwnProperty('formedDate__edit')) {
            return;
        }
        setEditFormValues({
            ...editFormValues,
            ...val,
        });
    };

    // EDIT ACCOUNT MODAL
    // Open Edit Account Modal
    const onEditAccountModalOpen = () => {
        setEditFormValues(viewFormValues);
        setEditAccountModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On Edit account modal OK
    const onEditAccountModalOk = () => {
        formRef.current.validateFields().then(() => {
            setEditAccountModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));
            console.log(editFormValues);
            const payload = editFormValues?.depreciationRate
                ? editFormValues
                : { ...editFormValues, depreciationRate: 0 };
            axios
                .patch(
                    `${process.env.REACT_APP_HOST}/api/AssetsType/UpdateAssets/${editFormValues.acno}`,
                    payload
                )
                .then(res => {
                    if (res.data.statusCode !== 0) {
                        setEditAccountModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                        return message.error(res.data.statusMessage);
                    }
                    setViewFormValues(payload);
                    setEditAccountModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    message.success('Account updated!');
                })
                .catch(err => {
                    console.error(err, 'Failed to update assets type account');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setEditAccountModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };

    const onEditAccountModalCancel = () => {
        setEditAccountModal(prevValues => ({
            ...prevValues,
            visible: false,
            confirmLoading: false,
        }));
        setEditFormValues(null);
    };

    // ADD ACCOUNT MODAL
    const onAddAccountModalOpen = () => {
        setAddAccountModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // Get new account number
    const getNewAccNum = mano => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/AssetsType/NewAssets/${mano}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setAddFormValues(prevValues => ({
                    ...prevValues,
                    acno: res.data.data,
                }));
            })
            .catch(err => {
                console.error(
                    err,
                    'Failed to get account number for assets type'
                );
                message.error('Something went wrong. Please try again!');
            });
    };
    // On add form values change
    const onAddFormValuesChange = val => {
        if (val.hasOwnProperty('maName')) {
            setMainAccountType(val.maName);
            const mano = mainAccounts.find(
                acc => acc.maName === val.maName
            ).mano;
            setAddFormValues(null);
            // Remove account number from state
            const addFormValuesUpdated = { ...addFormValues };
            delete addFormValuesUpdated.acno;
            setAddFormValues(addFormValuesUpdated);
            // Get new account number
            getNewAccNum(mano);
        } else if (val.hasOwnProperty('acName'))
            return setAddFormValues(prevValues => ({
                ...prevValues,
                acName: val.acName.toUpperCase(),
            }));
        else if (val.hasOwnProperty('formedDate__add')) {
            return;
        }
        setAddFormValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };
    // On account create success
    const onAccCreateSuccess = async payload => {
        const mano = mainAccounts.find(
            acc => acc.maName === addFormValues.maName
        ).mano;
        await getAssetsAccountsByMANO(mano);
        setAddAccountModal(prevValues => ({
            ...prevValues,
            visible: false,
            confirmLoading: false,
        }));
        setViewFormValues(payload);
        message.success(`${addFormValues.acName} created`);
        setAddFormValues(null);
    };
    // On add account modal ok
    const onAddAccountModalOk = async () => {
        formRef.current
            .validateFields()
            .then(() => {
                setAddAccountModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));

                const payload = addFormValues?.depreciationRate
                    ? addFormValues
                    : { ...addFormValues, depreciationRate: 0 };

                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/AssetsType/AddAssets/${addFormValues.acno}`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0) {
                            setAddAccountModal(prevValues => ({
                                ...prevValues,
                                confirmLoading: false,
                            }));
                            return message.error(res.data.statusMessage);
                        }

                        onAccCreateSuccess(payload);
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to create assets type account'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                    })
                    .finally(() => {
                        setAddAccountModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {});
    };

    // On add account modal cancel
    const onAddAccountModalCancel = () => {
        setAddAccountModal(prevValues => ({
            ...prevValues,
            visible: false,
            confirmLoading: false,
        }));
        setAddFormValues(null);
    };

    // DELETE ACCOUNT MODAL
    const onDeleteAccountClick = () => {
        setDeleteAccountModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On delete account modal OK
    const onDeleteAccountModalOk = () => {
        setDeleteAccountModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));

        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/AssetsType/DeleteAccount/${viewFormValues.acno}`
            )
            .then(res => {
                console.log('res: ', res);
                if (res.data.statusCode !== 0) {
                    return message.error(res.data.statusMessage);
                }
                setViewFormValues(null);
                setAccounts([]);
                setDeleteAccountModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to delete account');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setDeleteAccountModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // on delete account modal close
    const onDeleteAccountModalCancel = () => {
        setDeleteAccountModal(prevValues => ({
            ...prevValues,
            visible: false,
            confirmLoading: false,
        }));
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(
            key,
            date,
            mode,
            setViewFormValues,
            setEditFormValues,
            setAddFormValues
        );
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(
            e,
            mode,
            setViewFormValues,
            setEditFormValues,
            setAddFormValues
        );
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            formedDate: moment().format(),
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewFormValues,
            setEditFormValues,
            setAddFormValues
        );
    }, []);

    // Form props
    const formProps = {
        ref: formRef,
        mainAccountType,
        mainAccounts,
        accounts,
        onDateChange,
        onDateKeyUp,
        setDefaultValues,
    };

    // On component mount
    React.useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/AssetsType/MainAccount`)
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                setMainAccounts(res.data.data);
            })
            .catch(err => {
                console.error(err, 'Failed to get assets main accounts');
                message.error('Something went wrong. Please refresh the page');
            });
    }, []);
    return (
        <PageWrapper>
            <AssetsTypeAccountForm
                {...formProps}
                formValues={viewFormValues}
                onValuesChange={onViewFormValuesChange}
                mode="view"
            />
            <BottomActionBarFixed>
                <Button
                    type="primary"
                    onClick={onEditAccountModalOpen}
                    disabled={!viewFormValues?.acno}
                >
                    Edit
                </Button>
                <Button type="primary" onClick={onAddAccountModalOpen}>
                    Add
                </Button>
                <Button
                    type="primary"
                    disabled={!viewFormValues?.acno}
                    onClick={onDeleteAccountClick}
                >
                    Delete
                </Button>
            </BottomActionBarFixed>
            {/* Edit account modal */}
            <Modal
                title="Edit account"
                visible={editAccountModal.visible}
                confirmLoading={editAccountModal.confirmLoading}
                onOk={onEditAccountModalOk}
                onCancel={onEditAccountModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: editAccountModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <AssetsTypeAccountForm
                    {...formProps}
                    formValues={editFormValues}
                    onValuesChange={onEditFormValuesChange}
                    mode="edit"
                />
            </Modal>
            {/* Add account modal */}
            <Modal
                title="Add an account"
                visible={addAccountModal.visible}
                confirmLoading={addAccountModal.confirmLoading}
                onOk={onAddAccountModalOk}
                onCancel={onAddAccountModalCancel}
                okText="Create"
                cancelButtonProps={{
                    disabled: addAccountModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <AssetsTypeAccountForm
                    {...formProps}
                    formValues={addFormValues}
                    onValuesChange={onAddFormValuesChange}
                    mode="add"
                />
            </Modal>
            {/* Delete account modal */}
            <Modal
                title="Delete account?"
                visible={deleteAccountModal.visible}
                confirmLoading={deleteAccountModal.confirmLoading}
                onOk={onDeleteAccountModalOk}
                onCancel={onDeleteAccountModalCancel}
                cancelButtonProps={{
                    disabled: deleteAccountModal.confirmLoading,
                }}
            >
                <p>The account and its data will be deleted forever</p>
            </Modal>
        </PageWrapper>
    );
}
