import React from 'react';
import { Form, Table, Checkbox, Pagination, Button, Row, Col } from 'antd';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../utils/functions';
import ColumnsSelection from '../../../Common/ColumnsSelection/ColumnsSelection';
import Print from '../../../Common/Print/Print';
import ReactToPrint from 'react-to-print';

export default function ExpiringSmsBankingAccountsForm(props) {
    // Destructuring
    const {
        accountsList,
        isLoading,
        onPaginationChange,
        columnsList,
        onColumnsChange,
        onDateChange,
        onDateKeyUp,
        values,
        onValuesChange,
    } = props;

    // Refs
    const printRef = React.useRef();

    // NEPALI DATEPICKER
    // From date
    const fromDateInput = document.getElementById('from');
    fromDateInput &&
        fromDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, fromDateInput)
        );

    // To date
    const toDateInput = document.getElementById('to');
    toDateInput &&
        toDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, toDateInput)
        );

    // Variables and Constants

    const dataSource = accountsList?.dataList?.map((item, i) => {
        return {
            key: i + 1 + '',
            ...item,
        };
    });

    const columns = [
        ...(columnsList.includes('SMS Feature')
            ? [
                  {
                      title: 'SMS Feature',
                      dataIndex: 'smsFeature',
                      key: 'smsFeature',
                      render: text => (
                          <Checkbox checked={text} disabled></Checkbox>
                      ),
                  },
              ]
            : []),
        {
            title: 'Member No',
            dataIndex: 'memberNO',
            key: 'memberNO',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'A/C Type',
            dataIndex: 'acName',
            key: 'acName',
        },
        ...(columnsList.includes('A/C Type No')
            ? [
                  {
                      title: 'A/C Type No',
                      dataIndex: 'acno',
                      key: 'acno',
                  },
              ]
            : []),

        {
            title: 'Account No',
            dataIndex: 'accountNO',
            key: 'accountNO',
        },
        ...(columnsList.includes('Entry Date')
            ? [
                  {
                      title: 'Entry Date',
                      dataIndex: 'smsEntryDate',
                      key: 'smsEntryDate',
                      render: text => ENG_ISO_TO_NEP(text),
                  },
              ]
            : []),
        ...(columnsList.includes('Expiry Date')
            ? [
                  {
                      title: 'Expiry Date',
                      dataIndex: 'smsExpireDate',
                      key: 'smsExpireDate',
                      render: text => ENG_ISO_TO_NEP(text),
                  },
              ]
            : []),

        {
            title: 'Mobile No',
            dataIndex: 'smsMobileNO',
            key: 'smsMobileNO',
        },
        ...(columnsList.includes('Renewed By')
            ? [
                  {
                      title: 'Renewed By',
                      dataIndex: 'smsRenewedBy',
                      key: 'smsRenewedBy',
                  },
              ]
            : []),
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const columnsSelectionList = [
        'SMS Feature',
        'A/C Type No',
        'Entry Date',
        'Expiry Date',
        'Renewed By',
    ];

    return (
        <>
            <Form
                onValuesChange={onValuesChange}
                fields={[
                    {
                        name: 'from',
                        value: values?.from && ENG_ISO_TO_NEP(values?.from),
                    },
                    {
                        name: 'to',
                        value: values?.to && ENG_ISO_TO_NEP(values?.to),
                    },
                ]}
            >
                <Row gutter={20}>
                    <Col span={20}>
                        <Row gutter={20}>
                            <Col span={6}>
                                <Form.Item label="From" name="from">
                                    <input
                                        type="text"
                                        className="ant-input"
                                        onKeyUp={onDateKeyUp}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label="To" name="to">
                                    <input
                                        type="text"
                                        className="ant-input"
                                        onKeyUp={onDateKeyUp}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        span={4}
                        className="text-right"
                        style={{ marginBottom: '1rem' }}
                    >
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    type="default"
                                    style={{ marginLeft: 'auto' }}
                                >
                                    Print
                                </Button>
                            )}
                            content={() => printRef.current}
                        />
                    </Col>
                </Row>
            </Form>
            <ColumnsSelection
                columns={columnsSelectionList}
                selectedColumns={columnsList}
                onColumnsChange={onColumnsChange}
            />
            <Print ref={printRef} title="SMS Banking Accounts">
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    size="small"
                    style={{ marginTop: '1rem' }}
                    loading={isLoading}
                />
            </Print>
            <Pagination
                size="small"
                total={accountsList?.totalRecords}
                showSizeChanger
                onChange={onPaginationChange}
                style={{ marginTop: '.5rem' }}
            />
        </>
    );
}
