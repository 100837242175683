import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
    GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS,
    GET_SAVING_TYPE_ACCOUNTS_FULL,
    GET_SAVING_ACCOUNT_INFO_SHORT,
    GET_SMS_BANKING_ACTIVE,
    GET_MOBILE_BANKING_ACTIVE,
    ACTIVATE_SMS_BANKING,
    ACTIVATE_MOBILE_BANKING,
} from '../../../../utils/axios';
import { ON_DATE_CHANGE, ON_DATE_KEYUP } from '../../../../utils/functions';
// Components
import PageWrapper from '../../../../components/Common/PageWrapper/PageWrapper';
import ActivateDepositFeaturesForm from '../../../../components/Savings/DepositFeatures/ActivateDepositFeatures/ActivateDepositFeaturesForm/ActivateDepositFeaturesForm';

export default function ActivateDepositFeatures() {
    // Local states
    const [viewValues, setViewValues] = React.useState({});
    const [depTypes, setDepTypes] = React.useState([]);
    const [depTypeAccounts, setDepTypeAccounts] = React.useState([]);
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];

    // Destructuring
    const { confirm } = Modal;

    // Variables and Constants

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // On values change
    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        // const values = viewValues;

        // Update values on state
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(item => item[key] === value);
            return updateValues(branch);
        } else if (key === 'acno' || key === 'acName') {
            const acc = depTypes.find(item => item[key] === value);
            return updateValues(acc);
        } else if (key === 'fullName' || key === 'accountNo') {
            const acc = depTypeAccounts.find(item => item[key] === value);
            return updateValues(acc);
        }

        // Update values
        updateValues(val);
    };

    // On features save click
    const onFeaturesSaveClick = feature => {
        confirm({
            title: 'Do you want to save?',
            icon: <ExclamationCircleOutlined />,
            content: 'Please review your changes before submitting.',
            okText: 'Save',
            onOk() {
                return new Promise((resolve, reject) => {
                    let payload = {
                        accountNO: '01R0002',
                        acno: '030.01',
                        memberNO: '010002',
                        name: 'HARI SHANKAR TIMALSINA',
                        acName: 'REGULAR MONTHLY SAVING',
                    };
                    if (feature === 'sms') {
                        payload = {
                            ...payload,
                            smsFeature: viewValues.smsFeature,
                            smsMobileNO: viewValues.smsMobileNO,
                            smsEntryDate: viewValues.smsEntryDate,
                            smsExpireDate: viewValues.smsExpireDate,
                            smsRenewedBy: JSON.parse(
                                localStorage.getItem('USER_CREDENTIALS')
                            ).userName,
                            status: viewValues.smsStatus,
                        };

                        (async () => {
                            const res = await ACTIVATE_SMS_BANKING(payload);
                            setViewValues(prevValues => ({
                                ...prevValues,
                                ...res,
                                smsStatus: res.status,
                            }));
                            resolve();
                        })();
                    } else if (feature === 'mobile') {
                        payload = {
                            ...payload,
                            mbFeature: viewValues.mbFeature,
                            mbMobileNO: viewValues.mbMobileNO,
                            mbEntryDate: viewValues.mbEntryDate,
                            mbExpireDate: viewValues.mbExpireDate,
                            mbRenewedBy: JSON.parse(
                                localStorage.getItem('USER_CREDENTIALS')
                            ).userName,
                            status: viewValues.mbStatus,
                        };
                        (async () => {
                            const res = await ACTIVATE_MOBILE_BANKING(payload);
                            setViewValues(prevValues => ({
                                ...prevValues,
                                ...res,
                                mbStatus: res.status,
                            }));
                            resolve();
                        })();
                    }
                });
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // Get member info short
    React.useEffect(() => {
        if (!viewValues.branchID || !viewValues?.accountNo || !viewValues?.acno)
            return;
        (async () => {
            const memInfoShort = await GET_SAVING_ACCOUNT_INFO_SHORT(
                viewValues?.branchID,
                viewValues?.accountNo,
                viewValues?.acno
            );
            const { memberNo, address } = memInfoShort;

            setViewValues(prevValues => ({
                ...prevValues,
                memberNo,
                address,
            }));
        })();
    }, [viewValues?.branchID, viewValues?.accountNo, viewValues?.acno]);

    // Get deposit type accounts
    React.useEffect(() => {
        if (!viewValues?.branchID || !viewValues?.acno) return;
        (async () => {
            setDepTypeAccounts([]);
            const depTypeAccounts = await GET_SAVING_TYPE_ACCOUNTS_FULL(
                viewValues?.branchID,
                viewValues?.acno
            );
            setDepTypeAccounts(depTypeAccounts);
        })();
    }, [viewValues?.branchID, viewValues?.acno]);

    // Get SMS Banking detail
    React.useEffect(() => {
        if (!viewValues?.acno || !viewValues?.accountNo) return;
        (async () => {
            const smsBanking = await GET_SMS_BANKING_ACTIVE(
                viewValues?.acno,
                viewValues?.accountNo
            );
            const mobileBanking = await GET_MOBILE_BANKING_ACTIVE(
                viewValues?.acno,
                viewValues?.accountNo
            );
            console.log('smsBanking: ', smsBanking);
            console.log('mobileBanking: ', mobileBanking);
            setViewValues(prevValues => ({
                ...prevValues,
                ...smsBanking,
                smsStatus: smsBanking.status,
                ...mobileBanking,
                mbStatus: mobileBanking.status,
            }));
        })();
    }, [viewValues?.acno, viewValues?.accountNo]);

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
        (async () => {
            const depTypeAccounts = await GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS();
            setDepTypes(depTypeAccounts);
        })();
    }, []);

    // Form props
    const formProps = {
        branches,
        onValuesChange,
        onDateChange,
        onDateKeyUp,
        values: viewValues,
        depTypes,
        depTypeAccounts,
        onFeaturesSaveClick,
    };
    return (
        <PageWrapper>
            <ActivateDepositFeaturesForm {...formProps} />
        </PageWrapper>
    );
}
