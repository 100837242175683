import React from 'react';
// Import utils
import { GET_MEMBERS, GET_MEMBER_PROPERTIES } from '../../../utils/axios';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import MemberInfoForm from '../../../components/Membership/MemberInfo/MemberInfoForm/MemberInfoForm';
import MemberInfoPersonalDetail from './MemberInfoPersonalDetail/MemberInfoPersonalDetail';
import MemberInfoDepositDetail from './MemberInfoDepositDetail/MemberInfoDepositDetail';
import MemberInfoShareDetail from './MemberInfoShareDetail/MemberInfoShareDetail';
import MemberInfoLoanDetail from './MemberInfoLoanDetail/MemberInfoLoanDetail';
import MemberInfoLPFundDetail from './MemberInfoLPFundDetail/MemberInfoLPFundDetail';
import { IS_VALID_DATE } from '../../../utils/functions';
import { message } from 'antd';

export default function MemberInfo(props) {
    // Local states
    const [members, setMembers] = React.useState([]);
    const [memberProperties, setMemberProperties] = React.useState(null);
    const [activePanelKey, setActivePanelKey] = React.useState('0');
    const [viewValues, setViewValues] = React.useState(null);

    // Destructuring
    // Ref
    const formRef = React.useRef();

    // Variables and constants
    const tabPanes = [
        {
            title: 'Personal Detail',
            component: <MemberInfoPersonalDetail />,
        },
        {
            title: 'Share Detail',
            component: <MemberInfoShareDetail />,
        },
        {
            title: 'Deposit Detail',
            component: <MemberInfoDepositDetail />,
        },
        {
            title: 'Loan Detail',
            component: <MemberInfoLoanDetail />,
        },
        {
            title: 'Loan Payment Fund Detail',
            component: <MemberInfoLPFundDetail />,
        },
    ];

    // Utility functions
    // On tabs change
    const onPanelChange = activeKey => {
        setActivePanelKey(activeKey);
    };

    // On values change
    const onValuesChange = val => {
        // console.log('val: ', val);
        const key = Object.keys(val)[0];
        const value = val[key];
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };

        if (key === 'memberNO' || key === 'memberName') {
            const member = members.find(item => item[key] === value);
            updateValues(member);
        }

        updateValues(val);
    };

    // Form props
    const formProps = {
        ref: formRef,
        tabPanes,
        viewValues,
        members,
        memberProperties,
        activePanelKey,
        onPanelChange,
        onValuesChange,
    };

    // Get member properties
    React.useEffect(() => {
        if (!viewValues?.memberNO) return;
        setMemberProperties(null);
        (async () => {
            const memberProperties = await GET_MEMBER_PROPERTIES(
                viewValues?.memberNO
            );
            if (
                !IS_VALID_DATE(memberProperties?.dateOfBirth) ||
                !IS_VALID_DATE(memberProperties?.dobEnglish)
            ) {
                message.error('Invalid Date of Birth!');
                delete memberProperties.dateOfBirth;
                delete memberProperties.dobEnglish;
            }
            console.log('memberProperties: ', memberProperties);
            setMemberProperties(memberProperties);
        })();
    }, [viewValues?.memberNO]);

    // On component mount
    React.useEffect(() => {
        // GET MEMBERS
        (async () => {
            const members = await GET_MEMBERS();
            setMembers(members);
        })();
    }, []);

    return (
        <PageWrapper>
            <MemberInfoForm {...formProps} mode="view" />
        </PageWrapper>
    );
}
