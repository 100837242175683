import React from 'react';
// Import from dependencies
import { Layout, Avatar, Dropdown, Menu, Badge } from 'antd';
import {
    UserOutlined,
    SettingOutlined,
    LogoutOutlined,
    BellFilled,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import {
    verificationStatus as verificationStatusAtom,
    enterpriseDetail as enterpriseDetailAtom,
    notifications as notificationsAtom,
} from '../../../recoil/atoms';
import moment from 'moment';
import axios, { GET_NOTIFICATIONS } from '../../../utils/axios';
import Loading from '../../Common/Loading/Loading';

export default function AppLayoutHeader() {
    // Global states
    const setVerificationStatus = useSetRecoilState(verificationStatusAtom);
    const enterpriseDetail = useRecoilValue(enterpriseDetailAtom);
    const [notifications, setNotifications] = useRecoilState(notificationsAtom);
    // Local states
    const [isLoadingNotifications, setIsLoadingNotifications] =
        React.useState(false);

    // Variables and constants
    const history = useHistory();
    const date = moment().format('dddd Do MMMM, YYYY');

    // Destructuring
    const { Header } = Layout;

    // Logout
    const handleLogout = async () => {
        await axios.post(`${process.env.REACT_APP_HOST}/api/login/logout`);
        // Clear local storage
        localStorage.clear();
        // Set verification status
        setVerificationStatus('unauthorized');
        // Return to login screen
        history.push('/auth/login');
    };

    // Avatar menu
    const avatarMenu = (
        <Menu>
            <Menu.Item key="0" icon={<UserOutlined />}>
                View Profile
            </Menu.Item>
            <Menu.Item key="1" icon={<SettingOutlined />}>
                Settings
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3" icon={<LogoutOutlined />} onClick={handleLogout}>
                Logout
            </Menu.Item>
        </Menu>
    );

    const notificationsDropdownMenu = isLoadingNotifications ? (
        <Menu>
            <Menu.Item key="0">
                <Loading height="4rem" />
            </Menu.Item>
        </Menu>
    ) : (
        <Menu>
            {notifications?.dataList?.map((item, i) => (
                <Menu.Item key={i}>
                    <div className="d-flex" style={{ alignItems: 'center' }}>
                        {item.header}
                        <div style={{ marginLeft: 'auto' }}>
                            <Badge count={item.count} size="small"></Badge>
                        </div>
                    </div>
                </Menu.Item>
            ))}
        </Menu>
    );

    // On notifications dropdown visible change
    const onNotificationsDropdownVisibleChange = async visible => {
        if (!visible) return;
        setIsLoadingNotifications(true);
        const notifications = await GET_NOTIFICATIONS();
        setNotifications(notifications);
        setIsLoadingNotifications(false);
    };

    // On component mount
    React.useEffect(() => {
        // Get notifications
        (async () => {
            const notifications = await GET_NOTIFICATIONS();
            setNotifications(notifications);
        })();
    }, [setNotifications]);

    return (
        <Header className="site-layout-background app-layout-header">
            <div className="app-layout-header__left">
                {enterpriseDetail?.companyName}
            </div>
            <div className="app-layout-header__right">
                <div className="app-layout-header__date-time">{date}</div>
                <div className="app-layout-header__notifications">
                    <Dropdown
                        overlay={notificationsDropdownMenu}
                        trigger={['click']}
                        onVisibleChange={onNotificationsDropdownVisibleChange}
                        overlayStyle={{ minWidth: '14rem' }}
                    >
                        <Badge count={notifications?.totalnotify} size="small">
                            <div className="app-layout-header__notifications-icon">
                                <BellFilled />
                            </div>
                        </Badge>
                    </Dropdown>
                </div>
                <div className="app-layout-header__username">
                    {
                        JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                            ?.userName
                    }
                </div>
                <Dropdown overlay={avatarMenu} trigger={['click']}>
                    <Avatar src="https://joeschmoe.io/api/v1/random" />
                </Dropdown>
            </div>
        </Header>
    );
}
