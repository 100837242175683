import React from 'react';
import {
    Form,
    Row,
    Col,
    Input,
    Select,
    Table,
    Checkbox,
    Space,
    Button,
    Radio,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import {
    GET_DATEPICKER_CONFIG,
    ENG_ISO_TO_NEP,
} from '../../../../utils/functions';
import validator from 'validator';

import Branches from '../../../Common/Geography/Branches/Branches';

const LoanRecoveryForm = React.forwardRef((props, ref) => {
    // Destructure props
    const {
        onValuesChange,
        onDateChange,
        values,
        onDateKeyUp,
        mode,
        setDefaultValues,
        loanTypeAccounts,
        loanMembers,
        onLoanPaymentCheckboxChange,
        handleSubmitAmount,
        cashCounters,
        banks,
        otherAccounts,
        otherItems,
        onShowAccountsClick,
        onTotalLoanClick,
        onFormSubmit,
        onIntCalcReportClick,
        onSubmitAmountKeyUp,
        onLoanScheduleClick,
    } = props;

    // Variables and constants
    const options = [
        {
            label: 'PM',
            value: '2',
        },
        {
            label: 'CM',
            value: '1',
        },
        {
            label: 'CD',
            value: '3',
        },
    ];
    const dataSource = [
        {
            key: '1',
            particular: 'Due Penalty',
            receivable: values?.duepenalty,
            receivableFieldName: 'duepenalty',
            deductedFieldName: 'duePenaltyPaid',
            rebateFieldName: 'rebateDuePenaltyBal',
        },
        {
            key: '2',
            particular: 'Due Interest',
            receivable: values?.dueinterest,
            receivableFieldName: 'dueinterest',
            deductedFieldName: 'dueInterestPaid',
            rebateFieldName: 'rebateDueIntBal',
        },
        {
            key: '3',
            particular: 'Regular Pen.',
            receivable: values?.penalty,
            receivableFieldName: 'penalty',
            deductedFieldName: 'penaltyPaid',
            rebateFieldName: 'rebatePenaltyBal',
        },
        {
            key: '4',
            particular: 'Regular Int.',
            receivable: values?.interest,
            receivableFieldName: 'interest',
            deductedFieldName: 'interestPaid',
            rebateFieldName: 'rebateIntBal',
        },
        {
            key: '5',
            particular: 'Add Interest',
            receivable: values?.additionalinterest,
            receivableFieldName: 'additionalinterest',
            deductedFieldName: 'additionalInterestPaid',
            rebateFieldName: 'rebateAddIntBal',
        },
        {
            key: '6',
            particular: 'Rem. Loan',
            receivable: values?.balance,
            receivableFieldName: 'balance',
            deductedFieldName: 'loanBal',
            rebateFieldName: '',
        },
        {
            key: '7',
            particular: 'Total',
            receivable: values?.receivableTotal,
            receivableFieldName: 'receivableTotal',
            deductedFieldName: 'deductedTotal',
            rebateFieldName: 'rebateBal',
        },
    ];

    const columns = [
        {
            title: 'Particular',
            dataIndex: 'particular',
            key: 'particular',
            render: text => (
                <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                    {text}
                </span>
            ),
        },
        {
            title: 'Receivable',
            dataIndex: 'receivable',
            key: 'receivable',
            render: (text, data) => {
                const isEditable = [
                    'penalty',
                    'interest',
                    'additionalinterest',
                ];
                return (
                    <Form.Item name={data.receivableFieldName}>
                        <Input
                            disabled={
                                !isEditable.includes(
                                    data.receivableFieldName
                                ) || !values?.selfCalculation
                            }
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: <CheckOutlined />,
            dataIndex: 'isChecked',
            key: 'isChecked',
            render: (bool, data) =>
                data.particular !== 'Total' && (
                    <Form.Item
                        name={`loanPaymentCheckbox__${data.key}`}
                        valuePropName="checked"
                    >
                        <Checkbox
                            // checked={bool}
                            onChange={e => onLoanPaymentCheckboxChange(e, data)}
                        ></Checkbox>
                    </Form.Item>
                ),
        },
        {
            title: 'Deducted',
            dataIndex: 'deducted',
            key: 'deducted',
            render: (text, data) => {
                return (
                    <Form.Item
                        name={data.deductedFieldName}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue(
                                            data.receivableFieldName
                                        ) >= value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error('Amount overflow')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input
                            disabled={
                                data.deductedFieldName === 'deductedTotal'
                            }
                        />
                    </Form.Item>
                );
            },
        },
        {
            title: 'Rebate',
            dataIndex: 'rebate',
            key: 'rebate',
            render: (text, data) => {
                return (
                    data.rebateFieldName && (
                        <Form.Item
                            name={data.rebateFieldName}
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (
                                            !value ||
                                            getFieldValue(
                                                data.deductedFieldName
                                            ) >= value
                                        ) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('Amount overflow')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                disabled={
                                    !values?.rebateSystem ||
                                    data.rebateFieldName === 'rebateBal'
                                }
                            />
                        </Form.Item>
                    )
                );
            },
        },
    ];

    // NEPALI DATEPICKER
    // Trans date
    const intCalcDateInput = document.getElementById('loanCalcDate');
    intCalcDateInput &&
        intCalcDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, intCalcDateInput, mode)
        );

    // Pay Interest Upto
    const intDateInput = document.getElementById('interestCalcDate');
    intDateInput &&
        intDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, intDateInput, mode)
        );

    // Utils

    React.useEffect(() => {
        setDefaultValues();
    }, [setDefaultValues]);

    return (
        <Form
            ref={ref}
            layout="horizontal"
            onValuesChange={onValuesChange}
            // onFinish={onFormSubmit}
            fields={[
                {
                    name: 'branchID',
                    value: values?.branchID,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'acno',
                    value: values?.acno,
                },
                {
                    name: 'acName',
                    value: values?.acName,
                },
                {
                    name: 'months',
                    value: values?.months,
                },
                {
                    name: 'name',
                    value: values?.name,
                },
                {
                    name: 'loanNo',
                    value: values?.loanNo,
                },
                {
                    name: 'duepenalty',
                    value: values?.duepenalty,
                },
                {
                    name: 'dueinterest',
                    value: values?.dueinterest,
                },
                {
                    name: 'penalty',
                    value: values?.penalty,
                },
                {
                    name: 'interest',
                    value: values?.interest,
                },
                {
                    name: 'additionalinterest',
                    value: values?.additionalinterest,
                },
                {
                    name: 'balance',
                    value: values?.balance,
                },
                {
                    name: 'selfCalculation',
                    value: values?.selfCalculation,
                },
                {
                    name: 'duePenaltyPaid',
                    value: values?.duePenaltyPaid,
                },
                {
                    name: 'dueInterestPaid',
                    value: values?.dueInterestPaid,
                },
                {
                    name: 'penaltyPaid',
                    value: values?.penaltyPaid,
                },
                {
                    name: 'interestPaid',
                    value: values?.interestPaid,
                },
                {
                    name: 'additionalInterestPaid',
                    value: values?.additionalInterestPaid,
                },
                {
                    name: 'loanBal',
                    value: values?.loanBal,
                },
                {
                    name: 'receivableTotal',
                    value: values?.receivableTotal,
                },
                {
                    name: 'deductedTotal',
                    value: values?.deductedTotal,
                },
                {
                    name: 'loanPaymentCheckbox__1',
                    value: values?.loanPaymentCheckbox__1,
                },
                {
                    name: 'loanPaymentCheckbox__2',
                    value: values?.loanPaymentCheckbox__2,
                },
                {
                    name: 'loanPaymentCheckbox__3',
                    value: values?.loanPaymentCheckbox__3,
                },
                {
                    name: 'loanPaymentCheckbox__4',
                    value: values?.loanPaymentCheckbox__4,
                },
                {
                    name: 'loanPaymentCheckbox__5',
                    value: values?.loanPaymentCheckbox__5,
                },
                {
                    name: 'loanPaymentCheckbox__6',
                    value: values?.loanPaymentCheckbox__6,
                },
                {
                    name: 'submitAmount',
                    value: values?.submitAmount,
                },
                {
                    name: 'rebateSystem',
                    value: values?.rebateSystem,
                },
                {
                    name: 'cashItem',
                    value: values?.cashItem,
                },
                {
                    name: 'cashItemNo',
                    value: values?.cashItemNo,
                },
                {
                    name: 'bankItemNo',
                    value: values?.bankItemNo,
                },
                {
                    name: 'bankItem',
                    value: values?.bankItem,
                },
                {
                    name: 'otherACNO',
                    value: values?.otherACNO,
                },
                {
                    name: 'otherAcName',
                    value: values?.otherAcName,
                },
                {
                    name: 'otherItem',
                    value: values?.otherItem,
                },
                {
                    name: 'otherItemNo',
                    value: values?.otherItemNo,
                },
                {
                    name: 'lpFundCheckbox',
                    value: values?.lpFundCheckbox,
                },
                {
                    name: 'lpBalancePaid',
                    value: values?.lpBalancePaid,
                },
                {
                    name: 'shareCheckbox',
                    value: values?.shareCheckbox,
                },
                {
                    name: 'shareBalPaid',
                    value: values?.shareBalPaid,
                },
                {
                    name: 'cashCheckbox',
                    value: values?.cashCheckbox,
                },
                {
                    name: 'cashBal',
                    value: values?.cashBal,
                },
                {
                    name: 'bankBal',
                    value: values?.bankBal,
                },
                {
                    name: 'bankCheckbox',
                    value: values?.bankCheckbox,
                },
                {
                    name: 'otherCheckbox',
                    value: values?.otherCheckbox,
                },
                {
                    name: 'otherBal',
                    value: values?.otherBal,
                },
                {
                    name: 'rebateBal',
                    value: values?.rebateBal,
                },
                {
                    name: 'depositCheckbox',
                    value: values?.depositCheckbox,
                },
                {
                    name: 'return',
                    value: values?.return,
                },
                {
                    name: 'netPayment',
                    value: values?.netPayment,
                },
                {
                    name: 'interestCalcDate',
                    value:
                        values?.interestCalcDate &&
                        ENG_ISO_TO_NEP(values.interestCalcDate),
                },
                {
                    name: 'loanCalcDate',
                    value:
                        values?.loanCalcDate &&
                        ENG_ISO_TO_NEP(values.loanCalcDate),
                },
            ]}
        >
            <Row gutter={20}>
                <Col span={9}>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Branches
                                item="branchName"
                                label="Branch"
                                labelCol={{ span: 6 }}
                            />
                        </Col>
                        <Col span={8}>
                            <Branches item="branchID" label={false} />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Loan Type"
                                name="acName"
                                labelCol={{ span: 6 }}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {loanTypeAccounts?.length &&
                                        loanTypeAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acName}
                                            >
                                                {acc.acName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="acno">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {loanTypeAccounts?.length &&
                                        loanTypeAccounts.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.acno}
                                            >
                                                {acc.acno}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Loan No"
                                name="loanNo"
                                labelCol={{ span: 6 }}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {loanMembers?.length &&
                                        loanMembers.map((acc, i) => (
                                            <Select.Option
                                                key={i}
                                                value={acc.loanNo}
                                            >
                                                {acc.loanNo}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item>
                                <div className="ant-input ant-input--fake">
                                    {values?.memberNO}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Name" name="name" labelCol={{ span: 4 }}>
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {loanMembers?.length &&
                                loanMembers.map((acc, i) => (
                                    <Select.Option key={i} value={acc.name}>
                                        {acc.name}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Address"
                        name="address"
                        labelCol={{ span: 4 }}
                    >
                        {values?.address}
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Start Date" labelCol={{ span: 10 }}>
                        {ENG_ISO_TO_NEP(values?.entranceDate)}
                    </Form.Item>
                    <Form.Item label="Final Date" labelCol={{ span: 10 }}>
                        {ENG_ISO_TO_NEP(values?.finalDate)}
                    </Form.Item>
                    <Form.Item label="Int. Start Date" labelCol={{ span: 10 }}>
                        {ENG_ISO_TO_NEP(values?.interestStartDate)}
                    </Form.Item>
                    <Form.Item label="Contact" labelCol={{ span: 10 }}>
                        {values?.mobileno}
                    </Form.Item>
                    <Form.Item label="Loan Pmt. Sys." labelCol={{ span: 9 }}>
                        {values?.lps}
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Int. Rate" labelCol={{ span: 9 }}>
                        {values?.interestRate}
                    </Form.Item>
                    <Form.Item label="Fine Rate" labelCol={{ span: 9 }}>
                        {values?.fineRate}
                    </Form.Item>
                    <Form.Item label="Fine On" labelCol={{ span: 9 }}>
                        {values?.calcFineOn}
                    </Form.Item>
                    <Form.Item label="Rebate (%)" labelCol={{ span: 9 }}>
                        {values?.rebateRate}
                    </Form.Item>
                    <Form.Item label="Int. Calc. Sys." labelCol={{ span: 9 }}>
                        {values?.intCalcmethod}
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item
                        label="Date"
                        labelCol={{ span: 8 }}
                        name="loanCalcDate"
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                    <Form.Item label="Journal No" labelCol={{ span: 8 }}>
                        {values?.journalNO}
                    </Form.Item>
                    <Form.Item label="Receipt No" labelCol={{ span: 8 }}>
                        {values?.receiptNo}
                    </Form.Item>
                    <Form.Item label="Trans No" labelCol={{ span: 8 }}>
                        {values?.transNo}
                    </Form.Item>
                </Col>
            </Row>
            <div
                style={{
                    margin: '.25rem 0',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1.5rem',
                }}
            >
                <Form.Item label="Agreed Loan">{values?.agreedLoan}</Form.Item>
                <Form.Item label="LP Balance">{values?.lpBalance}</Form.Item>
                <Form.Item label="Saving Acc.">{values?.stno}</Form.Item>
                <Form.Item>{values?.sano}</Form.Item>
                <Form.Item>{values?.depbalance}</Form.Item>
                <Form.Item
                    label="Submit Amount"
                    name="submitAmount"
                    rules={[
                        () => ({
                            validator(_, value) {
                                if (!value || validator.isInt(value)) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Digits only'));
                            },
                        }),
                    ]}
                    style={{ marginLeft: 'auto' }}
                >
                    <Input onKeyUp={e => onSubmitAmountKeyUp(e)} />
                </Form.Item>
                <Button
                    type="default"
                    size="small"
                    disabled={!values?.submitAmount}
                    onClick={handleSubmitAmount}
                >
                    Submit
                </Button>
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '.25rem 0',
                    gap: '8px',
                }}
            >
                <Form.Item label="Pay Interest Upto" name="interestCalcDate">
                    <input
                        type="text"
                        className="ant-input"
                        onKeyUp={e => onDateKeyUp(e, mode)}
                    />
                </Form.Item>
                <Form.Item name="months">
                    <Radio.Group
                        options={options}
                        // onChange={onChange4}
                        // value={value4}
                        optionType="button"
                        buttonStyle="solid"
                    />
                </Form.Item>
                <Button
                    typ="primary"
                    size="small"
                    onClick={onLoanScheduleClick}
                >
                    Schedule
                </Button>
                <Button
                    typ="primary"
                    size="small"
                    onClick={onIntCalcReportClick}
                >
                    Int. Calc. Report
                </Button>
                <div
                    style={{
                        marginLeft: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                    }}
                >
                    <Button typ="primary" size="small">
                        Current Kista
                    </Button>
                    <Button
                        typ="primary"
                        size="small"
                        onClick={onTotalLoanClick}
                    >
                        Total Loan
                    </Button>
                    <Button typ="primary" size="small">
                        Renew
                    </Button>
                </div>
            </div>
            <Row gutter={20} style={{ marginTop: '1rem' }}>
                <Col span={12}>
                    <div className="block-general block-general--sm block-general--primary">
                        <div
                            style={{
                                marginBottom: '.25rem',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Form.Item
                                name="selfCalculation"
                                valuePropName="checked"
                            >
                                <Checkbox>Self Calculation</Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="rebateSystem"
                                valuePropName="checked"
                                style={{ marginLeft: 'auto' }}
                            >
                                <Checkbox>Rebate System</Checkbox>
                            </Form.Item>
                        </div>
                        <Table
                            className="clean"
                            dataSource={dataSource}
                            columns={columns}
                            size="small"
                            pagination={false}
                        />
                    </div>
                </Col>
                <Col span={12}>
                    <div className="block-general block-general--sm block-general--secondary">
                        <Row gutter={20}>
                            <Col span={8}>
                                <Form.Item
                                    name="lpFundCheckbox"
                                    valuePropName="checked"
                                >
                                    <Checkbox>LP Fund :</Checkbox>
                                </Form.Item>
                            </Col>
                            {/* <Col span={6}></Col> */}
                            <Col span={6}></Col>
                            <Col span={5}>
                                <Form.Item>
                                    <div className="ant-input ant-input--fake">
                                        {values?.lpBalance}
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="lpBalancePaid"
                                    rules={[
                                        {
                                            required: values?.lpFundCheckbox,
                                            message: 'Required',
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (
                                                    !value ||
                                                    value <= values?.lpBalance
                                                ) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error('Amount overflow')
                                                );
                                            },
                                        }),
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={1}>
                                <Form.Item
                                    name="cashCheckbox"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Cash</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label=" "
                                    name="cashItemNo"
                                    labelCol={{ span: 9 }}
                                    wrapperCol={{ span: 15 }}
                                    rules={[
                                        {
                                            required: values?.cashCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {cashCounters?.length &&
                                            cashCounters.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.itemCode}
                                                >
                                                    {item.itemCode}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="cashItem"
                                    rules={[
                                        {
                                            required: values?.cashCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {cashCounters?.length &&
                                            cashCounters.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.itemName}
                                                >
                                                    {item.itemName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name="cBVRCNO">
                                    <Input placeholder="BVRCNO" />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="cashBal"
                                    rules={[
                                        {
                                            required: values?.cashCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={1}>
                                <Form.Item
                                    name="bankCheckbox"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Bank</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label=" "
                                    name="bankItemNo"
                                    labelCol={{ span: 9 }}
                                    wrapperCol={{ span: 15 }}
                                    rules={[
                                        {
                                            required: values?.bankCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {banks?.length &&
                                            banks.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.itemCode}
                                                >
                                                    {item.itemCode}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="bankItem"
                                    rules={[
                                        {
                                            required: values?.bankCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {banks?.length &&
                                            banks.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.itemName}
                                                >
                                                    {item.itemName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name="bBVRCNO">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="bankBal"
                                    rules={[
                                        {
                                            required: values?.bankCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={1}>
                                <Form.Item
                                    name="depositCheckbox"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Deposit</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label=" "
                                    name="depacName"
                                    labelCol={{ span: 9 }}
                                >
                                    {/* <Select></Select> */}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="depAcno">
                                    {/* <Select></Select> */}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name="chqno">
                                    <Button
                                        type="default"
                                        size="small"
                                        onClick={onShowAccountsClick}
                                    >
                                        Show A/C
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="depositAccountsTotal"
                                    rules={[
                                        {
                                            required: values?.depositCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={1}></Col>
                            <Col span={7}></Col>
                            <Col span={6}>
                                <Form.Item name="depAccountNo">
                                    {/* <Select></Select> */}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name="depBalPaid">
                                    {/* <Input /> */}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item name="TotaldepBal">
                                    {/* <Input /> */}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={1}>
                                <Form.Item
                                    name="shareCheckbox"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Share</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label=" "
                                    labelCol={{ span: 9 }}
                                ></Form.Item>
                            </Col>
                            <Col span={6}></Col>
                            <Col span={5}>
                                <Form.Item name="shareBalance">
                                    <div className="ant-input ant-input--fake">
                                        {values?.shareBalance}
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="shareBalPaid"
                                    rules={[
                                        {
                                            required: values?.shareCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={1}>
                                <Form.Item
                                    name="otherCheckbox"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Other</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label=" "
                                    name="otherACNO"
                                    labelCol={{ span: 9 }}
                                    wrapperCol={{ span: 15 }}
                                    rules={[
                                        {
                                            required: values?.otherCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {otherAccounts?.length &&
                                            otherAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.acno}
                                                >
                                                    {item.acno}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    name="otherAcName"
                                    rules={[
                                        {
                                            required: values?.otherCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {otherAccounts?.length &&
                                            otherAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.acName}
                                                >
                                                    {item.acName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            {/* <Col span={5}></Col> */}
                            <Col span={5}>
                                <Form.Item
                                    name="otherBal"
                                    rules={[
                                        {
                                            required: values?.otherCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={1}></Col>
                            <Col span={7}>
                                <Form.Item
                                    label=" "
                                    name="otherItemNo"
                                    labelCol={{ span: 9 }}
                                    wrapperCol={{ span: 15 }}
                                    colon={false}
                                    rules={[
                                        {
                                            required: values?.otherCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {otherItems?.length &&
                                            otherItems.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.itemCode}
                                                >
                                                    {item.itemCode}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item
                                    name="otherItem"
                                    rules={[
                                        {
                                            required: values?.otherCheckbox,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {otherItems?.length &&
                                            otherItems.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.itemName}
                                                >
                                                    {item.itemName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div>
                            <Space style={{ marginTop: '.25rem' }} size={15}>
                                <Form.Item
                                    label="Net Payment"
                                    name="netPayment"
                                >
                                    <Input addonBefore="Rs" disabled />
                                </Form.Item>
                                <Form.Item label="Return" name="return">
                                    <Input addonBefore="Rs" disabled />
                                </Form.Item>
                            </Space>
                        </div>
                        <Space
                            style={{
                                marginTop: '1rem',
                                width: '100%',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button type="default" onClick={onFormSubmit}>
                                Save
                            </Button>
                            <Button type="default">Print</Button>
                        </Space>
                    </div>
                </Col>
            </Row>
        </Form>
    );
});

export default LoanRecoveryForm;
