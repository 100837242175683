import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Loading(props) {
    const { height } = props;
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: height || '8rem',
            }}
        >
            <Spin indicator={antIcon} />
        </div>
    );
}
