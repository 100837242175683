import React from 'react';
import { Row, Col, Form, Select, Input, Space, Button, Divider } from 'antd';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../../utils/functions';

const RemitPaymentForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        mode,
        values,
        onValuesChange,
        setDefaultValues,
        onFormSubmit,
        handleNew,
        banks,
        cashItems,
        onDateChange,
        onDateKeyUp,
        canSubmit,
    } = props;
    // Global states

    // NEPALI DATEPICKER
    // Trans date
    const transDateInput = document.getElementById('transDate');
    transDateInput &&
        transDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, transDateInput, mode)
        );

    // Set default values
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);

    return (
        <Form
            ref={ref}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onValuesChange={val => onValuesChange(val, mode)}
            fields={[
                {
                    name: 'transDate',
                    value:
                        values?.transDate && ENG_ISO_TO_NEP(values?.transDate),
                },
                {
                    name: 'bankCode',
                    value: values?.bankCode,
                },
                {
                    name: 'bankName',
                    value: values?.bankName,
                },
                {
                    name: 'cashCode',
                    value: values?.cashCode,
                },
                {
                    name: 'cashName',
                    value: values?.cashName,
                },
                {
                    name: 'bankAcno',
                    value: values?.bankAcno,
                },
                {
                    name: 'bankAddress',
                    value: values?.bankAddress,
                },
                {
                    name: 'voucherNo',
                    value: values?.voucherNo,
                },
                {
                    name: 'depositedBy',
                    value: values?.depositedBy,
                },
                {
                    name: 'description',
                    value: values?.description,
                },
                {
                    name: 'totalCollection',
                    value: values?.totalCollection,
                },
                {
                    name: 'cashBalance',
                    value: values?.cashBalance,
                },
                {
                    name: 'bankBalance',
                    value: values?.bankBalance,
                },
                {
                    name: 'totalCollectionInWords',
                    value: values?.totalCollectionInWords,
                },
                {
                    name: 'journalNO',
                    value: values?.journalNO,
                },
                {
                    name: 'transNo',
                    value: values?.transNo,
                },
            ]}
        >
            <h4 className="colored-title">Where to collect?</h4>
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="Bank Code"
                        name="bankCode"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                        labelCol={{ span: 6 }}
                    >
                        <Select placeholder="Select...">
                            {banks.length &&
                                banks.map((item, i) => (
                                    <Select.Option
                                        key={i}
                                        value={item.itemCode}
                                    >
                                        {item.itemCode}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={6}>
                    <Form.Item
                        label="Date"
                        name="transDate"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="Bank Name"
                        name="bankName"
                        labelCol={{ span: 6 }}
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select placeholder="Select...">
                            {banks.length &&
                                banks.map((item, i) => (
                                    <Select.Option
                                        key={i}
                                        value={item.itemName}
                                    >
                                        {item.itemName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={6}>
                    <Form.Item label="Journal No." name="journalNO">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="Bank Acc No"
                        name="bankAcno"
                        labelCol={{ span: 6 }}
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={6}>
                    <Form.Item label="Trans No." name="transNo">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="Bank Address"
                        name="bankAddress"
                        labelCol={{ span: 6 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={6}>
                    <Form.Item name="bankBalance" label="Bank Balance">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="Voucher No"
                        name="voucherNo"
                        labelCol={{ span: 6 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={10}></Col>
                <Col span={6}></Col>
            </Row>
            <h4 className="colored-title">What is the source?</h4>
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="Cash From"
                        name="cashName"
                        labelCol={{ span: 6 }}
                    >
                        <Select placeholder="Select...">
                            {cashItems.length &&
                                cashItems.map((item, i) => (
                                    <Select.Option
                                        key={i}
                                        value={item.itemName}
                                    >
                                        {item.itemName}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="Cash Book No"
                        name="cashCode"
                        labelCol={{ span: 6 }}
                    >
                        <Select placeholder="Select...">
                            {cashItems.length &&
                                cashItems.map((item, i) => (
                                    <Select.Option
                                        key={i}
                                        value={item.itemCode}
                                    >
                                        {item.itemCode}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="Deposited By"
                        name="depositedBy"
                        labelCol={{ span: 6 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={6}>
                    <Form.Item label="Cash Balance" name="cashBalance">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="Description"
                        name="description"
                        labelCol={{ span: 6 }}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Divider style={{ margin: '.75rem 0' }} />
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="Total Collection"
                        name="totalCollection"
                        labelCol={{ span: 6 }}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('cashBalance') >= value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error('Amount overflow')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={8}>
                    <Form.Item
                        label="In Words"
                        name="totalCollectionInWords"
                        labelCol={{ span: 6 }}
                    >
                        <Input.TextArea disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={6}>
                    <Form.Item label=" " colon={false}>
                        <Space style={{ marginTop: '1.5rem' }}>
                            <Button
                                onClick={onFormSubmit}
                                disabled={
                                    !values?.totalCollection || !canSubmit
                                }
                            >
                                Save
                            </Button>
                            <Button>Print Receipt</Button>
                            <Button>Deno</Button>
                            <Button onClick={handleNew}>New</Button>
                        </Space>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default RemitPaymentForm;
