import { Form, Select, Input, Radio, Checkbox, Row, Col, Button } from 'antd';
// Import utils

export default function DepositTypeInterest(props) {
    // Destructuring
    const {
        values,
        onCheckBoxChange,
        mode,
        onFlexRateModalOpen,
        flexRateSets,
        onDateKeyUp,
    } = props;

    // Variables and constants

    return (
        <div className="deposit-type-interest-form-view">
            <Row gutter={10}>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Interest Start Date"
                        name={`intStartDate__${mode}`}
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Interest Rate"
                        name="rateOfInterest"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Input
                            addonBefore="On Deposit"
                            addonAfter="%"
                            disabled={mode === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Interest Rate"
                        name="intRateOD"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    if (+value < 0 || +value > 100)
                                        return Promise.reject(
                                            new Error('Between 0 - 100')
                                        );
                                    return Promise.resolve();
                                },
                            }),
                            values?.loanable && {
                                required: values.loanable,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input
                            addonBefore="On Overdraft"
                            disabled={!values?.loanable || mode === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={24}
                    sm={12}
                    md={8}
                    lg={6}
                    xl={
                        mode !== 'view' && values?.flexibleRateSetCheckbox
                            ? 3
                            : 6
                    }
                >
                    <Form.Item label=" ">
                        <Checkbox
                            name="flexibleRateSetCheckbox"
                            checked={values?.flexibleRateSetCheckbox}
                            onChange={e => onCheckBoxChange(e, mode)}
                            disabled={mode === 'view'}
                        >
                            Flexible rate
                            {mode === 'view' &&
                                values?.flexibleRateSet !== 0 &&
                                values?.flexibleRateSet &&
                                ` (Set ${values?.flexibleRateSet})`}
                        </Checkbox>
                        {/* {mode === 'view' &&
                            values?.flexibleRateSet !== 0 &&
                            values?.flexibleRateSet && (
                                <p style={{ marginTop: '4px' }}>
                                    Set {values?.flexibleRateSet} applied
                                </p>
                            )} */}
                    </Form.Item>
                </Col>
                {mode !== 'view' && values?.flexibleRateSetCheckbox ? (
                    <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Form.Item label=" " name="flexibleRateSet">
                            <Select
                                placeholder="Select a set..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {flexRateSets.map((set, i) => (
                                    <Select.Option key={i} value={set}>
                                        Set {set}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                ) : null}
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label=" ">
                        {mode !== 'view' && (
                            <Button onClick={onFlexRateModalOpen}>
                                Show Rates
                            </Button>
                        )}
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Tax Rate on Interest"
                        name="taxRatePerson"
                    >
                        <Input
                            addonBefore="For Persons"
                            addonAfter="%"
                            disabled={mode === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Form.Item label="Tax Rate on Interest" name="taxRateorg">
                        <Input
                            addonBefore="For Organizations"
                            addonAfter="%"
                            disabled={mode === 'view'}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} md={16}>
                    <Form.Item
                        label="Interest Calculation Date"
                        name="intCalcPeriod"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Radio.Group disabled={mode === 'view'}>
                            <Radio value="1">Month-end</Radio>
                            <Radio value="2">Quarter-end</Radio>
                            <Radio value="3">Half-year-end</Radio>
                            <Radio value="4">Year-end</Radio>
                            <Radio value="5">Maturity-Date</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24}>
                    <Form.Item
                        label="Interest Calculation Method"
                        name="intCalcBasis"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Radio.Group disabled={mode === 'view'}>
                            <Radio value="DayBal">Daily Balance</Radio>
                            <Radio value="DayMinBal">Daily Minimum</Radio>
                            <Radio value="WeekBal">Weekly Minimum</Radio>
                            <Radio value="HMBal">Half Monthly Minimum</Radio>
                            <Radio value="MinBal">Monthly Minimum</Radio>
                            <Radio value="MEBal">Month-End Balance</Radio>
                            <Radio value="Fixed">Fixed</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} md={16}>
                    <Form.Item
                        label="Interest Pay Transfer"
                        name="autoCalcInterest"
                    >
                        <Radio.Group disabled={mode === 'view'}>
                            <Radio value="Automatically">Automatically</Radio>
                            <Radio value="Manually">Manually</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
