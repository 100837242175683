import React from 'react';
import { Form, Select, Input, Row, Col, Checkbox } from 'antd';

import {
    CONTAINS_DIGITS_ONLY,
    GET_DATEPICKER_CONFIG,
} from '../../../utils/functions';
import validator from 'validator';

export default function DepositRegistrationForm2(props) {
    // Destructuring
    const { values, mode, onDateKeyUp, onDateChange } = props;

    // Init datepicker
    React.useEffect(() => {
        // Start date
        const startDateInput = document.getElementById(`startDate__${mode}`);
        startDateInput &&
            startDateInput.nepaliDatePicker(
                GET_DATEPICKER_CONFIG(onDateChange, startDateInput, mode)
            );

        // Expire date
        const expireDateInput = document.getElementById(`expireDate__${mode}`);
        expireDateInput &&
            expireDateInput.nepaliDatePicker(
                GET_DATEPICKER_CONFIG(onDateChange, expireDateInput, mode)
            );

        // Interest start date
        const interestStartDAteInput = document.getElementById(
            `interestStartDAte__${mode}`
        );
        interestStartDAteInput &&
            interestStartDAteInput.nepaliDatePicker(
                GET_DATEPICKER_CONFIG(
                    onDateChange,
                    interestStartDAteInput,
                    mode
                )
            );
    }, [mode, onDateChange]);
    return (
        <div>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Saving Period"
                        name="savingPeriod"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Saving Period Type"
                        name="periodType"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value="Not Mentioned">
                                Not mentioned
                            </Select.Option>
                            <Select.Option value="Day">Day(s)</Select.Option>
                            <Select.Option value="Months">
                                Month(s)
                            </Select.Option>
                            <Select.Option value="Year">Year(s)</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}></Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Start Date"
                        name={`startDate__${mode}`}
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="End Date"
                        name={`expireDate__${mode}`}
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Interest Start Date"
                        name={`interestStartDAte__${mode}`}
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                        ]}
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={2}></Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Interest Rate"
                        name="interestRate"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value || validator.isInt(value + '')) {
                                        if (+value < 0 || +value > 50)
                                            return Promise.reject(
                                                new Error('Between 0 and 50')
                                            );
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input addonAfter="%" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Saving Rate"
                        name="savingRate"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                            () => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        validator.isDecimal(value + '')
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Minimum Balance"
                        name="minimumBalance"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value || validator.isInt(value + '')) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={6}></Col>
                <Col xs={24} sm={12} md={2}>
                    <Form.Item
                        label="OD Facility"
                        name="odFacility"
                        valuePropName="checked"
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="OD Interest Rate"
                        name="odIntrate"
                        rules={
                            values?.odFacility && [
                                {
                                    required: values?.odFacility,
                                    message: 'Required',
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value) return Promise.resolve();
                                        if (CONTAINS_DIGITS_ONLY(value)) {
                                            if (+value < 0 || +value > 50)
                                                return Promise.reject(
                                                    new Error(
                                                        'Between 0 and 50'
                                                    )
                                                );
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            new Error('Digits only')
                                        );
                                    },
                                }),
                            ]
                        }
                    >
                        <Input disabled={!values?.odFacility} addonAfter="%" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="OD Limit"
                        name="oDlimit"
                        rules={[
                            {
                                required: values?.odFacility,
                                message: 'Required',
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value || CONTAINS_DIGITS_ONLY(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input disabled={!values?.odFacility} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}></Col>

                <Col xs={24} sm={12} md={2}>
                    <Form.Item
                        label="SMS Facilty"
                        name="smsFacility"
                        valuePropName="checked"
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="Mobile No"
                        name="mobileNO"
                        rules={[
                            {
                                required: values?.smsFacility,
                                message: 'Required',
                            },
                            () => ({
                                validator(_, value) {
                                    if (CONTAINS_DIGITS_ONLY(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={2}>
                    <Form.Item
                        label="Confidential"
                        name="confidential"
                        valuePropName="checked"
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={2}>
                    <Form.Item
                        label="Dormant"
                        name="dormant"
                        valuePropName="checked"
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={2}>
                    <Form.Item
                        label="Disabled"
                        name="disabled"
                        valuePropName="checked"
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={2}>
                    <Form.Item
                        label="Locked"
                        name="locked"
                        valuePropName="checked"
                    >
                        <Checkbox></Checkbox>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4}>
                    <Form.Item
                        label="Locked Amount"
                        name="lockedAmount"
                        rules={[
                            {
                                required: values?.locked,
                                message: 'Required',
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value || CONTAINS_DIGITS_ONLY(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input disabled={!values?.locked} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}
