import React from 'react';
// Import from dependencies
import { Form, Select, Row, Col, Input, Checkbox, Button, Tag } from 'antd';
import {
    CloseCircleOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import validator from 'validator';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../../utils/functions';

export default function ActivateDepositFeaturesForm(props) {
    // Destructuring
    const {
        onValuesChange,
        values,
        mode,
        onDateChange,
        onDateKeyUp,
        branches,
        depTypes,
        depTypeAccounts,
        onFeaturesSaveClick,
    } = props;

    // NEPALI DATEPICKER
    // SMS start date
    const smsEntryDateInput = document.getElementById('smsEntryDate');
    smsEntryDateInput &&
        smsEntryDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, smsEntryDateInput, mode)
        );
    // SMS expire date
    const smsExpireDateInput = document.getElementById('smsExpireDate');
    smsExpireDateInput &&
        smsExpireDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, smsExpireDateInput, mode)
        );
    // Mobile banking start date
    const mbEntryDateInput = document.getElementById('mbEntryDate');
    mbEntryDateInput &&
        mbEntryDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, mbEntryDateInput, mode)
        );
    // Mobile banking expire date
    const mbExpireDateInput = document.getElementById('mbExpireDate');
    mbExpireDateInput &&
        mbExpireDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, mbExpireDateInput, mode)
        );

    return (
        <Form
            layout="horizontal"
            onValuesChange={onValuesChange}
            fields={[
                {
                    name: 'branchID',
                    value: values?.branchID,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'acName',
                    value: values?.acName,
                },
                {
                    name: 'acno',
                    value: values?.acno,
                },
                {
                    name: 'accountNo',
                    value: values?.accountNo,
                },
                {
                    name: 'fullName',
                    value: values?.fullName,
                },
                {
                    name: 'smsFeature',
                    value: values?.smsFeature,
                },
                {
                    name: 'smsMobileNO',
                    value: values?.smsMobileNO,
                },
                {
                    name: 'smsEntryDate',
                    value:
                        values?.smsEntryDate &&
                        ENG_ISO_TO_NEP(values.smsEntryDate),
                },
                {
                    name: 'smsExpireDate',
                    value:
                        values?.smsExpireDate &&
                        ENG_ISO_TO_NEP(values.smsExpireDate),
                },
                {
                    name: 'mbFeature',
                    value: values?.mbFeature,
                },
                {
                    name: 'mbMobileNO',
                    value: values?.mbMobileNO,
                },
                {
                    name: 'mbEntryDate',
                    value:
                        values?.mbEntryDate &&
                        ENG_ISO_TO_NEP(values.mbEntryDate),
                },
                {
                    name: 'mbExpireDate',
                    value:
                        values?.mbExpireDate &&
                        ENG_ISO_TO_NEP(values.mbExpireDate),
                },
            ]}
        >
            <Row gutter={20}>
                <Col span={12}>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Branch"
                                name="branchName"
                                labelCol={{ span: 7 }}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {branches?.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.branchName}
                                        >
                                            {item.branchName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="branchID">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {branches?.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.branchID}
                                        >
                                            {item.branchID}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Deposit Type"
                                name="acName"
                                labelCol={{ span: 7 }}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depTypes?.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.acName}
                                        >
                                            {item.acName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="acno">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depTypes?.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.acno}
                                        >
                                            {item.acno}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Account No"
                                name="accountNo"
                                labelCol={{ span: 7 }}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depTypeAccounts?.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.accountNo}
                                        >
                                            {item.accountNo}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item
                                label="Account Holder"
                                name="fullName"
                                labelCol={{ span: 7 }}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {depTypeAccounts?.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.fullName}
                                        >
                                            {item.fullName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <div className="ant-input ant-input--fake">
                                {values?.memberNo}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={16}>
                            <Form.Item label="Address" labelCol={{ span: 7 }}>
                                <div className="ant-input ant-input--fake">
                                    {values?.address}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={20} style={{ marginTop: '1rem' }}>
                <Col span={4}>
                    <Form.Item valuePropName="checked" name="smsFeature">
                        <Checkbox>SMS Banking</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Mobile No"
                        name="smsMobileNO"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                            () => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        (value.length === 10 &&
                                            validator.isInt(value))
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Invalid'));
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Start Date" name="smsEntryDate">
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Expire Date" name="smsExpireDate">
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item>
                        <StatusTag status={values?.smsStatus} />
                    </Form.Item>
                </Col>
                <Col span={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => onFeaturesSaveClick('sms')}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col span={4}>
                    <Form.Item valuePropName="checked" name="mbFeature">
                        <Checkbox>Mobile Banking</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Mobile No"
                        name="mbMobileNO"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                            () => ({
                                validator(_, value) {
                                    if (!value || value.length === 10) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error('Must be 10 digits')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Start Date" name="mbEntryDate">
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
                <Col span={5}>
                    <Form.Item label="Expire Date" name="mbExpireDate">
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item>
                        <StatusTag status={values?.mbStatus} />
                    </Form.Item>
                </Col>
                <Col span={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => onFeaturesSaveClick('mobile')}
                    >
                        Save
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export const StatusTag = props => {
    const { status } = props;
    if (!status) return <p>Status</p>;

    let color;
    let icon;

    if (status.toLowerCase() === 'active') {
        icon = <CheckCircleOutlined />;
        color = '#4BB543';
    } else if (status.toLowerCase() === 'inactive') {
        icon = <ExclamationCircleOutlined />;
        color = '#FE6702';
    } else if (status.toLowerCase() === 'deactivated') {
        icon = <CloseCircleOutlined />;
        color = '#ff3333';
    }

    return (
        <Tag icon={icon} color={color}>
            {status}
        </Tag>
    );
};
