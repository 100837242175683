import React from 'react';
import { Form, Select, Input, Row, Col } from 'antd';
// Import utils
import {
    ENG_ISO_TO_NEP,
    CONTAINS_DIGITS_OR_DECIMAL_ONLY,
    GET_DATEPICKER_CONFIG,
} from '../../../utils/functions';

const { TextArea } = Input;

const BankRegistrationForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        mode,
        bankAccounts,
        branches,
        banks,
        bankClasses,
        values,
        onValuesChange,
        setDefaultValues,
        getBanks,
        getNewBankCode,
        onDateChange,
        onDateKeyUp,
    } = props;

    // NEPALI DATEPICKER
    // Formed date
    const formedDateInput = document.getElementById(`startDate__${mode}`);
    formedDateInput &&
        formedDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, formedDateInput, mode)
        );

    let bankCodeInput;
    switch (mode) {
        case 'view':
            bankCodeInput = (
                <Select
                    placeholder="Select..."
                    disabled={!values?.branchID}
                    showSearch
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {banks.map((bank, i) => (
                        <Select.Option key={i} value={bank.itemCode}>
                            {bank.itemCode}
                        </Select.Option>
                    ))}
                </Select>
            );
            break;
        case 'edit':
            bankCodeInput = <p>{values?.itemCode}</p>;
            break;
        case 'add':
            bankCodeInput = <Input />;
            break;
        default:
            bankCodeInput = null;
    }

    // On component mount
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);

    // Get banks or new bank code
    React.useEffect(() => {
        if (values?.branchID) {
            if (mode === 'add') {
                getNewBankCode(values.branchID);
            } else {
                getBanks(values.branchID);
            }
        }
    }, [getBanks, mode, values?.branchID, getNewBankCode]);

    return (
        <Form
            ref={ref}
            layout="vertical"
            onValuesChange={val => onValuesChange(val, mode)}
            fields={[
                {
                    name: ['acno'],
                    value: values?.acno,
                },
                {
                    name: ['acName'],
                    value: values?.acName,
                },
                {
                    name: ['branchID'],
                    value: values?.branchID,
                },
                {
                    name: ['branchName'],
                    value: values?.branchName,
                },
                {
                    name: ['itemCode'],
                    value: values?.itemCode,
                },
                {
                    name: ['bankName'],
                    value: values?.bankName,
                },
                {
                    name: ['nepNameItem'],
                    value: values?.nepNameItem,
                },
                {
                    name: ['bankClass'],
                    value: values?.bankClass,
                },
                {
                    name: ['address'],
                    value: values?.address,
                },
                {
                    name: ['accountType'],
                    value: values?.accountType,
                },
                {
                    name: ['bankAccountNo'],
                    value: values?.bankAccountNo,
                },
                {
                    name: ['interestRate'],
                    value: values?.interestRate,
                },
                {
                    name: ['operator1'],
                    value: values?.operator1,
                },
                {
                    name: ['operator2'],
                    value: values?.operator2,
                },
                {
                    name: ['operator3'],
                    value: values?.operator3,
                },
                {
                    name: ['operator4'],
                    value: values?.operator4,
                },
                {
                    name: ['operator5'],
                    value: values?.operator5,
                },
                {
                    name: ['description'],
                    value: values?.description,
                },
                {
                    name: [`startDate__${mode}`],
                    value:
                        values?.startDate && ENG_ISO_TO_NEP(values?.startDate),
                },
            ]}
        >
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Branch ID"
                        name="branchID"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {branches.map((branch, i) => (
                                <Select.Option key={i} value={branch.branchID}>
                                    {branch.branchID}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Branch Name"
                        name="branchName"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {branches.map((branch, i) => (
                                <Select.Option
                                    key={i}
                                    value={branch.branchName}
                                >
                                    {branch.branchName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Formed Date"
                        name={`startDate__${mode}`}
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <input
                            type="text"
                            className="ant-input"
                            onKeyUp={e => onDateKeyUp(e, mode)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Account Name"
                        name="acName"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {bankAccounts.map((acc, i) => (
                                <Select.Option key={i} value={acc.acName}>
                                    {acc.acName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Account Code"
                        name="acno"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        {mode === 'edit' ? (
                            <p>{values?.acno}</p>
                        ) : (
                            <Select
                                placeholder="Select..."
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {bankAccounts.map((acc, i) => (
                                    <Select.Option key={i} value={acc.acno}>
                                        {acc.acno}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item
                        label="Item Code"
                        name="itemCode"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        {bankCodeInput}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                    <Form.Item label="Bank Account No." name="bankAccountNo">
                        <Input disabled={!values?.itemCode} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={8}>
                    <Form.Item
                        label="Bank Name"
                        name="bankName"
                        rules={
                            mode !== 'view' && [
                                {
                                    required: true,
                                    message: 'Required',
                                },
                            ]
                        }
                    >
                        {mode === 'view' ? (
                            <Select
                                placeholder="Select..."
                                disabled={!values?.branchID}
                                showSearch
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {banks.map((bank, i) => (
                                    <Select.Option
                                        key={i}
                                        value={bank.bankName}
                                    >
                                        {bank.bankName}
                                    </Select.Option>
                                ))}
                            </Select>
                        ) : (
                            <Input disabled={!values?.itemCode} />
                        )}
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Form.Item label="Bank Name (Nepali)" name="nepNameItem">
                        <Input disabled={!values?.branchID} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={8}>
                    <Form.Item
                        label="Class"
                        name="bankClass"
                        rules={
                            mode !== 'view' && [
                                { required: true, message: 'Required' },
                            ]
                        }
                    >
                        <Select
                            placeholder="Select..."
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {bankClasses.map((bankClass, i) => (
                                <Select.Option key={i} value={bankClass}>
                                    {bankClass}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Form.Item label="Account Type" name="accountType">
                        <Select
                            placeholder="Select..."
                            disabled={!values?.itemCode}
                            showSearch
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Select.Option value="CURRENT">
                                Current
                            </Select.Option>
                            <Select.Option value="SAVING">Saving</Select.Option>
                            <Select.Option value="FIXED">Fixed</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} sm={12} md={8} lg={6} xl={8}>
                    <Form.Item
                        label="Bank Address"
                        name="address"
                        rules={
                            mode !== 'view' && [
                                { required: true, message: 'Required' },
                            ]
                        }
                    >
                        <Input disabled={!values?.itemCode} />
                    </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Form.Item
                        label="Interest Rate"
                        name="interestRate"
                        rules={[
                            () => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        CONTAINS_DIGITS_OR_DECIMAL_ONLY(value)
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error('Digits only')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input
                            addonAfter="% per Year"
                            disabled={!values?.itemCode}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={24} md={16} lg={12} xl={8}>
                    <Form.Item
                        label="Account Operators"
                        name=""
                        className="account-operation-list"
                    >
                        <Row>
                            <Col span={1}>1.</Col>
                            <Col span={22}>
                                <Form.Item name="operator1">
                                    <Input disabled={!values?.itemCode} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={1}>2.</Col>
                            <Col span={22}>
                                <Form.Item name="operator2">
                                    <Input disabled={!values?.itemCode} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={1}>3.</Col>
                            <Col span={22}>
                                <Form.Item name="operator3">
                                    <Input disabled={!values?.itemCode} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={1}>4.</Col>
                            <Col span={22}>
                                <Form.Item name="operator4">
                                    <Input disabled={!values?.itemCode} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={1}>5.</Col>
                            <Col span={22}>
                                <Form.Item name="operator5">
                                    <Input disabled={!values?.itemCode} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form.Item>
                </Col>
                <Col xs={24} lg={6} xl={6}>
                    <Form.Item label="Description" name="description">
                        <TextArea rows={8} disabled={!values?.itemCode} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default BankRegistrationForm;
