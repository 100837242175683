import React from 'react';
import moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';
// Import components
import {
    DLT_KEYS_FROM_OBJ,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../utils/functions';
import {
    GET_MAIN_ACCOUNTS,
    GET_SUB_ACCOUNTS,
    GET_ITEMS,
    GET_ITEM_WISE_LEDGER,
} from '../../../utils/axios';
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import ItemWiseLedgerForm from '../../../components/Reports/ItemWiseLedger/ItemWiseLedgerForm/ItemWiseLedgerForm';

export default function ItemWiseLedger() {
    // Local states
    const branches = JSON.parse(localStorage.getItem('BRANCHES'));
    const [viewValues, setViewValues] = React.useState(null);
    const [mainAccounts, setMainAccounts] = React.useState([]);
    const [subAccounts, setSubAccounts] = React.useState([]);
    const [items, setItems] = React.useState([]);
    const [report, setReport] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // Variables and constants
    const location = useLocation();
    const history = useHistory();

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            fromDate: moment().format(),
            tillDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            showSubTotals: true,
            hideZeros: true,
            level: 'accountLevel',
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On values change
    const onValuesChange = val => {
        // console.log('val: ', val);
        const key = Object.keys(val)[0];
        const value = val[key];
        const values = viewValues;
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'fromDate' || key === 'tillDate') {
            return;
        } else if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(item => item[key] === value);
            setReport([]);
            updateValues({
                ...DLT_KEYS_FROM_OBJ(values, [
                    'acno',
                    'acName',
                    'itemCode',
                    'itemName',
                ]),
                ...(value === 'all'
                    ? {
                          branchID: 'all',
                          branchName: 'all',
                      }
                    : { ...branch }),
            });
        } else if (key === 'mano' || key === 'maName') {
            const acc = mainAccounts.find(item => item[key] === value);
            setReport([]);
            updateValues({
                ...DLT_KEYS_FROM_OBJ(values, [
                    'acno',
                    'acName',
                    'itemCode',
                    'itemName',
                ]),
                ...acc,
            });
        } else if (key === 'acno' || key === 'acName') {
            const acc = subAccounts.find(item => item[key] === value);
            setReport([]);
            updateValues({
                ...DLT_KEYS_FROM_OBJ(values, ['itemName', 'itemCode']),
                ...acc,
            });
        } else if (key === 'itemName' || key === 'itemCode') {
            const item = items.find(item => item[key] === value);
            updateValues(item);
        }
        // Update values
        updateValues(val);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        if (key === 'fromDate' || key === 'tillDate') setReport([]);
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        const id = e.target.id;
        if (e.keyCode === 13 && (id === 'fromDate' || id === 'tillDate'))
            setReport([]);
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Form props
    const formProps = {
        branches,
        viewValues,
        onValuesChange,
        mainAccounts,
        subAccounts,
        report,
        isLoading,
        items,
        onDateChange,
        onDateKeyUp,
    };

    // Get sub accounts
    React.useEffect(() => {
        if (viewValues?.mano) {
            setSubAccounts([]);
            (async () => {
                const subAccounts = await GET_SUB_ACCOUNTS(viewValues.mano);
                setSubAccounts(subAccounts);
            })();
        }
    }, [viewValues?.mano]);

    // On component mount
    React.useEffect(() => {
        // Set default values
        setDefaultValues();
        // Get main accounts
        (async () => {
            const mainAccounts = await GET_MAIN_ACCOUNTS();
            setMainAccounts(mainAccounts);
        })();
    }, []);

    // Get item wise ledger
    React.useEffect(() => {
        if (
            viewValues?.branchID &&
            viewValues?.fromDate &&
            viewValues?.tillDate &&
            viewValues?.mano &&
            viewValues?.acno &&
            viewValues?.itemCode
        ) {
            (async () => {
                setIsLoading(true);
                const branchID = viewValues.branchID;
                let fromDate = viewValues.fromDate;
                fromDate = moment(fromDate).format('YYYY-MM-DD');
                let tillDate = viewValues.tillDate;
                tillDate = moment(tillDate).format('YYYY-MM-DD');
                const mano = viewValues.mano;
                const acno = viewValues.acno;
                const itemCode = viewValues.itemCode;
                const ledger = await GET_ITEM_WISE_LEDGER(
                    branchID,
                    fromDate,
                    tillDate,
                    mano,
                    acno,
                    itemCode
                );
                setIsLoading(false);
                setReport(ledger);
            })();
        }
    }, [
        viewValues?.branchID,
        viewValues?.fromDate,
        viewValues?.tillDate,
        viewValues?.mano,
        viewValues?.acno,
        viewValues?.itemCode,
    ]);

    // Get items
    React.useEffect(() => {
        if (viewValues?.acno && viewValues?.branchID) {
            (async () => {
                const acno = viewValues.acno;
                const branchID = viewValues.branchID;
                const items = await GET_ITEMS(acno, branchID);
                setItems(items);
            })();
        }
    }, [viewValues?.acno, viewValues?.branchID]);

    // Get acno from url
    React.useEffect(() => {
        const search = location.search;
        if (!search) return;

        const mano = new URLSearchParams(search).get('mano');
        const maName = new URLSearchParams(search).get('maName');
        const acno = new URLSearchParams(search).get('acno');
        const acName = new URLSearchParams(search).get('acName');
        const itemCode = new URLSearchParams(search).get('itemCode');
        const itemName = new URLSearchParams(search).get('itemName');
        let tillDate = new URLSearchParams(search).get('tillDate');
        const fromDate = moment(tillDate)
            .subtract(1, 'month')
            .format('YYYY-MM-DD');

        setViewValues(prevValues => ({
            ...prevValues,
            mano,
            maName,
            tillDate,
            fromDate,
            ...(subAccounts?.length ? { acno, acName } : {}),
            ...(items?.length ? { itemCode, itemName } : {}),
        }));
        if (report?.length) history.push('item-wise-ledger');
    }, [
        location.search,
        items?.length,
        subAccounts?.length,
        history,
        report.length,
    ]);

    return (
        <PageWrapper>
            <ItemWiseLedgerForm {...formProps} />
        </PageWrapper>
    );
}
