import React from 'react';
import { Modal, Card, Checkbox } from 'antd';
import { PRINT_SETTINGS_FOOTER } from '../../../utils/helpers';

export default function PrintSettingsModal(props) {
    // Destructuring
    const { visible, onOk, onCancel, footerTemp, setFooterTemp } = props;
    return (
        <Modal
            title="Print Settings"
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            okText="Apply"
        >
            <Card title="Footer" size="small">
                <Checkbox.Group
                    options={PRINT_SETTINGS_FOOTER}
                    value={footerTemp}
                    onChange={val => setFooterTemp(val)}
                />
            </Card>
        </Modal>
    );
}
