import React from 'react';
import { Row, Col, Form, Select, Input, Button, Space } from 'antd';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../utils/functions';
import Branches from '../../../Common/Geography/Branches/Branches';
import PaymentSource from '../../../Common/PaymentSource/PaymentSource';
import Print from '../../../Common/Print/Print';
import ReactToPrint from 'react-to-print';

export default function CounterWithdrawForm(props) {
    // Destructuring
    const {
        depTypeAccounts,
        savingTypeAccounts,
        values,
        onValuesChange,
        getDepShortInfoQuick,
        isProcessing,
        handleNew,
        onFormSubmit,
        mode,
        onDateChange,
        onDateKeyUp,
    } = props;

    // Refs
    const printRef = React.useRef();

    // NEPALI DATEPICKER
    // Trans date
    const transDateInput = document.getElementById('transDate');
    transDateInput &&
        transDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, transDateInput, mode)
        );

    // Local states
    return (
        <Form
            layout="horizontal"
            onValuesChange={onValuesChange}
            onFinish={onFormSubmit}
            fields={[
                {
                    name: 'branchID',
                    value: values?.branchID,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'acno',
                    value: values?.acno,
                },
                {
                    name: 'acName',
                    value: values?.acName,
                },
                {
                    name: 'accountNo',
                    value: values?.accountNo,
                },
                {
                    name: 'accountNoAlt',
                    value: values?.accountNoAlt,
                },
                {
                    name: 'fullName',
                    value: values?.fullName,
                },
                {
                    name: 'memberNo',
                    value: values?.memberNo,
                },
                {
                    name: 'address',
                    value: values?.address,
                },
                {
                    name: 'receivedPaidBy',
                    value: values?.receivedPaidBy,
                },
                {
                    name: 'transDate',
                    value:
                        values?.transDate && ENG_ISO_TO_NEP(values.transDate),
                },
                {
                    name: 'preTotal',
                    value: values?.preTotal,
                },
                {
                    name: 'cashNo',
                    value: values?.cashNo,
                },
                {
                    name: 'cashName',
                    value: values?.cashName,
                },
                {
                    name: 'cashAmount',
                    value: values?.cashAmount,
                },
                {
                    name: 'bankNo',
                    value: values?.bankNo,
                },
                {
                    name: 'bankName',
                    value: values?.bankName,
                },
                {
                    name: 'bankAmount',
                    value: values?.bankAmount,
                },
                {
                    name: 'otherNo',
                    value: values?.otherNo,
                },
                {
                    name: 'otherName',
                    value: values?.otherName,
                },
                {
                    name: 'otherAmount',
                    value: values?.otherAmount,
                },
                {
                    name: 'otherAcno',
                    value: values?.otherAcno,
                },
                {
                    name: 'otherAcName',
                    value: values?.otherAcName,
                },
                {
                    name: 'journalNO',
                    value: values?.journalNO,
                },
                {
                    name: 'receiptNo',
                    value: values?.receiptNo,
                },
                {
                    name: 'transNo',
                    value: values?.transNo,
                },
                {
                    name: 'totalAmount',
                    value: values?.totalAmount,
                },
                {
                    name: 'totalAmountInWords',
                    value: values?.totalAmountInWords,
                },
                {
                    name: 'actualBal',
                    value: values?.actualBal,
                },
                {
                    name: 'isCash',
                    value: values?.isCash,
                },
            ]}
        >
            <Print ref={printRef} title="Counter Deposit">
                <Row gutter={20}>
                    <Col span={15}>
                        <Row gutter={20}>
                            <Col xs={24} sm={12} md={9} lg={12}>
                                <Branches item="branchName" />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6}>
                                <Branches item="branchID" />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} sm={12} md={6} lg={5}>
                                <Form.Item
                                    label="Account No"
                                    className="borderinfo"
                                ></Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={13} lg={13}>
                                <Form.Item name="accountNoAlt">
                                    <Input
                                        className="ant-input--highlight-secondary"
                                        onPressEnter={getDepShortInfoQuick}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} sm={12} md={13} lg={4}></Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} sm={12} md={6} lg={5}>
                                <Form.Item label="Deposit Type"></Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={13}>
                                <Form.Item
                                    name="acName"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {depTypeAccounts?.length &&
                                            depTypeAccounts.map((acc, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={acc.acName}
                                                >
                                                    {acc.acName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6}>
                                <Form.Item
                                    name="acno"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {depTypeAccounts?.length &&
                                            depTypeAccounts.map((acc, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={acc.acno}
                                                >
                                                    {acc.acno}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} sm={12} md={6} lg={5}>
                                <Form.Item label="Account No"></Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={6} lg={13}>
                                <Form.Item
                                    name="accountNo"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {savingTypeAccounts?.length &&
                                            savingTypeAccounts.map((acc, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={acc.accountNo}
                                                >
                                                    {acc.accountNo}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} sm={12} md={6} lg={5}>
                                <Form.Item label="Name"></Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={6} lg={13}>
                                <Form.Item name="fullName">
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {savingTypeAccounts?.length &&
                                            savingTypeAccounts.map((acc, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={acc.fullName}
                                                >
                                                    {acc.fullName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6}>
                                <Form.Item name="memberNo">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} sm={12} md={6} lg={5}>
                                <Form.Item label="Address"></Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={6} lg={13}>
                                <Form.Item name="address">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} sm={12} md={6} lg={5}>
                                <Form.Item label="Deposited By"></Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={6} lg={13}>
                                <Form.Item name="receivedPaidBy">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={9}>
                        <Row gutter={20}>
                            <Col xs={24} sm={12} md={6} lg={8}>
                                <Form.Item label="Date"></Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={6} lg={16}>
                                <Form.Item name="transDate">
                                    <input
                                        type="text"
                                        className="ant-input"
                                        onKeyUp={e => onDateKeyUp(e, mode)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={10} lg={8}>
                                <Form.Item label="Journal No"></Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={9} lg={16}>
                                <Form.Item name="journalNO">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={10} lg={8}>
                                <Form.Item label="Receipt No"></Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={9} lg={16}>
                                <Form.Item name="receiptNo">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={8}>
                                <Form.Item label="Trans No "></Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={9} lg={16}>
                                <Form.Item name="transNo">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={24}>
                                <br></br>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={8}>
                                <Form.Item label="Current Balance"></Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={9} lg={16}>
                                <Form.Item name="actualBal">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={8}>
                                <Form.Item label="Deposit Rs"></Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={9} lg={16}>
                                <Form.Item name="preTotal">
                                    <Input
                                        // disabled
                                        className="ant-input--highlight-primary"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Print>
            <Space>
                <Button type="default" className="btns">
                    Statement
                </Button>
                <Button type="default" className="btns">
                    Notes
                </Button>
            </Space>
            <PaymentSource
                title="Receipt Source"
                mode="receipt"
                values={values}
            />
            <Space style={{ marginTop: '1rem' }}>
                <Button htmlType="submit" disabled={isProcessing}>
                    Save
                </Button>
                <ReactToPrint
                    trigger={() => (
                        <Button type="default">Print Receipt</Button>
                    )}
                    content={() => printRef.current}
                />
                <Button>Deno</Button>
                <Button onClick={handleNew}>New</Button>
            </Space>
        </Form>
    );
}
