import React from 'react';
import { Modal, Divider } from 'antd';

export default function InterestReportModal({
    visible,
    setIntCalcReportModal,
    viewValues,
}) {
    return (
        <Modal
            title="Interest Report"
            visible={visible}
            footer={false}
            onCancel={() => setIntCalcReportModal(false)}
        >
            <div>
                <p>
                    <strong>Interest Calculation Detail:</strong>
                </p>
                <p>{viewValues?.calcIntDetail || 'N/A'}</p>
            </div>
            <Divider />
            <div>
                <p>
                    <strong>Fine:</strong>
                </p>
                <p>{viewValues?.calcPenaltyDetail || 'N/A'}</p>
            </div>
        </Modal>
    );
}
