import React from 'react';
// Import from dependencies
import { Form, Select, Row, Col, Button } from 'antd';
import Print from '../../../Common/Print/Print';
import ReactToPrint from 'react-to-print';
import numToNepaliWords from 'num-to-nep-words';

const PrintShareCertificateForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        onValuesChange,
        values,
        members,
        certificateNos,
        certificateDetail,
    } = props;

    // Refs
    const printRef = React.useRef();

    // Variables and Constants

    return (
        <Form
            ref={ref}
            layout="horizontal"
            onValuesChange={onValuesChange}
            fields={[
                {
                    name: 'memberNO',
                    value: values?.memberNO,
                },
                {
                    name: 'memberName',
                    value: values?.memberName,
                },
                {
                    name: 'certificateNo',
                    value: values?.certificateNo,
                },
            ]}
        >
            <Row gutter={20}>
                <Col span={16}>
                    <Row gutter={20}>
                        <Col span={14}>
                            <Form.Item
                                label="Member ID"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                                name="memberNO"
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members?.length &&
                                        members?.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.memberNO}
                                            >
                                                {item.memberNO}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item name="memberName">
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members?.length &&
                                        members?.map((item, i) => (
                                            <Select.Option
                                                key={i}
                                                value={item.memberName}
                                            >
                                                {item.memberName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={14}>
                            <Form.Item
                                label="Certificate No"
                                labelCol={{ span: 8 }}
                                labelAlign="left"
                                name="certificateNo"
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {certificateNos?.length &&
                                        certificateNos?.map((item, i) => (
                                            <Select.Option key={i} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <ReactToPrint
                        trigger={() => <Button type="default">Print</Button>}
                        content={() => printRef.current}
                    />
                </Col>
            </Row>
            <Print
                ref={printRef}
                title="शेयर प्रमाणपत्र"
                lang="np"
                footer={['अध्यक्ष']}
            >
                <div className="print-share-certificate">
                    <div className="print-share-certificate__header">
                        <div className="print-share-certificate__header-left">
                            <div>प्र.प.नं.: {certificateDetail?.unCertiNo}</div>
                            <div>
                                सदस्यता नं.: {certificateDetail?.unMemberNO}
                            </div>
                        </div>
                        <div className="print-share-certificate__header-right">
                            <div>
                                शेयर संख्या: {certificateDetail?.uNnoOfShare}
                            </div>
                        </div>
                    </div>
                    <div className="print-share-certificate__body">
                        सहकारी ऐन २०४८ को संशोधन २०७४ दफा १२ अन्तर्गत यस
                        संस्थाको विनियम बमोजिम श्री
                        <span className="print-share-certificate__field">
                            {certificateDetail?.unFather}
                        </span>
                        को छोरा/छोरी/श्रीमती
                        <span className="print-share-certificate__field">
                            {certificateDetail?.unDistrict}
                        </span>
                        जिल्ला
                        <span className="print-share-certificate__field">
                            {certificateDetail?.unVdcMun}
                        </span>
                        नं.पा./गा.पा/वडा नं
                        <span className="print-share-certificate__field">
                            {certificateDetail?.unWardNO}
                        </span>
                        बस्ने श्री/सुश्री/श्रीमती
                        <span className="print-share-certificate__field">
                            {certificateDetail?.unName}
                        </span>
                        ले यस संस्थाको रु १०० दरको शेयर नं.
                        <span className="print-share-certificate__field">
                            {certificateDetail?.uNfrom}
                        </span>
                        देखि
                        <span className="print-share-certificate__field">
                            {certificateDetail?.uNto}
                        </span>
                        सम्मको जम्मा
                        <span className="print-share-certificate__field">
                            {certificateDetail?.uNnoOfShare}
                        </span>
                        कित्ता शेयर वापत जम्मा रु
                        <span className="print-share-certificate__field">
                            {certificateDetail?.unBalance}
                        </span>
                        अक्षरेपी
                        <span className="print-share-certificate__field">
                            {certificateDetail?.balance &&
                                numToNepaliWords(certificateDetail?.balance)}
                        </span>
                        बुझाई यस संस्थाको शेयर खरीद गर्नुभएकोले संस्थाको छाप
                        लागेको यो प्रमाण–पत्र प्रदान गरिएको छ ।
                    </div>
                </div>
            </Print>
        </Form>
    );
});

export default PrintShareCertificateForm;
