import React from 'react';
import moment from 'moment';
import axios from '../../../utils/axios';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import ProfitLossForm from '../../../components/Reports/ProfitLoss/ProfitLossForm/ProfitLossForm';
import { ON_DATE_CHANGE, ON_DATE_KEYUP } from '../../../utils/functions';
import { message } from 'antd';

export default function ProfitLoss() {
    // Local states
    const branches = JSON.parse(localStorage.getItem('BRANCHES'));
    const [viewValues, setViewValues] = React.useState(null);
    const [profitLoss, setProfitLoss] = React.useState(null);

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            fromDate: moment().format(),
            tillDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            acHeadTotal: true,
            level: 'accountLevel',
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On values change
    const onValuesChange = val => {
        console.log('val: ', val);
        const key = Object.keys(val)[0];
        const value = val[key];
        // const values = viewValues;

        console.log(key, value);
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'fromDate' || key === 'tillDate') {
            return;
        } else if (key === 'branchID' || key === 'branchName') {
            const branch =
                value === 'all'
                    ? { branchID: 'all', branchName: 'all' }
                    : branches.find(item => item[key] === value);
            updateValues(branch);
        }
        // Update values
        updateValues(val);
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Form props
    const formProps = {
        branches,
        viewValues,
        onValuesChange,
        profitLoss,
        onDateChange,
        onDateKeyUp,
    };

    React.useEffect(() => {
        if (viewValues) {
            const { fromDate, tillDate, branchID } = viewValues;
            const payload = {
                fromDate,
                tillDate,
                branchID,
            };
            // console.log('payload: ', payload);

            axios
                .post(
                    `${process.env.REACT_APP_HOST}/api/TrialBalance/ProfitLoss`,
                    payload
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    console.log(res.data.data);
                    setProfitLoss(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get profit loss account');
                    message.error(err, 'Failed to get trial balance');
                });
        }
    }, [viewValues]);

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    return (
        <PageWrapper>
            <ProfitLossForm {...formProps} />
        </PageWrapper>
    );
}
