import React from 'react';
// Import from dependencies
// Import utils
import moment from 'moment';
import {
    SET_DEFAULT_VALUES,
    UPDATE_VALUES_IN_STATE,
} from '../../../utils/functions';
import {
    GET_AREAS,
    GET_GROUPS,
    GET_GROUP_TYPES,
    GET_GROUP_DETAIL,
    GET_SHARE_PANJIKA,
} from '../../../utils/axios';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import SharePanjikaForm from '../../../components/Membership/SharePanjikaForm/SharePanjikaForm';

export default function SharePanjika() {
    // Local states
    const [viewValues, setViewValues] = React.useState(null);
    const [areas, setAreas] = React.useState([]);
    const [groups, setGroups] = React.useState([]);
    const [groupTypes, setGroupTypes] = React.useState([]);
    const [sharePanjika, setSharePanjika] = React.useState([]);
    const [sharePanjikaInfo, setSharePanjikaInfo] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [resPerPage, setResPerPage] = React.useState(10);

    // On view form values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];

        // Update values on state
        const updateValues = valObj => {
            UPDATE_VALUES_IN_STATE(mode, valObj, setViewValues);
        };

        if (key === 'groupName' || key === 'groupId') {
            if (value === 'All')
                return updateValues({
                    groupName: value,
                    groupId: value,
                });
            const group = groups.find(group => group[key] === value);
            return updateValues(group);
        }

        // Update values
        updateValues(val);
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            transDate: moment().format(),
        };
        SET_DEFAULT_VALUES(defaultValues, mode, setViewValues);
    }, []);

    // On pagination change
    const onPaginationChange = (page, pageSize) => {
        setPage(page);
        setResPerPage(pageSize);
    };

    // Form props
    const formProps = {
        areas,
        groups,
        groupTypes,
        sharePanjika,
        sharePanjikaInfo,
        onValuesChange,
        setDefaultValues,
        isLoading,
        onPaginationChange,
        page,
        resPerPage,
    };

    // Get group detail
    React.useEffect(() => {
        if (!viewValues?.groupId || viewValues?.groupId === 'All') return;
        (async () => {
            const groupDetail = await GET_GROUP_DETAIL(viewValues?.groupId);
            const { address, coordinator } = groupDetail;
            setViewValues(prevValues => ({
                ...prevValues,
                address,
                coordinator,
            }));
        })();
    }, [viewValues?.groupId]);

    // Get share panjika
    React.useEffect(() => {
        if (!viewValues?.groupName || !viewValues?.area) return;
        setSharePanjika([]);
        setIsLoading(true);
        (async () => {
            const payload = {
                branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                    .branchID,
                group: viewValues.groupName,
                area: viewValues.area,
                pageNO: page,
                rpp: resPerPage,
            };
            const res = await GET_SHARE_PANJIKA(payload);
            setSharePanjika(res.data);
            console.log('res.data: ', res.data);
            // Share panjika info
            const info = res;
            delete info['data'];
            setSharePanjikaInfo(info);
            setIsLoading(false);
        })();
    }, [viewValues?.groupName, viewValues?.area, page, resPerPage]);

    // On component mount
    React.useEffect(() => {
        (async () => {
            // Areas
            const areas = await GET_AREAS();
            setAreas(areas);
            // Groups
            const groups = await GET_GROUPS();
            setGroups(groups);
            // Group types
            const groupTypes = await GET_GROUP_TYPES();
            setGroupTypes(groupTypes);
            // Set default values
            setDefaultValues();
        })();
    }, [setDefaultValues]);

    return (
        <PageWrapper>
            <SharePanjikaForm {...formProps} values={viewValues} />
        </PageWrapper>
    );
}
