import React from 'react';
// Import from dependencies
import { Button, Modal, message } from 'antd';
// Import utils
import axios, { GET_COUNTERS_BY_BRANCH } from '../../../utils/axios';
import {
    SET_DEFAULT_VALUES,
    DLT_KEYS_FROM_OBJ_EXCEPT,
} from '../../../utils/functions';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import CounterRegistrationForm from '../../../components/Counter/CounterRegistration/CounterRegistrationForm/CounterRegistrationForm';
import BottomActionBarFixed from '../../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function CounterRegistration() {
    // Global states
    const branches = React.useState(
        JSON.parse(localStorage.getItem('BRANCHES'))
    )[0];
    // Local states
    const [counters, setCounters] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [addValues, setAddValues] = React.useState(null);
    const [editValues, setEditValues] = React.useState(null);
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [asyncUpdateModal, setAsyncUpdateModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Get new account number to create account
    const getNewCounterNo = React.useCallback(() => {
        axios
            .get(`${process.env.REACT_APP_HOST}/api/Counters/GetNewCounterID`)
            .then(res => {
                setAddValues(prevValues => ({
                    ...prevValues,
                    counterNO: res.data.data,
                }));
            })
            .catch(err => {
                console.error(err, 'Failed to get new counter number');
                message.error('Something went wrong!');
            });
    }, []);

    const getCountersByBranch = React.useCallback(async branchID => {
        const counters = await GET_COUNTERS_BY_BRANCH(branchID);
        setCounters(counters);
    }, []);

    // On view form values change
    const onValuesChange = (val, mode) => {
        const key = Object.keys(val)[0];
        const value = val[key];
        console.log(key, value);
        let values;
        if (mode === 'view') values = viewValues;
        else if (mode === 'edit') values = editValues;
        else if (mode === 'add') values = addValues;
        const updateValues = valObj => {
            if (mode === 'view')
                setViewValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'edit')
                setEditValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
            else if (mode === 'add')
                setAddValues(prevValues => ({
                    ...prevValues,
                    ...valObj,
                }));
        };
        if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(branch => branch[key] === value);

            updateValues({
                ...DLT_KEYS_FROM_OBJ_EXCEPT(values, ['branchID', 'branchName']),
                ...branch,
            });
        } else if (key === 'counterNO' || key === 'counterName') {
            if (mode === 'view') {
                const counter = counters.find(
                    counter => counter[key] === value
                );
                updateValues(counter);
            } else {
                if (key === 'counterName') {
                    return updateValues({ [key]: value.toUpperCase() });
                }
            }
        }
        // Update values
        updateValues(val);
    };

    // On edit account button click
    const onEditModalOpen = () => {
        setEditValues(viewValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On Edit account modal OK
    const onEditModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setEditModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: true,
                }));
                axios
                    .patch(
                        `${process.env.REACT_APP_HOST}/api/Counters/UpdateCounter`,
                        editValues
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0) {
                            if (res.data.statusCode === 2) {
                                return setAsyncUpdateModal(prevValues => ({
                                    ...prevValues,
                                    visible: true,
                                }));
                            } else {
                                return message.error(res.data.statusMessage);
                            }
                        }
                        const countersUpdated = [...counters];
                        const i = countersUpdated.findIndex(
                            counter =>
                                counter.counterNO === editValues.counterNO
                        );
                        countersUpdated[i] = editValues;
                        setCounters(countersUpdated);
                        setViewValues(editValues);
                        setEditModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success(res.data.statusMessage);
                    })
                    .catch(err => {
                        console.error(err, 'Failed to update counter');
                        message.error('Failed to update counter');
                    })
                    .finally(() => {
                        setEditModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setEditValues(null);
    };

    // ASYNC UPDATE MODAL
    // On async update modal ok
    const onAsyncUpdateModalOk = () => {
        setAsyncUpdateModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .patch(
                `${process.env.REACT_APP_HOST}/api/Counters/UpdateAllCounter`,
                editValues
            )
            .then(res => {
                if (res.data.statusCode !== 0) {
                    setAsyncUpdateModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                    return message.error(res.data.statusMessage);
                }
                setViewValues(editValues);
                const countersUpdated = [...counters];
                const i = countersUpdated.findIndex(
                    counter => counter.counterNO === editValues.counterNO
                );
                countersUpdated[i] = res.data.data;
                setCounters(countersUpdated);
                setAsyncUpdateModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                setEditModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success(res.data.statusMessage);
            })
            .catch(err => {
                console.error(err, 'Failed to update counter asynchronously');
                message.error('Something went wrong. Please try again!');
            })
            .finally(() => {
                setAsyncUpdateModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // On async update modal cancel
    const onAsyncUpdateModalCancel = () => {
        setAsyncUpdateModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // ADD ACCOUNT MODAL
    // On Add account modal OK
    const onAddModalOk = () => {
        formRef.current
            .validateFields()
            .then(() => {
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/Counters/InsertCounter`,
                        addValues
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0)
                            return message.error(res.data.statusMessage);
                        const data = res.data.data;
                        setViewValues(data);
                        setCounters([...counters, data]);
                        setAddModal(prevValues => ({
                            ...prevValues,
                            visible: false,
                        }));
                        message.success(res.data.statusMessage);
                        setAddValues(null);
                    })
                    .catch(err => {
                        console.error(err, 'Failed to create counter');
                        message.error('Failed to create counter');
                    })
                    .finally(() => {
                        setAddModal(prevValues => ({
                            ...prevValues,
                            confirmLoading: false,
                        }));
                    });
            })
            .catch(() => {
                message.error('Please fill all required fields!');
            });
    };

    // On Add account modal Cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
        setAddValues(null);
    };

    // On Add account button click
    const onAddModalOpen = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // DELETE ACCOUNT MODAL
    // On delete account button click
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On delete account modal Ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/Counters/DeleteCounter/${viewValues.counterNO}`
            )
            .then(res => {
                const countersUpdated = [...counters].filter(
                    counter => counter.counterNO !== viewValues.counterNO
                );
                setCounters(countersUpdated);
                setViewValues(
                    DLT_KEYS_FROM_OBJ_EXCEPT(viewValues, [
                        'branchID',
                        'branchName',
                    ])
                );
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
                message.success(res.data.statusMessage);
            })
            .catch(err => console.error(err, 'Failed to delete counter'))
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // On delete account modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
            confirmLoading: false,
        }));
    };

    // Utils
    const setDefaultValues = React.useCallback(mode => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
        };
        SET_DEFAULT_VALUES(
            defaultValues,
            mode,
            setViewValues,
            setEditValues,
            setAddValues
        );
    }, []);

    // Form props
    const formProps = {
        ref: formRef,
        onValuesChange,
        counters,
        setDefaultValues,
        getCountersByBranch,
        getNewCounterNo,
    };

    return (
        <PageWrapper>
            <CounterRegistrationForm
                {...formProps}
                mode="view"
                values={viewValues}
            />
            <BottomActionBarFixed>
                <Button
                    type="primary"
                    onClick={onEditModalOpen}
                    disabled={!viewValues?.counterNO}
                >
                    Edit
                </Button>
                <Button type="primary" onClick={onAddModalOpen}>
                    Add
                </Button>
                <Button
                    type="primary"
                    onClick={onDeleteModalOpen}
                    disabled={!viewValues?.counterNO}
                >
                    Delete
                </Button>
            </BottomActionBarFixed>
            {/* Edit account modal */}
            <Modal
                title="Edit Counter"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                okText="Update"
                onOk={onEditModalOk}
                onCancel={onEditModalCancel}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <CounterRegistrationForm
                    {...formProps}
                    mode="edit"
                    values={editValues}
                />
            </Modal>
            {/* Async edit modal */}
            <Modal
                title="Update counter?"
                visible={asyncUpdateModal.visible}
                confirmLoading={asyncUpdateModal.confirmLoading}
                onOk={onAsyncUpdateModalOk}
                onCancel={onAsyncUpdateModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: asyncUpdateModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>
                    The update may take a while. Please be patient during the
                    operation.
                </p>
            </Modal>
            {/* Add account modal */}
            <Modal
                title="Add Counter"
                visible={addModal.visible}
                confirmLoading={addModal.confirmLoading}
                onOk={onAddModalOk}
                onCancel={onAddModalCancel}
                okText="Create"
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <CounterRegistrationForm
                    {...formProps}
                    mode="add"
                    values={addValues}
                />
            </Modal>
            {/* Delete modal */}
            <Modal
                title="Delete account?"
                visible={deleteModal.visible}
                confirmLoading={deleteModal.confirmLoading}
                onOk={onDeleteModalOk}
                onCancel={onDeleteModalCancel}
                width={480}
                okText="Delete"
                cancelButtonProps={{
                    disabled: deleteModal.confirmLoading,
                }}
                maskClosable={false}
            >
                <p>The counter and its data will be deleted forever</p>
            </Modal>
        </PageWrapper>
    );
}
