import React from 'react';
import { Form, Row, Col, Input, Button } from 'antd';

const ChangePasswordForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        values,
        onValuesChange,
        handlePasswordConfirm,
        isChangingPassword,
    } = props;
    return (
        <Form
            ref={ref}
            layout="vertical"
            onValuesChange={onValuesChange}
            fields={[
                {
                    name: 'confirmpassword',
                    value: values?.confirmpassword,
                },
                {
                    name: 'password',
                    value: values?.password,
                },
                {
                    name: 'confirmNewPassword',
                    value: values?.confirmNewPassword,
                },
            ]}
        >
            <Row gutter={20}>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item label="Branch Name ">
                        <div className="ant-input ant-input--fake">
                            {
                                JSON.parse(
                                    localStorage.getItem('USER_CREDENTIALS')
                                ).branchName
                            }
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item label="Branch ID">
                        <div className="ant-input ant-input--fake">
                            {
                                JSON.parse(
                                    localStorage.getItem('USER_CREDENTIALS')
                                ).branchID
                            }
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}></Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item label=" User Name">
                        <div className="ant-input ant-input--fake">
                            {
                                JSON.parse(
                                    localStorage.getItem('USER_CREDENTIALS')
                                ).userName
                            }
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label=" Old Password"
                        name="confirmpassword"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                            {
                                min: 6,
                                message: 'At least 6 characters',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12}></Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="New Password"
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                            {
                                min: 6,
                                message: 'At least 6 characters',
                            },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={6}>
                    <Form.Item
                        label="Confirm New Password"
                        name="confirmNewPassword"
                        rules={[
                            {
                                required: true,
                                message: 'Required',
                            },
                            {
                                min: 6,
                                message: 'At least 6 characters',
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (
                                        !value ||
                                        getFieldValue('password') === value
                                    ) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error('Passwords do not match!')
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Form.Item>
                    <Button
                        type="primary"
                        style={{ marginTop: '8px' }}
                        disabled={
                            !values?.confirmpassword ||
                            !values?.password ||
                            !values?.confirmNewPassword ||
                            isChangingPassword
                        }
                        loading={isChangingPassword}
                        onClick={handlePasswordConfirm}
                    >
                        Change password
                    </Button>
                </Form.Item>
            </Row>
        </Form>
    );
});

export default ChangePasswordForm;
