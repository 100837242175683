import React from 'react';
import moment from 'moment';
import axios from '../../../utils/axios';
import {
    GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS,
    GET_SAVING_TYPE_ACCOUNTS_FULL,
} from '../../../utils/axios';
// Import components
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import ShowStatementForm from '../../../components/Savings/ShowStatement/ShowStatementForm/ShowStatementForm';
import { ON_DATE_CHANGE, ON_DATE_KEYUP } from '../../../utils/functions';
import { message } from 'antd';

export default function ShowStatement() {
    // Local states
    const branches = JSON.parse(localStorage.getItem('BRANCHES'));
    const [viewValues, setViewValues] = React.useState(null);
    const [statement, setStatement] = React.useState(null);
    const [depTypeAccounts, setDepTypeAccounts] = React.useState([]);
    const [savingTypeAccounts, setSavingTypeAccounts] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            fromDate: moment().format(),
            tillDate: moment().format(),
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            acHeadTotal: true,
            level: 'accountLevel',
            showJournalNo: true,
            showBalance: true,
            showDocNo: true,
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // On values change
    const onValuesChange = val => {
        console.log('val: ', val);
        const key = Object.keys(val)[0];
        const value = val[key];
        // const values = viewValues;
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'fromDate' || key === 'tillDate') {
            return;
        } else if (key === 'branchID' || key === 'branchName') {
            const branch = branches.find(item => item[key] === value);
            updateValues(branch);
        } else if (key === 'acName' || key === 'acno') {
            const acc = depTypeAccounts.find(item => item[key] === value);
            updateValues(acc);
        } else if (key === 'fullName' || key === 'accountNo') {
            const acc = savingTypeAccounts.find(item => item[key] === value);
            updateValues(acc);
        }
        // Update values
        updateValues(val);
    };

    // Form props
    const formProps = {
        branches,
        viewValues,
        onValuesChange,
        statement,
        depTypeAccounts,
        savingTypeAccounts,
        isLoading,
        onDateChange,
        onDateKeyUp,
    };

    React.useEffect(() => {
        if (
            viewValues?.acno &&
            viewValues?.accountNo &&
            viewValues?.fromDate &&
            viewValues?.tillDate &&
            viewValues?.branchID
        ) {
            const payload = {
                acno: viewValues.acno,
                accountNo: viewValues.accountNo,
                fromDate: viewValues.fromDate,
                tillDate: viewValues.tillDate,
                branchID: viewValues.branchID,
            };

            setIsLoading(true);
            axios
                .post(
                    `${process.env.REACT_APP_HOST}/api/SavingAccount/DepositStatement`,
                    payload
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    console.log(res.data.data);
                    setStatement(res.data.data);
                })
                .catch(err => {
                    console.error(err, 'Failed to get trial balance');
                    message.error(err, 'Failed to get trial balance');
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [viewValues]);

    // On component mount
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    // Get active deposit type accounts
    React.useEffect(() => {
        (async () => {
            const depTypeAccounts = await GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS();
            setDepTypeAccounts(depTypeAccounts);
            setDefaultValues();
        })();
    }, []);

    // Get saving type accounts
    React.useEffect(() => {
        if (viewValues?.branchID && viewValues?.acno) {
            console.log(viewValues.branchID, viewValues.acno);
            (async () => {
                const savingTypeAccounts = await GET_SAVING_TYPE_ACCOUNTS_FULL(
                    viewValues.branchID,
                    viewValues.acno
                );
                setSavingTypeAccounts(savingTypeAccounts);
                setDefaultValues();
            })();
        }
    }, [viewValues?.branchID, viewValues?.acno]);

    return (
        <PageWrapper>
            <ShowStatementForm {...formProps} />
        </PageWrapper>
    );
}
