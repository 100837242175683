import React from 'react';
import { message } from 'antd';
import moment from 'moment';
import {
    DLT_KEYS_FROM_OBJ,
    ON_DATE_CHANGE,
    ON_DATE_KEYUP,
} from '../../../utils/functions';
import axios, {
    GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS,
    GET_SAVING_TYPE_ACCOUNTS_FULL,
} from '../../../utils/axios';
import PageWrapper from '../../../components/Common/PageWrapper/PageWrapper';
import CalculateInterestForm from '../../../components/Savings/CalculateInterest/CalculateInterestForm/CalculateInterestForm';

export default function CalculateInterest() {
    // Global states
    // Local states
    const [depAccounts, setDepAccounts] = React.useState([]);
    const [savingAccounts, setSavingAccounts] = React.useState([]);
    const [intColSavingAccounts, setIntColSavingAccounts] = React.useState([]);
    const [viewValues, setViewValues] = React.useState(null);
    const [isProcessing, setIsProcessing] = React.useState(false);

    // Ref
    const formRef = React.createRef();

    // DATEPICKER
    // On date change
    const onDateChange = (key, date, mode) => {
        ON_DATE_CHANGE(key, date, mode, setViewValues);
    };

    // On date select
    const onDateKeyUp = (e, mode) => {
        ON_DATE_KEYUP(e, mode, setViewValues);
    };

    // Set default values
    const setDefaultValues = () => {
        const defaultValues = {
            branchID: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchID,
            branchName: JSON.parse(localStorage.getItem('USER_CREDENTIALS'))
                .branchName,
            tillDate: moment().format(),
            intDate: moment().format(),
            intSaveType: 'Same',
            selfCalculation: false,
        };
        setViewValues(prevValues => ({
            ...defaultValues,
            ...prevValues,
        }));
    };

    // On values change
    const onValuesChange = val => {
        const key = Object.keys(val)[0];
        const value = val[key];
        const values = viewValues;
        const updateValues = valObj => {
            setViewValues(prevValues => ({
                ...prevValues,
                ...valObj,
            }));
        };
        if (key === 'tillDate' || key === 'intDate') {
            return;
        } else if (key === 'acName' || key === 'acno') {
            const acc = depAccounts.find(acc => acc[key] === value);
            setViewValues(acc);
        } else if (key === 'accountNo' || key === 'fullName') {
            const acc = savingAccounts.find(acc => acc[key] === value);
            updateValues(acc);
        } else if (key === 'intCollAccountNo' || key === 'intCollName') {
            const accKey = key === 'intCollName' ? 'fullName' : 'accountNo';
            const acc = intColSavingAccounts.find(acc => acc[accKey] === value);
            updateValues({
                intCollAccountNo: acc.accountNo,
                intCollName: acc.fullName,
            });
        } else if (key === 'intCollACName' || key === 'intCollACNO') {
            const accKey = key === 'intCollACName' ? 'acName' : 'acno';
            const acc = depAccounts.find(acc => acc[accKey] === value);
            updateValues({
                ...DLT_KEYS_FROM_OBJ(values, [
                    'intCollAccountNo',
                    'intCollName',
                ]),
                intCollACName: acc.acName,
                intCollACNO: acc.acno,
            });
        } else if (key === 'interest') {
            const tax = Math.round((+value * +viewValues?.taxRate) / 100);
            updateValues({ tax, odInterest: 0 });
        } else if (key === 'odInterest') {
            updateValues({
                interest: 0,
                tax: 0,
            });
        } else if (key === 'intSaveType') {
            if (value === 'Same') {
                setViewValues(prevValues => ({
                    ...prevValues,
                    intCollACName: viewValues?.acName,
                    intCollACNO: viewValues?.acno,
                    intCollAccountNo: viewValues?.accountNo,
                    intCollName: viewValues?.fullName,
                }));
            }
        }
        // Update values
        updateValues(val);
    };

    // On form submit
    const onFormSubmit = () => {
        formRef.current
            .validateFields()
            .then(() => {
                setIsProcessing(true);
                const payload = {
                    transDate: viewValues?.tillDate,
                    enteredBy: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).userName,
                    memberNO: viewValues?.memberNo,
                    memberName: viewValues?.fullName,
                    memAddress: viewValues?.address,
                    branchID: JSON.parse(
                        localStorage.getItem('USER_CREDENTIALS')
                    ).branchID,
                    transactionType: 'Individual Interest',
                    description: viewValues?.description,
                    jnDesc: `Interest Collected (${viewValues?.fullName} - ${viewValues?.accountNo})`,
                    transDesc: `Interest Collected (${viewValues?.fullName} - ${viewValues?.accountNo})`,
                    debittrans: [
                        ...(viewValues?.interest
                            ? [
                                  {
                                      mano: '150',
                                      acno: '150.07',
                                      bvrcno: 'INT001',
                                      itemCode: `INT${viewValues?.acno.slice(
                                          -2
                                      )}`,
                                      itemName: viewValues?.acName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchName,
                                      receivedPaidBy: 'Office',
                                      remarks1: viewValues?.accountNo,
                                      rate: viewValues?.interest,
                                      particulars: `Interest Collected (${viewValues?.accountNo})`,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.interest,
                                      quantity: '1',
                                  },
                              ]
                            : []),
                        ...(viewValues?.odInterest
                            ? [
                                  {
                                      mano: '030',
                                      acno: viewValues?.acno,
                                      bvrcno: '',
                                      itemCode: viewValues?.accountNo,
                                      itemName: viewValues?.fullName,
                                      itemLocation: viewValues?.address,
                                      receivedPaidBy: 'Office',
                                      remarks1: '0',
                                      rate: viewValues?.interest,
                                      particulars: `Interest on Overdraft Charged(${viewValues?.fullName}-${viewValues?.accountNo})`,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.interest,
                                      quantity: '1',
                                      CalculatedTill: viewValues?.intDate,
                                      updateDeposit: true,
                                  },
                              ]
                            : []),
                    ],
                    credittrans: [
                        ...(viewValues?.interest
                            ? [
                                  {
                                      mano: '030',
                                      acno: viewValues?.acno,
                                      bvrcno: '',
                                      itemCode: viewValues?.accountNo,
                                      itemName: viewValues?.fullName,
                                      itemLocation: viewValues?.address,
                                      receivedPaidBy: 'Office',
                                      remarks1: '0',
                                      rate: viewValues?.interest,
                                      particulars: `Interest Collected(${viewValues?.fullName}-${viewValues?.accountNo})`,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.interest,
                                      quantity: '1',
                                      DepositTDS: viewValues?.tax,
                                      CalculatedTill: viewValues?.intDate,
                                      intSaveType: viewValues?.intSaveType,
                                      intCollACNO: viewValues?.intCollACNO,
                                      intCollAccountNo:
                                          viewValues?.intCollAccountNo,
                                      intCollName: viewValues?.intCollName,
                                      updateDeposit: true,
                                  },
                              ]
                            : []),
                        ...(viewValues?.odInterest
                            ? [
                                  {
                                      mano: '160',
                                      acno: '160.02',
                                      bvrcno: 'INT001',
                                      itemCode: `INTOD${viewValues?.acno.slice(
                                          -2
                                      )}`,
                                      itemName: viewValues?.acName,
                                      itemLocation: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchName,
                                      receivedPaidBy: 'Office',
                                      remarks1: viewValues?.acno,
                                      rate: viewValues?.odInterest,
                                      particulars: `Interest on Overdraft Charged (${viewValues?.accountNo})`,
                                      interBranchID: JSON.parse(
                                          localStorage.getItem(
                                              'USER_CREDENTIALS'
                                          )
                                      ).branchID,
                                      amount: viewValues?.odInterest,
                                      quantity: '1',
                                  },
                              ]
                            : []),
                    ],
                };
                console.log(payload, 'payload');
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/AllTransactions/SaveIndividualInterest`,
                        payload
                    )
                    .then(res => {
                        if (res.data.statusCode !== 0) {
                            setIsProcessing(false);
                            return message.error(res.data.statusMessage);
                        }
                        setViewValues(prevValues => ({
                            ...prevValues,
                            ...res.data.data,
                        }));
                        message.success(res.data.statusMessage);
                    })
                    .catch(err => {
                        console.error(
                            err,
                            'Failed to process individual interest calculation'
                        );
                        message.error(
                            'Something went wrong. Please try again!'
                        );
                        setIsProcessing(false);
                    });
            })
            .catch(err => {
                console.error(err);
                message.error('Please fill all required fields!');
            });
    };

    // Handle new
    const handleNew = () => {
        setViewValues(null);
        setDefaultValues();
        setIsProcessing(false);
    };

    // Form props
    const formProps = {
        ref: formRef,
        depAccounts,
        savingAccounts,
        intColSavingAccounts,
        onValuesChange,
        isProcessing,
        handleNew,
        onFormSubmit,
        onDateChange,
        onDateKeyUp,
    };

    // Get active deposit type accounts
    React.useEffect(() => {
        (async () => {
            const depTypeAccounts = await GET_ACTIVE_DEPOSIT_TYPE_ACCOUNTS();
            setDepAccounts(depTypeAccounts);
            setDefaultValues();
        })();
    }, []);

    // Get saving type accounts
    React.useEffect(() => {
        if (viewValues?.acno) {
            (async () => {
                const savingTypeAccounts = await GET_SAVING_TYPE_ACCOUNTS_FULL(
                    undefined,
                    viewValues.acno
                );
                setSavingAccounts(savingTypeAccounts);
                setDefaultValues();
            })();
        }
    }, [viewValues?.branchID, viewValues?.acno]);

    // Get saving type accounts
    React.useEffect(() => {
        if (viewValues?.intCollACNO) {
            (async () => {
                const savingTypeAccounts = await GET_SAVING_TYPE_ACCOUNTS_FULL(
                    undefined,
                    viewValues.intCollACNO
                );
                setIntColSavingAccounts(savingTypeAccounts);
                setDefaultValues();
            })();
        }
    }, [viewValues?.branchID, viewValues?.intCollACNO]);

    // Get Saving Account Info
    React.useEffect(() => {
        if (
            viewValues?.acno &&
            viewValues?.accountNo &&
            viewValues?.tillDate &&
            viewValues?.intDate
        ) {
            const branchID = JSON.parse(
                localStorage.getItem('USER_CREDENTIALS')
            ).branchID;
            const acno = viewValues.acno;
            const accountNo = viewValues.accountNo;
            const tillDate = viewValues.tillDate;
            const intDate = viewValues.intDate;
            (async () => {
                axios
                    .post(
                        `${process.env.REACT_APP_HOST}/api/SavingAccount/DepositAccountinfowithInterest`,
                        {
                            branchID,
                            acno,
                            accountNo,
                            tillDate,
                            intDate,
                        }
                    )
                    .then(res => {
                        const data = res.data.data;
                        console.log(data, 'res');
                        setViewValues(prevValues => ({
                            ...prevValues,
                            ...data,
                            ...(data.style === 'Fixed'
                                ? { intSaveType: 'Payable' }
                                : {}),
                            ...(data.intCollACNO && data.intCollAccountNo
                                ? {
                                      intSaveType: 'Other',
                                  }
                                : {}),
                        }));
                    })
                    .catch(err => {
                        console.error(err, 'Failed to get saving account info');
                        message.error(
                            err,
                            'Something went wrong. Please try again!'
                        );
                    });
            })();
        }
    }, [
        viewValues?.acno,
        viewValues?.accountNo,
        viewValues?.tillDate,
        viewValues?.intDate,
    ]);

    // console.log(viewValues, 'viewValues');

    // Set default values
    React.useEffect(() => {
        setDefaultValues();
    }, []);

    return (
        <PageWrapper>
            <CalculateInterestForm {...formProps} values={viewValues} />
        </PageWrapper>
    );
}
