import React from 'react';
import { Form, Select, Input, Row, Col, Checkbox, Radio } from 'antd';

import { PROFILE_PIC_SUPPORTED_EXT } from '../../../utils/constants';
import {
    SET_PROFILE_PIC,
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../utils/functions';
import PhotoPlaceholder from '../../../assets/images/pp.jpeg';

const StaffRegistrationForm = React.forwardRef((props, ref) => {
    // Local states
    const [photoUrl, setPhotoUrl] = React.useState(null);
    // Destructuring
    const {
        branches,
        staffs,
        getStaffs,
        getNewStaffID,
        staffTypes,
        staffPosts,
        memberNos,
        provinces,
        districts,
        municipalities,
        casteGroups,
        castes,
        religions,
        maritalStatuses,
        values,
        onValuesChange,
        onCheckboxChange,
        onFileChange,
        mode,
        getMemberNos,
        setDefaultValues,
        onDateChange,
        onDateKeyUp,
    } = props;

    // NEPALI DATEPICKER
    // Entrance date
    const entranceDateInput = document.getElementById(`entranceDate__${mode}`);
    entranceDateInput &&
        entranceDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, entranceDateInput, mode)
        );
    // Formed date
    const dateOfBirthInput = document.getElementById(`dateOfBirth__${mode}`);
    dateOfBirthInput &&
        dateOfBirthInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, dateOfBirthInput, mode)
        );

    // On component mount
    React.useEffect(() => {
        // Set default values
        setDefaultValues(mode);
        // Set profile pic
        SET_PROFILE_PIC(values?.photo, values?.photoLoc, setPhotoUrl);
    }, [mode, setDefaultValues, values?.photo, values?.photoLoc]);

    // Get staffs or new staff ID
    React.useEffect(() => {
        if (values?.branchID) {
            if (mode === 'add') {
                getNewStaffID(values.branchID);
            } else {
                getStaffs(values.branchID);
            }
        }
    }, [getStaffs, mode, values?.branchID, getNewStaffID]);

    return (
        <Form
            ref={ref}
            layout="vertical"
            onValuesChange={val => onValuesChange(val, mode)}
            fields={[
                {
                    name: 'branchID',
                    value: values?.branchID,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'staffID',
                    value: values?.staffID,
                },
                {
                    name: 'staffType',
                    value: values?.staffType,
                },
                {
                    name: 'status',
                    value: values?.status,
                },
                {
                    name: 'workingPlace',
                    value: values?.workingPlace,
                },
                {
                    name: 'workingPlace',
                    value: values?.workingPlace,
                },
                {
                    name: 'collectorID',
                    value: values?.collectorID,
                },
                {
                    name: 'password',
                    value: values?.password,
                },
                {
                    name: 'memberNo',
                    value: values?.memberNo,
                },
                {
                    name: 'staffName',
                    value: values?.staffName,
                },
                {
                    name: 'panno',
                    value: values?.panno,
                },
                {
                    name: 'province',
                    value: values?.province,
                },
                {
                    name: 'vdcMun',
                    value: values?.vdcMun,
                },
                {
                    name: 'wardNo',
                    value: values?.wardNo,
                },
                {
                    name: 'tole',
                    value: values?.tole,
                },
                {
                    name: 'district',
                    value: values?.district,
                },
                {
                    name: 'telephone',
                    value: values?.telephone,
                },
                {
                    name: 'father',
                    value: values?.father,
                },
                {
                    name: 'education',
                    value: values?.education,
                },
                {
                    name: 'gender',
                    value: values?.gender,
                },
                {
                    name: 'mobileNo',
                    value: values?.mobileNo,
                },
                {
                    name: 'citizenshipNo',
                    value: values?.citizenshipNo,
                },
                {
                    name: 'emailID',
                    value: values?.emailID,
                },
                {
                    name: 'casteName',
                    value: values?.casteName,
                },
                {
                    name: 'casteGroup',
                    value: values?.casteGroup,
                },
                {
                    name: 'maritalStatus',
                    value: values?.maritalStatus,
                },
                {
                    name: 'religion',
                    value: values?.religion,
                },
                {
                    name: 'post',
                    value: values?.post,
                },
                {
                    name: 'unStaffName',
                    value: values?.unStaffName,
                },
                {
                    name: `entranceDate__${mode}`,
                    value:
                        values?.entranceDate &&
                        ENG_ISO_TO_NEP(values.entranceDate),
                },
                {
                    name: `dateOfBirth__${mode}`,
                    value:
                        values?.dateOfBirth &&
                        ENG_ISO_TO_NEP(values.dateOfBirth),
                },
            ]}
        >
            {/* <Row gutter={20}> */}
            <Row gutter={20}>
                <Col xs={24} lg={20} xl={21}>
                    <Row gutter={20}>
                        {/* <Col xs={24} sm={12} md={8} lg={2}></Col> */}
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Row gutter={10}>
                                <Col lg={8}>
                                    <Form.Item
                                        label="Office ID"
                                        name="branchID"
                                    >
                                        <Select
                                            placeholder="Select..."
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {branches.map((branch, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={branch.branchID}
                                                >
                                                    {branch.branchID}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={16}>
                                    <Form.Item
                                        label="Working Office"
                                        name="branchName"
                                    >
                                        <Select
                                            placeholder="Select..."
                                            showSearch
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase()
                                                    ) >= 0
                                            }
                                        >
                                            {branches.map((branch, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={branch.branchName}
                                                >
                                                    {branch.branchName}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={3}>
                            <Form.Item
                                label="Staff ID"
                                name="staffID"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {staffs.map((staff, i) => (
                                            <Select.Option
                                                key={i}
                                                value={staff.staffID}
                                            >
                                                {staff.staffID}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <p>{values?.staffID}</p>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Date of Appointment"
                                name={`entranceDate__${mode}`}
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <input
                                    type="text"
                                    className="ant-input"
                                    onKeyUp={e => onDateKeyUp(e, mode)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={5}>
                            <Form.Item
                                label="Staff Type"
                                name="staffType"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {staffTypes.map((type, i) => (
                                        <Select.Option
                                            key={i}
                                            value={type.staffType}
                                        >
                                            {type.staffType}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Status"
                                name="status"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Select.Option value="PERMANENT">
                                        PERMANENT
                                    </Select.Option>
                                    <Select.Option value="TEMPORARY">
                                        TEMPORARY
                                    </Select.Option>
                                    <Select.Option value="CONTRACT BASIS">
                                        CONTRACT BASIS
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={2}>
                            <Form.Item label="Collector">
                                <Checkbox
                                    name="isCollector"
                                    checked={values?.isCollector}
                                    onChange={e => onCheckboxChange(e, mode)}
                                >
                                    {values?.isCollector ? 'Yes' : 'No'}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={5}>
                            <Form.Item
                                label="Working Place"
                                name="workingPlace"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Radio.Group>
                                    <Radio value="In-door">Indoor</Radio>
                                    <Radio value="Out-door">Outdoor</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {values?.isCollector && (
                            <>
                                <Col xs={24} sm={12} md={8} lg={3}>
                                    <Form.Item
                                        label="Collector ID"
                                        name="collectorID"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={4}>
                                    <Form.Item
                                        label="Collection Password"
                                        name="password"
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                        <Col xs={24} sm={12} md={8} lg={3}>
                            <Form.Item label="Existing Member">
                                <Checkbox
                                    checked={values?.isMember}
                                    name="isMember"
                                    onChange={e => onCheckboxChange(e, mode)}
                                >
                                    {values?.isMember ? 'Yes' : 'No'}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        {values?.isMember && (
                            <>
                                {mode !== 'view' && (
                                    <Col xs={24} sm={12} md={8} lg={4}>
                                        <Form.Item
                                            label="Branch"
                                            rules={
                                                mode !== 'view' && [
                                                    {
                                                        required: true,
                                                        message: 'Required',
                                                    },
                                                ]
                                            }
                                        >
                                            <Select
                                                placeholder="Select..."
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                                onChange={val =>
                                                    getMemberNos(val)
                                                }
                                            >
                                                {branches.map((branch, i) => (
                                                    <Select.Option
                                                        key={i}
                                                        value={branch.branchID}
                                                    >
                                                        {branch.branchName}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                )}
                                <Col xs={24} sm={12} md={8} lg={3}>
                                    <Form.Item
                                        label="Member ID"
                                        name="memberNo"
                                        rules={
                                            mode !== 'view' && [
                                                {
                                                    required: true,
                                                    message: 'Required',
                                                },
                                            ]
                                        }
                                    >
                                        {mode === 'view' ? (
                                            <Input />
                                        ) : (
                                            <Select
                                                placeholder="Select..."
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase()
                                                        ) >= 0
                                                }
                                            >
                                                {memberNos.map((no, i) => (
                                                    <Select.Option
                                                        key={i}
                                                        value={no.memberNO}
                                                    >
                                                        {no.memberNO}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} sm={12} md={8} lg={7}>
                            <Form.Item
                                label="Name"
                                name="staffName"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={7}>
                            <Form.Item label="Nepali Name" name="unStaffName">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Post"
                                name="post"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <Input />
                                ) : (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {staffPosts.map((post, i) => (
                                            <Select.Option
                                                key={i}
                                                value={post.post}
                                            >
                                                {post.post}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={4}>
                            <Form.Item
                                label="Pan No."
                                name="panno"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={5}>
                            <Form.Item
                                label="Province"
                                name="province"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <Input />
                                ) : (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {provinces.map((province, i) => (
                                            <Select.Option
                                                key={i}
                                                value={province.provinceName}
                                            >
                                                {province.provinceName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                            <Form.Item
                                label="District"
                                name="district"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <Input />
                                ) : (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {districts.map((district, i) => (
                                            <Select.Option
                                                key={i}
                                                value={district.districtName}
                                            >
                                                {district.districtName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="VDC/Mun"
                                name="vdcMun"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <Input />
                                ) : (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {municipalities.map(
                                            (municipality, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={
                                                        municipality.municipalName
                                                    }
                                                >
                                                    {municipality.municipalName}
                                                </Select.Option>
                                            )
                                        )}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={3}>
                            <Form.Item
                                label="Ward No."
                                name="wardNo"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Tole"
                                name="tole"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={3}>
                            <Form.Item label="Telephone" name="telephone">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <Form.Item
                                label="Date of Birth"
                                name={`dateOfBirth__${mode}`}
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <input
                                    type="text"
                                    className="ant-input"
                                    onKeyUp={e => onDateKeyUp(e, mode)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Father" name="father">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={5}>
                            <Form.Item label="Education" name="education">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Mobile No."
                                name="mobileNo"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                        {
                                            len: 10,
                                            message: 'Must be 10 digits',
                                        },
                                    ]
                                }
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Citizenship No."
                                name="citizenshipNo"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Caste Group"
                                name="casteGroup"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <Input />
                                ) : (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {casteGroups.map((group, i) => (
                                            <Select.Option
                                                key={i}
                                                value={group.casteGroup}
                                            >
                                                {group.casteGroup}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item
                                label="Caste"
                                name="casteName"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <Input />
                                ) : (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {castes.map((caste, i) => (
                                            <Select.Option
                                                key={i}
                                                value={caste.casteName}
                                            >
                                                {caste.casteName}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6}>
                            <Form.Item label="Religion" name="religion">
                                {mode === 'view' ? (
                                    <Input />
                                ) : (
                                    <Select
                                        placeholder="Select..."
                                        showSearch
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                    >
                                        {religions.map((religion, i) => (
                                            <Select.Option
                                                key={i}
                                                value={religion}
                                            >
                                                {religion}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Form.Item
                                label="Gender"
                                name="gender"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                <Radio.Group>
                                    <Radio value="M">Male</Radio>
                                    <Radio value="F">Female</Radio>
                                    <Radio value="O">Other</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={16}>
                            <Form.Item
                                label="Marital Status"
                                name="maritalStatus"
                                rules={
                                    mode !== 'view' && [
                                        {
                                            required: true,
                                            message: 'Required',
                                        },
                                    ]
                                }
                            >
                                {mode === 'view' ? (
                                    <p>{values?.maritalStatus || '--'}</p>
                                ) : (
                                    <Select placeholder="Select...">
                                        {maritalStatuses?.map((status, i) => (
                                            <Select.Option
                                                key={i}
                                                value={status.maritalStatus}
                                            >
                                                {status.maritalStatus}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={8} lg={3}>
                    <Form.Item label="Add/Change Photo">
                        <div className="avatar">
                            <div className="avatar--pic">
                                <img
                                    src={photoUrl || PhotoPlaceholder}
                                    alt="Profile"
                                />
                            </div>
                            {mode !== 'view' && (
                                <div className="avatar--upload-button">
                                    <input
                                        type="file"
                                        id="photo"
                                        name="photo"
                                        accept={PROFILE_PIC_SUPPORTED_EXT}
                                        onChange={e => onFileChange(e, mode)}
                                    />
                                </div>
                            )}
                        </div>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
});

export default StaffRegistrationForm;
