import React from 'react';
import { Form, Tabs, Row, Col, Select, Image } from 'antd';

const MemberInfoForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        tabPanes,
        onValuesChange,
        viewValues: values,
        memberProperties,
        activePanelKey,
        onPanelChange,
        members,
    } = props;

    return (
        <Form
            ref={ref}
            layout="horizontal"
            onValuesChange={onValuesChange}
            fields={[
                {
                    name: 'memberNO',
                    value: values?.memberNO,
                },
                {
                    name: 'memberName',
                    value: values?.memberName,
                },
            ]}
        >
            <Row gutter={20}>
                <Col span={16}>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Member No"
                                name="memberNO"
                                labelCol={{ span: 5 }}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members?.length &&
                                        members?.map((member, i) => (
                                            <Select.Option
                                                key={i}
                                                value={member.memberNO}
                                            >
                                                {member.memberNO}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Member Name"
                                name="memberName"
                                labelCol={{ span: 6 }}
                            >
                                <Select
                                    placeholder="Select..."
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {members?.length &&
                                        members?.map((member, i) => (
                                            <Select.Option
                                                key={i}
                                                value={member.memberName}
                                            >
                                                {member.memberName}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="Address"
                                name="address"
                                labelCol={{ span: 5 }}
                                labelAlign="left"
                            >
                                {memberProperties?.memAddress}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Contact"
                                name="contactNo"
                                labelCol={{ span: 6 }}
                                labelAlign="left"
                            >
                                {memberProperties?.memContact}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12}>
                            <Form.Item
                                label="KYM Status"
                                labelCol={{ span: 5 }}
                            >
                                {memberProperties?.kymStaus}
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col span={8}>
                    <Image
                        width={100}
                        src={`${process.env.REACT_APP_STATIC_FILES}/${memberProperties?.photoLoc}`}
                    />
                </Col>
            </Row>

            <Tabs activeKey={activePanelKey} onChange={onPanelChange}>
                {tabPanes.map((pane, i) => {
                    return (
                        <Tabs.TabPane tab={pane.title} key={i}>
                            {React.cloneElement(pane.component, props)}
                        </Tabs.TabPane>
                    );
                })}
            </Tabs>
        </Form>
    );
});

export default MemberInfoForm;
