import React from 'react';
import { Form, Tabs } from 'antd';
import { ENG_ISO_TO_NEP, GET_DATEPICKER_CONFIG } from '../../utils/functions';

const OwnerDetailsForm = React.forwardRef((props, ref) => {
    // Destructuring
    const {
        mode,
        tabPanes,
        activePanelKey,
        onPanelChange,
        owners,
        ownerTypes,
        setOwnerTypes,
        members,
        setMembers,
        provinces,
        setProvinces,
        districtsTmp,
        districtsPer,
        municipalitiesTmp,
        municipalitiesPer,
        casteGroups,
        setCasteGroups,
        castes,
        religions,
        setReligions,
        maritalStatuses,
        setMaritalStatuses,
        relations,
        setRelations,
        education,
        setEducation,
        occupation,
        setOccupation,
        groups,
        setGroups,
        areas,
        setAreas,
        staffs,
        setStaffs,
        values,
        onValuesChange,
        onDateChange,
        onDateKeyUp,
        onDateChangeEng,
        handleAddressSameAsPermanent,
        onFileChange,
        ownerNOValidationStatus,
        setDefaultValues,
    } = props;

    // NEPALI DATEPICKER
    // Entry date
    const entryDateInput = document.getElementById(`entryDate__${mode}`);
    entryDateInput &&
        entryDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, entryDateInput, mode)
        );
    // Date of birth
    const dateOfBirthInput = document.getElementById(`dateOfBirth__${mode}`);
    dateOfBirthInput &&
        dateOfBirthInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, dateOfBirthInput, mode)
        );
    // CIT date
    const citDateInput = document.getElementById(`citDate__${mode}`);
    citDateInput &&
        citDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, citDateInput, mode)
        );

    // On component mount
    React.useEffect(() => {
        setDefaultValues(mode);
    }, [setDefaultValues, mode]);
    return (
        <Form
            ref={ref}
            layout="vertical"
            onValuesChange={val => onValuesChange(val, mode)}
            fields={[
                {
                    name: 'area',
                    value: values?.area,
                },
                {
                    name: 'blockNO',
                    value: values?.blockNO,
                },
                {
                    name: 'branchID',
                    value: values?.branchID,
                },
                {
                    name: 'branchName',
                    value: values?.branchName,
                },
                {
                    name: 'casteGroup',
                    value: values?.casteGroup,
                },
                {
                    name: 'caste',
                    value: values?.caste,
                },
                {
                    name: 'citDate',
                    value: values?.citDate,
                },
                {
                    name: 'citDistrict',
                    value: values?.citDistrict,
                },
                {
                    name: 'citOffice',
                    value: values?.citOffice,
                },
                {
                    name: 'citizenLoc',
                    value: values?.citizenLoc,
                },
                {
                    name: 'citizenshipno',
                    value: values?.citizenshipno,
                },
                {
                    name: 'districtName',
                    value: values?.districtName,
                },
                {
                    name: 'districtNepali',
                    value: values?.districtNepali,
                },
                {
                    name: 'education',
                    value: values?.education,
                },
                {
                    name: 'emailID',
                    value: values?.emailID,
                },
                {
                    name: 'gender',
                    value: values?.gender,
                },
                {
                    name: 'grandHeadName',
                    value: values?.grandHeadName,
                },
                {
                    name: 'group',
                    value: values?.group,
                },
                {
                    name: 'groupType',
                    value: values?.groupType,
                },
                {
                    name: 'headName',
                    value: values?.headName,
                },
                {
                    name: 'lastModifiedBy',
                    value: values?.lastModifiedBy,
                },
                {
                    name: 'maritalStatus',
                    value: values?.maritalStatus,
                },
                {
                    name: 'ownerNO',
                    value: values?.ownerNO,
                },
                {
                    name: 'ownerType',
                    value: values?.ownerType,
                },
                {
                    name: 'ownerName',
                    value: values?.ownerName,
                },
                {
                    name: 'mobileNO',
                    value: values?.mobileNO,
                },
                {
                    name: 'motherName',
                    value: values?.motherName,
                },
                {
                    name: 'msid',
                    value: values?.msid,
                },
                {
                    name: 'nomDOB',
                    value: values?.nomDOB,
                },
                {
                    name: 'nomRel',
                    value: values?.nomRel,
                },
                {
                    name: 'nominee',
                    value: values?.nominee,
                },
                {
                    name: 'occupation',
                    value: values?.occupation,
                },
                {
                    name: 'panno',
                    value: values?.panno,
                },
                {
                    name: 'passportLoc',
                    value: values?.passportLoc,
                },
                {
                    name: 'photoLoc',
                    value: values?.photoLoc,
                },
                {
                    name: 'provinceName',
                    value: values?.provinceName,
                },
                {
                    name: 'provinceNepali',
                    value: values?.provinceNepali,
                },
                {
                    name: 'religion',
                    value: values?.religion,
                },
                {
                    name: 'signLoc',
                    value: values?.signLoc,
                },
                {
                    name: 'signLoc',
                    value: values?.signLoc,
                },
                {
                    name: 'staffID',
                    value: values?.staffID,
                },
                {
                    name: 'staffName',
                    value: values?.staffName,
                },
                {
                    name: 'telephone',
                    value: values?.telephone,
                },
                {
                    name: 'tmpBlockNO',
                    value: values?.tmpBlockNO,
                },
                {
                    name: 'tmpDistrict',
                    value: values?.tmpDistrict,
                },
                {
                    name: 'tmpProvince',
                    value: values?.tmpProvince,
                },
                {
                    name: 'tmpTole',
                    value: values?.tmpTole,
                },
                {
                    name: 'tmpVDCMun',
                    value: values?.tmpVDCMun,
                },
                {
                    name: 'tmpWardNO',
                    value: values?.tmpWardNO,
                },
                {
                    name: 'type',
                    value: values?.type,
                },
                {
                    name: 'unDistrict',
                    value: values?.unDistrict,
                },
                {
                    name: 'unFather',
                    value: values?.unFather,
                },
                {
                    name: 'unGrandFather',
                    value: values?.unGrandFather,
                },
                {
                    name: 'unMotherName',
                    value: values?.unMotherName,
                },
                {
                    name: 'unName',
                    value: values?.unName,
                },
                {
                    name: 'unNominee',
                    value: values?.unNominee,
                },
                {
                    name: 'unProvince',
                    value: values?.unProvince,
                },
                {
                    name: 'spouse',
                    value: values?.spouse,
                },
                {
                    name: 'unSpouse',
                    value: values?.unSpouse,
                },
                {
                    name: 'unTole',
                    value: values?.unTole,
                },
                {
                    name: 'unWardNO',
                    value: values?.unWardNO,
                },
                {
                    name: 'untDistrict',
                    value: values?.untDistrict,
                },
                {
                    name: 'untProvince',
                    value: values?.untProvince,
                },
                {
                    name: 'untTole',
                    value: values?.untTole,
                },
                {
                    name: 'untWardNO',
                    value: values?.untWardNO,
                },
                {
                    name: 'untvdc',
                    value: values?.untvdc,
                },
                {
                    name: 'unvdc',
                    value: values?.unvdc,
                },
                {
                    name: 'municipalName',
                    value: values?.municipalName,
                },
                {
                    name: 'municipalNepali',
                    value: values?.municipalNepali,
                },
                {
                    name: 'wardNO',
                    value: values?.wardNO,
                },
                {
                    name: 'tole',
                    value: values?.tole,
                },
                {
                    name: 'memAddress',
                    value: values?.memAddress,
                },
                {
                    name: 'memContact',
                    value: values?.memContact,
                },
                {
                    name: 'memberNO',
                    value: values?.memberNO,
                },
                {
                    name: 'memberName',
                    value: values?.memberName,
                },
                {
                    name: `entryDate__${mode}`,
                    value:
                        values?.entryDate && ENG_ISO_TO_NEP(values.entryDate),
                },
                {
                    name: 'dateOfBirth',
                    value:
                        values?.entryDate && ENG_ISO_TO_NEP(values.entryDate),
                },
                {
                    name: `dateOfBirth__${mode}`,
                    value:
                        values?.dateOfBirth &&
                        ENG_ISO_TO_NEP(values.dateOfBirth),
                },
                {
                    name: `citDate__${mode}`,
                    value: values?.citDate && ENG_ISO_TO_NEP(values.citDate),
                },
            ]}
        >
            <Tabs activeKey={activePanelKey} onChange={onPanelChange}>
                {tabPanes.map((pane, i) => {
                    return (
                        <Tabs.TabPane tab={pane.title} key={i}>
                            {React.cloneElement(pane.component, {
                                mode,
                                owners,
                                ownerTypes,
                                setOwnerTypes,
                                members,
                                setMembers,
                                provinces,
                                setProvinces,
                                districtsTmp,
                                districtsPer,
                                municipalitiesTmp,
                                municipalitiesPer,
                                casteGroups,
                                setCasteGroups,
                                castes,
                                religions,
                                setReligions,
                                values,
                                maritalStatuses,
                                setMaritalStatuses,
                                relations,
                                setRelations,
                                education,
                                setEducation,
                                occupation,
                                setOccupation,
                                groups,
                                setGroups,
                                areas,
                                setAreas,
                                staffs,
                                setStaffs,
                                onDateChange,
                                onDateKeyUp,
                                onDateChangeEng,
                                handleAddressSameAsPermanent,
                                onFileChange,
                                ownerNOValidationStatus,
                            })}
                        </Tabs.TabPane>
                    );
                })}
            </Tabs>
        </Form>
    );
});

export default OwnerDetailsForm;
