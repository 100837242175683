import React from 'react';
import { Form, Row, Col, Select, Table, Button, Space } from 'antd';
import { Link } from 'react-router-dom';
import ReactExport from 'react-export-excel';
import {
    ENG_ISO_TO_NEP,
    GET_DATEPICKER_CONFIG,
} from '../../../../utils/functions';

export default function ItemWiseLedgerForm(props) {
    // Destructuring
    const {
        mode,
        branches,
        viewValues: values,
        onValuesChange,
        mainAccounts,
        subAccounts,
        report,
        isLoading,
        items,
        onDateChange,
        onDateKeyUp,
    } = props;

    // NEPALI DATEPICKER
    // From date
    const fromDateInput = document.getElementById('fromDate');
    fromDateInput &&
        fromDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, fromDateInput, mode)
        );
    // Till date
    const tillDateInput = document.getElementById('tillDate');
    tillDateInput &&
        tillDateInput.nepaliDatePicker(
            GET_DATEPICKER_CONFIG(onDateChange, tillDateInput, mode)
        );

    // DATA SOURCE - START
    const dataSource = report?.length
        ? report.map(item => {
              return {
                  ...item,
                  transDate: ENG_ISO_TO_NEP(item.transDate, 'en'),
              };
          })
        : [];

    const columns = [
        {
            title: 'Journal',
            dataIndex: 'journalNO',
            key: 'journalNO',
            width: '80px',
            render: (text, data) => {
                if (data.journalNO) return <Link to="/">{text}</Link>;
                else
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
            },
        },
        {
            title: 'Date',
            dataIndex: 'transDate',
            key: 'transDate',
            width: '120px',
            render: (text, data) => {
                if (data.journalNO) return <Link to="/">{text}</Link>;
                else
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
            },
        },
        {
            title: 'Particular',
            dataIndex: 'particular',
            key: 'particular',
            render: (text, data) => {
                if (data.journalNO) return <Link to="/">{text}</Link>;
                else
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
            },
        },
        {
            title: 'Dr Amount Rs',
            dataIndex: 'debit',
            key: 'debit',
            width: '120px',
            render: (text, data) => {
                if (data.journalNO) return <Link to="/">{text}</Link>;
                else
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
            },
        },
        {
            title: 'Cr Amount Rs',
            dataIndex: 'credit',
            key: 'credit',
            width: '120px',
            render: (text, data) => {
                if (data.journalNO) return <Link to="/">{text}</Link>;
                else
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
            },
        },
        {
            title: 'Balance Rs',
            dataIndex: 'balance',
            key: 'balance',
            width: '120px',
            render: (text, data) => {
                if (data.journalNO) return <Link to="/">{text}</Link>;
                else
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
            },
        },
        {
            title: 'Doc No',
            dataIndex: 'bvrcno',
            key: 'bvrcno',
            width: '120px',
            render: (text, data) => {
                if (data.journalNO) return <Link to="/">{text}</Link>;
                else
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
            },
        },
        {
            title: 'Remarks',
            dataIndex: 'receivedPaidBy',
            key: 'receivedPaidBy',
            width: '120px',
            render: (text, data) => {
                if (data.journalNO) return <Link to="/">{text}</Link>;
                else
                    return (
                        <div className="ant-table-cell-link">
                            <strong>{text}</strong>
                        </div>
                    );
            },
        },
    ];
    // DATA SOURCE - END

    return (
        <>
            <Form
                layout="vertical"
                onValuesChange={onValuesChange}
                // onFinish={onFormSubmit}
                fields={[
                    {
                        name: 'fromDate',
                        value:
                            values?.fromDate && ENG_ISO_TO_NEP(values.fromDate),
                    },
                    {
                        name: 'tillDate',
                        value:
                            values?.tillDate && ENG_ISO_TO_NEP(values.tillDate),
                    },
                    {
                        name: 'branchID',
                        value: values?.branchID,
                    },
                    {
                        name: 'branchName',
                        value: values?.branchName,
                    },
                    {
                        name: 'level',
                        value: values?.level,
                    },
                    {
                        name: 'mano',
                        value: values?.mano,
                    },
                    {
                        name: 'maName',
                        value: values?.maName,
                    },
                    {
                        name: 'acno',
                        value: values?.acno,
                    },
                    {
                        name: 'acName',
                        value: values?.acName,
                    },
                    {
                        name: 'showSubTotals',
                        value: values?.showSubTotals,
                    },
                    {
                        name: 'hideZeros',
                        value: values?.hideZeros,
                    },
                    {
                        name: 'itemName',
                        value: values?.itemName,
                    },
                    {
                        name: 'itemCode',
                        value: values?.itemCode,
                    },
                ]}
            >
                <Row gutter={20}>
                    <Col span={4}>
                        <Form.Item label="From" name="fromDate">
                            <input
                                type="text"
                                className="ant-input"
                                onKeyUp={e => onDateKeyUp(e, mode)}
                            />
                        </Form.Item>
                        <Form.Item label="Till" name="tillDate">
                            <input
                                type="text"
                                className="ant-input"
                                onKeyUp={e => onDateKeyUp(e, mode)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Row gutter={20}>
                            <Col span={14}>
                                <Form.Item
                                    label="Ledger Selection"
                                    name="maName"
                                >
                                    <Select placeholder="Select...">
                                        {mainAccounts.length &&
                                            mainAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.maName}
                                                >
                                                    {item.maName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label=" " name="mano">
                                    <Select placeholder="Select...">
                                        {mainAccounts.length &&
                                            mainAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.mano}
                                                >
                                                    {item.mano}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={14}>
                                <Form.Item name="acName">
                                    <Select placeholder="Select...">
                                        {subAccounts.length &&
                                            subAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.acName}
                                                >
                                                    {item.acName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label="" name="acno">
                                    <Select placeholder="Select...">
                                        {subAccounts.length &&
                                            subAccounts.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.acno}
                                                >
                                                    {item.acno}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={14}>
                                <Form.Item name="itemName">
                                    <Select placeholder="Select...">
                                        {items.length &&
                                            items.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.itemName}
                                                >
                                                    {item.itemName}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label="" name="itemCode">
                                    <Select placeholder="Select...">
                                        {items.length &&
                                            items.map((item, i) => (
                                                <Select.Option
                                                    key={i}
                                                    value={item.itemCode}
                                                >
                                                    {item.itemCode}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={2}>
                        <Form.Item label="Branch ID" name="branchID">
                            <Select placeholder="Select...">
                                <Select.Option value="all">All</Select.Option>
                                {branches?.length &&
                                    branches.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.branchID}
                                        >
                                            {item.branchID}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Branch Name" name="branchName">
                            <Select placeholder="Select...">
                                <Select.Option value="all">All</Select.Option>
                                {branches?.length &&
                                    branches.map((item, i) => (
                                        <Select.Option
                                            key={i}
                                            value={item.branchName}
                                        >
                                            {item.branchName}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space style={{ marginTop: '1.5rem' }}>
                            <Button size="small">Print</Button>
                            <Excel data={dataSource} />
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={dataSource}
                columns={columns}
                className="ant-table-wrapper--collapsed ant-table-wrapper--link"
                pagination={false}
                scroll={{ y: 500 }}
                loading={isLoading}
            />
        </>
    );
}

export function Excel({ data }) {
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
        <ExcelFile
            element={<Button size="small">Excel</Button>}
            filename="Item Wise Ledger"
        >
            <ExcelSheet data={data} name="Item Wise Ledger">
                <ExcelColumn label="Journal" value="journalNO" />
                <ExcelColumn label="Date" value="transDate" />
                <ExcelColumn label="Particular" value="particular" />
                <ExcelColumn label="Dr Amount Rs" value="debit" />
                <ExcelColumn label="Dr. Amount Rs" value="credit" />
                <ExcelColumn label="Balance Rs" value="balance" />
                <ExcelColumn label="Doc No" value="bvrcno" />
                <ExcelColumn label="Remarks" value="receivedPaidBy" />
            </ExcelSheet>
        </ExcelFile>
    );
}
