import React from 'react';
import { message, Modal, Button } from 'antd';
// Import utils
import axios from '../../utils/axios';
import { UPDATE_AXIOS_AUTH } from '../../utils/functions';
// Import components
import {
    default as GroupTypeFormView,
    default as GroupTypeFormEdit,
} from '../../components/GroupTypeForm/GroupTypeForm';
import PageWrapper from '../../components/Common/PageWrapper/PageWrapper';
import BottomActionBarFixed from '../../components/Common/BottomActionBarFixed/BottomActionBarFixed';

export default function GroupType() {
    // Local states
    const [groupTypes, setGroupTypes] = React.useState([]);
    const [viewFormValues, setViewFormValues] = React.useState(null);
    const [editFormValues, setEditFormValues] = React.useState(null);
    const [addFormValues, setAddFormValues] = React.useState(null);
    const [editModal, setEditModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [addModal, setAddModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });
    const [deleteModal, setDeleteModal] = React.useState({
        visible: false,
        confirmLoading: false,
    });

    // Refs
    const formRef = React.useRef();

    // Get group details by group id
    const getGroupDetails = gtid => {
        axios
            .get(
                `${process.env.REACT_APP_HOST}/api/GroupType/GroupTypeDetail/${gtid}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                console.log(res.data.data);
                setViewFormValues(res.data.data);
            });
    };

    // VIEW FORM
    const onViewValuesChange = val => {
        if (val.hasOwnProperty('gtid')) {
            const group = groupTypes.find(group => group.gtid === val.gtid);
            getGroupDetails(group.gtid);
            setViewFormValues(prevValues => ({
                ...prevValues,
                groupType: group.groupType,
            }));
        } else if (val.hasOwnProperty('groupType')) {
            const group = groupTypes.find(
                group => group.gtid === val.groupType
            );
            getGroupDetails(group.gtid);
            setViewFormValues(prevValues => ({
                ...prevValues,
                gtid: group.gtid,
            }));
        }
    };

    // EDIT ACCOUNT MODAL
    // On edit modal open
    const onEditModalOpen = () => {
        setEditFormValues(viewFormValues);
        setEditModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On edit modal cancel
    const onEditModalCancel = () => {
        setEditModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // On edit modal ok
    const onEditModalOk = () => {
        formRef.current.validateFields().then(() => {
            setEditModal(prevValues => ({
                ...prevValues,
                confirmLoading: true,
            }));
            axios
                .patch(
                    `${process.env.REACT_APP_HOST}/api/GroupType/UpdateGroupType/${editFormValues.gtid}`,
                    editFormValues
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    setViewFormValues(editFormValues);
                    setEditModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                    setEditFormValues(null);
                })
                .catch(err => {
                    console.error(err, 'Failed to update group type account');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setEditModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };

    // On edit form values change
    const onEditValuesChange = val => {
        setEditFormValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // On edit form checkbox change
    const onEditCheckboxChange = e => {
        const key = e.target.name;
        const checked = e.target.checked;
        setEditFormValues(prevValues => ({
            ...prevValues,
            [key]: checked,
        }));
    };

    // ADD ACCOUNT MODAL
    // On edit modal open
    const onAddModalOpen = () => {
        setAddFormValues(null);
        setAddModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };

    // On add modal cancel
    const onAddModalCancel = () => {
        setAddModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // On edit modal ok
    const onAddModalOk = () => {
        formRef.current.validateFields().then(() => {
            setAddModal(prevValues => ({
                ...prevValues,
                confirmLoading: false,
            }));
            axios
                .post(
                    `${process.env.REACT_APP_HOST}/api/GroupType/AddGroupType`,
                    addFormValues
                )
                .then(res => {
                    if (res.data.statusCode !== 0)
                        return message.error(res.data.statusMessage);
                    setViewFormValues(res.data.data);
                    setAddModal(prevValues => ({
                        ...prevValues,
                        visible: false,
                    }));
                })
                .catch(err => {
                    console.error(err, 'Failed to add group type account');
                    message.error('Something went wrong. Please try again!');
                })
                .finally(() => {
                    setAddModal(prevValues => ({
                        ...prevValues,
                        confirmLoading: false,
                    }));
                });
        });
    };

    // On add form values change
    const onAddValuesChange = val => {
        setAddFormValues(prevValues => ({
            ...prevValues,
            ...val,
        }));
    };

    // On add form checkbox change
    const onAddCheckboxChange = e => {
        const key = e.target.name;
        const checked = e.target.checked;
        setAddFormValues(prevValues => ({
            ...prevValues,
            [key]: checked,
        }));
    };

    // DELETE ACCOUNT MODAL
    // On delete modal open
    const onDeleteModalOpen = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: true,
        }));
    };
    // On delete modal ok
    const onDeleteModalOk = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            confirmLoading: true,
        }));
        axios
            .delete(
                `${process.env.REACT_APP_HOST}/api/GroupType/DeleteGroupType/${viewFormValues.gtid}`
            )
            .then(res => {
                if (res.data.statusCode !== 0)
                    return message.error(res.data.statusMessage);
                const groupTypesUpdated = [...groupTypes].filter(
                    group => group.gtid !== viewFormValues.gtid
                );
                setViewFormValues(null);
                setGroupTypes(groupTypesUpdated);
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    visible: false,
                }));
            })
            .finally(() => {
                setDeleteModal(prevValues => ({
                    ...prevValues,
                    confirmLoading: false,
                }));
            });
    };

    // On delete modal cancel
    const onDeleteModalCancel = () => {
        setDeleteModal(prevValues => ({
            ...prevValues,
            visible: false,
        }));
    };

    // On component mount
    React.useEffect(() => {
        UPDATE_AXIOS_AUTH();
        // Get group types
        axios.get(`${process.env.REACT_APP_HOST}/api/GroupType`).then(res => {
            if (res.data.statusCode !== 0)
                return message.error(res.data.statusMessage);
            console.log(res.data.data);
            setGroupTypes(res.data.data);
        });
    }, []);
    return (
        <PageWrapper>
            <GroupTypeFormView
                ref={formRef}
                mode="view"
                groupTypes={groupTypes}
                values={viewFormValues}
                onValuesChange={onViewValuesChange}
            />
            {/* Edit account modal */}
            <Modal
                title="Edit account"
                visible={editModal.visible}
                confirmLoading={editModal.confirmLoading}
                onOk={onEditModalOk}
                onCancel={onEditModalCancel}
                okText="Update"
                cancelButtonProps={{
                    disabled: editModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <GroupTypeFormEdit
                    ref={formRef}
                    mode="edit"
                    groupTypes={groupTypes}
                    values={editFormValues}
                    onValuesChange={onEditValuesChange}
                    onCheckboxChange={onEditCheckboxChange}
                />
            </Modal>
            {/* Add account modal */}
            <Modal
                title="Add an account"
                visible={addModal.visible}
                confirmLoading={addModal.confirmLoading}
                onOk={onAddModalOk}
                onCancel={onAddModalCancel}
                okText="Create"
                cancelButtonProps={{
                    disabled: addModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
                width={1000}
            >
                <GroupTypeFormEdit
                    ref={formRef}
                    mode="add"
                    groupTypes={groupTypes}
                    values={addFormValues}
                    onValuesChange={onAddValuesChange}
                    onCheckboxChange={onAddCheckboxChange}
                />
            </Modal>
            {/* Delete account modal */}
            <Modal
                title="Delete account"
                visible={deleteModal.visible}
                confirmLoading={deleteModal.confirmLoading}
                onOk={onDeleteModalOk}
                onCancel={onDeleteModalCancel}
                okText="Delete"
                cancelButtonProps={{
                    disabled: deleteModal.confirmLoading,
                }}
                destroyOnClose
                maskClosable={false}
            >
                <p>The account and its data will be deleted forever</p>
            </Modal>
            {/* Actions bar */}
            <BottomActionBarFixed>
                <Button
                    type="primary"
                    onClick={onEditModalOpen}
                    disabled={!viewFormValues}
                >
                    Edit
                </Button>
                <Button type="primary" onClick={onAddModalOpen}>
                    Add
                </Button>
                <Button
                    type="primary"
                    onClick={onDeleteModalOpen}
                    disabled={!viewFormValues}
                >
                    Delete
                </Button>
            </BottomActionBarFixed>
        </PageWrapper>
    );
}
